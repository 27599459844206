import React from 'react';
import ReactSelect, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';

export type Option = {
  value: string;
  label: string;
};

const optionStyles = {
  fontFamily: 'SFProDisplay',
  fontSize: 14,
  fontWeight: '600',
  lineHeight: '18px',
  color: '#2d2d2d',
  textTransform: 'normal',
};

function getStyles(styles) {
  return {
    control: (_, state) => ({
      display: 'flex',
      background: '#F4F4F6',
      borderRadius: 16,
      minWidth: 250,
      height: 40,
      border: state.isFocused ? '1px solid #3901f1' : 0,
      ...styles?.control?.(),
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: '0 0 5px #E8E8E8',
      borderRadius: 6,
      overflow: 'hidden',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '18px',
      color: '#6B6B6B',
      ...styles?.singleValue?.(),
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menuList: (provided) => ({
      ...provided,
      ...optionStyles,
      background: 'white',
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #E8E8E8',
      background: state.isSelected ? '#2d2d2d' : '#fff',
      color: state.isSelected ? 'white' : '#2d2d2d',
      cursor: 'pointer',
      '&:hover': {
        background: state.isSelected ? '#2d2d2d' : '#eee',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'black',
    }),
    groupHeading: (provided) => ({
      ...provided,
      ...optionStyles,
      borderBottom: '1px solid #E8E8E8',
      paddingBottom: '0.5rem',
      marginBottom: 0,
    }),
    group: (provided) => ({
      ...provided,
      ...optionStyles,
      borderBottom: '1px solid #E8E8E8',
      fontWeight: '400',
      fontSize: 12,
      paddingBottom: 0,

      'div > div': {
        opacity: 0.7,
        paddingLeft: '1.5rem',
      },

      'div > div:last-child': {
        border: 0,
      },
    }),
  };
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator style={{ padding: 10 }} {...props}>
      <FontAwesomeIcon icon={faSort} />
    </components.DropdownIndicator>
  );
}

export function Select(props) {
  const { styles = {}, ...rest } = props;

  return <ReactSelect components={{ DropdownIndicator }} styles={getStyles(styles)} {...rest} />;
}
