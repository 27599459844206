import styled from '@emotion/styled';
import React, { PropsWithChildren, useRef } from 'react';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { Form, Modal as BModal, Button as BButton } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';

import { CustomButton } from '@components/Button/CustomButton';
import { Drawer, MainDrawerHandles } from '@pages/Workflows/components/Drawer';
import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import { Button } from '@pages/Workflows/components/Button';
import Edit from '@assets/icons/edit-dark.svg';

import BaseNode, { NodeProps } from '../BaseNode';
import { EditPercentage } from './EditPercentage';

interface ApplyCouponStepProps extends NodeProps {
  percentage: number;
  enabled?: boolean;
}

interface ApplyCouponNodeProps {
  data: ApplyCouponStepProps;
  disabled?: boolean;
  showEnabledToggle?: boolean;
}

export const ApplyCouponNode = (props: PropsWithChildren<ApplyCouponNodeProps>) => {
  const { children, data, disabled, showEnabledToggle } = props;
  const { handleChangeWorkflowStep } = useWorkflowsContext();
  const drawerRef = useRef<MainDrawerHandles>(null);

  const formProps = useForm({
    shouldUnregister: false,
    defaultValues: {
      percentage: data?.percentage || 0,
    },
  });
  const { handleSubmit, getValues, reset } = formProps;

  function handleToggleModal() {
    drawerRef.current?.toggleDrawer();
  }

  function handleSave(formData) {
    handleChangeWorkflowStep(Number(data.id), formData, true);
    toast.success('Coupon Code successfully saved!');
    handleToggleModal();
  }

  function handleCancel() {
    reset();
    handleToggleModal();
  }

  function handleEnabledChange() {
    const values = getValues();
    handleChangeWorkflowStep(Number(data.id), { ...values, enabled: !data.enabled });
  }

  return (
    <>
      <BaseNode
        icon={faTag}
        color="#138D75"
        titleComponent={
          <FormCheck
            id="discount.enabled"
            type="switch"
            className="ml-2"
            disabled={disabled}
            defaultChecked={data.enabled}
            onChange={handleEnabledChange}
          />
        }
        showTitleComponent={showEnabledToggle}
        bodyComponent={
          <EditButton disabled={disabled} onClick={handleToggleModal}>
            {data.percentage}% OFF <img alt="icon" src={Edit} />
          </EditButton>
        }
        {...data}
        showCollapse={!!children}
        collapseComponent={children}
      />

      <Drawer
        ref={drawerRef}
        headerComponent={<BModal.Title>Edit Coupon Code</BModal.Title>}
        footerComponent={
          <>
            <BButton variant="outline-secondary" onClick={handleCancel}>
              Close
            </BButton>
            <BButton variant="secondary" onClick={handleSubmit(handleSave)}>
              Confirm
            </BButton>
          </>
        }
      >
        <FormProvider {...formProps}>
          <EditPercentage />
        </FormProvider>
      </Drawer>
    </>
  );
};

const EditButton = styled(Button)`
  width: 150px;
  justify-content: center;
  opacity: ${(props) => (props?.disabled ? '0.7' : '1')};

  img {
    margin-left: 5px;
  }
`;

const FormCheck = styled(Form.Check)`
  opacity: ${(props) => (props?.disabled ? '0.7' : '1')};

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &:after {
          background-color: #099b81;
          box-shadow: 0px 1px 4px #099b8144, inset 0 0 0 1px #099b81, inset 0 0 0 2px #ffffff;
        }
      }
    }
  }
`;
