import React from 'react';
import styled from '@emotion/styled';

export function Button({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

const Container = styled.button`
  align-items: center;
  background: #ffffff;
  border-radius: 2px;
  border: 0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  max-width: 200px;
  padding: 8px 20px;

  &:hover {
    filter: brightness(98%);
  }
`;
