import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { MultipleSelect } from '@components/Input/MultipleSelect';
import VariantListItem from '@pages/Products/SwapGroups/components/VariantListItem';

import axios from '@lib/axios';
import { ProductVariant } from '@typings/ProductVariant';
import { PaginationInfo } from '@typings/PaginationInfo';

interface TemplateMessageVariantsProps {
  variants: ProductVariant[];
}

interface VariantPage {
  data: ProductVariant[];
  paginationInfo: PaginationInfo;
}

const TemplateMessageVariants = ({ variants }: TemplateMessageVariantsProps) => {
  const { setValue } = useFormContext();
  const [errors, setErrors] = useState(null);
  const [variantPage, setVariantPage] = useState<VariantPage>();
  const [selectedVariants, setSelectedVariants] = useState(variants || []);

  const handleChange = () => {
    const productVariantsIDs = selectedVariants.map((variant) => variant.id);

    setValue('product_variant_ids', productVariantsIDs);
  };

  const searchVariants = async (search, page) => {
    try {
      const { data } = await axios.get('/sms/product_variants/search', {
        params: {
          search,
          page,
          limit: 5,
        },
      });

      setVariantPage(data);
    } catch (e) {
      setErrors('It was not possible to load variants.');
    }
  };

  useEffect(() => {
    handleChange();
  }, [selectedVariants.length]);

  return (
    <Card className="mt-3">
      <Card.Body>
        <MultipleSelect
          label="Select Product Variants"
          paginationInfo={variantPage?.paginationInfo}
          placeholder="Search by Variant or Product Info"
          onSearch={searchVariants}
          options={variantPage?.data}
          onSelectedOptionsChange={setSelectedVariants}
          renderOption={(option, optionProps) => (
            <VariantListItem variant={option} {...optionProps} />
          )}
          selectedOptions={selectedVariants}
        />
        {errors && <p className="text-danger mt-3">{errors}</p>}
      </Card.Body>
    </Card>
  );
};

export default TemplateMessageVariants;
