import { Shop } from '@typings/Shop';
import React, { useContext } from 'react';
import { Refetch } from './useFetch';

type ShopContextValues = Shop & {
  refetch: Refetch;
};

export const ShopContext = React.createContext<ShopContextValues>(undefined);

export function useCurrentShop() {
  return useContext(ShopContext);
}
