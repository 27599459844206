import React from 'react';

import { CustomerInfo } from '@components/Table/Row/CustomerInfo';
import { formatString } from '@utils/date';

import { DashboardViewPage } from './DashboardViewPage';

function Skips() {
  const tableHeaders = [
    {
      label: 'Customer',
      width: '32%',
    },
    {
      label: 'First order date',
    },
    {
      label: 'Skip request date',
    },
    {
      label: 'Expected charge skipped',
    },
    {
      label: 'Next charge date',
    },
  ];

  function tableRow(skippedOrder) {
    const data = [
      <CustomerInfo customer={skippedOrder.rechargeCustomer} />,
      <p>{formatString(skippedOrder.rechargeCustomer.createdAt, 'dd MMM hh:mm')}</p>,
      <p>{formatString(skippedOrder.skippedAt, 'dd MMM hh:mm')}</p>,
      <p>{formatString(skippedOrder.previousChargeDate, 'dd MMM hh:mm')}</p>,
      <p>{formatString(skippedOrder.nextChargeDate, 'dd MMM hh:mm')}</p>,
    ];

    return { data };
  }

  return (
    <DashboardViewPage
      baseUrl="/sms/recharge/skip_orders"
      headerDescription="Orders skipped"
      tableHeaders={tableHeaders}
      tableRow={tableRow}
      csvFilename="skipped-orders"
    />
  );
}

export default Skips;
