import React from 'react';
import styled from '@emotion/styled';

import { CustomButton } from '@components/Button/CustomButton';

import { Filter } from './Filter';

export function CustomerFilters() {
  return (
    <>
      <Container>
        <Label>
          Select additional filters to customize which customers will receive this discounted charge
          reminder:
        </Label>
        <Filter key="upcoming_charge_value" type="upcoming_charge_value" />
        <Filter key="total_orders" type="total_orders" maskOptions={{ prefix: '' }} />
        <Filter key="total_charge_amount" type="total_charge_amount" />
      </Container>
      <ButtonContainer>
        <CustomButton type="submit">Save Filters</CustomButton>
      </ButtonContainer>
    </>
  );
}

const Container = styled.div`
  padding: 1rem;
  padding-top: 0;
`;

const Label = styled.span`
  display: block;
  font-weight: 600;
  margin: 10px 0;
  font-size: 8px;
  color: #8a8a8a;
`;

const ButtonContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #dbdbdb;
`;
