import React from 'react';
import styled from '@emotion/styled';

import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';

interface TemplateProps {
  type: string;
  label: string;
}

const Template = ({ type, label }: TemplateProps) => {
  const { handleSelectTemplate } = useWorkflowsContext();

  return (
    <Container onClick={() => handleSelectTemplate(type)}>
      <Label>{label}</Label>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: ${(props) => props.theme.colors.light1};
  cursor: pointer;
  box-shadow: ${(props) => `0px 0px 4px ${props.theme.colors.dark0}44`};
  border-radius: 6px;
  margin-top: 10px;

  &:hover {
    filter: brightness(98%);
  }
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  margin: 0;
`;

export default Template;
