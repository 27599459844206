import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { Repeat } from 'react-feather';
import './multiple_select.scss';
import { Paginator, PaginationInfoPropTypes } from '@components/Paginator';

const callSearch = async (searchParams, handleSearch) => {
  await handleSearch(searchParams);
};

const debouncedSearch = debounce(callSearch, 500);

export type MultipleSelectItemAction = 'add' | 'remove';

type Option = { id: number | string };

interface MultipleSelectProps<T extends Option> {
  className?: string;
  disabled?: boolean;
  label: string | React.ReactNode;
  icon?: SvgComponent;
  initialSearch?: boolean;
  options: T[];
  paginationInfo: PaginationInfoPropTypes;
  placeholder?: string;
  selectedOptions: T[];
  onSearch(query: string, page: number): Promise<void>;
  onSelectedOptionsChange(selectedOptions: T[]): void;
  renderOption?(option: any, props: RenderItemProps): React.ReactNode;
}

interface RenderItemProps {
  selected?: boolean;
  action: 'add' | 'remove';
}

export const MultipleSelect = <T extends Option>(props: MultipleSelectProps<T>) => {
  const {
    className,
    disabled,
    label,
    options = [],
    paginationInfo,
    placeholder = 'Type to search',
    initialSearch = true,
    onSearch,
    renderOption = (option) => <>{option}</>,
    onSelectedOptionsChange = () => null,
    selectedOptions,
    icon = Repeat as SvgComponent,
  } = props;
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const selectedIds = selectedOptions.map((option) => option.id);

  const handleSearch = async (searchParams) => {
    setLoading(true);

    await onSearch(searchParams.query, searchParams.page);

    setLoading(false);
  };

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch({ query: target.value }, handleSearch);
  };

  const handlePageChange = (page) => {
    handleSearch({ query: search, page });
  };

  const onSelectOption = (selectedOption) => () => {
    if (!includes(selectedIds, selectedOption.id)) {
      onSelectedOptionsChange([selectedOption, ...selectedOptions]);
    }
  };

  const onRemoveOption = (optionToRemove) => () => {
    const newSelectedOptions = filter(selectedOptions, (option) => option.id !== optionToRemove.id);

    onSelectedOptionsChange(newSelectedOptions);
  };

  useEffect(() => {
    if (initialSearch) {
      callSearch({ query: '' }, handleSearch);
    }
  }, []);

  const Icon = icon;

  return (
    <Root className={cx('multiple-select', className)} disabled={disabled}>
      <p>{label}</p>
      <Row>
        <Col xs={5}>
          <div className="multiple-select__col">
            <Form.Group controlId="recharge_customers_search">
              <Form.Control
                name="search"
                placeholder={placeholder}
                value={search}
                onChange={onSearchChange}
              />
            </Form.Group>
            <div>
              {loading ? (
                <Row className="no-gutters justify-content-center mt-5">
                  <Col className="col-auto">
                    <Spinner animation="border" variant="primary" />
                  </Col>
                </Row>
              ) : (
                <ListGroup>
                  {options?.map((option) => {
                    const selected = selectedIds.includes(option.id);
                    return (
                      <ListGroup.Item
                        key={option.id}
                        as="div"
                        action={!selected}
                        disabled={disabled}
                        onClick={onSelectOption(option)}
                        style={{ cursor: !disabled && !selected && 'pointer' }}
                      >
                        {renderOption(option, { selected, action: 'add' })}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              )}
            </div>
          </div>
          <div className="multiple-select__paginator-area">
            <Paginator paginationInfo={paginationInfo} onPageChange={handlePageChange} />
          </div>
        </Col>
        <Col xs={2} className="multiple-select__middle-column">
          <Row>
            <Col className="col-auto">
              <Icon width={68} height={68} />
            </Col>
          </Row>
        </Col>
        <Col xs={5}>
          <div className="multiple-select__col">
            <ListGroup variant="flush">
              {selectedOptions.map((option) => (
                <ListGroup.Item
                  key={option.id}
                  as="div"
                  action
                  disabled={disabled}
                  onClick={onRemoveOption(option)}
                  style={{ cursor: !disabled && 'pointer' }}
                >
                  {renderOption(option, { action: 'remove' })}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Col>
      </Row>
    </Root>
  );
};

interface RootProps {
  disabled?: boolean;
}

const Root = styled.div<RootProps>`
  opacity: ${(props) => props.disabled && 0.4};
`;
