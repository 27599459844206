import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';

import { Action } from '@components/Table/Row/Action';
import { ConfirmationModal, ModalHandles } from '@components/Modal/ConfirmationModal';
import { CancellationReason } from '@typings/CancellationReason';

interface ActionsProps {
  cancellationReason: CancellationReason;
  handleDelete(id: number): void;
}

const BASE_URL = '/sms/cancellation_reasons';

export function Actions(props: ActionsProps) {
  const { cancellationReason, handleDelete } = props;
  const modalRef = useRef<ModalHandles>(null);

  function toggleModal() {
    modalRef?.current?.toggleModal();
  }

  return (
    <Container>
      <Action
        icon={faPen}
        color="#078FEE"
        href={`${BASE_URL}/${cancellationReason.id}/edit`}
        text="Edit"
      />
      <Action icon={faTrashAlt} color="red" onClick={toggleModal} text="Delete" />
      <ConfirmationModal
        ref={modalRef}
        title={cancellationReason.name}
        description="Are you sure you want to delete this reason?"
        onConfirm={() => handleDelete(cancellationReason.id)}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;
