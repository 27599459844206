import React from 'react';
import Form from 'react-bootstrap/Form';
import { ErrorMessage as ErrorMessageComponent } from '@hookform/error-message';

export function ErrorMessage(props) {
  return (
    <ErrorMessageComponent
      {...props}
      render={({ message }) => <Form.Text className="text-danger">{message}</Form.Text>}
    />
  );
}
