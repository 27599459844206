import React from 'react';

import Home from '@pages/Home';
import Simulator from '@pages/Dashboard/Simulator';
import Overview from '@pages/Dashboard/Overview';
import Skips from '@pages/Dashboard/Skips';
import Swaps from '@pages/Dashboard/Swaps';
import EndedSubscriptions from '@pages/Dashboard/EndedSubscriptions';
// Customers
import CustomersView from '@pages/Customers/Customers/View';
import CustomersShow from '@pages/Customers/Customers/Show';
import CustomersEdit from '@pages/Customers/Customers/Edit';
import SegmentsView from '@pages/Customers/Segments/View';
import SegmentsNew from '@pages/Customers/Segments/New';
import SegmentsShow from '@pages/Customers/Segments/Show';
import SegmentsEdit from '@pages/Customers/Segments/Edit';
// Messages
import TemplateMessagesView from '@pages/Messages/TemplateMessages/View';
import TemplateMessagesEdit from '@pages/Messages/TemplateMessages/Edit';
import CustomTemplateMessagesView from '@pages/Messages/CustomTemplateMessages/View';
import CustomKeywordsView from '@pages/Messages/CustomKeywords/View';
import CustomKeywordsNew from '@pages/Messages/CustomKeywords/New';
import CustomKeywordsEdit from '@pages/Messages/CustomKeywords/Edit';
import CancellationReasonsView from '@pages/Messages/CancellationReasons/View';
import CancellationReasonsNew from '@pages/Messages/CancellationReasons/New';
import CancellationReasonsEdit from '@pages/Messages/CancellationReasons/Edit';
// Products
import ProductVariantsView from '@pages/Products/ProductVariants/View';
import ProductVariantsEdit from '@pages/Products/ProductVariants/Edit';
import ArchivedView from '@pages/Products/Archived/View';
import SwapGroupsView from '@pages/Products/SwapGroups/View';
import SwapGroupsNew from '@pages/Products/SwapGroups/New';
import SwapGroupsEdit from '@pages/Products/SwapGroups/Edit';
// Settings
import Features from '@pages/Settings/Features';
import Advanced from '@pages/Settings/Advanced';
import Help from '@pages/Settings/Help';
import Widgets from '@pages/Settings/Widgets';
// Workflows
import WorkflowsView from '@pages/Workflows/View';
import { FeatureOnboardingView } from '@components/Onboarding/v2/View/FeatureOnboardingView';
import { WelcomeOnboardingView } from '@components/Onboarding/v2/View/WelcomeOnboardingView';
import { ProductOnboardingView } from '@components/Onboarding/v2/View/ProductOnboardingView';
import { ActivationOnboardingView } from '@components/Onboarding/v2/View/ActivationOnboardingView';
// Email
import Emails from '@pages/Settings/Emails/Emails';
import ChargeReminder from '@pages/Settings/Emails/ChargeReminder';
import OptOutConfirmation from '@pages/Settings/Emails/OptOutConfirmation';
import UnreachablePhoneNumber from '@pages/Settings/Emails/UnreachablePhoneNumber';

// We should make this more generic/convention based so we don't have to add a route every time a new page adds.
// See next/nuxt configuration by folder.
const componentsByPath = {
  // Dashboard
  '/': Home,
  '/sms': Home,
  '/sms/simulator': Simulator,
  '/sms/overview': Overview,
  '/sms/recharge/skip_orders': Skips,
  '/sms/recharge/product_swaps': Swaps,
  '/sms/recharge/ended_subscriptions': EndedSubscriptions,
  // Customers
  '/sms/recharge/customers': CustomersView,
  '/sms/recharge/customers/:id': CustomersShow,
  '/sms/recharge/customers/:id/edit': CustomersEdit,
  '/sms/segments': SegmentsView,
  '/sms/segments/new': SegmentsNew,
  '/sms/segments/:id': SegmentsShow,
  '/sms/segments/:id/edit': SegmentsEdit,
  // Messages
  '/sms/template_messages': TemplateMessagesView,
  '/sms/template_messages/custom': CustomTemplateMessagesView,
  '/sms/template_messages/:id/edit': TemplateMessagesEdit,
  '/sms/message_custom_keywords': CustomKeywordsView,
  '/sms/message_custom_keywords/new': CustomKeywordsNew,
  '/sms/message_custom_keywords/:id/edit': CustomKeywordsEdit,
  '/sms/cancellation_reasons': CancellationReasonsView,
  '/sms/cancellation_reasons/new': CancellationReasonsNew,
  '/sms/cancellation_reasons/:id/edit': CancellationReasonsEdit,
  // Products
  '/sms/product_variants': ProductVariantsView,
  '/sms/product_variants/:id/edit': ProductVariantsEdit,
  '/sms/product_variants/archived': ArchivedView,
  '/sms/swap_groups': SwapGroupsView,
  '/sms/swap_groups/new': SwapGroupsNew,
  '/sms/swap_groups/:id/edit': SwapGroupsEdit,
  '/sms/quantity_groups': SwapGroupsView,
  '/sms/quantity_groups/new': SwapGroupsNew,
  '/sms/quantity_groups/:id/edit': SwapGroupsEdit,
  // Settings
  '/sms/settings': Features,
  '/sms/settings/advanced': Advanced,
  '/sms/settings/email': Emails,
  '/sms/settings/email/charge_reminder': ChargeReminder,
  '/sms/settings/email/opt_out': OptOutConfirmation,
  '/sms/settings/email/unreachable_phone': UnreachablePhoneNumber,
  '/sms/settings/help': Help,
  '/sms/widgets': Widgets,
  // Workflows
  '/sms/workflows': WorkflowsView,

  '/sms/onboarding/welcome': WelcomeOnboardingView,
  '/sms/onboarding/features': FeatureOnboardingView,
  '/sms/onboarding/product': ProductOnboardingView,
  '/sms/onboarding/activation': ActivationOnboardingView,
};

const propsByPath = {
  '/sms/swap_groups': { groupType: 'swap' },
  '/sms/swap_groups/new': { groupType: 'swap' },
  '/sms/quantity_groups': { groupType: 'quantity' },
  '/sms/quantity_groups/new': { groupType: 'quantity' },
};

export const routes = Object.keys(componentsByPath).map((path) => {
  const Element = componentsByPath[path];
  const props = propsByPath[path] || {};

  return {
    path,
    element: <Element {...props} />,
  };
});

export const routesPaths = Object.keys(componentsByPath);
