import styled from '@emotion/styled';
import React from 'react';

interface PageTitleProps {
  title?: string;
  subtitle?: string;
  addon?: React.ReactNode;
}

export function PageTitle(props: PageTitleProps) {
  const { addon, subtitle, title } = props;
  return (
    <Root>
      {title && (
        <Row>
          <Title>{title}</Title>
          <SeparatorLine />
        </Row>
      )}
      <Row>
        <Subtitle>{subtitle}</Subtitle>
        <Addons>{addon}</Addons>
      </Row>
    </Root>
  );
}

const Root = styled.div`
  margin-bottom: 1rem;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
`;

const Title = styled.h3`
  margin-bottom: 0.5rem;
  margin-right: 1rem;
`;

const SeparatorLine = styled.div`
  height: 1px;
  flex-grow: 1;
  background-color: #ced1d4;
`;

const Subtitle = styled.div`
  flex-grow: 1;
`;

const Addons = styled.div``;
