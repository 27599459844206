import styled from '@emotion/styled';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { ExternalLink } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

import { useCurrentShop } from '@hooks/useCurrentShop';
import { ProductImage } from '@components/Image/ProductImage';
import { MultipleSelectItemAction } from '@components/Input/MultipleSelect';
import { ProductVariant } from '@typings/ProductVariant';

interface VariantListItemProps {
  action: MultipleSelectItemAction;
  selected?: boolean;
  isAddon?: boolean;
  variant: ProductVariant;
}

const VariantListItem = (props: VariantListItemProps) => {
  const { action, selected, variant, isAddon } = props;
  const shop = useCurrentShop();

  function openOnShopify(e) {
    e.stopPropagation();
    window.open(
      `https://${shop.shopifyDomain}/admin/products/${variant.shopifyProduct.externalId}/variants/${variant.externalId}`,
      '_blank'
    );
  }

  const priceText = isAddon ? variant.priceText : variant.discountedPriceText;

  const actionIcon = action === 'add' ? faPlus : faMinus;

  return (
    <Root>
      <ProductContainer>
        <ThumbnailContainer>
          <StyledThumbnail src={variant.imageUrl} alt="product" />
        </ThumbnailContainer>
        <InfoContainer>
          <h6>
            <strong>{variant.title}</strong>
          </h6>
          <span className="variants__subtitle">{priceText}</span>
          <Ids>
            Variant #{variant.externalId} | Product #{variant.shopifyProduct.externalId}
          </Ids>
          <div>
            <Button onClick={openOnShopify} style={{ padding: 0 }} variant="link">
              <ExternalLink size={14} /> Open on Shopify
            </Button>{' '}
          </div>
        </InfoContainer>
      </ProductContainer>
      {(action !== 'add' || !selected) && <FontAwesomeIcon icon={actionIcon} size="lg" />}
    </Root>
  );
};

const Root = styled.div`
  align-items: center;
  display: flex;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const InfoContainer = styled.div`
  flex-grow: 1;
  margin-left: 0.5rem;
  padding: 0.8rem;
`;

const Ids = styled.div`
  font-size: 0.8rem;
`;

const ThumbnailContainer = styled.div`
  max-width: 100px;
  padding: 0;
  border-radius: 8px 0 0 8px;
  overflow: hidden;
`;

const StyledThumbnail = styled(ProductImage)`
  margin: auto;
  width: 100%;
  height: 100%;
`;

export default VariantListItem;
