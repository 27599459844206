import React from 'react';
import styled from '@emotion/styled';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';

export interface DropdownOption {
  label: string;
  onClick: any;
  icon?: IconProp;
}

interface DropdownOptionsProps {
  options: DropdownOption[];
}

const DropdownOptions = ({ options }: DropdownOptionsProps) => (
  <Dropdown>
    <DropdownToggle id="node-actions">
      <Options icon={faEllipsisV} size="2x" />
    </DropdownToggle>
    <DropdownMenu>
      {options.map((option, index) => (
        <DropdownItem key={index} onClick={option.onClick}>
          {option.label}
          {option?.icon && <DropdownIcon icon={option.icon} />}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </Dropdown>
);

const Options = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.dark1};

  &:hover {
    color: ${(props) => props.theme.colors.dark0};
  }
`;

const DropdownToggle = styled(Dropdown.Toggle)`
  align-self: self-start;
  margin: 0 10px;
  padding: 0 !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  background: transparent !important;

  &::after {
    display: none;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  inset: 0px auto auto 40px !important;
  transform: translateY(-5px) !important;
  border-radius: 3px;
  box-shadow: ${(props) => `0px 1px 4px  ${props.theme.colors.dark0}44`};
`;

const DropdownItem = styled(Dropdown.Item)`
  font-size: 12px;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.light3}`};
  padding: 0.5rem 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 250ms ease-in-out;

  &:hover {
    background: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.light0};
  }

  &:last-child {
    border: 0;
  }
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  margin: 0 5px;
`;

export default DropdownOptions;
