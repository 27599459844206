import {
  ReAmazeLogo,
  ZendeskLogo,
  KustomerLogo,
  FreshdeskLogo,
  GorgiasLogo,
  HelpscoutLogo,
  IntercomLogo,
} from './images';

export const images = {
  reamaze: ReAmazeLogo,
  zendesk: ZendeskLogo,
  kustomer: KustomerLogo,
  freshdesk: FreshdeskLogo,
  gorgias: GorgiasLogo,
  intercom: IntercomLogo,
  helpscout: HelpscoutLogo,
};
