import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import FileDownload from 'js-file-download';
import toast from 'react-hot-toast';

import axios from '@lib/axios';
import { ViewPage } from '@components/Layout/ViewPage';
import { DatePicker } from '@components/Input';
import { TableHeader, TableData } from '@components/Table/Table';
import { formatString } from '@utils/date';

interface DashboardViewPageProps {
  baseUrl: string;
  headerDescription: string;
  tableHeaders: TableHeader[];
  tableRow(data: any): TableData;
  csvFilename: string;
}

interface Params {
  start_date?: string;
  end_date?: string;
}

export function DashboardViewPage(props: DashboardViewPageProps) {
  const { baseUrl, headerDescription, tableHeaders, tableRow, csvFilename } = props;
  const [dateRange, setDateRange] = useState<Date[]>();

  function handleChangeDate(dates: Date[]) {
    if (dates?.length === 2) setDateRange(dates);
  }

  const getParams = useCallback(() => {
    const params: Params = {};

    if (dateRange?.length === 2) {
      params.start_date = formatString(dateRange?.[0]?.toString(), 'MM-dd-yyyy');
      params.end_date = formatString(dateRange?.[1]?.toString(), 'MM-dd-yyyy');
    }

    return params;
  }, [dateRange]);

  async function handleExportCSV() {
    function onSuccess(response) {
      let date = new Date().toLocaleDateString();

      if (dateRange?.length === 2) {
        const startDate = dateRange?.[0].toLocaleDateString();
        const endDate = dateRange?.[1].toLocaleDateString();

        date = `${startDate}-${endDate}`;
      }

      FileDownload(response.data, `${csvFilename}-${date}.csv`);

      return 'CSV successfully exported!';
    }

    toast.promise(
      axios.get(baseUrl, {
        headers: {
          Accept: 'text/csv',
        },
        responseType: 'blob',
        params: getParams(),
      }),
      {
        loading: 'Exporting CSV...',
        success: onSuccess,
        error: 'Something went wrong, try again later.',
      }
    );
  }

  return (
    <ViewPage
      url={baseUrl}
      header={{
        description: headerDescription,
        component: {
          custom: (
            <HeaderContainer>
              <DatePicker value={dateRange} onChange={handleChangeDate} />
              <ExportButton onClick={handleExportCSV}>Export CSV</ExportButton>
            </HeaderContainer>
          ),
        },
      }}
      table={{
        headers: tableHeaders,
        row: tableRow,
      }}
      params={getParams()}
    />
  );
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ExportButton = styled.button`
  background: #000;
  color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: 0;
  padding: 7px 15px;
  border-radius: 100px;
  margin-left: 15px;
`;
