import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';

import { Text } from '@components/Typography/Text';
import { useCurrentShop } from '@hooks/useCurrentShop';

export const ExtensionDisabledWarningToast = () => {
  const shop = useCurrentShop();

  return (
    <Root>
      <IconSection>⚠️</IconSection>
      <InfoSection>
        <Text weight="bold">Recharge Checkout Extension Disabled</Text>
        <Text size="small">
          Your new customers are not able to opt-in to replenishment.
          <br />
          <a
            href={`https://${shop.shopifyDomain}/admin/settings/checkout#post-purchase`}
            target="_blank"
            rel="noreferrer"
          >
            Visit Shopify Checkout settings <StyledLinkIcon icon={faExternalLinkAlt} />
          </a>
        </Text>
      </InfoSection>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${(props) => props.theme.colors.light0};
  border: 1px ${(props) => props.theme.colors.dark0} solid;
  border-radius: ${(props) => props.theme.radius.xSmall};
  display: flex;
  margin-top: 0.5rem;
`;

const IconSection = styled.div`
  align-items: center;
  border-right: 2px ${(props) => props.theme.colors.light4} solid;
  display: flex;
  font-size: 18px;
  padding: ${(props) => props.theme.spacing.medium} ${(props) => props.theme.spacing.small};
`;

const InfoSection = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => props.theme.spacing.small} ${(props) => props.theme.spacing.medium};
  padding-right: ${(props) => props.theme.spacing.large};
`;

const StyledLinkIcon = styled(FontAwesomeIcon)`
  margin-left: ${(props) => props.theme.spacing.xSmall};
`;
