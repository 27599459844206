import React from 'react';
import styled from '@emotion/styled';

import { CircleProps } from './types';

export default function Circle({ color }: CircleProps) {
  return <CircleContainer color={color} />;
}

const CircleContainer = styled.div<CircleProps>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
  margin: 12px;
  margin-left: 0px;
`;
