import { CancelLink } from '@components/Router/CancelLink';
import React from 'react';
import { Card, Form as BootstrapForm, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import TemplateMessage from './components/TemplateMessage';
import TemplateMessageVariants from './components/TemplateMessageVariants';

interface FormProps {
  defaultValues?: any;
  onSubmit(values: any): Promise<any>;
}

const Form = (props: FormProps) => {
  const { defaultValues, onSubmit } = props;
  const navigate = useNavigate();

  const formProps = useForm({
    shouldUnregister: false,
    defaultValues: {
      ...defaultValues,
      actions: defaultValues?.customVariables?.actions,
    },
  });
  const { handleSubmit } = formProps;

  async function internalOnSubmit(data) {
    try {
      const formData = {
        template_message: data,
      };
      const response = await onSubmit(formData);

      if (formData.template_message.category === 'base') {
        navigate(`/sms/template_messages/${response.data.id}/edit`);
      }
      toast.success('Template message successfully updated.');
    } catch (e) {
      toast.error(e.response.data.message);
    }
  }

  return (
    <FormProvider {...formProps}>
      <BootstrapForm onSubmit={handleSubmit(internalOnSubmit)}>
        <Card>
          <Card.Body>
            <TemplateMessage {...defaultValues} />
            {defaultValues?.custom && <TemplateMessageVariants {...defaultValues} />}
          </Card.Body>
          <Card.Footer className="text-right">
            <CancelLink to="/sms/template_messages" />
            <Button type="submit">Update</Button>
          </Card.Footer>
        </Card>
      </BootstrapForm>
    </FormProvider>
  );
};

export default Form;
