import React from 'react';
import styled from '@emotion/styled';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFile } from '../ImageUploadContainer';
import axios from '@lib/axios';

interface SingleFileProps {
  fileUrl: string;
  setUploadedFile(value: IFile): void;
}

export function SingleFile(props: SingleFileProps) {
  const { fileUrl, setUploadedFile } = props;

  function handleDelete(e) {
    e.stopPropagation();
    axios
      .delete('/sms/shops/logo')
      .then(() => {
        setUploadedFile(null);
      })
      .catch((err) => {
        console.error('Unable to delete shop logo', err);
      });
  }

  return (
    <Container>
      <Image src={fileUrl} />

      <DeleteButton type="button" onClick={handleDelete}>
        <FontAwesomeIcon icon={faTrash} color="#ed534e" />
      </DeleteButton>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(45%, -45%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #b6b6bd;
  background: white;
`;
