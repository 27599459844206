import React from 'react';
import styled from '@emotion/styled';
import { Handle as ReactFlowHandle, HandleProps } from 'react-flow-renderer';

const Handle = (props: HandleProps) => <StyledHandle {...props} />;

const StyledHandle = styled(ReactFlowHandle)`
  background: transparent;
  border: 0;
`;

export default Handle;
