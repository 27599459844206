import { Page } from '@components/Layout/Page';
import { Card } from '@components/Layout/Card';
import styled from '@emotion/styled';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { useFlag } from '@hooks/useFlag';
import phone from '@utils/phone';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Branding } from '@components/Branding';
import { updateCustomize } from '@api/sms';

import { NavTabs } from '../NavTabs';
import { MessagingSettings } from './components/MessagingSettings';
import { StoreEmail } from './components/StoreEmail';
import { MiscellaneousSettings } from './components/MiscellaneousSettings';

interface AdvancedProps {
  replenishment: boolean;
}

function Advanced(props: AdvancedProps) {
  const { replenishment } = props;
  const shop = useCurrentShop();
  const [safeHours, setSafeHours] = useState([shop?.startSafeHour, shop?.endSafeHour]);
  const [isLoading, setIsLoading] = useState(false);
  const [tagOptIns, setTagOptIns] = useState<boolean>();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const refactorSettingsPagesEnabled = useFlag('refactor_settings_pages_enabled');

  const onSubmit = async (values: any) => {
    const newValues = {
      ...values,
      start_safe_hour: safeHours[0],
      end_safe_hour: safeHours[1],
      tag_opt_ins: tagOptIns,
    };

    try {
      setIsLoading(true);
      await updateCustomize(newValues);
      shop.refetch({ useLoading: false });
      toast.success('Shop successfully update!');
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const { setting: settings } = shop;
  const phoneNumber = settings?.bandwidth_from_number || settings?.twilio_from_number;

  useEffect(() => {
    setTagOptIns(shop?.tagOptIns);
  }, [shop?.tagOptIns]);

  return (
    <>
      {!replenishment && <NavTabs />}
      <Page
        headerComponent={
          <PhoneText>Your store's phone number is {phone.format(phoneNumber)}</PhoneText>
        }
      >
        {refactorSettingsPagesEnabled.enabled && (
          <Card className="mb-4 h-auto">
            <Branding />
          </Card>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className={`${refactorSettingsPagesEnabled.enabled && 'h-auto'} mb-4`}>
            {refactorSettingsPagesEnabled.enabled ? (
              <MessagingSettings
                replenishment={replenishment}
                formRegister={register}
                formErrors={errors}
                safeHours={safeHours}
                setSafeHours={setSafeHours}
                isLoading={isLoading}
              />
            ) : (
              <StoreEmail
                currentEmail={shop?.customEmail}
                replenishment={replenishment}
                formRegister={register}
                safeHours={safeHours}
                setSafeHours={setSafeHours}
                tagOptIns={tagOptIns}
                setTagOptIns={setTagOptIns}
                isLoading={isLoading}
              />
            )}
          </Card>

          {refactorSettingsPagesEnabled.enabled && (
            <MiscellaneousSettings
              tagOptIns={tagOptIns}
              setTagOptIns={setTagOptIns}
              isLoading={isLoading}
            />
          )}
        </form>
      </Page>
    </>
  );
}

export default Advanced;

const PhoneText = styled.span`
  color: #9a9b9c;
  font-size: 16px;
`;
