import React, { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';

import outlinedStarImage from '@assets/images/star-outlined.svg';
import { CustomButton } from '@components/Button/CustomButton';
import { Card } from '@components/Layout/Card';
import { Toggle } from '@components/Input/Toggle';
import { Text } from '@components/Typography/Text';
import styled from '@emotion/styled';
import axios from '@lib/axios';
import { Collection } from '@typings/Collection';

import { CollectionVariantsList } from './CollectionVariantsList';
import { InfoText } from './InfoText';
import { SmartSwapTag } from './SmartSwapTag';
import { ThumbnailsPreview } from './ThumbnailsPreview';
import { UpdateIntervalText } from './UpdateIntervalText';

interface CollectionListItemProps {
  collection: Collection;
  onChange?(): void;
}

export const CollectionListItem = (props: CollectionListItemProps) => {
  const { collection, onChange } = props;
  const [expanded, setExpanded] = useState(false);
  const [showIgnored, setShowIgnored] = useState(false);

  async function toggleActive() {
    await axios.post(`/sms/collections/${collection.collectionType}/toggle_active`);
    onChange?.();
  }

  function toggleExpand() {
    setExpanded((old) => !old);
  }

  function toggleIgnored() {
    setShowIgnored((old) => !old);
  }

  const allValidVariants = collection.collectionVariants.filter(
    (collectionVariant) => collectionVariant.validVariant
  );

  const firstFiveEligibleVariants = allValidVariants
    .filter((collectionVariant) => collectionVariant.eligible)
    .slice(0, 5);

  const variantsToDisplay = showIgnored ? allValidVariants : firstFiveEligibleVariants;
  // we let it active/enabled if it's already active, otherwise check the variant count
  const blocked = !collection.activeAt && allValidVariants.length < 2;

  return (
    <>
      <SmartSwapCard active={!!collection.activeAt}>
        <CardHeader onClick={blocked ? undefined : toggleExpand} disabled={blocked}>
          <TitleSection>
            <Title weight="bold" size="large">
              {collection.name}
            </Title>
            <SmartSwapTag active={!!collection.activeAt} />
          </TitleSection>
          <ToggleSection>
            <Toggle
              checked={!!collection.activeAt}
              disabledLabel="Inactive"
              enabledLabel="Active"
              onChange={toggleActive}
              disabled={blocked}
              size="sm"
            />
          </ToggleSection>
          <div className="d-flex">
            <ThumbnailsPreview collectionVariants={allValidVariants} />
            <ViewProductsButton size="sm" variant="outline-primary" disabled={blocked}>
              {expanded ? 'Hide Products' : 'View Products'}
            </ViewProductsButton>
          </div>
        </CardHeader>
        <Collapse in={expanded}>
          <ProductsContainer>
            <CollectionVariantsList
              collectionVariants={variantsToDisplay}
              onChange={onChange}
              showIgnored={showIgnored}
            />
            <Footer>
              <UpdateIntervalText collectionName={collection.name} />
              <ShowIgnoredButton variant="link" onClick={toggleIgnored}>
                {showIgnored ? 'Hide ignored variants' : 'View ignored variants'}
              </ShowIgnoredButton>
            </Footer>
          </ProductsContainer>
        </Collapse>
      </SmartSwapCard>
      {!blocked && !expanded && <UpdateIntervalText collectionName={collection.name} />}
      {blocked && <InfoText>Your store needs more data to enable this feature</InfoText>}
    </>
  );
};

interface SmartSwapCardProps {
  active: boolean;
}

const SmartSwapCard = styled(Card)<SmartSwapCardProps>`
  box-shadow: 0px 1px 4px 0px #00000040;
  opacity: ${(props) => (props.active ? 1 : 0.7)};
  overflow: hidden;
  transition: 250ms ease-in-out;
  height: auto;
`;

interface CardHeaderProps {
  disabled?: boolean;
}

const CardHeader = styled.div<CardHeaderProps>`
  align-items: center;
  background: url(${outlinedStarImage}) no-repeat left top;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  padding: ${(props) => props.theme.spacing.medium} ${(props) => props.theme.spacing.large};
  padding-left: 10rem;
`;

const ProductsContainer = styled.div`
  background-color: #f3f3f3;
  padding-top: ${(props) => props.theme.spacing.medium};
`;

const TitleSection = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Title = styled(Text)`
  margin-bottom: ${(props) => props.theme.spacing.xSmall};
`;

const ToggleSection = styled.div`
  padding: 0 ${(props) => props.theme.spacing.large};
`;

const ViewProductsButton = styled(CustomButton)`
  font-weight: normal;
  font-size: 12px;
  padding: 5px 14px;
  &.btn-outline-primary:not(:disabled) {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${(props) => props.theme.spacing.medium};
`;

const ShowIgnoredButton = styled(Button)`
  color: #6b6b6b;
`;
