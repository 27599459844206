import React from 'react';
import { Col } from 'react-bootstrap';
import { FeaturesView } from '@components/Onboarding/v2/components/FeaturesView';
import { SimulatorView } from '@components/Onboarding/v2/components/SimulatorView';
import { FeatureContainer } from '@components/Onboarding/v2/components/styling/OnboardingViewStyling';

export const FeatureSelectView = () => (
  <FeatureContainer highlightSimulator>
    <Col sm={6}>
      <FeaturesView disabled />
    </Col>
    <Col sm={6} className="simulator-onboarding">
      <SimulatorView />
    </Col>
  </FeatureContainer>
);
