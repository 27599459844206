import styled from '@emotion/styled';
import React from 'react';

import AddAction from './ActionComponents/Add';

const AddableAction = () => (
  <Container>
    <AddAction />
  </Container>
);

const Container = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 5px 0;

  > div {
    margin-left: 40px;
  }
`;

export default AddableAction;
