import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useZoomPanHelper } from 'react-flow-renderer';

import { Insights } from './Insights';
import { EditMessage } from './EditMessage';
import { ProductSelector } from './ProductSelector';
import { useLayoutContext } from '../LayoutContext';
import { useWorkflowsContext } from '../WorkflowsContext';

export default React.memo(() => {
  const { rightSidebar, leftSidebar, closeRightSidebar } = useLayoutContext();
  const { currentWorkflow } = useWorkflowsContext();
  const [activeAnimation, setActiveAnimation] = useState(false);
  const { fitView } = useZoomPanHelper();

  const fitViewhandler = () => {
    fitView();
  };

  useEffect(() => {
    if (!leftSidebar) {
      return;
    }
    animateResize();
  }, [leftSidebar]);

  useEffect(() => {
    if (!rightSidebar) {
      return;
    }
    animateResize();
  }, [rightSidebar?.open, rightSidebar?.width]);

  function animateResize() {
    if (activeAnimation) {
      return;
    }
    setActiveAnimation(true);
    const interval = setInterval(() => {
      fitViewhandler();
    }, 33); // ~30 fps
    setTimeout(() => {
      clearInterval(interval);
      setActiveAnimation(false);
    }, 500);
  }
  function closeHandler() {
    closeRightSidebar();
  }

  const ActiveContent = ({ content }: { content: any }) => {
    if (content?.type === 'edit-message') {
      return <EditMessage closeHandler={closeHandler} />;
    }
    if (content?.type === 'insights') {
      return <Insights currentId={currentWorkflow?.id} closeHandler={closeHandler} />;
    }
    if (content?.type === 'select-products') {
      return <ProductSelector closeHandler={closeHandler} />;
    }

    return <div>debug unknown rsb content</div>;
  };

  const ActiveComponentMemo = useMemo(() => <ActiveContent content={rightSidebar} />, [
    rightSidebar?.type,
    currentWorkflow?.id,
  ]);

  return (
    <SidebarContainer className={`${rightSidebar?.open ? 'open' : ''}`} width={rightSidebar?.width}>
      <div className="sidebar-body-container">
        {rightSidebar?.type ? ActiveComponentMemo : <></>}
      </div>
    </SidebarContainer>
  );
});

const SidebarContainer = styled.aside<{ width?: number }>`
  width: 0px;
  transition: width 0.5s ease-in-out;
  height: 100%;
  position: relative;
  &.open {
    width: ${(props) => `${props.width}px` || '350px'};
    .sidebar-body-container {
      transform: translateX(0px);
    }
  }
  .sidebar-body-container {
    position: absolute;
    width: 100%;
    background: white;
    width: ${(props) => `${props.width}px` || '350px'};
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.07);
    height: 100%;
    transition: transform 0.5s ease-in-out;
    transform: translateX(${(props) => `${props.width}px` || '350px'});
  }
`;
