import React from 'react';
import { Credentials } from '@typings/Integration';
import { TextInput } from '../TextInput';

interface FormProps {
  credentials: Credentials;
}

export function GorgiasForm(props: FormProps) {
  const { credentials } = props;

  return (
    <div>
      <TextInput
        name="gorgias_base_url"
        label="Base API URL"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.base_url ? credentials.base_url : '',
          placeholder: 'https://shopname.gorgias.com/api',
        }}
      />

      <TextInput
        name="gorgias_username"
        label="Username"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.username,
          placeholder: 'Your Email Address',
        }}
      />

      <TextInput
        name="gorgias_password"
        label="API Key"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.password,
        }}
      />
    </div>
  );
}
