import React from 'react';
import styled from '@emotion/styled';

import { ProductVariant } from '@typings/ProductVariant';
import { formatCurrency } from '@utils/number';

interface PriceProps {
  productVariant: ProductVariant;
}

export function Price(props: PriceProps) {
  const { productVariant } = props;

  if (!productVariant.price) return <>-</>;

  if (productVariant.discount) {
    return (
      <>
        {formatCurrency(productVariant.discountedPrice)}{' '}
        <DisabledPrice>{formatCurrency(productVariant.price)}</DisabledPrice>
      </>
    );
  }

  return <>{formatCurrency(productVariant.price)}</>;
}

const DisabledPrice = styled.span`
  color: grey;
  text-decoration: line-through;
`;
