import { Action } from '@typings/TemplateMessage';
import React, { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import createAction from './createAction';

const CustomActionsContext = createContext(null);

export const CustomActionProvider = ({ initialActions, availableActions, onChange, children }) => {
  const [actions, setActions] = useState<Action[]>(
    initialActions?.map((action) => ({ ...action, id: uuidv4() })) || []
  );

  const handleChange = (newActions) => {
    setActions(newActions);
    onChange(newActions);
  };

  const handleChangeAction = (id, data) => {
    handleChange(
      actions.map((action) => {
        if (action.id === id) {
          return { ...action, ...data };
        }
        return action;
      })
    );
  };

  const handleDeleteAction = (id) => {
    handleChange(actions.filter((action) => action.id !== id));
  };

  const handleAddAction = (newAction) => {
    handleChange([...actions, { id: uuidv4(), ...createAction(actions, newAction) }]);
  };

  const reorderActions = (list, startIndex, endIndex): Action[] => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);

    return [...list];
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) return;

    const reorderedActions = reorderActions(actions, source.index, destination.index);

    handleChange(reorderedActions);
  };

  const selectedTypes = actions.map((action) => action.type);
  const hiddenActions = availableActions.filter((availableAction) => {
    const limit = availableAction.limit || 1;
    return selectedTypes.filter((type) => type === availableAction.type).length < limit;
  });

  const removable = (action: Action) => {
    if (action.required) return false;

    const atLeast = action.at_least || 0;
    return selectedTypes.filter((type) => type === action.type).length > atLeast;
  };

  const value = {
    actions,
    visibleActions: actions,
    hiddenActions,
    handleChangeAction,
    handleAddAction,
    handleDeleteAction,
    reorderActions,
    handleDragEnd,
    removable,
  };
  return <CustomActionsContext.Provider value={value}>{children}</CustomActionsContext.Provider>;
};

export const useCustomActionsContext = () => {
  const context = React.useContext(CustomActionsContext);

  if (context === undefined) {
    throw new Error('useCustomActionsContext must be used within a CustomActionProvider');
  }
  return context;
};
