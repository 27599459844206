import React, { useState } from 'react';

import { Shop } from '@typings/Shop';
import { routes } from '@components/Router/navigationRoutes';
import { useFlag } from '@hooks/useFlag';

import { BaseSidebarV2 } from '@components/Layout/Sidebar/components/BaseSidebarV2';
import { Route } from '@components/Layout/Sidebar/components';

interface SidebarV2Props {
  shop: Shop;
}

export function SidebarV2(props: SidebarV2Props) {
  const { shop } = props;
  const [open, setOpen] = useState('');
  const { enabled: workflowsRouteEnabled } = useFlag('workflows');
  const { enabled: homepageRouteEnabled } = useFlag('homepage');

  return (
    <BaseSidebarV2 shop={shop} homeUrl="/sms">
      {routes({ workflowsRouteEnabled, homepageRouteEnabled }).map((route) => {
        if (route.disabled) return null;

        return <Route key={route.title} route={route} open={open} setOpen={setOpen} />;
      })}
    </BaseSidebarV2>
  );
}
