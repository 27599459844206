import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';

import { formatString } from '@utils/date';
import { Chat } from '@components/Chat/Chat';
import { ProductImage } from '@components/Image/ProductImage';

import { Data } from './Show';

export function Info(props: Data) {
  const { rechargeCustomer, mainRechargeCustomer, subRechargeCustomers = [] } = props;

  return (
    <div className="row">
      <div className="col-md-6">
        {!rechargeCustomer?.reachableViaSms && (
          <span className="badge badge-danger mb-4">Unreachable by SMS</span>
        )}

        <div>
          <h6>
            <strong>Name</strong>
          </h6>
          <p>{rechargeCustomer?.name}</p>
        </div>
        <div>
          <h6>
            <strong>Email</strong>
          </h6>
          <p>{rechargeCustomer?.email}</p>
        </div>

        {rechargeCustomer?.tempPhone ? (
          <div>
            <h6>
              <strong>Phone</strong> (duplicated)
            </h6>
            <p>{rechargeCustomer?.tempPhone}</p>
          </div>
        ) : (
          <div>
            <h6>
              <strong>Phone</strong>
            </h6>
            <p>{rechargeCustomer?.phone}</p>
          </div>
        )}

        <div>
          <h6>
            <strong>Address</strong>
          </h6>
          <p>{rechargeCustomer?.formattedAddress}</p>
        </div>
        <div>
          <h6>
            <strong>Customer since</strong>
          </h6>
          <p>{formatString(rechargeCustomer?.createdAt, 'dd MMM hh:mm')}</p>
        </div>
        <div>
          <h6>
            <strong>Recharge ID</strong>
          </h6>
          <p>
            <a href={rechargeCustomer?.rechargeUrl} target="_blank" rel="noreferrer">
              {rechargeCustomer?.externalId}
            </a>
          </p>
        </div>

        {rechargeCustomer.optOut && (
          <div>
            <h6>
              <strong>Opt out at</strong>
            </h6>
            <p>{formatString(rechargeCustomer?.optOutAt, 'dd MMM hh:mm')}</p>
          </div>
        )}

        {rechargeCustomer?.phoneError && (
          <div>
            <h6 className="text-danger">
              <strong>Phone Error</strong>
            </h6>
            <p>{rechargeCustomer?.phoneError}</p>
          </div>
        )}

        {rechargeCustomer?.tempPhone && mainRechargeCustomer && (
          <div>
            <h6>
              <strong>Main Customer Account</strong>
            </h6>
            <p>
              <Link to={mainRechargeCustomer.emailUrl}>{mainRechargeCustomer.email}</Link>
            </p>
          </div>
        )}

        {rechargeCustomer?.nextChargeDate && (
          <div>
            <h6>
              <strong>Next Charge Date</strong>
            </h6>
            <p>{formatString(rechargeCustomer?.nextChargeDate, 'MMM dd')}</p>
          </div>
        )}

        {subRechargeCustomers?.length > 0 && (
          <div>
            <h6>
              <strong>Customer Sub-accounts</strong>
            </h6>
            {subRechargeCustomers.map((subRechargeCustomer) => (
              <p key={subRechargeCustomer.id}>
                <Link to={subRechargeCustomer.emailUrl}>{subRechargeCustomer.email}</Link>
              </p>
            ))}
          </div>
        )}

        {rechargeCustomer?.activeSubscriptions?.length > 0 && (
          <div>
            <h6>
              <strong>Active Subscriptions</strong>
            </h6>

            {rechargeCustomer?.activeSubscriptions.map((subscription, id) => (
              <p key={`${subscription.variantId}-${id}`}>
                <OverlayTrigger
                  placement="top-start"
                  trigger="click"
                  rootClose
                  overlay={
                    <SubscriptionTooltip id={`${subscription.variantId}-${id}`}>
                      <Row>
                        <Col lg={4}>
                          <ProductImage
                            className="img-fluid"
                            src={subscription.variantImageUrl}
                            alt={subscription.variantTitle}
                          />
                        </Col>
                        <Col lg={8}>
                          <h3 className="mt-3">Subscription Information</h3>
                          <p className="mt-3">
                            <strong>Product Title:</strong> {subscription.variantTitle}
                          </p>
                          <p>
                            <strong>Shopify Product ID:</strong> {subscription.shopifyProductId}
                          </p>
                          <p>
                            <strong>Shopify Variant ID:</strong> {subscription.shopifyVariantId}
                          </p>
                        </Col>
                      </Row>
                    </SubscriptionTooltip>
                  }
                >
                  <span className="text-primary" role="button">
                    {subscription.variantTitle}
                  </span>
                </OverlayTrigger>
              </p>
            ))}
          </div>
        )}

        {rechargeCustomer?.inactiveSubscriptions?.length > 0 && (
          <div>
            <h6>
              <strong>Inactive Subscriptions</strong>
            </h6>

            {rechargeCustomer?.inactiveSubscriptions.map((subscription) => (
              <p key={subscription.variantId}>
                <Link to={subscription.variantUrl}>{subscription?.variantTitle}</Link>
              </p>
            ))}
          </div>
        )}
      </div>
      <div className="col-md-6">
        <Chat rechargeCustomer={rechargeCustomer} />
      </div>
    </div>
  );
}

const SubscriptionTooltip = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  margin-bottom: 1rem;
  max-width: 500px;
  overflow: hidden;
  width: 100%;

  h3 {
    color: ${(props) => props.theme.colors.secondary};
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin-bottom: 4px;
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
