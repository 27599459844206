import toast from 'react-hot-toast';

import axios from '@lib/axios';

const BASE_URL = '/sms/segments';

async function getTestSegment() {
  return await tryCatchWrapper(async () => {
    const response = await axios.get(`${BASE_URL}/test_customers`);

    return response?.data;
  });
}

async function updateSegment(id, params) {
  return await tryCatchWrapper(async () => {
    const response = await axios.put(`${BASE_URL}/${id}.json`, {
      segment: params,
    });

    return response;
  });
}

async function toggleActive(id) {
  return await tryCatchWrapper(async () => {
    const response = await axios.post(`${BASE_URL}/${id}/toggle_active`);

    return response;
  });
}

async function saveCustomerToSegment(id, customerEmail) {
  return await tryCatchWrapper(async () => {
    const response = await axios.post(`${BASE_URL}/${id}/import_customers_by_emails`, {
      emails: customerEmail,
    });

    return response;
  });
}

async function removeCustomerFromSegment(id, customerEmail) {
  return await tryCatchWrapper(async () => {
    const response = await axios.post(`${BASE_URL}/${id}/remove_customers_by_emails`, {
      emails: customerEmail,
    });

    return response;
  });
}

async function tryCatchWrapper(fn) {
  try {
    return await fn();
  } catch (e) {
    toast.error(e.response.data.message);
  }
}

export default {
  getTestSegment,
  updateSegment,
  saveCustomerToSegment,
  removeCustomerFromSegment,
  toggleActive,
};
