import styled from '@emotion/styled/macro';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';

import { Action } from '@typings/TemplateMessage';
import DefaultAction from './ActionComponents/Default';
import DelayAction from './ActionComponents/Delay';
import { useCustomActionsContext } from './CustomActionsContext';

type DraggableActionProps = Action & {
  index: number;
  position: number;
  isRemovable: boolean;
};

const DraggableAction = ({
  index,
  position,
  isRemovable,
  id,
  type,
  label,
  params,
}: DraggableActionProps) => {
  const { handleDeleteAction } = useCustomActionsContext();

  const actionComponents = {
    delay_order: <DelayAction id={id} params={params} />,
    default: <DefaultAction label={label} />,
  };

  const actionComponent = actionComponents[type] || actionComponents.default;

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <PositionContainer>
            <FontAwesomeIcon icon={faBars} color="#9A9B9C" />
            <Position>{position}.</Position>
          </PositionContainer>
          {actionComponent}
          <TrashIcon
            icon={isRemovable ? faTrash : faLock}
            onClick={() => isRemovable && handleDeleteAction(id)}
            title={isRemovable ? 'Delete Action' : 'Required'}
          />
        </Container>
      )}
    </Draggable>
  );
};

const TrashIcon = styled(FontAwesomeIcon)`
  visibility: hidden;
  margin-left: 10px;
  color: #ee0000;
  cursor: pointer;
`;

const Container = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 5px 0;

  &:hover {
    ${TrashIcon} {
      visibility: visible;
    }
  }
`;

const PositionContainer = styled.div`
  width: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Position = styled.span`
  font-size: 14px;
  width: 25px;
  text-align: center;
  color: #000000;
`;

export default DraggableAction;
