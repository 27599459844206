import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { switchStyle } from '@utils/styles';

import { formatters } from './formatters';
import { MetricBase, MetricBaseProps, MetricText } from './MetricBase';

export function MetricVariation(props: MetricBaseProps) {
  const { type = 'number' } = props;

  return (
    <MetricBase {...props}>
      {(data) =>
        data && (
          <>
            <MetricTendency direction={data.variation > 0 ? 'up' : 'down'}>
              {data.variation}%
            </MetricTendency>
            <MetricText>{formatters[type](data.value)}</MetricText>
          </>
        )
      }
    </MetricBase>
  );
}

const MetricTendency = styled.div<{ direction: 'up' | 'down' }>`
  align-items: center;
  border-right: 3px solid #f6f6f6;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-right: 15px;
  padding: 10px 15px;
  position: relative;

  &:before {
    content: '';
    height: 0;
    width: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    ${switchStyle('direction')({
      up: css`
        border-bottom: 10px solid #31d75a;
      `,
      down: css`
        border-top: 10px solid #d73131;
      `,
    })}
  }
`;
