import { useOnboardingV2 } from '@hooks/useOnboardingV2';
import React, { useState, useEffect } from 'react';
import {
  SecondaryButton,
  PrimaryButton,
  ContinueButton,
} from '@components/Onboarding/v2/components/styling/SidebarStyling';
import { useAppSelector, useAppDispatch } from '@redux/hooks';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';
import { SidebarLayout } from '@components/Onboarding/v2/components/Sidebar/SidebarLayout';
import {
  FEATURE_1_SELECT_STEP,
  FEATURE_2_SIMULATOR_STEP,
  FEATURE_3_RECOMMEND_STEP,
  FEATURE_4_PLAY_AROUND_STEP,
  FEATURE_FLOW,
  PRODUCT_1_SETUP_STEP,
  CONTINUE_BUTTON_DELAY_TIME_MILLIS,
  sleep,
} from '@components/Onboarding/v2/onboarding';

export const SidebarFeatureFlow = () => {
  const { updateOnboarding } = useOnboardingV2();

  const dispatch = useAppDispatch();

  const onboardingState = useAppSelector((state) => state.onboarding);

  const [loading, setLoading] = useState(false);

  const onSimulateClick = async () => {
    dispatch(onboardingSlice.actions.startSimulate());
  };

  const onEnableFeatureClick = async () => {
    dispatch(onboardingSlice.actions.startEnableFeatures());
  };

  const onContinueClick = async (nextStep) => {
    setLoading(true);
    await sleep(CONTINUE_BUTTON_DELAY_TIME_MILLIS);

    await updateOnboarding(onboardingState.data.id, {
      step: nextStep,
    });
    setLoading(false);
  };

  const onNotEnableFeaturesClick = async () => {
    dispatch(onboardingSlice.actions.setFeaturesEnabled(false));
    dispatch(onboardingSlice.actions.setFeaturesCollape(true));

    setLoading(true);
    await sleep(CONTINUE_BUTTON_DELAY_TIME_MILLIS);

    await updateOnboarding(onboardingState.data.id, {
      step: FEATURE_4_PLAY_AROUND_STEP,
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const contentMap = {
    [FEATURE_1_SELECT_STEP]: (
      <>
        Congrats on getting started! You’ve made it to the Features Page. Here is where you can
        configure all the ways in which your customers will be able to interact with your service
        via text.
        <br />
        <br />
        Let’s show you an example of <b>how this service works.</b>
      </>
    ),

    [FEATURE_2_SIMULATOR_STEP]: onboardingState.isEverSimulate ? (
      <>
        If you need to see that again, feel free to click <b>Replay</b> below, if not you can
        continue on through your tour.
      </>
    ) : (
      <>
        The simulator allows you to <b>preview features</b> that your customers can experience.
        <br />
        <br />
        We’ll now simulate an interaction between your shop and a subscriber.
      </>
    ),

    [FEATURE_3_RECOMMEND_STEP]: (
      <>
        We’ve gone ahead and recommended the most effective features of RechargeSMS
        <br />
        <br />
        Click <b>Enable Features</b> to expedite your setup and view them or click <b>Continue</b>{' '}
        to explore on your own.
      </>
    ),

    [FEATURE_4_PLAY_AROUND_STEP]: onboardingState.isFeaturesEnabled ? (
      <>
        You did it! That’s the gist of the Features Page. We’ve gone ahead and turned on all our
        recommended features.
        <br />
        <br />
        When you’re ready to proceed to the next stage, <b>Product import & formatting,</b> click
        below.
      </>
    ) : (
      <>
        We noticed you chose to pass on our recommended features. We encourage you then to take your{' '}
        time to learn and simulate our features. When you’re ready,{' '}
        <b>continue to Product Setup.</b>
      </>
    ),
  };

  const secondaryButtonMap = {
    [FEATURE_2_SIMULATOR_STEP]: onboardingState.isEverSimulate ? (
      <SecondaryButton
        className="btn outline"
        type="button"
        onClick={() => onSimulateClick()}
        disabled={onboardingState.isSimulating}
      >
        Replay
      </SecondaryButton>
    ) : null,

    [FEATURE_3_RECOMMEND_STEP]: (
      <SecondaryButton
        className="btn outline"
        type="button"
        onClick={() => onNotEnableFeaturesClick()}
      >
        Continue
      </SecondaryButton>
    ),
  };

  const primaryButtonMap = {
    [FEATURE_1_SELECT_STEP]: (
      <ContinueButton onClick={() => onContinueClick(FEATURE_2_SIMULATOR_STEP)}>
        Continue
      </ContinueButton>
    ),
    [FEATURE_2_SIMULATOR_STEP]: onboardingState.isEverSimulate ? (
      <PrimaryButton onClick={() => onContinueClick(FEATURE_3_RECOMMEND_STEP)}>
        Continue
      </PrimaryButton>
    ) : (
      <PrimaryButton onClick={() => onSimulateClick()}>Simulate Away</PrimaryButton>
    ),
    [FEATURE_3_RECOMMEND_STEP]: (
      <PrimaryButton onClick={() => onEnableFeatureClick()}>Enable Features</PrimaryButton>
    ),
    [FEATURE_4_PLAY_AROUND_STEP]: (
      <ContinueButton onClick={() => onContinueClick(PRODUCT_1_SETUP_STEP)}>
        Take me to products!
      </ContinueButton>
    ),
  };

  const stepTitleMap = {
    [FEATURE_1_SELECT_STEP]: 'Select Features',
    [FEATURE_2_SIMULATOR_STEP]: 'Messaging Simulator',
    [FEATURE_3_RECOMMEND_STEP]: 'Recommended Setup',
    [FEATURE_4_PLAY_AROUND_STEP]: onboardingState.isFeaturesEnabled
      ? 'Play around'
      : 'Take your time',
  };

  const stepProgressMap = {
    [FEATURE_1_SELECT_STEP]: (100 * 1) / 4,
    [FEATURE_2_SIMULATOR_STEP]: (100 * 2) / 4,
    [FEATURE_3_RECOMMEND_STEP]: (100 * 3) / 4,
    [FEATURE_4_PLAY_AROUND_STEP]: 100,
  };

  return (
    <SidebarLayout
      flowTitle="Feature configuration"
      welcomeProgressValue={100}
      featureProgressValue={stepProgressMap[onboardingState.data.step]}
      productProgressValue={0}
      content={contentMap[onboardingState.data.step]}
      primaryButton={primaryButtonMap[onboardingState.data.step]}
      secondaryButton={secondaryButtonMap[onboardingState.data.step]}
      stepTitle={stepTitleMap[onboardingState.data.step]}
      loading={loading}
    />
  );
};
