import React from 'react';

import { Card } from '@components/Layout/Card';
import { Page } from '@components/Layout/Page';
import { EmailsPayload } from '@typings/Email';
import { useFetch } from '@hooks/useFetch';
import { FeaturesProvider } from '@contexts/FeaturesContext';
import { EditEmailTemplate } from '@pages/Settings/Features/tabs/EmailSettings/components/EditEmailTemplate';
import { CollapsableFeatureSwitch } from '@pages/Settings/Features/shared/CollapsableFeatureSwitch';

const UnreachablePhoneNumber = () => {
  const { data, loading } = useFetch<EmailsPayload>('/sms/features');
  const { allFeatures, emailFeatures, emailTemplates } = data || {};
  const feature = emailFeatures?.find(
    (emailFeature) => emailFeature.key === 'enable_unreachable_handset_email'
  );
  const emailTemplateTitleMapper = {
    unreachable_handsets: 'Unreachable Phone Number Fallback Email',
  };
  const emailTemplatesMapper = {
    enable_unreachable_handset_email: ['unreachable_handsets'],
  };

  return (
    <Page loading={loading} data-testid="unreachable-phone-number-page">
      <FeaturesProvider allFeatures={allFeatures}>
        {emailTemplates
          ?.filter((template) => emailTemplatesMapper[feature.key].includes(template.emailType))
          .map((template, idx) => (
            <div key={template.id}>
              {!idx && (
                <div className="d-flex align-items-center mt-2 mb-4">
                  <CollapsableFeatureSwitch key={feature.key} feature={feature} alt />
                  <div className="ml-2">
                    Sends your customers an email in the case the charge reminder SMS message is not
                    delivered to them.
                  </div>
                </div>
              )}
              <Card className="mb-4 h-auto" data-testid="unreachable-phone-number-template">
                <EditEmailTemplate
                  template={template}
                  title={emailTemplateTitleMapper[template.emailType]}
                />
              </Card>
            </div>
          ))}
      </FeaturesProvider>
    </Page>
  );
};

export default UnreachablePhoneNumber;
