import React, { useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';

import { Page } from '@components/Layout/Page';
import { TabsContextProvider } from '@components/Tabs/TabsContext';
import styled from '@emotion/styled';
import { EmailTemplate } from '@typings/EmailTemplate';
import { Feature } from '@typings/Feature';
import { Settings } from '@typings/Settings';
import { Shop } from '@typings/Shop';
import { useFetch } from '@hooks/useFetch';
import { FeaturesProvider } from '@contexts/FeaturesContext';

import FeaturesAPI from './api';
import RevertBatchActionsButton from './shared/BatchActions/RevertBatchActionsButton';
import { Messaging } from './tabs';
import { NavTabs } from '../NavTabs';
import './features.scss';

interface FeaturesPayload {
  allFeatures: Feature[];
  generalFeatures: Feature[];
  accountSettingsFeatures: Feature[];
  modifyOrderFeatures: Feature[];
  bundledSubscriptionsFeatures: string[];
  modifyAllFeatures: Feature[];
  otherSmsFeatures: Feature[];
  chargeReminderFeatures: Feature[];
  emailFeatures: Feature[];
  isRequireModifyAllEnabled: boolean;
  shop: Shop;
  settings: Settings;
  emailTemplates: EmailTemplate[];
}

const Features = () => {
  const { data, loading } = useFetch<FeaturesPayload>('/sms/features');
  const [tab, setTab] = useState('messaging');
  const [forceCollapse, setForceCollapse] = useState(0);

  const changeBatchActionsFeature = async (status) => {
    const batchActionsFeature = modifyAllFeatures[0];
    FeaturesAPI.UpdateStatus(batchActionsFeature.key, status).then(() => window.location.reload());
  };

  const {
    allFeatures,
    generalFeatures,
    accountSettingsFeatures,
    modifyOrderFeatures,
    bundledSubscriptionsFeatures,
    modifyAllFeatures,
    otherSmsFeatures,
    chargeReminderFeatures,
    isRequireModifyAllEnabled,
  } = data || {};
  return (
    <>
      <NavTabs />
      <Page
        loading={loading}
        headerDescription={<Description>Manage application features for your store.</Description>}
      >
        <FeaturesProvider allFeatures={allFeatures}>
          <div className="features">
            {isRequireModifyAllEnabled && (
              <RevertBatchActionsButton changeBatchActionsFeature={changeBatchActionsFeature} />
            )}
            <TabsContextProvider setTab={setTab}>
              <Tabs id="features-tab" activeKey={tab} onSelect={(key) => setTab(key)}>
                <Tab eventKey="messaging" title="Messaging">
                  <Card>
                    <Messaging
                      accountSettingsFeatures={accountSettingsFeatures}
                      modifyOrderFeatures={modifyOrderFeatures}
                      bundledSubscriptionsFeatures={bundledSubscriptionsFeatures}
                      generalFeatures={generalFeatures}
                      otherSmsFeatures={otherSmsFeatures}
                      chargeReminderFeatures={chargeReminderFeatures}
                      batchActionsEnabled={isRequireModifyAllEnabled}
                      changeBatchActionsFeature={changeBatchActionsFeature}
                      forceCollapse={forceCollapse}
                    />
                  </Card>
                </Tab>
              </Tabs>
            </TabsContextProvider>
          </div>
        </FeaturesProvider>
      </Page>
    </>
  );
};

export default Features;

const Description = styled.p`
  margin-top: 0;
  display: flex;
`;

const InfoContainer = styled.div`
  margin-left: 5px;
`;
