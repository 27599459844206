import React from 'react';
import styled from '@emotion/styled';

import { Keyword, Template } from '@typings/Workflow';
import { useFetch } from '@hooks/useFetch';
import { CenteredLoading } from '@components/Loading';

import { Sidebar } from './Sidebar';
import { MainArea } from './MainArea';
import { LayoutProvider } from './LayoutContext';
import { WorkflowsProvider } from './WorkflowsContext';
import { ReactFlowProvider } from './ReactFlowContext';

interface ViewProps {
  singleTemplate?: boolean;
  showBackButton?: boolean;
  showTemplateTitle?: boolean;
}

interface WorkflowsPayload {
  keywords: Keyword[];
  templates: Template[];
}

function View(props: ViewProps) {
  const { singleTemplate = false, showBackButton = true, showTemplateTitle = true } = props;
  const { data, loading } = useFetch<WorkflowsPayload>('/sms/workflows');

  if (loading) return <CenteredLoading />;

  const { keywords, templates } = data;
  return (
    <Container>
      <LayoutProvider>
        <ReactFlowProvider>
          <WorkflowsProvider
            keywords={keywords}
            templates={templates}
            selectedTemplate={singleTemplate && templates[0]}
          >
            <Sidebar showBackButton={showBackButton} showTemplateTitle={showTemplateTitle} />
            <MainArea />
          </WorkflowsProvider>
        </ReactFlowProvider>
      </LayoutProvider>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 100%;

  * {
    font-family: 'Greycliff';
  }
`;

export default View;
