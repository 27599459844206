import React from 'react';
import { OnboardingFormData } from '@typings/Onboarding';
import { SidebarLayout } from '@components/Onboarding/v2/components/Sidebar/SidebarLayout';
import {
  SecondaryButton,
  PrimaryButton,
} from '@components/Onboarding/v2/components/styling/SidebarStyling';

interface SidebarContinueOnboardingProps {
  onboardingId: string;
  flowTitle: string;
  stepTitle: string;
  content: React.ReactElement;
  welcomeProgressValue: number;
  featureProgressValue: number;
  productProgressValue: number;
  updateOnboarding(id: string, data: OnboardingFormData): Promise<void>;
  collapsed?: boolean;
  onCollapsedClick?: () => void;
}

export default function SidebarContinueOnboarding(props: SidebarContinueOnboardingProps) {
  const {
    flowTitle,
    stepTitle,
    content,
    welcomeProgressValue,
    featureProgressValue,
    productProgressValue,
    onboardingId,
    updateOnboarding,
    collapsed = false,
    onCollapsedClick = () => {},
  } = props;

  const onContinueClick = async () => {
    await updateOnboarding(onboardingId, {
      is_paused: false,
    });
  };

  const onLaterClick = async () => {
    await updateOnboarding(onboardingId, {
      is_paused: true,
    });
  };

  return (
    <SidebarLayout
      collapsed={collapsed}
      onCollapsedClick={onCollapsedClick}
      flowTitle={flowTitle}
      stepTitle={stepTitle}
      welcomeProgressValue={welcomeProgressValue}
      featureProgressValue={featureProgressValue}
      productProgressValue={productProgressValue}
      content={content}
      secondaryButton={
        <SecondaryButton className="btn outline" type="button" onClick={() => onLaterClick()}>
          Later
        </SecondaryButton>
      }
      primaryButton={<PrimaryButton onClick={() => onContinueClick()}>Continue</PrimaryButton>}
    />
  );
}
