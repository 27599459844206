import React from 'react';
import styled from '@emotion/styled';

import { ProcessedBreakdownProps } from './types';

export default function ProcessedBreakdown({ data }: ProcessedBreakdownProps) {
  return (
    <ProcessedBreakdownContainer>
      <div className="section-title">Processed Breakdown</div>
      {data.purchases > 0 ? (
        <div className="processed-row">
          <div className="processed-column">
            <div className="label">Average order value</div>
            <div className="value">{toCurrency(data.revenue / data.purchases)}</div>
          </div>
          <div className="processed-column">
            <div className="label">Number of purchases</div>
            <div className="value">{data.purchases.toLocaleString()}</div>
          </div>
          <div className="processed-column">
            <div className="label">Revenue</div>
            <div className="value green">{toCurrency(data.revenue)}</div>
          </div>
        </div>
      ) : (
        <div>Not Found</div>
      )}
    </ProcessedBreakdownContainer>
  );
}

function toCurrency(val) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val);
}

const ProcessedBreakdownContainer = styled.div`
  border-top: 1px solid #f2f3f5;
  padding-top: 16px;
  margin-top: 16px;
  .section-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .processed-row {
    display: flex;
    justify-content: space-between;
    .label {
      color: #8a8a8a;
      font-size: 12px;
    }
    .value {
      font-size: 18px;
      font-weight: 700;
    }
    .green {
      color: #2a9f01;
    }
  }
`;
