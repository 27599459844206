import React from 'react';
import styled from '@emotion/styled';

import { Select, SearchBar } from '@components/Input';

const featuresOptions = [
  { value: 'one_time_item', label: 'Add One Time Item' },
  { value: 'subscription', label: 'Subscription' },
  { value: 'product_swap', label: 'Product Swap' },
  { value: 'multiple_option', label: 'Multiple Option' },
  { value: 'ignored', label: 'Ignored' },
];

const sortOptions = [
  { value: 'most_recently_updated', label: 'Most recently updated' },
  { value: 'title_a_z', label: 'By title, A-Z' },
  { value: 'title_z_a', label: 'By title, Z-A' },
];

export function Filters(props) {
  const { filter, setFilter, setSearch, sort, setSort, replenishment } = props;

  return (
    <Container>
      <SearchBar
        handleSearch={setSearch}
        inputProps={{
          placeholder: 'Search by product titles, variant titles, or ID',
        }}
      />

      {!replenishment && (
        <Select
          className="ml-3"
          placeholder="All features"
          onChange={setFilter}
          value={filter}
          options={featuresOptions}
        />
      )}
      <Select
        className="ml-3"
        placeholder="Sort by"
        onChange={setSort}
        value={sort}
        options={sortOptions}
      />
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  z-index: 2;
  position: relative;
`;
