import React from 'react';
import styled from '@emotion/styled';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';

import { useCurrentShop } from '@hooks/useCurrentShop';

import PostPurchaseImage from './post-purchase-page.png';
import { ActivateFooter } from './ActivateFooter';

export const ActivateContent = () => {
  function openShopifySettingsNewTab() {
    window
      .open(`https://${currentShop.shopifyDomain}/admin/settings/checkout#post-purchase`, '_blank')
      .focus();
  }

  const currentShop = useCurrentShop();

  return (
    <>
      <h1>⚡ Activate Replenishment</h1>

      <p>
        Your customers are nearly ready to begin receiving replenishment messages via SMS. <br />
        You need to select the Recharge Post-Purchase Extension on your Shopify store’s checkout
        settings to continue.
      </p>

      <hr />

      <h3>Select the Recharge Post-Purchase Extension to enable replenishment:</h3>
      <img className="w-100 my-1" src={PostPurchaseImage} />
      <ExtensionLabel>Extension preview</ExtensionLabel>

      <hr />

      <ActivateFooter
        rightButtonOnClick={openShopifySettingsNewTab}
        rightButtonText="Visit your Shopify Checkout settings"
        rightButtonIcon={faExternalLinkAlt}
      />
    </>
  );
};

const ExtensionLabel = styled.span`
  text-align: center;
  color: #a9a9a9;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 8px;
  display: block;
`;
