import React, { useState } from 'react';

import productPlaceholderImage from '@assets/images/product-placeholder.png';

interface ProductImageProps {
  alt?: string;
  src?: string;
  className?: string;
  width?: string | number;
  height?: string | number;
}

export function ProductImage({ src, ...props }: ProductImageProps) {
  const [imageUrl, setImageUrl] = useState(src || productPlaceholderImage);

  function handleError() {
    setImageUrl(productPlaceholderImage);
  }

  return <img alt="product" src={imageUrl} onError={handleError} {...props} />;
}
