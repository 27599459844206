import React from 'react';
import styled from '@emotion/styled';

import productPlaceholderImage from '@assets/images/product-placeholder.png';

import { MetricBase, MetricBaseProps } from './MetricBase';

export function MostSkippedProductList(props: MetricBaseProps) {
  return (
    <MetricBase {...props}>
      {(data) => (
        <ListGroup>
          {data.length > 0 &&
            data.map((variant, index) => (
              <ListGroupItem key={variant.id}>
                <Subtitle>{index + 1}.</Subtitle>
                <Image src={variant.imageUrl || productPlaceholderImage} />
                <Subtitle>{variant.title}</Subtitle>
              </ListGroupItem>
            ))}
        </ListGroup>
      )}
    </MetricBase>
  );
}

const ListGroup = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  li:nth-child(2n) {
    background-color: #f6f6f7;
  }
`;

const ListGroupItem = styled.li`
  border: 0;
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
`;

const Image = styled.img`
  object-fit: cover;
  width: 70px;
  height: 70px;
  display: inline;
  margin: 1rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
`;

const Subtitle = styled.p`
  display: inline;
  font-size: 24px;
  line-height: 1;
  vertical-align: middle;
`;
