import React from 'react';

import WorkflowsSingleTemplateView from '@pages/Workflows/SingleTemplateView';
import ProductVariantsView from '@pages/Products/ProductVariants/View';
import SettingsAdvanced from '@pages/Settings/Advanced';

const componentsByPath = {
  '/replenishment': WorkflowsSingleTemplateView,
  '/replenishment/workflows': WorkflowsSingleTemplateView,
  '/replenishment/products': ProductVariantsView,
  '/replenishment/settings': SettingsAdvanced,
};

const propsByPath = {
  '/replenishment/products': { replenishment: true },
  '/replenishment/settings': { replenishment: true },
};

export const routes = Object.keys(componentsByPath).map((path) => {
  const Element = componentsByPath[path];
  const props = propsByPath[path] || {};

  return {
    path,
    element: <Element {...props} />,
  };
});

export const routesPaths = Object.keys(componentsByPath);
