import React from 'react';

interface PhoneProps {
  mainColor: string;
  imageUrl: string;
}

export function Phone(props: PhoneProps) {
  const { mainColor, imageUrl } = props;

  return (
    <div className="phone-container">
      <i>Speaker</i>
      <b>Camera</b>

      <div className="phone-header">
        {'\n'}
        <img src={imageUrl} className="phone-header-img" alt="" />
      </div>

      <div className="phone-body">
        <div>
          <ul className="chat">
            <li
              className="msg"
              style={{
                backgroundColor: mainColor,
              }}
            >
              info
            </li>
            <li className="msg">
              Your next order of Cacao / Refill for $39.90 is scheduled for <u>Mar 20</u> Reply
              'Modify Order' to make changes :)
            </li>
            <li
              className="msg"
              style={{
                backgroundColor: mainColor,
              }}
            >
              modify order
            </li>
            <li className="msg">
              Hi Victor, please reply with a number to modify your upcoming <u>Mar 20</u> order of
              Cacao / Refill:
              <br />
              1. Swap Flavors
              <br />
              2. Skip Upcoming Order
              <br />
              3. Update Quantity
              <br />
              4. Update Billing Info
              <br />
              5. Update Shipping Address
              <br />
              6. Update Next Charge Date
              <br />
              7. Add One-Time Item
              <br />
            </li>
            <li
              className="msg"
              style={{
                backgroundColor: mainColor,
              }}
            >
              2
            </li>
            <li className="msg">
              Hey Victor - Are you sure you want to skip your order for Cacao / Refill scheduled for
              <u> Mar 20</u>? If so, please reply 'yes' to confirm or 'no' to keep.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
