import React from 'react';
import { useTheme } from '@emotion/react';

import { Loading } from '@components/Loading';
import { SmartSwapBanner } from '@pages/Products/SwapGroups/components/SmartSwapBanner';
import { usePageOnboarding } from '@hooks/usePageOnboarding';

import { CollectionsList } from './CollectionsList';

export const SmartSwapSection = () => {
  const { loading, finished, setFinished } = usePageOnboarding('smart_swap_banner');
  const theme = useTheme();

  if (loading) return <Loading color={theme.colors.primary} />;

  if (finished) {
    return <CollectionsList />;
  }

  return <SmartSwapBanner onEnable={setFinished} />;
};
