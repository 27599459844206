import React from 'react';
import styled from '@emotion/styled';

import { useCurrentShop } from '@hooks/useCurrentShop';
import { Card } from '@components/Layout/Card';

export function Instructions() {
  const shop = useCurrentShop();

  return (
    <Container>
      <Title>Instructions</Title>
      <Steps>
        <p>
          1. Create a new Shopify page from the following URL:
          <br />
          <a href={`https://${shop?.shopifyDomain}/admin/pages`} target="_blank" rel="noreferrer">
            https://{shop?.shopifyDomain}/admin/pages
          </a>
        </p>
        <p>
          2. Title the page <strong>Subscription Management via Text</strong>
        </p>
        <p>3. In the Content section, click on the {'<>'} for "Show HTML"</p>
        <p>4. Paste the above snippet and save the page</p>
        <p>5. Preview the page and email sms@rechargepayments.com with any questions</p>
      </Steps>
    </Container>
  );
}

const Container = styled(Card)`
  padding: 1rem;
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  width: 100%;

  @media (min-width: 992px) {
    width: calc(100% - 1rem);
  }
`;

const Title = styled.h3`
  margin: 0;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`;

const Steps = styled.div`
  color: #6b6b6b;
  font-size: 16px;

  strong {
    font-weight: 500;
  }
`;
