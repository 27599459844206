import React, { forwardRef, CSSProperties } from 'react';
import { Modal as BModal } from 'react-bootstrap';

import BaseModal, { BaseModalProps } from '@components/Modal/BaseModal';

import { useWorkflowsContext } from '../WorkflowsContext';

export * from '@components/Modal/BaseModal';

interface ModalProps extends BaseModalProps {
  title?: string;
  headerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  bodyStyle?: CSSProperties;
}

function ModalComponent(props: ModalProps, ref) {
  const { children, headerComponent, footerComponent, bodyStyle, ...rest } = props;
  const { setIsDragabble } = useWorkflowsContext();

  const handleShowModal = () => setIsDragabble(false);
  const handleHideModal = () => setIsDragabble(true);

  return (
    <BaseModal ref={ref} onShow={handleShowModal} onHide={handleHideModal} {...rest}>
      {headerComponent && <BModal.Header closeButton>{headerComponent}</BModal.Header>}

      <BModal.Body style={bodyStyle}>{children}</BModal.Body>

      {footerComponent && <BModal.Footer>{footerComponent}</BModal.Footer>}
    </BaseModal>
  );
}

export const Modal = forwardRef(ModalComponent);
