import React, { useEffect, useMemo } from 'react';

import { formatString } from '@utils/date';
import styled from '@emotion/styled';
import CohortBreakdown from '@pages/Workflows/components/CohortBreakdown';
import ProcessedBreakdown from '@pages/Workflows/components/ProcessedBreakdown';

import { Loading } from '@components/Loading';
import { useTheme } from '@emotion/react';
import { useLayoutContext } from '../LayoutContext';
import { getInsights } from './api';
import Header from './shared/Header';

export const Insights = React.memo(
  ({ currentId, closeHandler }: { currentId: number; closeHandler: () => void }) => {
    const { insightsData, setInsightsData } = useLayoutContext();
    const theme = useTheme();

    useEffect(() => {
      if (!currentId || insightsData?.id === currentId) {
        return;
      }
      getData();
    }, [currentId]);

    async function getData() {
      setInsightsData(null);
      const data = await getInsights(currentId);
      setInsightsData(data);
    }

    function formateDateRange() {
      // returns a date range
      // if the range is within a calander year the year is ommited from the start date
      if (!insightsData?.startDate || !insightsData?.endDate) {
        return null;
      }
      const endFormat = 'MMM d, y';
      let startFormat = endFormat;
      if (insightsData.startDate.slice(0, 4) === insightsData.endDate.slice(0, 4)) {
        startFormat = 'MMM d';
      }
      const from = formatString(insightsData.startDate, startFormat);
      const to = formatString(insightsData.endDate, endFormat);
      return `${from} - ${to}`;
    }

    const cohortData = useMemo(() => insightsData?.data.cohortBreakdown, [
      insightsData?.data.cohortBreakdown,
    ]);

    if (!insightsData?.id || !cohortData) {
      return (
        <LoadingContainer>
          <Loading color={theme.colors.primary} />
        </LoadingContainer>
      );
    }

    return (
      <InsightsContiner>
        <Header onClose={closeHandler}>
          <div>
            <DateRange>{formateDateRange()}</DateRange>
            <h4>Insights</h4>
          </div>
        </Header>
        <InsightsBody>
          <CohortBreakdown data={cohortData} />
          <ProcessedBreakdown data={insightsData?.data.processedBreakdown} />
          <FlexGrow />
        </InsightsBody>
      </InsightsContiner>
    );
  }
);

const InsightsContiner = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  * {
    font-family: 'SFProDisplay';
  }
`;

const InsightsBody = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0px;
  padding: 16px;
  .flex {
    display: flex;
    justify-content: space-between;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Footer = styled.div`
  padding: 0 16px;
  margin-top: 16px;
  position: relative;
  .bg-divider {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const ViewMore = styled.a`
  background: #ffffff;
  border-radius: 2px;
  border: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  color: #4316ef;
  display: inline-block;
  justify-self: center;
  margin: 0;
  padding: 10px 21px;
  position: relative;

  &:after {
    background-color: #f2f3f5;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-10px);
    width: 100%;
    z-index: -1;
  }

  img {
    margin-left: 6px;
    transform: scale(1.5);
  }
`;

const DateRange = styled.div`
  color: #8a8a8a;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Greycliff';
  text-transform: uppercase;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const LoadingContainer = styled.div`
  margin-top: 32px;
`;
