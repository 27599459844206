import React from 'react';
import { useLocation } from 'react-router-dom';

import { Page } from '@components/Layout/Page';
import { useFlag } from '@hooks/useFlag';

import { Setup } from './Setup';
import { Integrations } from './Integrations';
import { NavTabs } from '../NavTabs';
import { Tabs } from './components/Tabs';
import BestPracticesGuides from './components/BestPracticesGuides';
import Deactivate from './components/Deactivate';

function Help() {
  const location = useLocation();
  const refactorSettingsPagesEnabled = useFlag('refactor_settings_pages_enabled');

  return (
    <>
      <NavTabs />
      <Page>
        {refactorSettingsPagesEnabled?.enabled ? (
          <>
            <h1 className="font-weight-bold mb-3">Help</h1>
            <BestPracticesGuides />
            <Deactivate />
          </>
        ) : (
          <>
            <NavTabs />
            <Tabs
              id="settings-help-tabs"
              defaultActiveKey={location?.hash?.substring(1) || 'setup'}
              items={[
                {
                  key: 'setup',
                  label: 'Setup',
                  component: <Setup />,
                },
                {
                  key: 'integrations',
                  label: 'CS Integrations',
                  component: <Integrations />,
                },
              ]}
            />
          </>
        )}
      </Page>
    </>
  );
}

export default Help;
