import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Page } from '@components/Layout/Page';
import axios from '@lib/axios';
import { CancellationReason } from '@typings/CancellationReason';

import { Form } from './Form';

function New() {
  const navigate = useNavigate();

  async function handleSubmit(values: CancellationReason) {
    await axios.post('/sms/cancellation_reasons', values);
    toast.success('Cancellation reason successfully created');
    navigate('/sms/cancellation_reasons');
  }

  return (
    <Page headerDescription="Create a new cancellation reason">
      <Form onSubmit={handleSubmit} />
    </Page>
  );
}

export default New;
