import React from 'react';

import { CustomerInfo } from '@components/Table/Row/CustomerInfo';
import { formatString } from '@utils/date';

import { DashboardViewPage } from './DashboardViewPage';

function EndedSubscriptions() {
  const tableHeaders = [
    {
      label: 'Customer',
      width: '32%',
    },
    {
      label: 'Product',
    },
    {
      label: 'First order date',
    },
    {
      label: 'Subscription end date',
    },
    {
      label: 'Subscription lifetime',
    },
  ];

  function tableRow(endedSubscription) {
    const data = [
      <CustomerInfo customer={endedSubscription.rechargeCustomer} />,
      <p>{endedSubscription.title}</p>,
      <p>{formatString(endedSubscription.createdAt, 'dd MMM hh:mm')}</p>,
      <p>{formatString(endedSubscription.cancelledAt, 'dd MMM hh:mm')}</p>,
      <p>{endedSubscription.lifetimeInDays} day(s)</p>,
    ];

    return { data };
  }

  return (
    <DashboardViewPage
      baseUrl="/sms/recharge/ended_subscriptions"
      headerDescription="Ended subscriptions"
      tableHeaders={tableHeaders}
      tableRow={tableRow}
      csvFilename="ended-subscriptions"
    />
  );
}

export default EndedSubscriptions;
