import axios, { AxiosRequestConfig } from 'axios';
import ReactOnRails from 'react-on-rails';

import { responseInterceptor, responseInterceptorError } from './interceptors';

/* Reads CSRF-Token from DOM, generated by csrf_meta_tags */
const csrfToken = ReactOnRails.authenticityToken();
const instance = axios.create();

instance.defaults.headers = {
  'Content-Type': 'application/json',
  'X-CSRF-Token': csrfToken,
};
instance.defaults.withCredentials = true;

instance.interceptors.response.use(responseInterceptor, responseInterceptorError);

export const fetcher = (url, config?: AxiosRequestConfig) =>
  instance.get(url, config).then((res) => res.data);

export default instance;
