import { ButtonGroup } from '@components/Button/ButtonGroup';
import { CustomButton } from '@components/Button/CustomButton';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Routes } from './Form';

interface AddonsButtonsProps {
  setRoute(route: Routes): void;
}

export function AddonsButtons(props: AddonsButtonsProps) {
  const { setRoute } = props;

  const {
    formState: { isSubmitting },
  } = useFormContext();

  function goToVariants() {
    setRoute('variants');
  }

  return (
    <ButtonGroup>
      <CustomButton variant="outline-primary" disabled={isSubmitting} onClick={goToVariants}>
        Back to Swap Options
      </CustomButton>
      <CustomButton variant="primary" type="submit" className="onboarding-swap-group-addons-save">
        {isSubmitting ? 'Loading..' : 'Save Swap Group'}
      </CustomButton>
    </ButtonGroup>
  );
}
