import styled from '@emotion/styled';
import React from 'react';
import enabledIcon from '@assets/icons/enabled-toggle.svg';
import disabledIcon from '@assets/icons/disabled-toggle.svg';

interface ToggleProps {
  name?: string;
  disabledLabel?: string;
  enabledLabel?: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  onChange?(value: boolean): void;
  children?: JSX.element;
}

export const ToggleAlt = (props: ToggleProps) => {
  const {
    name,
    checked = false,
    disabled,
    disabledLabel = 'OFF',
    enabledLabel = 'ON',
    onChange,
    label,
    children,
    ...rest
  } = props;

  return (
    <Container>
      <ToggleLabel disabled={disabled} label={label} onClick={(e) => e.stopPropagation()} {...rest}>
        <div className="label">{label}</div>
        <input
          name={name}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={() => onChange?.(!checked)}
        />
        <div className="slider-bg" />
        <div className="slider">
          {(checked && <img src={enabledIcon} />) || <img src={disabledIcon} />}
        </div>
        {(checked && <span className="status checked">{enabledLabel}</span>) || (
          <span className="status unchecked">{disabledLabel}</span>
        )}
      </ToggleLabel>
      {children && <Subtitle>{children}</Subtitle>}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

type ToggleLabelProps = {
  disabled?: boolean;
  label?: string;
};

const ToggleLabel = styled.label<ToggleLabelProps>`
  align-items: center;
  border-radius: 9px;
  border: 1px solid #d7d7d7;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 25px;
  margin: 0;
  padding: 0;
  position: relative;
  width: ${(props) => (props.label ? '100%' : '73px')};
  overflow: hidden;
  font-size: 12px;
  background-color: #fff;

  filter: ${(props) => (props.disabled ? 'grayscale(1)' : 'grayscale(0)')};
  transition: filter 700ms ease-in-out;

  .label {
    color: #000;
    font-weight: 600;
    margin-left: 8px;
    margin-top: 2px;
  }

  input {
    display: none;
  }

  .slider {
    transition: transform 0.4s ease-in-out;
    z-index: 2;
    img {
      display: block;
      margin: auto;
      margin-top: 4px;
    }
  }

  .slider {
    background-color: white;
    border-radius: 7px;
    border: 1px solid #dd1818;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    height: 19px;
    right: 2px;
    position: absolute;
    top: 2px;
    width: 19px;
    transform: translateX(-48px);
  }

  .slider-bg {
    background-color: #ffc4b5;
    border-radius: 9px;
    height: 26px;
    right: -1px;
    position: absolute;
    top: -1px;
    width: 73px;
    border: 1px solid #d7d7d7;
  }

  .status {
    color: #787878;
    font-weight: 600;
    position: absolute;
    text-align: center;
    font-size: 14px;
    margin-top: 2px;
  }

  .checked {
    right: 36px;
  }

  .unchecked {
    right: 12px;
  }

  input:checked {
    ~ .checked {
    }

    ~ .unchecked {
    }

    ~ .slider {
      border-color: #3fbf3d;
      transform: translateX(0);
    }

    ~ .slider-bg {
      background-color: #c2ede8;
    }
  }
`;

const Subtitle = styled.div`
  margin: 4px 8px;
  font-size: 12px;
  color: rgba(107, 107, 107, 1);
`;
