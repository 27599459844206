import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { updateCustomize } from '@api/sms';

import '@pages/Settings/Features/shared/FeatureSwitch/featureSwitch.scss';
import './storeEmail.scss';

export const EmailForm = ({
  emailUpdatedHandler,
  emailChangedHandler,
  storeEmails,
  formErrors,
  autoSave = true,
}) => {
  const [updateError, setUpdateError] = useState(false);

  const handleEmailSave = () => {
    if (autoSave) {
      updateCustomize({ shop: { custom_email: storeEmails.original } })
        .then(() => {
          setUpdateError(false);
          emailUpdatedHandler();
        })
        .catch(() => {
          setUpdateError(true);
        });
    } else {
      emailUpdatedHandler();
    }
  };

  const hasFormErrors = Object.values(formErrors).includes(true);

  return (
    <div className="settings__email-form-container">
      <Form.Group>
        <Form.Label>Preferred Support Email</Form.Label>
        <Form.Control
          type="email"
          id="store-email"
          data-testid="store-email-original"
          placeholder="support@shop.com"
          value={storeEmails.original}
          isInvalid={formErrors.format}
          onChange={(e) => emailChangedHandler(e.target.value, 'original', 'confirmation')}
        />
        <Form.Control.Feedback type="invalid">Invalid email format.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Confirm Support Email</Form.Label>
        <Form.Control
          type="email"
          id="store-email-confirmation"
          data-testid="store-email-confirmation"
          placeholder="support@shop.com"
          defaultValue={storeEmails.confirmation}
          isInvalid={formErrors.confirmation}
          onChange={(e) => emailChangedHandler(e.target.value, 'confirmation', 'original')}
        />
        <Form.Control.Feedback type="invalid">
          The email confirmation does not match your email address.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Button
          className="float-right"
          variant="primary"
          onClick={handleEmailSave}
          disabled={hasFormErrors || storeEmails.original === null}
        >
          Confirm
        </Button>

        {updateError && (
          <p className="settings_error">
            An error ocurred when saving your email. Try again later.
          </p>
        )}
      </Form.Group>
    </div>
  );
};

export default EmailForm;
