import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import VariablesInput, { VariableInputHandle } from '@components/Input/VariablesInput';

interface EditMessageProps {
  prefix?: string;
  variables?: string[];
}

// TODO: get the variables from the backend
const defaultVariables = [
  // customer
  'customer_first_name',
  'customer_last_name',
  'customer_email',
  'customer_phone',
  // product variant
  'product_title',
  'product_price',
  // shop
  'shop_name',
  'shop_domain',
  'shop_email',
  'shop_phone',
];

export function EditMessage({ prefix = '', variables = [] }: EditMessageProps) {
  const { register, setValue, getValues } = useFormContext();
  const variableInputRef = useRef<VariableInputHandle>();

  const availableVariables = [...new Set([...defaultVariables, ...variables])];

  const bodyValue = getValues(`${prefix}.body`);

  return (
    <Container>
      {!prefix && (
        <FormRow>
          <Label>Title</Label>
          <FormControl {...register(`${prefix}.title`, { required: true })} />
        </FormRow>
      )}
      {prefix === 'email' && (
        <FormRow>
          <Label>Subject</Label>
          <FormControl
            {...register(`${prefix}.subject`, { required: true })}
            placeholder="Enter email subject title"
          />
        </FormRow>
      )}
      <FormRow>
        <Label style={{ marginBottom: 0 }}>Body</Label>
        <VariablesInputContainer>
          <VariablesInput
            ref={variableInputRef}
            value={bodyValue}
            variables={availableVariables}
            onChange={(body) => setValue(`${prefix}.body`, body)}
          />
        </VariablesInputContainer>
      </FormRow>
      <FormRow>
        <Label>Available Variables</Label>
        <VariablesContainer>
          {availableVariables?.map((variable) => (
            <VariableBadge
              key={variable}
              onClick={() => variableInputRef.current?.addVariable(variable)}
              className="btn btn-sm btn-secondary btn-variable"
            >
              {variable}
            </VariableBadge>
          ))}
        </VariablesContainer>
      </FormRow>
    </Container>
  );
}

const Container = styled.div`
  margin: 0.5rem 0;
`;

const Label = styled(Form.Label)`
  color: #191d48;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 0.25rem;
`;

const FormRow = styled(Form.Row)`
  flex-direction: column;
  padding: 0.75rem 0;
  margin: 0;

  tags {
    background: ${(props) => props.theme.colors.light0};
  }
`;

const FormControl = styled(Form.Control)`
  border-radius: 0;
  margin: 0 -14px;
  padding: 0 14px;
  border: none;
  border-bottom: 1px solid #b6b6bd;
  border-top: 1px solid #b6b6bd;
  width: calc(100% + 28px);
`;

const VariablesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const VariableBadge = styled.div`
  text-transform: none;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
`;

const VariablesInputContainer = styled.div`
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin: 5px -16px 0;
  padding: 8px 16px;

  .tagify--mix {
    border: none !important;
  }
`;
