import React from 'react';
import toast from 'react-hot-toast';
import { useForm, FormProvider } from 'react-hook-form';

import axios from '@lib/axios';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { updateCustomize } from '@api/sms';

import { Branding } from './Branding';

interface BrandingContainerProps {
  onSuccess?(): void;
}

export function BrandingContainer(props: BrandingContainerProps) {
  const { onSuccess } = props;
  const shop = useCurrentShop();

  const formProps = useForm({
    shouldUnregister: false,
    defaultValues: {
      custom_name: shop?.customName,
      logo_url: shop?.logoUrl,
      main_color: shop?.mainColor,
    },
    mode: 'all',
  });
  const { handleSubmit } = formProps;

  function update(formData) {
    function success() {
      shop.refetch();

      onSuccess?.();

      return 'Successfully updated!';
    }

    toast.promise(updateCustomize(formData), {
      loading: 'Updating...',
      success,
      error: 'Something went wrong! try again later.',
    });
  }

  async function processUpload(file) {
    const data = new FormData();
    data.append('logo', file.file, file.name);

    await axios.put('/sms/update_logo', data);
  }

  return (
    <FormProvider {...formProps}>
      <Branding handleSubmit={handleSubmit(update)} processUpload={processUpload} />
    </FormProvider>
  );
}
