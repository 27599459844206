import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons/faExpandAlt';
import { faCompressAlt } from '@fortawesome/free-solid-svg-icons/faCompressAlt';

import { ifStyle } from '@utils/styles';

import { Templates } from './Templates';
import { Workflows } from './Workflows';
import { useWorkflowsContext } from '../WorkflowsContext';
import { useLayoutContext } from '../LayoutContext';

interface SidebarProps {
  showBackButton?: boolean;
  showTemplateTitle?: boolean;
}

export function Sidebar(props: SidebarProps) {
  const { showBackButton, showTemplateTitle } = props;
  const { selectedView, expanded, setExpanded } = useWorkflowsContext();
  const { rightSidebar, setLeftSidebar } = useLayoutContext();

  useEffect(() => {
    if (rightSidebar?.open) {
      setExpanded(false);
    }
  }, [rightSidebar?.open]);

  useEffect(() => {
    setLeftSidebar({ open: expanded });
  }, [expanded]);

  function toggleExpanded() {
    setExpanded((prevExpanded) => !prevExpanded);
  }

  const views = {
    templates: <Templates />,
    workflows: <Workflows showBackButton={showBackButton} showTemplateTitle={showTemplateTitle} />,
  };

  return (
    <Container expanded={expanded}>
      {views[selectedView]}

      <ExpandIcon
        onClick={toggleExpanded}
        className={`${expanded ? 'expanded' : ''}`}
        icon={expanded ? faCompressAlt : faExpandAlt}
      />
    </Container>
  );
}

interface ExpandedProps {
  expanded: boolean;
}

const Container = styled.div<ExpandedProps>`
  height: 100%;
  padding: 0.75rem 0;
  box-shadow: ${(props) => `2px 0px 4px ${props.theme.colors.dark0}44`};
  z-index: 5;
  background: #44466b;
  display: flex;
  justify-content: center;
  position: relative;
  width: ${(props) => (props.expanded ? '325px' : '50px')};
  transition: width 500ms ease-in-out;

  > div {
    opacity: 0;
    transition: 150ms;
    transition-delay: 0ms;
    pointer-events: none;

    ${ifStyle('expanded')(css`
      transition: 250ms ease-in-out;
      transition-delay: 250ms;
      opacity: 1;
      pointer-events: auto;
    `)}
  }
`;

const ExpandIcon = styled(FontAwesomeIcon)`
  color: white;
  margin: auto 0;
  cursor: pointer;
  font-size: 1.15rem;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);

  &.expanded {
    transform: translateX(0);
    top: 10px;
    right: 10px;
  }
`;
