import styled from '@emotion/styled';
import React from 'react';

interface ActionDefaultProps {
  label: string;
}

const ActionDefault = ({ label }: ActionDefaultProps) => (
  <Container>
    <Title>{label}</Title>
  </Container>
);

const Container = styled.div`
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 5px 15px;
  width: 150px;
`;

const Title = styled.strong`
  font-size: 12px;
  color: #000;
`;

export default ActionDefault;
