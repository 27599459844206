import React from 'react';
import styled from '@emotion/styled';

interface BadgeProps {
  color?: string;
  textColor?: string;
  label: string;
  upperCase?: boolean;
}

export function Badge(props: BadgeProps) {
  const { color = '#dbf2f0', textColor = '#000000', label, upperCase = true, ...rest } = props;

  return (
    <BadgeComponent color={color} textColor={textColor} {...rest}>
      {upperCase ? label.toUpperCase() : label}
    </BadgeComponent>
  );
}

interface BadgeComponentProps {
  color: string;
  textColor: string;
}

const BadgeComponent = styled.div<BadgeComponentProps>`
  min-width: 60px;
  width: fit-content;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 2px;
  padding: 0 10px;
  color: ${(props) => props.textColor};
  background: ${(props) => props.color};
`;
