import React from 'react';
import Form from 'react-bootstrap/Form';
import { Toggle } from '@components/Input/Toggle';
import { Card } from '@components/Layout/Card';
import { SaveButton } from '@components/Button/SaveButton';

export const MiscellaneousSettings = ({ tagOptIns, setTagOptIns, isLoading }) => (
  <Card className="h-auto mb-4" data-testid="miscellaneous-settings-component">
    <div className="d-flex align-items-center px-4 pt-3">
      <h4 className="font-weight-bold mb-0">Miscellaneous Settings</h4>
      <SaveButton type="submit" disabled={isLoading}>
        Save Misc settings
      </SaveButton>
    </div>
    <hr className="mb-0" />

    <div className="d-flex flex-column">
      <div className="form-row bg-light pt-3 px-4">
        <div className="form-group col-6">
          <Form.Label className="font-weight-bold">Tag customers in Shopify</Form.Label>
          <Form.Text>
            To distinguish within Shopify which customers are enrolled in your SMS program, turn on
            this switch. You'll see `rcSMS opt-in` as a tag on each applicable customer's page.
          </Form.Text>
          <Toggle
            className="ml-n3"
            checked={tagOptIns}
            onChange={async () => {
              await setTagOptIns(!tagOptIns);
            }}
            size="sm"
          />
          ,
        </div>
      </div>
    </div>
  </Card>
);
