import React from 'react';
import styled from '@emotion/styled';
import { BrowserRouter } from 'react-router-dom';

import { Footer } from '@components/Layout/Footer';
import { Activate as ReplenishmentActivate } from '@components/Replenishment/Activate';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { Shop } from '@typings/Shop';

import { OnboardingRouter } from '@components/Onboarding/v2/OnboardingRouter';
import { useOnboardingV2 } from '@hooks/useOnboardingV2';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { BaseAppProvider } from '@components/BaseAppContext';
import ExpandIcon from '@assets/icons/expand-btn.svg';
import { navigationSlice } from '@redux/reducer/navigationSlice';
import { ContactCardModal } from '@components/Modal/ContactCardModal/ContactCardModal';
import AppGlobals from './AppGlobals';

interface BaseAppProps {
  shop: Shop;
  layout: 'embedded_app' | 'replenishment';
  Router: React.ElementType;
  Sidebar: React.ElementType;
}

export function BaseApp(props: BaseAppProps) {
  const { shop, layout, Router, Sidebar } = props;

  useOnboardingV2();

  const onboardingState = useAppSelector((state) => state.onboarding);
  const navigationState = useAppSelector((state) => state.navigation);

  const dispatch = useAppDispatch();

  const onExpandButtonClick = () => {
    dispatch(navigationSlice.actions.setSideNavCollapsed(false));
  };

  return (
    <ErrorBoundary>
      <Wrapper>
        <BrowserRouter>
          <BaseAppProvider>
            <AppContainer>
              <PageContainer className="page-container">
                {/* {navigationState.isSideNavCollapsed && (
                  <ExpandButtonContainer onClick={onExpandButtonClick}>
                    <ExpandButton src={ExpandIcon} />
                  </ExpandButtonContainer>
                )} */}
                <React.Suspense fallback={<span />}>
                  {onboardingState.isDisplay && <OnboardingRouter />}
                  <Router />
                  <Footer />
                </React.Suspense>
              </PageContainer>
            </AppContainer>

            <Sidebar shop={shop} />

            <AppGlobals />
            {layout === 'replenishment' && <ReplenishmentActivate />}
            <ContactCardModal />
          </BaseAppProvider>
        </BrowserRouter>
      </Wrapper>
    </ErrorBoundary>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
`;

const AppContainer = styled.div`
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto !important;
  position: relative;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ExpandButton = styled.img`
  width: 16px;
  height: 12px;
  margin-left: 2px;
`;

const ExpandButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2e3685;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 16px;
  top: 10px;
  cursor: pointer;
  z-index: 1000 !important;
`;
