import React from 'react';
import styled from '@emotion/styled';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface CardProps {
  showHeader?: boolean;
  headerBackground?: string;
  headerTooltip?: string;
  headerTooltipIcon?: string;
  children?: React.ReactNode;
}

export function Card(props: CardProps) {
  const { showHeader, headerBackground, headerTooltip, headerTooltipIcon, children } = props;

  return (
    <Container>
      {showHeader && (
        <Header background={headerBackground}>
          {!!headerTooltip && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${headerTooltip.replace(' ', '-')}`}>{headerTooltip}</Tooltip>
              }
            >
              <img src={headerTooltipIcon} alt="Tooltip Icon" />
            </OverlayTrigger>
          )}
        </Header>
      )}
      <Content>{children}</Content>
    </Container>
  );
}

const Container = styled.div`
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  position: relative;
  margin-top: 1.5rem;
  height: 185px;
`;

const Header = styled.div<{ background?: string }>`
  background: ${(props) => props?.background};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 50px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: -25px;
  width: 100%;

  img {
    margin-top: 4px;
  }
`;

const Content = styled.div`
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 182px;
  padding: 20px;
  position: relative;
  width: 100%;
  z-index: 1;
`;
