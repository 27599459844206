import React from 'react';
import { Col } from 'react-bootstrap';
import { EmptyModalView } from '@components/Onboarding/v2/components/Modal/EmptyModalView';
import { FeaturesView } from '@components/Onboarding/v2/components/FeaturesView';
import { SimulatorView } from '@components/Onboarding/v2/components/SimulatorView';
import { FeatureContainer } from '@components/Onboarding/v2/components/styling/OnboardingViewStyling';

export const FeatureSimulatorView = () => (
  <>
    <EmptyModalView />
    <FeatureContainer highlightSimulator>
      <Col sm={6}>
        <FeaturesView disabled />
      </Col>
      <Col sm={6} className="simulator-onboarding">
        <SimulatorView />
      </Col>
    </FeatureContainer>
  </>
);
