import React from 'react';
import styled from '@emotion/styled';

import Circle from './Circle';
import { DonutKeyProps } from './types';

export default function DonutKey({
  data,
  totalUsers,
  colors = ['#0069A4', '#1DCAE1', '#000'],
}: DonutKeyProps) {
  return (
    <KeyContainer>
      <div className="title">Key</div>
      {data.map((item, i) => (
        <div className="key-row" key={i}>
          <Circle color={colors[i]} />
          <div className="flex-grow key-text">{item[0]}</div>
          <div className="right-column">
            <div className="grey-text">{Math.round(10 * ((item[1] / totalUsers) * 100)) / 10}%</div>
            <div className="sub-text">{item[1].toLocaleString()}</div>
          </div>
        </div>
      ))}
    </KeyContainer>
  );
}

const KeyContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  font-family: 'SFProDisplay';
  .title {
    font-size: 8px;
    text-transform: uppercase;
    font-weight: 900;
  }
  .key-row {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    .right-column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 12px;
    }
  }
  .key-text {
    font-size: 12px;
  }
  .grey-text {
    color: #8a8a8a;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .sub-text {
    font-size: 8px;
    text-transform: uppercase;
    font-weight: 700;
  }
`;
