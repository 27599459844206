import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

import { ActivateContent } from './ActivateContent';

interface ActivateModalProps {
  show?: boolean;
  onHide?(): void;
}

export function ActivateModal(props: ActivateModalProps) {
  const { show, onHide } = props;

  return (
    <Modal size="lg" centered show={show} onHide={onHide}>
      <CloseButton icon={faTimes} onClick={onHide} />
      <Content>
        <ActivateContent />
      </Content>
    </Modal>
  );
}

const CloseButton = styled(FontAwesomeIcon)`
  color: #9a9b9c;
  cursor: pointer;
  font-size: 20px;
  margin: 1rem;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
`;

interface ContentProps {
  showBg?: boolean;
}

const Content = styled.div<ContentProps>`
  border-radius: 8px;
  padding: 45px;
  padding-top: 0;
  padding-bottom: 25px;

  h1 {
    font-weight: bold;
    font-size: 24px;
  }

  h2,
  h3 {
    font-weight: 600;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }

  .btn {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    padding: 14px 68px;
  }
`;
