import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from '@emotion/styled';
import PauseOnboardingButton from '@components/Onboarding/v2/components/PauseOnboardingButton';

export const EmptyModalView = () => (
  <div className="onboarding__root">
    <EmptyModal show backdrop="static" />
    <PauseOnboardingButton />
  </div>
);

const EmptyModal = styled(Modal)`
  .modal-dialog .modal-content {
    display: none;
  }
`;
