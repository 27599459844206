import styled from '@emotion/styled';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import React from 'react';
import Loading from '@components/Loading';
import { CenterLoading } from '../styling/OnboardingViewStyling';

interface SidebarLayoutProps {
  flowTitle: string;
  stepTitle: string;
  welcomeProgressValue: number;
  featureProgressValue: number;
  productProgressValue: number;
  content: React.ReactElement;
  primaryButton: React.ReactElement;
  secondaryButton?: React.ReactElement;
  loading?: boolean;
  collapsed?: boolean;
  onCollapsedClick?: () => void;
}

export const SidebarLayout = (props: SidebarLayoutProps) => {
  const {
    flowTitle,
    welcomeProgressValue,
    featureProgressValue,
    productProgressValue,
    stepTitle,
    content,
    primaryButton,
    secondaryButton = null,
    loading = false,
    collapsed = false,
    onCollapsedClick = () => {},
  } = props;

  const renderFooter = () => {
    if (secondaryButton) {
      return (
        <>
          <LeftCol sm={4}>{secondaryButton}</LeftCol>
          <RightCol sm={8}>{primaryButton}</RightCol>
        </>
      );
    }

    return primaryButton;
  };

  return collapsed ? (
    <CollapsedContainer onClick={onCollapsedClick}>
      <Col>
        <CustomRow>
          <CollapseTitle>{stepTitle}</CollapseTitle>
        </CustomRow>

        <ProgressRow>
          <CustomProgressBar now={welcomeProgressValue} />
          <CustomProgressBar now={featureProgressValue} />
          <CustomProgressBar now={productProgressValue} />
        </ProgressRow>
      </Col>
    </CollapsedContainer>
  ) : (
    <Container>
      <Col>
        <CustomRow>
          <FlowTitle>{flowTitle}</FlowTitle>
        </CustomRow>

        <ProgressRow>
          <CustomProgressBar now={welcomeProgressValue} />
          <CustomProgressBar now={featureProgressValue} />
          <CustomProgressBar now={productProgressValue} />
        </ProgressRow>
        <CustomRow>
          <Title>{stepTitle}</Title>
        </CustomRow>
      </Col>
      <HorizontalLine />
      <Col>
        <ContentRow>
          {loading ? (
            <Row>
              <CenterLoading />
            </Row>
          ) : (
            <Content>{content}</Content>
          )}
        </ContentRow>
        <FooterRow>{!loading && renderFooter()}</FooterRow>
      </Col>
    </Container>
  );
};

const CustomRow = styled(Row)`
  margin-left: 0px;
  margin-right: 0px;
`;

const ProgressRow = styled(CustomRow)`
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const CustomProgressBar = styled(ProgressBar)`
  width: 32%;
  border-radius: 30px;
  height: 0.5rem;
  background-color: #c2ede8;

  .progress-bar {
    background-color: #17cc9d;
  }
`;

const FlowTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.47px;
  color: #191d48;
`;

const CollapseTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.47px;
  color: #191d48;
  font-weight: bold;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #191d48;
`;

const HorizontalLine = styled.hr`
  border: 1px solid #f4f4f4;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  transform: rotate(0.06deg);
`;

const Container = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2000 !important;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 11px;
  padding-bottom: 20px;
  color: #191d48;
`;

const CollapsedContainer = styled(Container)`
  padding-bottom: 0px !important;
  cursor: pointer;
`;

const Content = styled.p`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.47px;
  color: #191d48;
`;

const ContentRow = styled(CustomRow)`
  height: 250px;
`;

const FooterRow = styled(CustomRow)`
  height: 40px;
`;

const LeftCol = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
`;

const RightCol = styled(Col)`
  padding-left: 10px;
  padding-right: 0px;
`;
