import { createSlice } from '@reduxjs/toolkit';
import { OnboardingData } from '@typings/Onboarding';
import { bool } from 'yup';

interface State {
  loading: boolean;
  isModalOpen: boolean;
  isSimulating: boolean;
  isEverSimulate: boolean;
  isDisplay: boolean;
  isSidebarCollapsed: boolean;
  isEnablingFeatures: boolean;
  isFeaturesEnabled: boolean;
  isFeaturesCollapse: boolean;
  isFormattingProductTitle: boolean;
  isProductTitleFormatted: boolean;
  isShowCompletedFormatTitleBanner: boolean;
  numProducts: number;
  error: string;
  data: OnboardingData;
}

const initialState: State = {
  loading: false,
  isModalOpen: false,
  isSimulating: false,
  isEverSimulate: false,
  isDisplay: false,
  isSidebarCollapsed: false,
  isEnablingFeatures: false,
  isFeaturesEnabled: false,
  isFeaturesCollapse: false,
  isFormattingProductTitle: false,
  isProductTitleFormatted: false,
  isShowCompletedFormatTitleBanner: false,
  numProducts: 0,
  data: null,
  error: null,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOnboardingModal: (state, action) => {
      state.isModalOpen = action.payload;
    },

    setOnboardingData: (state, action) => {
      state.loading = false;
      state.data = action.payload;

      if (state.data) {
        state.isDisplay = !state.data.finished_at && !state.data.paused_at;
        state.isSidebarCollapsed = state.data.paused_at !== null;
        state.isModalOpen = state.isDisplay;
      }

      state.isEverSimulate = false;
      state.isSimulating = false;
      state.isEnablingFeatures = false;
      state.isFormattingProductTitle = false;
      state.isProductTitleFormatted = false;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    startSimulate: (state) => {
      state.isEverSimulate = true;
      state.isSimulating = true;
    },

    stopSimulate: (state) => {
      state.isSimulating = false;
    },

    startEnableFeatures: (state) => {
      state.isEnablingFeatures = true;
    },

    setFeaturesEnabled: (state, action) => {
      state.isFeaturesEnabled = action.payload;
      if (state.isFeaturesEnabled) {
        state.isFeaturesCollapse = true;
      }
    },

    setFeaturesCollape: (state, action) => {
      state.isFeaturesCollapse = action.payload;
    },

    completeEnableFeatures: (state) => {
      state.isEnablingFeatures = false;
      state.isFeaturesEnabled = true;
    },

    setNumProducts: (state, action) => {
      state.numProducts = action.payload;
    },

    startFormattingProductTitle: (state) => {
      state.isFormattingProductTitle = true;
    },

    completeFormattingProductTitle: (state) => {
      state.isFormattingProductTitle = false;
      state.isProductTitleFormatted = true;
    },

    setShowCompletedFormatTitleBanner: (state, action) => {
      state.isShowCompletedFormatTitleBanner = action.payload;
    },

    setSidebarCollapsed: (state, action) => {
      state.isSidebarCollapsed = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});
