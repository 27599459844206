import React from 'react';
import styled from '@emotion/styled';

import logoPlaceholder from '@assets/images/logo-placeholder.svg';
import { useCurrentShop } from '@hooks/useCurrentShop';
import phone from '@utils/phone';
import infoIcon from '@assets/icons/info.svg';

import { Card } from './Card';

export function ShopCard() {
  const shop = useCurrentShop();
  const phoneNumber = shop?.setting?.bandwidth_from_number || shop?.setting?.twilio_from_number;

  return (
    <Card
      showHeader
      headerBackground="linear-gradient(109.08deg, #2f335b 66.82%, #585e9e 89.56%)"
      headerTooltip="Shop Info"
      headerTooltipIcon={infoIcon}
    >
      <Container>
        <div>
          <Name>{shop?.name}</Name>
          <PhoneNumber>{phone.format(phoneNumber)}</PhoneNumber>
        </div>
        <Logo src={shop?.logoUrl || logoPlaceholder} />
      </Container>
    </Card>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 300px;
`;

const Name = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
`;

const PhoneNumber = styled.p`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.47px;
  color: #333333;
  opacity: 0.9;
`;

const Logo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
`;
