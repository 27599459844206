import { ConfirmationModal, ModalHandles } from '@components/Modal/ConfirmationModal';
import { Toggle } from '@components/Input/Toggle';
import React, { useRef } from 'react';
import { useWorkflowsContext } from '../WorkflowsContext';

export function WorkflowToggle() {
  const { currentWorkflow, handleToggleWorkflowActive } = useWorkflowsContext();
  const modalRef = useRef<ModalHandles>(null);

  function toggleModal() {
    modalRef?.current?.toggleModal();
  }

  return (
    <>
      {currentWorkflow.id !== 0 && (
        <Toggle
          disabledLabel="Inactive"
          enabledLabel="Active"
          checked={!!currentWorkflow?.activeAt}
          onChange={(checked) => (checked ? handleToggleWorkflowActive() : toggleModal())}
        />
      )}

      <ConfirmationModal
        ref={modalRef}
        title="Deactivate Workflow?"
        description="Deactivating this workflow will prevent it’s roll out to customers subscribed to this product. Are you sure you want to deactivate it?"
        onConfirm={handleToggleWorkflowActive}
      />
    </>
  );
}
