import React from 'react';
import styled from '@emotion/styled';
import { Modal, Button } from 'react-bootstrap';

import { CloseModalIcon } from '@components/Modal/CloseModalIcon';

interface BatchActionsModalProps {
  show: boolean;
  onClose(): void;
  onConfirm(): void;
  enabled?: boolean;
}

const ModalFooter = (props) => {
  const { onConfirm, onClose } = props;

  return (
    <Modal.Footer>
      <Button variant="outline-danger" onClick={onConfirm}>
        Yes, I'm sure
      </Button>

      <Button variant="primary" onClick={onClose}>
        Nevermind
      </Button>
    </Modal.Footer>
  );
};

const BatchActionsModal = (props: BatchActionsModalProps) => {
  const { show, onClose, onConfirm, enabled = false } = props;

  const bodyCopies = {
    whenDisabled: `Enabling this will cause you to lose access to certain features. This option should only
      be considered for stores that sell bundled products.`,
    whenEnabled: `Disabling this will cause your customers to lose bundled subscription functionality. We
      recommend only disabling Batch Actions if you no longer sell bundled subscriptions.`,
  };

  const bodyCopy = enabled ? bodyCopies.whenEnabled : bodyCopies.whenDisabled;

  return (
    <StyledModal show={show} centered>
      <CloseModalIcon onClose={onClose} />

      <div className="text-center">
        <h4>Are you sure?</h4>

        <Modal.Body>
          <p>{bodyCopy}</p>
        </Modal.Body>

        <ModalFooter onClose={onClose} onConfirm={onConfirm} />
      </div>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  padding: 35px;

  .modal-footer {
    display: flex;
    justify-content: center;
    border-top: none;
    padding: 25px;

    button {
      padding: 8px 35px;
    }
  }
`;

export default BatchActionsModal;
