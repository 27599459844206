import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Row, Col } from 'react-bootstrap';

import BatchActionsModal from './BatchActionsModal';

interface BatchActionsBannerProps {
  onEnable(): void;
}

const BatchActionsBanner = (props: BatchActionsBannerProps) => {
  const { onEnable } = props;
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const enableBatchActions = async () => {
    toggleModal();
    onEnable();
  };

  return (
    <>
      <Container>
        <Row className="m-0">
          <Col xs={8} className="p-0">
            <strong>If your store sells bundled subscriptions</strong> to customers, you'll need to
            use <strong>Batch Actions</strong> to make sure your experience is optimized for your
            brand.
          </Col>
          <Col xs={4} className="p-0 text-right">
            <StyledButton type="button" variant="primary" onClick={toggleModal}>
              <strong>Batch Actions</strong>
            </StyledButton>
          </Col>
        </Row>
      </Container>

      <BatchActionsModal show={showModal} onConfirm={enableBatchActions} onClose={toggleModal} />
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #e5e5e5;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
`;

const StyledButton = styled(Button)`
  padding: 8px 32px 8px 32px;
`;

export default BatchActionsBanner;
