import React from 'react';
import styled from '@emotion/styled';
import Dropdown, { DropdownProps } from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';

export interface DropdownOption {
  label: string;
  onClick: any;
  icon?: IconProp;
}

interface DropdownOptionsProps {
  id: string;
  options: DropdownOption[];
  drop?: DropdownProps['drop'];
  className?: string;
}

export function DropdownOptions(props: DropdownOptionsProps) {
  const { id, options, drop = 'left', className } = props;

  return (
    <Dropdown className={className} drop={drop}>
      <DropdownToggle id={id}>
        <Options icon={faEllipsisV} size="2x" />
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option, index) => (
          <DropdownItem key={index} onClick={option.onClick}>
            {option.label}
            {option?.icon && <DropdownIcon icon={option.icon} />}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

const Options = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.dark1};

  &:hover {
    color: ${(props) => props.theme.colors.dark0};
  }
`;

const DropdownToggle = styled(Dropdown.Toggle)`
  align-self: self-start;
  margin: 0 10px;
  padding: 0 !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  background: transparent !important;

  &::after {
    display: none;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.45);
  border-radius: 6px;
  overflow: hidden;
`;

const DropdownItem = styled(Dropdown.Item)`
  font-size: 12px;
  line-height: 14px;
  color: black;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.light3}`};
  padding: 0.5rem 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #f7f7f7;
  }

  &:last-child {
    border: 0;
  }
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  margin: 0 5px;
`;
