import React, { useState } from 'react';
import { uniqueId } from 'lodash';

import { ImageUpload } from './ImageUpload';

export interface IFile {
  id?: string;
  name?: string;
  url: string;
  file?: File;
}

interface ImageUploadProps {
  defaultFile?: IFile;
  processUpload(file: IFile): void;
}

function ImageUploadContainerComponent(props: ImageUploadProps) {
  const { defaultFile, processUpload } = props;
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<IFile>(defaultFile);

  async function handleUpload(files) {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      url: URL.createObjectURL(file),
    }));
    const uploadedFile = uploadedFiles[0];

    setLoading(true);

    await processUpload(uploadedFile);
    setUploadedFile(uploadedFile);

    setLoading(false);
  }

  return (
    <ImageUpload
      loading={loading}
      handleUpload={handleUpload}
      uploadedFile={uploadedFile}
      setUploadedFile={setUploadedFile}
    />
  );
}

function propsAreEqual(oldProps, newProps) {
  return oldProps?.defaultFile?.url === newProps?.defaultFile?.url;
}

export const ImageUploadContainer = React.memo(ImageUploadContainerComponent, propsAreEqual);
