import React from 'react';
import { Badge } from 'react-bootstrap';
import styled from '@emotion/styled';

import { ProductVariant } from '@typings/ProductVariant';

interface FeaturesProps {
  productVariant: ProductVariant & {
    productSwap?: boolean;
    swapGroup?: boolean;
    subscription?: boolean;
    oneTimeItem?: boolean;
    productPresent?: boolean;
    multipleOption?: boolean;
  };
}

export function Features(props: FeaturesProps) {
  const { productVariant } = props;

  const features = [
    {
      condition: productVariant.productSwap,
      className: 'green',
      label: 'PRODUCT SWAP',
    },
    {
      condition: productVariant.swapGroup,
      className: 'green',
      label: 'SWAP GROUP',
    },
    {
      condition: productVariant.subscription,
      className: 'purple',
      label: 'SUBSCRIPTION',
    },
    {
      condition: productVariant.oneTimeItem,
      className: 'purple',
      label: 'ADD-ON',
    },
    {
      condition: productVariant.productPresent,
      className: 'green',
      label: 'TEXT TO ORDER',
    },
    {
      condition: productVariant.multipleOption,
      className: 'yellow',
      label: 'MULTIPLE OPTION',
    },
    {
      condition: productVariant.ignoredAt,
      className: 'red',
      label: 'IGNORED',
    },
  ];

  return (
    <>
      {features.map(
        (feature) =>
          feature.condition && (
            <StyledBadge key={feature.label} className={feature.className}>
              {feature.label}
            </StyledBadge>
          )
      )}
    </>
  );
}

const StyledBadge = styled(Badge)`
  border-radius: 10rem;
  color: black;
  font-weight: 600;
  margin: 2px;
  padding-top: 0.4rem;

  &.green {
    color: black;
    background-color: #99e1d9;
  }

  &.purple {
    background-color: #cdc1f4;
  }

  &.yellow {
    background-color: #f4f3c0;
  }

  &.red {
    background-color: #f4c0c0;
  }
`;
