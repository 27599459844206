import isEqual from 'lodash/isEqual';
import { ActionCreator } from '.';

export const createDelayOrder: ActionCreator = (selectedActions, actionToCreate) => {
  const selectedDelays = selectedActions.filter((action) => action.type === 'delay_order');

  const firstAvailableOption = availableDelayOptions.filter(
    (delayOptions) =>
      !selectedDelays.find((selectedDelay) => isEqual(selectedDelay.params, delayOptions.params))
  )[0];

  return { ...actionToCreate, params: { ...firstAvailableOption.params } };
};

export const availableDelayOptions = [
  { value: '1-week', label: 'Delay 1 week', params: { frequency: 1, unit: 'week' } },
  { value: '2-week', label: 'Delay 2 weeks', params: { frequency: 2, unit: 'week' } },
  { value: '3-week', label: 'Delay 3 weeks', params: { frequency: 3, unit: 'week' } },
  { value: '1-month', label: 'Delay 1 month', params: { frequency: 1, unit: 'month' } },
  { value: '2-month', label: 'Delay 2 months', params: { frequency: 2, unit: 'month' } },
];
