import React from 'react';

import { Credentials } from '@typings/Integration';
import { TextInput } from '../TextInput';

interface FormProps {
  credentials: Credentials;
}

export function FreshdeskForm(props: FormProps) {
  const { credentials } = props;

  return (
    <div>
      <TextInput
        name="freshdesk_base_url"
        label="Freshdesk Portal URL"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.base_url ? credentials.base_url : '',
          placeholder: 'https://shopname.freshdesk.com',
        }}
      />

      <TextInput
        name="freshdesk_api_key"
        label="API Key"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.api_key,
        }}
      />
    </div>
  );
}
