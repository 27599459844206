import axios from '@lib/axios';
import { PageOnboardingKey } from '@typings/PageOnboarding';
import { useEffect, useRef, useState } from 'react';

interface State {
  loading: boolean;
  shouldDisplay: boolean;
  finished: boolean;
}

export const pageOnboardingUpdatedKey = 'page_onboarding_updated';

export function usePageOnboarding(pageKey: PageOnboardingKey) {
  const isMounted = useRef(false);
  const [state, setState] = useState<State>({
    loading: true,
    shouldDisplay: false,
    finished: false,
  });

  useEffect(() => {
    isMounted.current = true;
    async function fetch() {
      const responses = await Promise.all([
        axios.get('/sms/shops/onboarding'),
        axios.get(`/sms/page_onboardings/${pageKey}`),
      ]);

      const { data: onboardingData } = responses[0];
      const { data: pageOnboardingData } = responses[1];

      if (isMounted.current) {
        setState({
          loading: false,
          shouldDisplay:
            onboardingData?.onboarding &&
            onboardingData?.onboarding.finished_at &&
            !pageOnboardingData?.page_onboarding.finished_at,
          finished: !!pageOnboardingData?.page_onboarding.finished_at,
        });
      }
    }
    fetch();

    return () => {
      isMounted.current = false;
    };
  }, []);

  async function setFinished() {
    await axios.put(`/sms/page_onboardings/${pageKey}`, {
      page_onboarding: {
        finished: true,
      },
    });
    setState((old) => ({ ...old, shouldDisplay: false, finished: true }));
    window.dispatchEvent(new Event(pageOnboardingUpdatedKey));
  }

  return {
    ...state,
    setFinished,
  };
}
