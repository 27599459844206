import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { Edit, RefreshCw, Send } from 'react-feather';
import axios from '@lib/axios';
import toast from 'react-hot-toast';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { Link } from 'react-router-dom';

const EDIT_SEGMENT_PATH = (id) => `/sms/segments/${id}/edit`;
const OPT_IN_SMS_SEGMENT_PATH = (id) => `/sms/segments/${id}/opt_in_sms`;
const refreshPath = (id) => `/sms/segments/${id}/refresh`;

const Actions = ({ segment }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const shop = useCurrentShop();

  const sendOptInSms = async () => {
    try {
      setLoading(true);

      await axios.post(OPT_IN_SMS_SEGMENT_PATH(segment.id));

      setLoading(false);
      setModalShow(false);
    } catch (e) {
      setLoading(false);

      setErrors('It was not possible to send the SMS messages. Please try again later.');
    }
  };

  async function handleRefresh() {
    try {
      await axios.post(refreshPath(segment.id));
      toast(`Refreshing ${segment.name}`);
    } catch (e) {
      toast.error(e.response.data.errors);
    }
  }

  return (
    <>
      <Modal show={modalShow} size="lg" onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Segment Actions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Row className="justify-content-between align-items-center">
                <Col xs={10}>
                  <h5>Send Opt-in SMS</h5>
                  <span className="text-secondary">
                    Click to send the Opt-in message to all customers within this segment. Each
                    customer will only receive this message once, which means it will not send
                    duplicated.
                  </span>
                </Col>
                <Col xs={2}>
                  <Button
                    variant="primary"
                    disabled={loading || !shop.activeAt}
                    onClick={sendOptInSms}
                  >
                    {loading ? 'Loading..' : 'Send Now'}
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
          {errors && <p className="text-danger mt-3">{errors}</p>}
        </Modal.Body>
        <Modal.Footer>
          {!shop.activeAt && (
            <div className="mr-auto text-warning">
              You cannot perform an action until your shop is activated
            </div>
          )}
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ButtonGroup aria-label="Segment Actions">
        <Button variant="primary" onClick={() => setModalShow(true)}>
          <Send size={20} className="mr-2" />
          Actions
        </Button>
        {!segment.custom && (
          <Button variant="light" onClick={handleRefresh}>
            <RefreshCw size={20} className="mr-2" />
            Refresh
          </Button>
        )}
        {segment?.editable && (
          <Link className="btn btn-info" to={EDIT_SEGMENT_PATH(segment.id)}>
            <Edit size={20} className="mr-2" />
            Edit Segment
          </Link>
        )}
      </ButtonGroup>
    </>
  );
};

export default Actions;
