import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PauseOnboardingButton from '@components/Onboarding/v2/components/PauseOnboardingButton';
import styled from '@emotion/styled';

export const VideoModalView = () => (
  <div className="onboarding__root">
    <CustomModal show backdrop="static" centered>
      <CustomModalHeader>
        <CustomModalTitle>What is RechargeSMS?</CustomModalTitle>
      </CustomModalHeader>
      <CustomModalBody>
        <CustomIframe
          src="https://www.youtube.com/embed/fM_pb5GQyi4"
          title="What is RechargeSMS"
          frameBorder="0"
          allowFullScreen
        />
      </CustomModalBody>
      <CustomModalFooter>
        <span className="prompt">Click play to learn more about the benefits of RechargeSMS.</span>
      </CustomModalFooter>
    </CustomModal>
    <PauseOnboardingButton />
  </div>
);

const CustomModalTitle = styled(Modal.Title)`
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #191d48;
`;

const CustomModalHeader = styled(Modal.Header)`
  border-bottom: 0px;
  padding: 0px;
  margin-bottom: 15px;
`;

const CustomIframe = styled.iframe`
  width: 100%;
  height: 280px;
`;

const CustomModalBody = styled(Modal.Body)`
  padding: 0px;
`;

const CustomModalFooter = styled(Modal.Footer)`
  border-top: 0px;
  justify-content: flex-start;
  padding: 0px;
  margin-top: 15px;

  .prompt {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.47px;
    color: #000000;
  }
`;

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    width: 600px;
    padding-top: 28px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 25px;
    box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
    border-radius: 8px;
  }
`;
