import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';

import BatchActionsBanner from '@pages/Settings/Features/shared/BatchActions/BatchActionsBanner';
import FeatureSwitch from '@pages/Settings/Features/shared/FeatureSwitch';
import GenericPage from '@pages/Settings/Features/shared/GenericPage';
import { Feature } from '@typings/Feature';

import ChargeReminderIcon from '@assets/icons/charge-reminder.svg';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { settingSlice } from '@redux/reducer/settingSlice';
import { useBaseAppContext } from '@components/BaseAppContext';
import { features } from 'process';
import FeaturesDrawer from './components/FeaturesDrawer';
import { AutoSelectFeatureSwitch } from './components/AutoSelectFeatureSwitch';
import { EnableRecommendedFeatures } from './components/EnableRecommendedFeatures';

import EnableChargeReminderSmsSwitch from './components/EnableChargeReminderSmsSwitch';

interface MessagingProps {
  accountSettingsFeatures: Feature[];
  modifyOrderFeatures: Feature[];
  bundledSubscriptionsFeatures: string[];
  generalFeatures: Feature[];
  otherSmsFeatures: Feature[];
  chargeReminderFeatures: Feature[];
  batchActionsEnabled: boolean;
  forceCollapse?: number;
  changeBatchActionsFeature(status): void;
}

function Messaging(props: MessagingProps) {
  const {
    accountSettingsFeatures,
    modifyOrderFeatures,
    bundledSubscriptionsFeatures,
    generalFeatures,
    otherSmsFeatures,
    chargeReminderFeatures,
    batchActionsEnabled,
    forceCollapse,
    changeBatchActionsFeature,
  } = props;

  const dispatch = useAppDispatch();

  const [openConciergeFeatures, setOpenConciergeFeatures] = useState(false);
  const { setShowContactCardModal } = useBaseAppContext();

  useEffect(() => {
    if (
      chargeReminderFeatures &&
      chargeReminderFeatures.length > 0 &&
      !chargeReminderFeatures[0].enabled
    ) {
      dispatch(settingSlice.actions.setLockChargeReminderToggle(false));
    }
  }, [chargeReminderFeatures]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('open') === 'contact-card-modal') {
      setShowContactCardModal(true);
      setOpenConciergeFeatures(true);
    } else if (params.get('open') === 'concierge-features') {
      setOpenConciergeFeatures(true);
    }
  }, []);

  return (
    <GenericPage>
      {!batchActionsEnabled && (
        <div className="mb-3">
          <EnableRecommendedFeatures />
        </div>
      )}
      {chargeReminderFeatures &&
        chargeReminderFeatures.length > 0 &&
        !chargeReminderFeatures[0].enabled && (
          <FeaturesDrawer
            title="Charge Reminder Setting"
            description="An SMS message to inform customers that their next order is processing in 3 days"
            titleTestId="concierge-features-title"
            iconCustom={ChargeReminderIcon}
            forceCollapse={1}
            collapseComponent={
              <FeaturesList>
                <EnableChargeReminderSmsSwitch
                  key={chargeReminderFeatures[0].key}
                  feature={chargeReminderFeatures[0]}
                />
              </FeaturesList>
            }
          />
        )}
      <FeaturesDrawer
        title="Modify Order"
        description="Add more order management options to your SMS service"
        titleTestId="modify-order-feature-title"
        icon={faShoppingCart}
        iconColor="#078FEE"
        forceCollapse={forceCollapse}
        collapseComponent={
          <FeaturesList>
            {modifyOrderFeatures.map((feature) => (
              <FeatureSwitch
                key={feature.key}
                feature={feature}
                show={
                  batchActionsEnabled ? bundledSubscriptionsFeatures.includes(feature.key) : true
                }
              />
            ))}
          </FeaturesList>
        }
      />
      <FeaturesDrawer
        title="Account Related Features"
        description="Provide customers with the ability to access account settings via SMS"
        titleTestId="account-related-features-title"
        icon={faUser}
        iconColor="#F7963F"
        collapseComponent={
          <FeaturesList>
            {accountSettingsFeatures.map((feature) => (
              <FeatureSwitch key={feature.key} feature={feature} />
            ))}
          </FeaturesList>
        }
      />
      <FeaturesDrawer
        title="Concierge Features"
        description="Add nice-to-have quality of life features to your SMS service"
        titleTestId="concierge-features-title"
        icon={faBell}
        iconColor="#3FBF3D"
        autoExpanded={openConciergeFeatures}
        collapseComponent={
          <FeaturesList>
            {otherSmsFeatures?.map((feature) => (
              <AutoSelectFeatureSwitch key={feature.key} feature={feature} />
            ))}
            {generalFeatures?.map((feature) => (
              <FeatureSwitch key={feature.key} feature={feature} showActions={false} />
            ))}
          </FeaturesList>
        }
      />
      {chargeReminderFeatures &&
        chargeReminderFeatures.length > 0 &&
        chargeReminderFeatures[0].enabled && (
          <FeaturesDrawer
            title="Charge Reminder Setting"
            description="An SMS message to inform customers that their next order is processing in 3 days"
            titleTestId="concierge-features-title"
            iconCustom={ChargeReminderIcon}
            onHideCollapse={() => dispatch(settingSlice.actions.setLockChargeReminderToggle(true))}
            onShowCollapse={() => dispatch(settingSlice.actions.setLockChargeReminderToggle(true))}
            collapseComponent={
              <FeaturesList>
                <EnableChargeReminderSmsSwitch
                  key={chargeReminderFeatures[0].key}
                  feature={chargeReminderFeatures[0]}
                />
              </FeaturesList>
            }
          />
        )}
      {!batchActionsEnabled && (
        <BatchActionsBanner
          onEnable={() => {
            changeBatchActionsFeature(true);
          }}
        />
      )}
    </GenericPage>
  );
}

const FeaturesList = styled.div`
  background: #fff;
  border: 1px solid #ced4d9;
  border-top: none;
  border-radius: 0 0 9px 9px;
  overflow: hidden;
`;

export default Messaging;
