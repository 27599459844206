import React from 'react';
import { Form } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import atRiskIcon from '@pages/Workflows/assets/at-risk-customer.svg';

import BaseNode, { NodeProps } from '../BaseNode';
import { CustomerFilters } from './CustomerFilters';

export type Operator = '>=' | '<=';
export type FilterTypes = 'upcoming_charge_value' | 'total_orders' | 'total_charge_amount';

export interface Filter {
  enabled: boolean;
  operator: Operator;
  amount: number;
}

type Filters = { [key in FilterTypes]: Filter };

interface CustomerFilterNodeProps extends NodeProps {
  filters: Filters;
}

export const CustomerFilterNode = ({ data }: { data: CustomerFilterNodeProps }) => {
  const { handleChangeWorkflowStep } = useWorkflowsContext();

  const formProps = useForm({
    shouldUnregister: false,
    defaultValues: {
      filters: data?.filters,
    },
  });
  const { handleSubmit } = formProps;

  async function handleSave({ filters }) {
    const values = Object.keys(filters).reduce(
      (obj, filterType) => getFilterObject(obj, filterType, filters),
      {}
    );

    handleChangeWorkflowStep(Number(data.id), { filters: values });
    toast.success('Customer filters successfully saved!');
  }

  function getFilterObject(obj, filterType, filters) {
    const filter = filters[filterType];
    if (typeof filter.amount === 'string') filter.amount = Number(filter.amount?.replace('$', ''));

    obj[filterType] = filter;
    return obj;
  }

  return (
    <FormProvider {...formProps}>
      <BaseNode
        icon={atRiskIcon}
        color="#45C268"
        showCollapse
        collapseComponent={
          <Form onSubmit={handleSubmit(handleSave)}>
            <CustomerFilters />
          </Form>
        }
        {...data}
      />
    </FormProvider>
  );
};
