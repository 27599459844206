import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { Action } from '@components/Table/Row/Action';
import { ConfirmationModal, ModalHandles } from '@components/Modal/ConfirmationModal';

export function SwapGroupActions(props) {
  const { swapGroup, baseUrl, handleDelete } = props;
  const modalRef = useRef<ModalHandles>(null);

  function toggleModal() {
    modalRef?.current?.toggleModal();
  }

  return (
    <Container>
      <Action color="#078fee" text="Edit" icon={faPen} href={`${baseUrl}/${swapGroup.id}/edit`} />
      <Action color="#e3242b" text="Delete" icon={faTrashAlt} onClick={toggleModal} />

      <ConfirmationModal
        ref={modalRef}
        title={swapGroup.name}
        description="Are you sure you want to delete this group?"
        onConfirm={() => handleDelete(swapGroup.id)}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-right: 1rem;
  }
`;
