import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import FeaturesDrawer from '@pages/Settings/Features/tabs/Messaging/components/FeaturesDrawer';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import Button from 'react-bootstrap/Button';
import { FeaturesProvider, useFeaturesContext } from '@contexts/FeaturesContext';
import FeatureSwitch from '@pages/Settings/Features/shared/FeatureSwitch/FeatureSwitchContainer';
import { AutoSelectFeatureSwitch } from '@pages/Settings/Features/tabs/Messaging/components/AutoSelectFeatureSwitch';
import { Feature } from '@typings/Feature';
import { useOnboardingV2 } from '@hooks/useOnboardingV2';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';
import { FEATURE_4_PLAY_AROUND_STEP } from '@components/Onboarding/v2/onboarding';
import GenericPage from '@pages/Settings/Features/shared/GenericPage';

interface FeaturesViewProps {
  allFeatures?: Feature[];
  generalFeatures?: Feature[];
  accountSettingsFeatures?: Feature[];
  modifyOrderFeatures?: Feature[];
  bundledSubscriptionsFeatures?: string[];
  otherSmsFeatures?: Feature[];
  isShowGenericPage?: boolean;
  disabled: boolean;
}

interface EnableFeaturesViewProps {
  disabled: boolean;
}

const EnableFeaturesView = (props: EnableFeaturesViewProps) => {
  const { enableRecommended } = useFeaturesContext();
  const { disabled } = props;

  const [isEnabling, setEnabling] = useState(false);

  const dispatch = useAppDispatch();

  const { updateOnboarding } = useOnboardingV2();
  const onboardingState = useAppSelector((state) => state.onboarding);

  const handleEnableFeature = async () => {
    await enableRecommended();
    await updateOnboarding(onboardingState.data.id, {
      step: FEATURE_4_PLAY_AROUND_STEP,
    });

    dispatch(onboardingSlice.actions.completeEnableFeatures());
  };

  useEffect(() => {
    if (onboardingState.isEnablingFeatures && !isEnabling) {
      setEnabling(true);
      handleEnableFeature();
    }
  }, [onboardingState]);

  return (
    <EnableFeatureContainer>
      <EnableFeatureRow>
        <h3 className="title">Recommended Features ✨</h3>
        <p>
          After millions of messages across many different shops, we've found these features to be
          the most effective for subscription management via text.
          <br />
          <br />
          Enabling these features has shown to increase average customer lifetime value (LTV) by 26%
          and will save you countless hours of customer support 🤝
        </p>
      </EnableFeatureRow>
      <EnableFeatureRow className="enable-feature-btn-row">
        <Button className="mt-2" onClick={() => handleEnableFeature()} disabled={disabled}>
          Enable Features
        </Button>
      </EnableFeatureRow>
    </EnableFeatureContainer>
  );
};

export const FeaturesView = (props: FeaturesViewProps) => {
  const {
    allFeatures,
    generalFeatures,
    accountSettingsFeatures,
    bundledSubscriptionsFeatures,
    modifyOrderFeatures,
    otherSmsFeatures,
    disabled = false,
    isShowGenericPage = false,
  } = props;

  const onboardingState = useAppSelector((state) => state.onboarding);

  const content = (
    <>
      <CustomRow>
        <EnableFeaturesView disabled={disabled} />
      </CustomRow>

      <CustomRow>
        <FeaturesDrawer
          title="Modify Order"
          icon={faShoppingCart}
          iconColor="#078FEE"
          disabled={disabled}
          forceCollapse={onboardingState.isFeaturesCollapse ? 1 : 0}
          collapseComponent={
            disabled ? (
              <FeaturesList />
            ) : (
              <FeaturesList>
                {modifyOrderFeatures &&
                  modifyOrderFeatures.map((feature) => (
                    <FeatureSwitch
                      key={feature.key}
                      feature={feature}
                      show={bundledSubscriptionsFeatures.includes(feature.key)}
                    />
                  ))}
              </FeaturesList>
            )
          }
        />
      </CustomRow>

      <CustomRow>
        <FeaturesDrawer
          title="Account Features"
          icon={faUser}
          iconColor="#F7963F"
          disabled={disabled}
          forceCollapse={onboardingState.isFeaturesCollapse ? 1 : 0}
          collapseComponent={
            disabled ? (
              <FeaturesList />
            ) : (
              <FeaturesList>
                {accountSettingsFeatures &&
                  accountSettingsFeatures.map((feature) => (
                    <FeatureSwitch key={feature.key} feature={feature} />
                  ))}
              </FeaturesList>
            )
          }
        />
      </CustomRow>

      <CustomRow>
        <FeaturesDrawer
          title="Concierge Notifications"
          icon={faBell}
          iconColor="#3FBF3D"
          disabled={disabled}
          forceCollapse={onboardingState.isFeaturesCollapse ? 1 : 0}
          collapseComponent={
            disabled ? (
              <FeaturesList />
            ) : (
              <FeaturesList>
                {otherSmsFeatures &&
                  otherSmsFeatures.map((feature) => (
                    <AutoSelectFeatureSwitch key={feature.key} feature={feature} />
                  ))}
                {generalFeatures &&
                  generalFeatures.map((feature) => (
                    <FeatureSwitch key={feature.key} feature={feature} showActions={false} />
                  ))}
              </FeaturesList>
            )
          }
        />
      </CustomRow>
    </>
  );

  return (
    <FeaturesProvider allFeatures={allFeatures}>
      <Title>Features</Title>

      {isShowGenericPage ? (
        <GenericPageContainer>
          <GenericPage>{content}</GenericPage>
        </GenericPageContainer>
      ) : (
        content
      )}
    </FeaturesProvider>
  );
};

const GenericPageContainer = styled.div`
  .generic-page-container {
    padding: 0px !important;
  }
`;

const CustomRow = styled(Row)`
  margin: 0px 0px 10px 0px;
`;

const FeaturesList = styled.div`
  background: #fff;
  border: 1px solid #ced4d9;
  border-top: none;
  border-radius: 0 0 9px 9px;
  overflow: hidden;
`;

const EnableFeatureContainer = styled.div`
  background: linear-gradient(268.99deg, #2900b1 0%, #191d48 100%);
  border-radius: 6px;
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.04);
  color: white;
  padding: 24px;
  margin-bottom: 15px;
  z-index: 2000 !important;

  h3.title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 15px;
  }

  p {
    font-family: SFProDisplay, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.47px;
  }

  .enable-feature-btn-row {
    justify-content: flex-end;
  }

  .enable-feature-btn-row > button {
    font-weight: 700;
    background: transparent;
    padding-left: 28px;
    padding-right: 28px;
    color: fff;
    border: 1px solid #fff;
    border-radius: 5px;
  }
`;

const EnableFeatureRow = styled(Row)`
  margin-left: 0px;
  margin-right: 0px;
`;

const Title = styled.div`
  font-family: Greycliff;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #191d48;
  margin-bottom: 16px;
`;
