import React from 'react';
import { TextInput, FormLabel } from '@components/Input';
import { Row, Col } from 'react-bootstrap';
import phone from '@utils/phone';
import ImageInput from '@components/Modal/ContactCardModal/ImageInput';

import { useCurrentShop } from '@hooks/useCurrentShop';

export function ContactCardModalBody() {
  const shop = useCurrentShop();

  return (
    <Row className="pb-4">
      <Col xs={5}>
        <FormLabel>Shop Logo</FormLabel>
        <ImageInput />
        <FormLabel>RechargeSMS Phone Number</FormLabel>
        <div>{phone.format(shop?.setting?.twilio_from_number)}</div>
      </Col>
      <Col xs={7}>
        <TextInput
          name="vcard_company_name"
          label="Shop Name"
          inputProps={{
            maxLength: 30,
          }}
        />
        <TextInput
          name="vcard_email"
          label="Shop Email"
          inputProps={{
            maxLength: 40,
          }}
        />
        <TextInput
          name="vcard_website"
          label="Shop Website"
          inputProps={{
            maxLength: 40,
          }}
        />
      </Col>
    </Row>
  );
}
