import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Edit3 } from 'react-feather';

import EmailForm from '@components/Input/EmailForm/EmailForm';
import { Card } from '@components/Layout/Card';
import { SaveButton } from '@components/Button/SaveButton';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { Separator } from '@components/Layout/Separator';

import '@pages/Settings/Features/shared/FeatureSwitch/featureSwitch.scss';
import '@components/Input/EmailForm/storeEmail.scss';

export const PreferredStoreEmail = ({
  isLoading,
  storeEmails,
  handleEmailChanged,
  clearEmails,
  formErrors,
}) => {
  const shop = useCurrentShop();
  const { customEmail: currentEmail = null } = shop;
  const [checked, setChecked] = useState(currentEmail !== null);
  const [showForm, setShowForm] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  useEffect(() => {
    const disable = isLoading || (checked && (formErrors?.confirmation || formErrors?.format));
    setDisableSaveBtn(disable);
  }, [isLoading, checked, formErrors?.confirmation, formErrors?.format]);

  const handleToggle = () => {
    const checkedStatus = !checked;

    if (!checkedStatus) {
      clearEmails();
    }

    setChecked(checkedStatus);
    setShowForm(checkedStatus);
  };

  const handleEditClick = () => {
    setShowForm(true);
  };

  const handleEmailUpdated = () => setShowForm(false);

  const allowEdit = storeEmails?.original !== null && checked && !showForm;

  return (
    <Card className="h-auto mb-4" data-testid="preferred-store-email-component">
      <div className="d-flex align-items-center px-4 pt-3">
        <h4 className="font-weight-bold mb-0">Preferred Store Email</h4>
        <SaveButton type="submit" disabled={disableSaveBtn}>
          Save Email
        </SaveButton>
      </div>
      <Separator />

      <div className="d-flex flex-column">
        <div className="form-row pt-3 px-4">
          <div className="form-group col-6">
            <Form.Label className="font-weight-bold">
              Are you interested in setting a preferred store email?
            </Form.Label>
            <Form.Text>
              A preferred store email is what RechargeSMS will pass along to customers looking for a
              way to get in contact with your shop. This is optional.
            </Form.Text>
            <div className="mb-3 w-100">
              <Form.Switch
                id="store-email-toggle"
                label="Set Preferred Store Email"
                name="store-email-toggle"
                checked={checked}
                onChange={handleToggle}
                className="mb-3 settings_email-form-switch"
                data-testid="set-preferred-email-toggle"
              />

              {'  '}

              {allowEdit && (
                <Edit3
                  size={16}
                  className="settings__edit-icon"
                  data-testid="edit-preferred-email-icon"
                  onClick={handleEditClick}
                />
              )}

              {showForm && (
                <EmailForm
                  emailUpdatedHandler={handleEmailUpdated}
                  storeEmails={storeEmails}
                  formErrors={formErrors}
                  emailChangedHandler={handleEmailChanged}
                  autoSave={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
