import styled from '@emotion/styled';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';

import { Text } from '@components/Typography/Text';

interface SmartSwapTagProps {
  active: boolean;
}

export const SmartSwapTag = (props: SmartSwapTagProps) => {
  const { active } = props;
  return (
    <Tag active={active}>
      <FontAwesomeIcon className="mr-1" icon={faStar} size="sm" />
      <Text size="xSmall">SMART SWAP</Text>
    </Tag>
  );
};

interface TagProps {
  active: boolean;
}

const Tag = styled.span<TagProps>`
  align-items: center;
  background-color: ${(props) => (props.active ? props.theme.colors.green3 : '#9A9B9C')};
  border-radius: ${(props) => props.theme.radius.xLarge};
  color: ${(props) => props.theme.colors.light0};
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0px ${(props) => props.theme.spacing.small};
`;
