import styled from '@emotion/styled';
import React from 'react';

interface ToggleProps {
  name?: string;
  disabledLabel?: string;
  enabledLabel?: string;
  checked?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'md';
  onChange?(value: boolean): void;
}

export const Toggle = (props: ToggleProps) => {
  const {
    name,
    checked,
    disabled,
    disabledLabel = 'Disabled',
    enabledLabel = 'Enabled',
    onChange,
    size,
    ...rest
  } = props;

  return (
    <ToggleLabel disabled={disabled} size={size} onClick={(e) => e.stopPropagation()} {...rest}>
      <input
        name={name}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={() => onChange?.(!checked)}
      />
      <span className="slider" />
      <span className="unchecked">{disabledLabel}</span>
      <span className="checked">{enabledLabel}</span>
    </ToggleLabel>
  );
};

const ToggleLabel = styled.label<{ disabled?: boolean; size?: string }>`
  align-items: center;
  background: #f4f4f7;
  border-radius: 6px;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 43px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 170px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  filter: grayscale(${(props) => (props.disabled ? 1 : 0)});
  transform: ${(props) => props.size === 'sm' && 'scale(0.8)'};

  input {
    display: none;
  }

  span {
    transition: all 0.4s;
    z-index: 2;
  }

  .slider {
    background-color: white;
    border-radius: 6px;
    border: 1px solid #dd1818;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  }

  .unchecked,
  .checked {
    color: #c4c4c4;
    font-size: 14px;
    margin-top: 2px;
    text-align: center;
    width: 50%;
  }

  .unchecked {
    color: #dd1818;
    font-weight: 600;
  }

  input:checked {
    ~ .checked {
      color: #3fbf3d;
      font-weight: 600;
    }

    ~ .unchecked {
      color: #c4c4c4;
      font-weight: normal;
    }

    ~ .slider {
      border-color: #3fbf3d;
      transform: translateX(100%);
    }
  }
`;
