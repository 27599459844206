import React, { PropsWithChildren, ReactElement } from 'react';
import styled from '@emotion/styled';

import { CenteredLoading } from '@components/Loading';

interface PageProps {
  className?: string;
  headerTitle?: ReactElement;
  headerDescription?: string | React.ReactNode;
  headerComponent?: React.ReactNode;
  loading?: boolean;
}

export function Page(props: PropsWithChildren<PageProps>) {
  const { children, headerTitle, headerDescription, headerComponent, loading, ...rest } = props;

  if (loading) return <CenteredLoading />;

  return (
    <Container {...rest}>
      <Header>
        {headerTitle && <Title>{headerTitle}</Title>}
        {headerDescription && <Description>{headerDescription}</Description>}
        {headerComponent}
      </Header>

      {children}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 15px;
  padding-bottom: 1rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
`;

const Title = styled.h1`
  color: #191d48;
  font-family: 'Greycliff', sans-serif;
  font-size: 28px;
  font-weight: bold;
  margin: 0;
`;

const Description = styled.p`
  display: flex;
  margin: 0;
  color: #6b6b6b;
`;
