import toast from 'react-hot-toast';

import axios from '@lib/axios';

const BASE_URL = '/sms/workflows';

async function getWorkflows(templateType: string) {
  return tryCatchWrapper(async () => {
    const { data } = await axios.get(`${BASE_URL}/${templateType}`);

    return data;
  });
}

async function getWorkflowsByTag(templateType: string) {
  return tryCatchWrapper(async () => {
    const { data } = await axios.get(`${BASE_URL}/${templateType}?includeTag=true`);

    return data;
  });
}

async function searchWorkflows(templateType: string, search: string) {
  return tryCatchWrapper(async () => {
    const { data } = await axios.get(`${BASE_URL}/${templateType}`, {
      params: {
        search,
      },
    });

    return data;
  });
}

async function createWorkflow(workflow: any) {
  return tryCatchWrapper(async () => {
    const { data } = await axios.post(BASE_URL, {
      workflow: getWorkflowParams(workflow),
    });

    toast.success('Workflow successfully created!');

    return data.workflow;
  });
}

async function updateWorkflow(workflow: any) {
  return tryCatchWrapper(async () => {
    await axios.put(`${BASE_URL}/${workflow.id}`, {
      workflow: getWorkflowParams(workflow),
    });

    toast.success('Workflow successfully saved!');
  });
}

async function deleteWorkflow(workflowId: number) {
  return tryCatchWrapper(async () => {
    await axios.delete(`${BASE_URL}/${workflowId}`);

    toast.success('Workflow successfully deleted!');
  });
}

async function toggleWorkflowActive(workflowId: number) {
  return tryCatchWrapper(async () => {
    await axios.post(`${BASE_URL}/${workflowId}/toggle_active`);
  });
}

function getWorkflowParams(workflow) {
  const workflowParams = {
    ...workflow,
    active_at: workflow.activeAt,
    template_type: workflow.templateType,
    template_tag: workflow.templateTag,
    product_variants: workflow.productVariants,
  };

  return workflowParams;
}

async function tryCatchWrapper(fn) {
  try {
    return await fn();
  } catch (e) {
    return toast.error(e.response.data.message);
  }
}

export default {
  getWorkflows,
  getWorkflowsByTag,
  searchWorkflows,
  createWorkflow,
  updateWorkflow,
  deleteWorkflow,
  toggleWorkflowActive,
};
