import styled from '@emotion/styled';
import speechBubbleImage from '@assets/icons/speech-bubble.svg';

export const ModalBody = styled.div`
  padding: 64px;
`;

export const SpeechBubblesModalBody = styled(ModalBody)`
  background: url(${speechBubbleImage}) -20px -30px no-repeat,
    url(${speechBubbleImage}) 95% 40% no-repeat, url(${speechBubbleImage}) 35% 85% no-repeat;
  background-size: 198px 183px, 111px 111px, 51px 51px;
`;
