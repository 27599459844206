import React from 'react';

import { useSmsMessages } from '@hooks/useSmsMessages';
import { RechargeCustomer } from '@typings/RechargeCustomer';

import { ChatForm, ChatHistoryInfinite } from './components';

interface ChatAsCustomerProps {
  rechargeCustomer: RechargeCustomer;
}

export const ChatAsCustomer = ({ rechargeCustomer }: ChatAsCustomerProps) => {
  const { data, setSize, error } = useSmsMessages({
    baseUrl: `/sms/recharge/customers/${rechargeCustomer.id}`,
  });

  return (
    <div>
      <div className="row no-gutters align-items-end justify-content-between mb-1">
        <h6>
          <strong>Messages</strong>
          {/* <RefreshButton onClick={revalidate} /> */}
        </h6>
      </div>
      <ChatHistoryInfinite id="chatAsCustomer" data={data} setSize={setSize} />
      <ChatForm
        rechargeCustomer={rechargeCustomer}
        submitUrl={`/sms/recharge/customers/${rechargeCustomer.id}/send_sms_as_customer`}
        // onSubmitSuccess={() => setTimeout(() => revalidate(), 1000)}
      />
      {error && (
        <div className="text-danger text-sm ml-1">Error updating chat, will retry in a moment.</div>
      )}
    </div>
  );
};
