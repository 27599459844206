import styled from '@emotion/styled';

interface TextProps {
  size?: keyof typeof textSizes;
  weight?: 'normal' | 'bold';
}

export const Text = styled.span<TextProps>`
  font-size: ${(props) => textSizes[props.size || 'medium']};
  font-weight: ${(props) => props.weight || 'normal'};
`;

const textSizes = {
  xSmall: '0.65rem',
  small: '0.75rem',
  medium: '1rem',
  large: '1.15rem',
  xLarge: '1.25rem',
};
