import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export function NavTabs() {
  const { pathname } = useLocation();

  const tabs = [
    {
      label: 'Features',
      to: '/sms/settings',
    },
    {
      label: 'Advanced',
      to: '/sms/settings/advanced',
    },
    {
      label: 'Emails',
      to: '/sms/settings/email',
    },
    {
      label: 'Help',
      to: '/sms/settings/help',
    },
    {
      label: 'Widgets',
      to: '/sms/widgets',
    },
  ];

  const currentTab = tabs.find((tab) => tab.to === pathname);

  return (
    <>
      <Helmet>
        <title>{currentTab?.label} - RechargeSMS</title>
      </Helmet>
      <div className="subnav">
        <ul className="nav nav-tabs">
          {tabs.map((tab) => (
            <li key={tab.to} className="nav-item">
              <Link to={tab.to} className={`nav-link ${tab.to === pathname && 'active'}`}>
                {tab.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
