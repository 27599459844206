import React from 'react';

import { Feature } from '@typings/Feature';
import { EmailTemplate } from '@typings/EmailTemplate';
import { Shop } from '@typings/Shop';

import { CollapsableFeatureSwitch } from '@pages/Settings/Features/shared/CollapsableFeatureSwitch';

import { EditEmailTemplate } from './components/EditEmailTemplate';
import { BrandingSettings } from './components/BrandingSettings';

interface EmailSettingsProps {
  shop: Shop;
  emailFeatures: Feature[];
  emailTemplates: EmailTemplate[];
}

function EmailSettings({ shop, emailFeatures, emailTemplates }: EmailSettingsProps) {
  return (
    <div className="row p-3" data-testid="email-settings-component">
      <div className="col-12 col-lg-8">
        <BrandingSettings shop={shop} />
        {emailFeatures.map((feature) => (
          <CollapsableFeatureSwitch
            key={feature.key}
            feature={feature}
            name={customFeatureNameMapper[feature.key]}
          >
            <>
              {emailTemplates
                .filter((template) =>
                  emailTemplatesMapper[feature.key].includes(template.emailType)
                )
                .map((template) => (
                  <EditEmailTemplate
                    key={template.id}
                    template={template}
                    title={emailTemplateTitleMapper[template.emailType]}
                  />
                ))}
            </>
          </CollapsableFeatureSwitch>
        ))}
      </div>
    </div>
  );
}

const emailTemplatesMapper = {
  enable_opt_out_email: ['opt_out'],
  enable_unreachable_handset_email: ['unreachable_handsets'],
  enable_charge_reminder_fallback_email: [
    'charge_reminder_fallback',
    'multiple_charge_reminder_fallback',
  ],
};

const emailTemplateTitleMapper = {
  charge_reminder_fallback: 'Single Charge',
  multiple_charge_reminder_fallback: 'Multiple Charges',
};

const customFeatureNameMapper = {
  enable_charge_reminder_fallback_email: 'Enable Charge Reminder Email Fallback',
};

export default EmailSettings;
