import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';

export function RefreshButton({ onClick }) {
  const [spin, setSpin] = useState(false);

  function handleClick() {
    setSpin(true);
    onClick();

    setTimeout(() => {
      setSpin(false);
    }, 1000);
  }

  return <RefreshIcon spin={spin} icon={faSyncAlt} onClick={handleClick} />;
}

interface RefreshIconProps {
  spin?: boolean;
}

const RefreshIcon = styled(FontAwesomeIcon)<RefreshIconProps>`
  margin-left: 10px;
  cursor: pointer;
  animation: ${(props) => props?.spin && `spin 1s ease-in-out`};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
