import React from 'react';
import placeholderImage from '@assets/images/image-placeholder.png';

interface ThumbnailProps {
  alt?: string;
  src?: string;
}

const Thumbnail = (props: ThumbnailProps) => {
  const { alt, src = undefined, ...rest } = props;
  const safeSrc = src || placeholderImage;

  return <img {...rest} alt={alt} src={safeSrc} />;
};

export default Thumbnail;
