import React from 'react';
import styled from '@emotion/styled';

import { SearchBar } from '@pages/Workflows/components/SearchBar';
import { ProductCard } from '@pages/Workflows/components/ProductCard';

import { ProductVariant } from '@typings/ProductVariant';

import { Loading } from '@components/Loading';
import CloseSaveBody from '../shared/CloseSaveBody';

interface ProductSelectorProps {
  loading: boolean;
  availableProducts: ProductVariant[];
  selectedProducts: ProductVariant[];
  handleSearch(event: any): void;
  handleSelectProduct(product: ProductVariant): void;
  handleSave: () => void;
}

const ProductSelectorBody = (props: ProductSelectorProps) => {
  const {
    loading,
    availableProducts,
    selectedProducts,
    handleSearch,
    handleSelectProduct,
    handleSave,
  } = props;

  return (
    <CloseSaveBody handleSave={handleSave}>
      <Subtitle>
        🛍 These products will be grouped into the same reminder interval for your customers
      </Subtitle>

      <CustomSearchBar
        handleSearch={handleSearch}
        inputProps={{
          placeholder: 'Search product catalog by name',
        }}
      />

      <ProductsContainer>
        {selectedProducts?.length > 0 && (
          <ProductsListContainer>
            <ProductsListTitle>SELECTED PRODUCTS</ProductsListTitle>
            <ProductsList>
              {selectedProducts?.map((variant) => (
                <SelectedProductCard
                  key={variant.id}
                  title={variant.title}
                  subtitle={variant.priceText}
                  variants={[variant]}
                  handleClick={() => handleSelectProduct(variant)}
                />
              ))}
            </ProductsList>
          </ProductsListContainer>
        )}

        <ProductsListContainer>
          <ProductsListTitle>SEARCH RESULTS</ProductsListTitle>
          {loading ? (
            <Loading />
          ) : (
            <ProductsList>
              {availableProducts?.map((variant) => (
                <CustomProductCard
                  key={variant.id}
                  title={variant.title}
                  subtitle={variant.priceText}
                  variants={[variant]}
                  handleClick={() => handleSelectProduct(variant)}
                />
              ))}
            </ProductsList>
          )}
        </ProductsListContainer>
      </ProductsContainer>

      <FooterText>Select one or more products to enable replenishment for.</FooterText>
    </CloseSaveBody>
  );
};

const Subtitle = styled.p`
  font-weight: normal;
  font-size: 12px;
  color: #686868;
  margin-top: 1rem;
`;

const ProductsContainer = styled.div``;

const ProductsListContainer = styled.div`
  margin: 25px 0;
`;

const ProductsListTitle = styled.h3`
  font-size: 12px;
  color: #9e9e9e;
  letter-spacing: 0.035em;
  margin-bottom: 0.25rem;
`;

const ProductsList = styled.div`
  max-height: 185px;
  overflow-y: auto;
  padding: 2px;
`;

const CustomSearchBar = styled(SearchBar)`
  box-shadow: none;
  background: white;
  border-radius: 0;
  margin-top: 1rem;
  border: 1px solid #cecccc;
  height: 35px;
`;

const CustomProductCard = styled(ProductCard)`
  background: ${(props) => props.theme.colors.light0};
  box-shadow: 0px 1px 4px #00000044;

  &:hover {
    background: #fbfbfb;
  }
`;

const SelectedProductCard = styled(ProductCard)`
  background: #ebf9f7;
  border: 1px solid #17cc9d;

  &:hover {
    background: #d6f3f0;
  }
`;

const FooterText = styled.span`
  font-size: 12px;
  color: #9e9e9e;
  margin-right: auto;
`;

export default ProductSelectorBody;
