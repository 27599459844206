import toast from 'react-hot-toast';

export const responseInterceptor = (response) => response;

export const responseInterceptorError = (error) => {
  if (error?.response?.status === 500) {
    toast.error('Unexpected error.');
  }

  return Promise.reject(error);
};
