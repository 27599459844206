import { PaginationInfo } from '@typings/PaginationInfo';
import range from 'lodash/range';
import React from 'react';
import { Pagination } from 'react-bootstrap';

export type PaginationInfoPropTypes = PaginationInfo;

export const Paginator = (props) => {
  const { onPageChange, paginationInfo } = props;

  if (!paginationInfo || paginationInfo.totalPages === 1) {
    return null;
  }

  const isFirst = paginationInfo.current === 1;
  const isLast = paginationInfo.current === paginationInfo.totalPages;

  function handlePageChange(page) {
    if (onPageChange) {
      onPageChange(page);
    }
  }

  function handleFirst() {
    handlePageChange(1);
  }

  function handlePrev() {
    handlePageChange(paginationInfo.previous);
  }

  function handleNext() {
    handlePageChange(paginationInfo.next);
  }

  function handleLast() {
    handlePageChange(paginationInfo.totalPages);
  }

  const initialRange = paginationInfo.current - 3 < 1 ? 1 : paginationInfo.current - 3;
  const finalRange =
    paginationInfo.current + 3 > paginationInfo.totalPages
      ? paginationInfo.totalPages
      : paginationInfo.current + 3;

  return (
    <Pagination>
      {!isFirst && <Pagination.First onClick={handleFirst}>First</Pagination.First>}
      {!isFirst && <Pagination.Prev onClick={handlePrev}>Previous</Pagination.Prev>}
      {range(initialRange, finalRange + 1).map((page) => {
        function handlePageClick() {
          handlePageChange(page);
        }
        return (
          <Pagination.Item
            key={page}
            active={page === paginationInfo.current}
            onClick={handlePageClick}
          >
            {page}
          </Pagination.Item>
        );
      })}
      {!isLast && <Pagination.Next onClick={handleNext}>Next</Pagination.Next>}
      {!isLast && <Pagination.Last onClick={handleLast}>Last</Pagination.Last>}
    </Pagination>
  );
};
