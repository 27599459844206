import React, { memo } from 'react';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons/faShoppingBag';

import BaseNode, { NodeProps } from './BaseNode';

export const ChargeReminderNode = memo(({ data }: { data: NodeProps }) => (
  <BaseNode
    icon={faShoppingBag}
    color="#4ECB71"
    title="Customer has an upcoming charge"
    {...data}
  />
));
