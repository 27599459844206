import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RotateCcw } from 'react-feather';
import toast from 'react-hot-toast';
import useUndo from 'use-undo';

import axios from '@lib/axios';
import Edit from '@assets/icons/edit-dark.svg';

interface EditableProductTitleProps {
  id: number;
  title: string;
}

export const EditableProductTitle = ({ id, title: initialTitle }: EditableProductTitleProps) => {
  const [{ past, present: title }, historic] = useUndo(initialTitle);
  const [fieldValue, setFieldValue] = useState(initialTitle);
  const [isEditing, setIsEditing] = useState(false);

  const updateProductTitle = async (text) =>
    axios.put(`/sms/product_variants/${id}.json`, {
      product_variant: { title: text },
    });

  const handleEdit = async () => {
    const newTitle = fieldValue;

    if (newTitle !== title) {
      await updateProductTitle(newTitle);
      historic.set(newTitle);
      toast.success('Product title changed!');
    }

    setIsEditing(false);
  };

  const handleRevert = async () => {
    const previousTitle = past[past.length - 1];
    await updateProductTitle(previousTitle);
    setFieldValue(previousTitle);
    historic.undo();
    toast.success('Product title reverted!');
  };

  const handleEnter = (e) => e.key === 'Enter' && handleEdit();

  return (
    <>
      {isEditing ? (
        <input
          className="form-control"
          name="title"
          value={fieldValue}
          onChange={(e) => setFieldValue(e.target.value)}
          onBlur={handleEdit}
          onKeyDown={handleEnter}
          autoComplete="off"
          autoFocus
        />
      ) : (
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: '80%' }} tabIndex={0} onFocus={() => setIsEditing(true)}>
            {title}
          </div>
          <div style={{ width: '20%' }} className="d-flex">
            {historic.canUndo && (
              <OverlayTrigger overlay={<Tooltip id="0">Revert</Tooltip>}>
                <button type="button" className="btn btn-sm p-1" onClick={handleRevert}>
                  <RotateCcw size={16} />
                </button>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              overlay={<Tooltip id={`edit-variant-title-tooltip-${id}`}>Edit</Tooltip>}
            >
              <button
                type="button"
                className="onboarding-edit-variant-title btn btn-sm p-1"
                onClick={() => setIsEditing(true)}
                tabIndex={-1}
              >
                <img src={Edit} />
              </button>
            </OverlayTrigger>
          </div>
        </div>
      )}
    </>
  );
};
