import React, { CSSProperties, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import { Loading } from '@components/Loading';

interface MetricContainerProps {
  title: string;
  loading?: boolean;
  bodyStyle?: CSSProperties;
}

export function MetricContainer(props: PropsWithChildren<MetricContainerProps>) {
  const { title, loading, bodyStyle, children } = props;

  return (
    <>
      <Title>{title}</Title>
      <Body style={bodyStyle}>
        {loading && <Loading />}
        {children}
      </Body>
    </>
  );
}

const Title = styled.h5`
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
`;

const Body = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: #808fa5;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 20px;
`;
