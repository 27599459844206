import React from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '@lib/axios';
import { Page } from '@components/Layout/Page';

import Form from './components/Form';

function New() {
  const navigate = useNavigate();

  async function handleSubmit(data, selectedCustomers, setLoading, setErrors) {
    try {
      setLoading(true);

      const formData = {
        segment: {
          ...data,
          recharge_customers_ids: selectedCustomers.map((cust) => cust.id),
        },
      };

      await axios.post('/sms/segments/', formData);

      navigate('/sms/segments');
    } catch (e) {
      setLoading(false);
      setErrors(e.response.data.errors);
    }
  }

  return (
    <Page headerDescription="Create a new segment and add customers to id">
      <Form onSubmit={handleSubmit} />
    </Page>
  );
}

export default New;
