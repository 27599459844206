import React, { forwardRef } from 'react';
import { Modal as BModal, Button } from 'react-bootstrap';

import MainDrawer, { MainDrawerProps } from '@components/Drawer/MainDrawer';
import styled from '@emotion/styled/macro';

import * as WorkflowsContext from '../WorkflowsContext';

export * from '@components/Drawer/MainDrawer';

function DrawerComponent(props: MainDrawerProps, ref) {
  const { children, headerComponent, footerComponent, bodyStyle, subtitle, ...rest } = props;
  const { setIsDragabble } = WorkflowsContext.useWorkflowsContext();

  const handleShowModal = () => setIsDragabble(false);
  const handleHideModal = () => setIsDragabble(true);

  return (
    <MainDrawer ref={ref} onShow={handleShowModal} onHide={handleHideModal} {...rest}>
      {headerComponent && <BModal.Header closeButton>{headerComponent}</BModal.Header>}
      {subtitle && (
        <ModalSubtitle>
          <h3>{subtitle}</h3>
        </ModalSubtitle>
      )}
      <BModal.Body style={bodyStyle}>{children}</BModal.Body>
      {footerComponent && <BModal.Footer>{footerComponent}</BModal.Footer>}
    </MainDrawer>
  );
}

const ModalSubtitle = styled.div`
  background: #f7f7f7;
  padding: 16px 16px 6px;

  h3 {
    color: #191d48;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Drawer = forwardRef(DrawerComponent);
