import React from 'react';
import { LineChart } from 'react-chartkick';

import { MetricBase, MetricBaseProps } from './MetricBase';

export function MetricLineChart(props: MetricBaseProps) {
  return (
    <MetricBase {...props}>
      {(data) => <LineChart discrete colors={['#000000', '#314CD7', '#31D75A']} data={data} />}
    </MetricBase>
  );
}
