import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';

import { Provider } from 'react-redux';

import { SidebarV2 } from '@components/Layout/Sidebar/SidebarV2';
import { Router } from './Router';
import { BaseApp } from './BaseApp';
import { store, persistor } from '@redux/store';

export function App(props) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BaseApp Router={Router} Sidebar={SidebarV2} layout="embedded_app" {...props} />;
      </PersistGate>
    </Provider>
  );
}
