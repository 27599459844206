import React, { useState } from 'react';
import styled from '@emotion/styled';

import { useCurrentShop } from '@hooks/useCurrentShop';
import { Toggle } from '@components/Input/Toggle';
import { Card } from '@components/Layout/Card';
import { postShopActivate } from '@api/sms';

const BestPracticesGuides = () => {
  const shop = useCurrentShop();
  const [shopActive, setShopActive] = useState<boolean>(!!shop?.activeAt);
  const [loading, setLoading] = useState<boolean>(false);

  const toggleShopActive = async () => {
    setLoading(true);
    await postShopActivate();
    setShopActive(!shopActive);
    setLoading(false);
  };

  return (
    <Card className="h-auto mb-4" data-testid="help-deactivate-component">
      <div className="d-flex align-items-center px-4 pt-3">
        <h2 className="font-weight-bold mb-0 p-2">Deactivating RechargeSMS</h2>
      </div>
      <hr className="mb-0" />
      <div className="d-flex flex-column p-4 bg-light">
        <h5 className="font-weight-bold">Halt all messages</h5>
        <Text>
          If you'd like to pause or stop sending text messages to your customers, click the toggle
          below to disable and deactivate.
        </Text>
        <Text>You can always reactivate when you're ready.</Text>
        <Toggle
          className="ml-n3 mt-1 mb-3"
          checked={shopActive}
          onChange={toggleShopActive}
          size="sm"
          disabled={loading}
        />
        <h5 className="font-weight-bold">Uninstall RechargeSMS</h5>
        <Text>
          If you'd like to uninstall RechargeSMS, please contact us{' '}
          <a
            href="https://support.rechargepayments.com/hc/en-us/requests/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </Text>
      </div>
    </Card>
  );
};

const Text = styled.div`
  font-size: 16px;
`;

export default BestPracticesGuides;
