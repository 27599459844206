import React from 'react';
import styled from '@emotion/styled';

import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import { Button } from '@pages/Workflows/components/Button';
import { ProductCard } from '@pages/Workflows/components/ProductCard';
import boxIcon from '@pages/Workflows/assets/box.svg';

import { useLayoutContext } from '@pages/Workflows/LayoutContext';
import { ViewProducts } from './ViewProducts';
import BaseNode, { NodeProps } from '../BaseNode';

export const ProductSelectorNode = ({ data }: { data: NodeProps }) => {
  const {
    currentWorkflow,
    handleChangeWorkflow,
    selectedProducts,
    setSelectedProducts,
    savedProducts,
    setSavedProducts,
  } = useWorkflowsContext();
  const { setRightSidebar } = useLayoutContext();

  if (!currentWorkflow) return null;

  function handleToggleProductSelector() {
    setRightSidebar({ type: 'select-products', open: true, data, width: 500 });
  }

  function handleSave(productVariants = selectedProducts) {
    setSavedProducts(productVariants);

    handleChangeWorkflow({ productVariants });
    handleToggleProductSelector();
  }

  function handleDelete(productId) {
    const productVariants = selectedProducts.filter((product) => product.id !== productId);

    setSelectedProducts(productVariants);
    setSavedProducts(productVariants);

    if (productVariants.length === 1) {
      const indexToDelete = selectedProducts.findIndex(({ id }) => id === productId);

      handleSave(selectedProducts.splice(indexToDelete, 1));
    }
  }

  const hasProducts = savedProducts?.length > 0;
  const hasMultipleProducts = savedProducts?.length > 1;

  return (
    <>
      <BaseNode
        icon={boxIcon}
        color="#4ECB71"
        showCollapse={hasProducts}
        collapseComponent={
          hasMultipleProducts ? (
            <ViewProducts
              products={savedProducts}
              handleSave={handleSave}
              handleDelete={handleDelete}
            />
          ) : (
            <SelectedProduct
              title={savedProducts?.[0]?.title}
              subtitle={savedProducts?.[0]?.priceText}
              variants={[savedProducts?.[0]]}
            />
          )
        }
        title="Customer purchased..."
        bodyComponent={
          <Button style={{ width: 150 }} onClick={handleToggleProductSelector}>
            Select Products
          </Button>
        }
        {...data}
      />
    </>
  );
};

const SelectedProduct = styled(ProductCard)`
  margin: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  cursor: grab;
  box-shadow: inset 0 7px 5px -7px ${(props) => props.theme.colors.dark0}44;

  > div {
    width: 45px;
  }
`;
