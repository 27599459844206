import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import toast from 'react-hot-toast';

import axios from '@lib/axios';
import { EmailTemplate } from '@typings/EmailTemplate';
import { useFlag } from '@hooks/useFlag';
import { Text } from '@components/Typography/Text';

import 'styles/abaculus/variables.scss';
import 'react-quill/dist/quill.snow.css';
import './EditEmailTemplate.scss';

interface EditEmailTemplateProps {
  template: EmailTemplate;
  title?: string;
  toggleCollapse?(): void;
}

export function EditEmailTemplate(props: EditEmailTemplateProps) {
  const { template, title, toggleCollapse } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingPreview, setIsSendingPreview] = useState(false);
  const [body, setBody] = useState(template?.body);
  const [subject, setSubject] = useState(template?.subject);
  const refactoredView = useFlag('refactor_settings_pages_enabled')?.enabled;

  async function update() {
    try {
      setIsLoading(true);

      await axios.put(`/sms/email_templates/${template?.emailType}`, {
        email_template: { ...template, body, subject },
      });

      toast.success('Email template successfully updated!');

      toggleCollapse?.();
    } finally {
      setIsLoading(false);
    }
  }

  async function getEmailTemplate() {
    const { data } = await axios.get(`/sms/email_templates/${template?.emailType}`);
    setBody(data?.body);
    setSubject(data?.subject);
  }

  async function sendPreview() {
    try {
      const response = confirm("We'll send the email preview to your store's email.");

      if (!response) return;

      setIsSendingPreview(true);

      await axios.post('/sms/email_templates/preview', {
        email_template: { email_type: template?.emailType, body, subject },
      });

      toast.success("The preview email has been sent to your store's email.");
    } finally {
      setIsSendingPreview(false);
    }
  }

  useEffect(() => {
    getEmailTemplate();
  }, []);

  const variableColorMapper = (variable) => {
    switch (true) {
      case variable.startsWith('shop_'):
        return 'iris-pill';
      case variable.startsWith('customer_'):
        return 'fuschia-pill !important';
      default:
        return 'teal-pill !important';
    }
  };

  return (
    <>
      {title && (
        <div
          className={refactoredView ? `d-flex justify-content-between align-items-center mb-4` : ``}
          data-testid="edit-email-template-title"
        >
          {refactoredView ? (
            <div className="d-flex w-100 pt-4 px-4 align-items-center">
              <h4 className="font-weight-bold mb-0">{title}</h4>
              <div className="ml-auto">
                <Button
                  className="font-weight-bold rounded-lg mr-1"
                  type="button"
                  variant="outline-secondary"
                  disabled={isSendingPreview}
                  onClick={sendPreview}
                >
                  {isSendingPreview ? 'Sending...' : 'Preview'}
                </Button>{' '}
                <Button
                  className="font-weight-bold rounded-lg"
                  variant="secondary"
                  type="button"
                  disabled={isLoading}
                  onClick={update}
                >
                  {isLoading ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </div>
          ) : (
            <Row>
              <Col>
                <Text weight="bold">{title}</Text>
              </Col>
            </Row>
          )}
        </div>
      )}
      <Row className={refactoredView ? `bg-light p-4` : ``} data-testid="edit-email-template-body">
        <Col>
          <Form.Group>
            <Form.Label>{refactoredView && 'Email '}Subject</Form.Label>
            <Form.Control
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Form.Group>
          <ReactQuill theme="snow" value={body} onChange={setBody} className="mt-2" />
        </Col>
        {refactoredView && (
          <Col>
            <div className="small">Variables - copy & paste to add</div>
            {template?.variables.map((variable) => (
              <span
                key={variable}
                className={`${variableColorMapper(
                  variable
                )} badge rounded-pill text-dark text-lowercase mr-2 mb-1`}
                dangerouslySetInnerHTML={{ __html: `{{${variable}}}` }}
              />
            ))}
          </Col>
        )}
      </Row>
      {!refactoredView && (
        <div className="p-4">
          <Row className="mt-2">
            <Col>
              {template?.variables.map((variable) => (
                <span
                  key={variable}
                  className="badge rounded-pill bg-light text-dark text-lowercase mr-2 mb-1"
                  dangerouslySetInnerHTML={{ __html: `{{${variable}}}` }}
                />
              ))}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-right">
              <Button
                type="button"
                variant="outline-primary"
                disabled={isSendingPreview}
                onClick={sendPreview}
              >
                {isSendingPreview ? 'Sending...' : 'Preview'}
              </Button>{' '}
              <Button type="button" disabled={isLoading} onClick={update}>
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default EditEmailTemplate;
