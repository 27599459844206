import styled from '@emotion/styled';
import { CollectionVariant } from '@typings/Collection';
import { ProductImage } from '@components/Image/ProductImage';
import React from 'react';

interface ThumbnailsPreviewProps {
  collectionVariants: CollectionVariant[];
}

export const ThumbnailsPreview = (props: ThumbnailsPreviewProps) => {
  const { collectionVariants } = props;

  const firstThree = collectionVariants.slice(0, 3);

  return (
    <Root>
      {firstThree.map((collectionVariant) => (
        <StyledThumbnail
          key={collectionVariant.id}
          src={collectionVariant.productVariant?.imageUrl}
          alt="product image"
        />
      ))}
    </Root>
  );
};

const Root = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;

const StyledThumbnail = styled(ProductImage)`
  border-radius: ${(props) => props.theme.radius.small};
  box-shadow: 0px 1px 4px 0px #00000059;
  margin-right: 3px;
  width: 33px;
`;
