import { useState, useEffect } from 'react';

import axios from '@lib/axios';
import { Integration, Service } from '@typings/Integration';

import { images } from '@pages/Home/components/CustomerIntegrationsCard/appsImages';
import { useAppDispatch } from '@redux/hooks';
import { customerSupportSlice } from '@redux/reducer/customerSupportSlice';

export function useIntegration() {
  const [allIntegrations, setAllIntegrations] = useState<Integration[]>([]);
  const [connectedIntegration, setConnectedIntegration] = useState<Integration>();

  const dispatch = useAppDispatch();

  const availableIntegrations = allIntegrations.filter(
    (integration) => integration.service !== connectedIntegration?.service
  );

  const setImage = (integration: Integration) => ({
    ...integration,
    image: images[integration.service],
  });

  const connectIntegration = async (formData: Integration) => {
    try {
      const { data: integration } = await axios.patch(
        `/sms/integrations/${formData.service}`,
        formData
      );

      const changedIntegration = setImage(integration);

      setConnectedIntegration(changedIntegration);

      dispatch(customerSupportSlice.actions.setConnectedIntegration(changedIntegration));

      setAllIntegrations((prevState) =>
        prevState.map((integration) => {
          if (integration.service === changedIntegration.service) return changedIntegration;
          return integration;
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const disconnectIntegration = () => {
    const disconnect = async (service: Service) => {
      try {
        await axios.patch(`/sms/integrations/${service}`, { status: 'disabled' });
      } catch (e) {
        console.log(e);
      }
    };

    const integration = connectedIntegration;
    disconnect(integration.service);
    setConnectedIntegration(null);
    dispatch(customerSupportSlice.actions.setConnectedIntegration(null));
  };

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const { data } = await axios.get('/sms/integrations', {
          params: { category: 'customer_support' },
        });

        const apps = data.map(setImage);
        const currentIntegration = apps.find((app) => app.status === 'enabled');
        const integrations = apps.filter((app) => app.service !== 'helpscout');

        setAllIntegrations(integrations);
        setConnectedIntegration(currentIntegration);
        dispatch(customerSupportSlice.actions.setConnectedIntegration(currentIntegration));
      } catch (e) {
        console.log(e);
      }
    };

    fetchIntegrations();
  }, []);

  return {
    availableIntegrations,
    connectedIntegration,
    connectIntegration,
    disconnectIntegration,
  };
}
