import React from 'react';
import styled from '@emotion/styled';

import { Integration } from '@typings/Integration';
import { Button } from '@pages/Home/components/Button';
import { Container } from 'react-bootstrap';

interface IntegrationsListProps {
  selectedIntegration: Integration;
  integrations: Integration[];
  setIntegration(value: Integration): void;
}

export function IntegrationsList(props: IntegrationsListProps) {
  const { integrations, selectedIntegration, setIntegration } = props;

  return (
    <>
      <Title>Supported Integrations</Title>
      <List isSelectIntegration={!!selectedIntegration}>
        {integrations.map((integration) => (
          <Item
            key={integration?.service}
            selected={integration?.service === selectedIntegration?.service}
          >
            <img height={25} src={integration?.image} />
            <Button onClick={() => setIntegration(integration)}>Connect</Button>
          </Item>
        ))}
      </List>
    </>
  );
}

const List = styled.ul<{ isSelectIntegration?: boolean }>`
  padding: 0;
  margin: 0;
  height: ${(props) => (!props.isSelectIntegration ? '450px' : 'auto')};
  overflow: ${(props) => !props.isSelectIntegration && 'auto'};
`;

const Item = styled.li<{ selected?: boolean }>`
  list-style: none;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  border-radius: 8px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  margin: 0 5px;
  margin-bottom: 1rem;
  border: ${(props) => props.selected && '1px solid #17CC9D'};
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 1rem;
  margin-left: 5px;
`;
