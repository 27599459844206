import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Page } from '@components/Layout/Page';
import { useFetch } from '@hooks/useFetch';
import axios from '@lib/axios';
import { SwapGroup } from '@typings/SwapGroup';

import Form from './components/Form';

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: swapGroup = {}, loading } = useFetch<SwapGroup | Record<any, never>>(
    `/sms/swap_groups/${id}`
  );

  const handleSubmit = async (data) => {
    const path = paths[swapGroup.groupType];
    await axios.put(`${path}/${swapGroup.id}`, data);

    navigate(path);
  };

  const typeTitles = titles[swapGroup?.groupType];

  return (
    <Page loading={loading}>
      <Form
        onSubmit={handleSubmit}
        defaultValues={swapGroup as SwapGroup}
        subtitle={typeTitles?.subtitle}
      />
    </Page>
  );
}

const paths = {
  swap: '/sms/swap_groups',
  quantity: '/sms/quantity_groups',
};

const titles = {
  swap: {
    title: (swapGroup) => `Edit Swap Group #${swapGroup?.id}`,
    subtitle: "Update product swap group's info",
  },
  quantity: {
    title: (swapGroup) => `Edit Quantity Group #${swapGroup?.id}`,
    subtitle: "Update product quantity group's info",
  },
};

export default Edit;
