import React, { forwardRef, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Form from 'react-bootstrap/Form';
import { FeatureSwitchProps } from '@pages/Settings/Features/shared/FeatureSwitch/FeatureSwitch';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faEye as faEyeSolid } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwitchLock from '@assets/icons/switch-lock.svg';
import { ConfirmationModal } from '@components/Modal/ConfirmationModal';
import { useFeaturesContext } from '@contexts/FeaturesContext';
import { ModalHandles } from '@components/Modal/BaseModal';
import { useAppSelector, useAppDispatch } from '@redux/hooks';
import { settingSlice } from '@redux/reducer/settingSlice';
import { AutoSelectFeatureSwitch } from './AutoSelectFeatureSwitch';

type EnableChargeReminderSmsSwitchProps = FeatureSwitchProps;

const EnableChargeReminderSmsSwitch = (props: EnableChargeReminderSmsSwitchProps) => {
  const { feature, disabled, showActions = true, showPreview = true } = props;

  let { childNestedLevel = 0 } = props;

  const settingState = useAppSelector((state) => state.setting);

  const unLockModalRef = useRef<ModalHandles>(null);
  const disableModalRef = useRef<ModalHandles>(null);

  const {
    selectedFeature,
    selectedPreview,
    currentState,
    handleFeatureSelected,
    handlePreviewChange,
    handleFeatureChanged,
  } = useFeaturesContext();

  const state = currentState(feature.key);
  const [loading, setLoading] = useState(false);
  const isFeatureSelected = selectedFeature?.key === feature?.key;
  const isPreviewSelected = selectedPreview === feature?.key;

  const handleToggle = async () => {
    setLoading(true);
    await handleFeatureChanged(feature.key);
    setLoading(false);
  };

  const onEnableChargeReminderLockClick = () => {
    unLockModalRef?.current?.toggleModal();
  };

  const showDisableConfirmationModal = () => {
    disableModalRef?.current?.toggleModal();
  };

  const dispatch = useAppDispatch();

  return (
    <>
      <UnlockChargeReminderConfirmationModal
        ref={unLockModalRef}
        handleConfirm={() => dispatch(settingSlice.actions.setLockChargeReminderToggle(false))}
      />
      <DisableChargeReminderConfirmationModal ref={disableModalRef} handleConfirm={handleToggle} />

      <OuterContainer>
        <Container>
          {!settingState.isLockChargeReminderToggle && (
            <Switch>
              <Form.Check
                type="switch"
                id={feature.key}
                name={feature.key}
                label="Enable Charge Reminder SMS"
                checked={state?.enabled}
                onChange={() => (state?.enabled ? showDisableConfirmationModal() : handleToggle())}
                disabled={disabled || loading}
                style={{
                  marginLeft: `${childNestedLevel}rem`,
                }}
              />
            </Switch>
          )}

          {settingState.isLockChargeReminderToggle && (
            <>
              <img
                src={SwitchLock}
                onClick={onEnableChargeReminderLockClick}
                style={{ cursor: 'pointer' }}
              />
              <label style={{ marginBottom: '0px', marginLeft: '5px' }}>
                Enable Charge Reminder SMS
              </label>
            </>
          )}

          <Options>
            {showActions && (
              <IconsContainer className="onboarding-features-list-actions">
                <Icon
                  size="1x"
                  icon={faInfoCircle}
                  inverse={!isFeatureSelected}
                  onClick={() => handleFeatureSelected(feature)}
                />
                <Icon
                  size="lg"
                  icon={isPreviewSelected ? faEyeSolid : faEye}
                  hide={childNestedLevel > 0 || !showPreview}
                  onClick={() => handlePreviewChange(feature.key)}
                />
              </IconsContainer>
            )}
          </Options>
        </Container>
        <DescriptionContainer>
          {(state?.enabled && (
            <>
              RechargeSMS will notify your customers of an upcoming charge. This is an essential
              feature of transactional subscription management that your customers expect.{' '}
              <b>Adjusting this setting will severely impact your customer’s experience.</b>
            </>
          )) || (
            <>
              <b>Leaving this feature off negatively impacts your customer's experience.</b>{' '}
              RechargeSMS will notify your customers of an upcoming charge. This is an essential
              feature of transactional subscription management that your customers expect.
            </>
          )}
        </DescriptionContainer>
      </OuterContainer>
      {++childNestedLevel &&
        state.enabled &&
        feature?.features?.map((childFeature) => (
          <AutoSelectFeatureSwitch
            key={childFeature.id}
            feature={childFeature}
            selectedFeature={selectedFeature}
            childNestedLevel={childNestedLevel}
            onInfo={handleFeatureSelected}
          />
        ))}
    </>
  );
};

interface ConfirmationModalComponentProps {
  handleConfirm(): void;
}

const DisableChargeReminderConfirmationModalComponent = (
  props: ConfirmationModalComponentProps,
  ref
) => {
  const { handleConfirm } = props;

  return (
    <ConfirmationModal
      ref={ref}
      title="Are you sure?"
      description="Adjusting this setting will severely impact your customer’s experience. Before making any changes, we recommend you consider the impact to your customers as well any compliance or legal requirements."
      confirmButtonLabel="Continue"
      cancelButtonLabel="Cancel"
      checkBoxConfirmLabel="Yes, I would like to change this settings"
      onConfirm={handleConfirm}
    />
  );
};

const DisableChargeReminderConfirmationModal = forwardRef(
  DisableChargeReminderConfirmationModalComponent
);

const UnlockChargeReminderConfirmationModalComponent = (
  props: ConfirmationModalComponentProps,
  ref
) => {
  const { handleConfirm } = props;

  return (
    <ConfirmationModal
      ref={ref}
      title="Are you sure?"
      description="Adjusting this setting will severely impact your customer’s experience. Before making any changes, we recommend you consider the impact to your customers as well any compliance or legal requirements."
      confirmButtonLabel="Continue"
      cancelButtonLabel="Cancel"
      onConfirm={handleConfirm}
    />
  );
};

const UnlockChargeReminderConfirmationModal = forwardRef(
  UnlockChargeReminderConfirmationModalComponent
);

const OuterContainer = styled.div`
  display: block;
  border-bottom: 1px solid #dedede;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #fbfbfb;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 45px;
  padding: 0 15px;
`;

const Switch = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 2;
`;

const Options = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const IconsContainer = styled.div`
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface IconProps {
  hide?: boolean;
  inverse?: boolean;
}

const Icon = styled(FontAwesomeIcon)<IconProps>`
  cursor: pointer;
  display: ${(props) => (props.hide ? 'none' : 'inline-block')};

  ${(props) =>
    props.inverse &&
    `
      background: #000000;
      border-radius: 50%;
      border: 1px solid #000000;
    `}
`;

const DescriptionContainer = styled.div`
  padding: 0px 50px 15px 50px;
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.47px;
  color: #787878;
  text-align: left;
`;

export default EnableChargeReminderSmsSwitch;
