import React, { useRef, useState, useCallback } from 'react';
import toast from 'react-hot-toast';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import FileDownload from 'js-file-download';
import { useNavigate } from 'react-router-dom';

import axios from '@lib/axios';
import BaseModal, { ModalHandles } from '@components/Modal/BaseModal';
import { ViewPage, ViewPageHandles } from '@components/Layout/ViewPage';
import { CustomerInfo } from '@components/Table/Row/CustomerInfo';
import { formatString } from '@utils/date';

import { CustomerBadge } from './CustomerBadge';
import { CustomerActions } from './CustomerActions';
import { ImportCustomerModal } from './ImportCustomerModal';
import { PendingCustomerBanner } from './PendingCustomerBanner';

const BASE_URL = '/sms/recharge/customers';

function View() {
  const navigate = useNavigate();
  const [ref, setRef] = useState<{ current: ViewPageHandles }>(null);
  const modalRef = useRef<ModalHandles>(null);

  const onRefChange = useCallback((node) => {
    setRef({ current: node });
  }, []);

  const filterOptions = [
    {
      value: null,
      label: 'Select a filter',
    },
    {
      label: 'Active Opt-in',
      options: [
        {
          value: 'transactional_opt_in',
          label: 'Most Recent',
        },
        {
          value: 'failed_to_deliver',
          label: 'Had a failed to deliver message',
        },
        {
          value: 'unknown_message',
          label: 'Sent an unknown message',
        },
        {
          value: 'inbound_message',
          label: 'Sent an inbound message',
        },
      ],
    },
    {
      value: 'transactional_pending',
      label: 'Pending opt-in',
    },
    {
      value: 'unreachable',
      label: 'Unreachable',
    },
    {
      value: 'transactional_opt_out',
      label: 'Opted-out',
    },
  ];

  const dropdownOptions = [
    {
      label: 'Export List (CSV)',
      onClick: handleExportCSV,
      icon: faDownload,
    },
  ];

  const totalCount = ref?.current?.page?.paginationInfo?.totalCount || 0;
  const tableHeaders = [
    {
      label: 'Contact',
      description: `(${totalCount} customers total)`,
      width: '50%',
    },
    {
      label: 'Status',
    },
    {
      label: 'Created at',
    },
    {
      label: 'Actions',
      width: '20%',
    },
  ];

  function tableRow(customer) {
    const data = [
      <CustomerInfo customer={customer} />,
      <CustomerBadge customer={customer} />,
      formatString(customer.createdAt, 'MM/dd/yyyy'),
      <CustomerActions customer={customer} />,
    ];

    function onClick() {
      navigate(`/sms/recharge/customers/${customer.id}`);
    }

    return { data, onClick };
  }

  async function handleImportCustomer(email) {
    function onSuccess() {
      handleToggleImportCustomerModal();
      ref?.current?.handleSearch();
      return 'Customer successfully imported!';
    }

    toast.promise(
      axios.post('/sms/recharge/customers/import', {
        recharge_customer: { email },
      }),
      {
        loading: 'Importing...',
        success: onSuccess,
        error: 'Customer not found!',
      }
    );
  }

  function generateCSVFilename(prefix) {
    return `${prefix ?? 'unfiltered'}_customers-${new Date().toLocaleDateString()}.csv`;
  }

  async function handleExportCSV() {
    const { selectedFilter, searchParam } = ref.current;

    function onSuccess(response) {
      // FileDownload(response.data, generateCSVFilename(selectedFilter.value));
      exportData(response.data, generateCSVFilename(selectedFilter.value), 'text/csv');

      return 'CSV successfully exported!';
    }

    const exportData = (data, fileName, type) => {
      // Create a link and download the file
      const blob = new Blob([data], { type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };

    toast.promise(
      axios.get(BASE_URL, {
        headers: {
          Accept: 'text/csv',
        },
        responseType: 'blob',
        params: {
          search: searchParam,
          filter: selectedFilter.value,
        },
      }),
      {
        loading: 'Exporting CSV...',
        success: onSuccess,
        error: 'Something went wrong, try again later.',
      }
    );
  }

  function handleToggleImportCustomerModal() {
    modalRef.current?.toggleModal();
  }

  return (
    <>
      <ViewPage
        ref={onRefChange}
        url={BASE_URL}
        header={{
          description: 'List and view details of your customers',
          component: {
            text: 'Import customer',
            onClick: handleToggleImportCustomerModal,
          },
        }}
        filter={{
          options: filterOptions,
          selectedFilter: filterOptions[0],
        }}
        dropdown={{
          id: 'customer-view-export-csv',
          options: dropdownOptions,
        }}
        table={{
          headers: tableHeaders,
          row: tableRow,
          banner: <PendingCustomerBanner selectedFilter={ref?.current?.selectedFilter} />,
        }}
        searchPlaceholder="Search by customers name, email or phone number"
      />
      <BaseModal ref={modalRef} showCloseIcon>
        <ImportCustomerModal
          handleSubmit={handleImportCustomer}
          toggleModal={handleToggleImportCustomerModal}
        />
      </BaseModal>
    </>
  );
}

export default View;
