import React from 'react';
import { ProductView } from '@components/Onboarding/v2/components/ProductView';
import { useAppSelector } from '@redux/hooks';

export const ProductFormatTitleCompleteView = () => {
  const onboardingState = useAppSelector((state) => state.onboarding);

  return (
    <ProductView
      showCompletedFormatTitleBanner={onboardingState.isShowCompletedFormatTitleBanner}
    />
  );
};
