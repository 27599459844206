import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import styled from '@emotion/styled';

interface HeaderProps {
  onClose: () => void;
  children: React.ReactNode;
}

export default function Header({ onClose, children }: HeaderProps) {
  return (
    <HeaderContainer>
      <div className="title-container">
        {children}
        <div className="close-container">
          <CloseButton icon={faTimes} onClick={onClose} />
        </div>
      </div>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  padding: 4px 0;

  * {
    font-family: 'SFProDisplay';
  }

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid #f2f3f5;

    .close-container {
      margin-bottom: 8px;
    }
  }
`;

const CloseButton = styled(FontAwesomeIcon)`
  color: #9a9b9c;
  cursor: pointer;
  font-size: 28px;
  margin: 0 8px;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
`;
