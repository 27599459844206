import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import capitalize from 'lodash/capitalize';
import { useParams, Link } from 'react-router-dom';

import { Page } from '@components/Layout/Page';
import { IPage } from '@components/Layout/ViewPage';
import { useFetch } from '@hooks/useFetch';
import axios from '@lib/axios';
import { RechargeCustomer } from '@typings/RechargeCustomer';
import { Segment } from '@typings/Segment';
import { Paginator } from '@components/Paginator';
import { formatString } from '@utils/date';

import Actions from './Actions';

function Show() {
  const { id } = useParams();

  const { data = {}, loading } = useFetch<Segment | Record<any, never>>(`/sms/segments/${id}`);
  const [rechargeCustomersPage, setRechargeCustomersPage] = useState<IPage<RechargeCustomer>>();

  async function handleFetchRechargeCustomers({
    pageNumber = rechargeCustomersPage?.paginationInfo?.current || 1,
  } = {}) {
    try {
      const { data } = await axios.get(`/sms/segments/${id}/recharge_customers`, {
        params: {
          page: pageNumber,
          limit: 10,
        },
      });

      setRechargeCustomersPage(data);
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
  }

  useEffect(() => {
    handleFetchRechargeCustomers();
  }, []);

  function handleCsvDownload() {
    document.location.href = `/sms/segments/${id}.csv`;
  }

  return (
    <Page
      loading={loading}
      headerDescription="View details and perform actions on your customers segments"
      headerComponent={data && <Actions segment={data} />}
    >
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div>
                <h6>
                  <strong>Name</strong>
                </h6>
                <p>{data?.name}</p>
              </div>
              <div>
                <h6>
                  <strong>Description</strong>
                </h6>
                <p>{data?.description}</p>
              </div>
              <div>
                <h6>
                  <strong>Target</strong>
                </h6>
                <p>{data?.target}</p>
              </div>
              <div>
                <h6>
                  <strong>Created at</strong>
                </h6>
                <p>{formatString(data?.createdAt, 'MM/dd/yyyy')}</p>
              </div>
              {data?.refreshedAt && (
                <div>
                  <h6>
                    <strong>Refreshed at</strong>
                  </h6>
                  <p>{formatString(data?.refreshedAt, 'MM/dd/yyyy hh:mm a')}</p>
                </div>
              )}
              <div>
                <h6>
                  <strong>Size</strong>
                </h6>
                {!rechargeCustomersPage?.paginationInfo ? (
                  <p>Loading customers...</p>
                ) : (
                  <p>{rechargeCustomersPage?.paginationInfo.totalCount}</p>
                )}
              </div>
              <div>
                <h6>
                  <strong>Status</strong>
                </h6>
                {data?.active ? (
                  <div className="badge badge-primary">Active</div>
                ) : (
                  <div className="badge badge-danger">Inactive</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              {data?.data &&
                Object.keys(data?.data).map((key) => (
                  <div key={key}>
                    <h6>
                      <strong>{capitalize(key.replace('_', ' '))}</strong>
                    </h6>
                    <p>{data?.data[key]}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {rechargeCustomersPage?.paginationInfo?.totalCount > 0 && (
        <div className="card mt-4">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <h4>Customers</h4>
                  <button type="button" className="btn btn-black" onClick={handleCsvDownload}>
                    Export CSV
                  </button>
                </div>
                <div className="table table-hover table-responsive mt-4">
                  <table className="table table-bordered" width="100%" cellSpacing="0">
                    <thead>
                      <tr>
                        <th style={{ width: '40%' }}>Info</th>
                        <th style={{ width: '40%' }}>Address</th>
                        <th style={{ width: '20%' }}>Customer Since</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rechargeCustomersPage?.data?.map((customer) => (
                        <tr key={customer?.id}>
                          <td>
                            <Link
                              to={`/sms/recharge/customers/${customer?.id}`}
                              className="text-decoration-none"
                            >
                              <div>
                                <h6>{customer?.name}</h6>
                                <span className="template-message__description">
                                  {customer?.email || customer?.phone}
                                </span>
                              </div>
                            </Link>
                          </td>
                          <td>{customer?.shortAddress}</td>
                          <td>{formatString(customer?.rechargeCreatedAt, 'MM/dd/yyyy')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <Paginator
                    paginationInfo={rechargeCustomersPage?.paginationInfo}
                    onPageChange={(pageNumber) => handleFetchRechargeCustomers({ pageNumber })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Page>
  );
}

export default Show;
