import { ButtonGroup } from '@components/Button/ButtonGroup';
import { CustomButton } from '@components/Button/CustomButton';
import { Loading } from '@components/Loading';
import styled from '@emotion/styled';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { useFlag } from '@hooks/useFlag';
import { useInitialState } from '@hooks/useInitialState';
import axios from '@lib/axios';
import React, { useEffect, useState } from 'react';
import { ApplyCouponNode } from './ApplyCouponNode';

export const ApplyShopifyDiscountNode = (props) => {
  const [support, setSupport] = useState(false);
  const [loading, setLoading] = useState(true);
  const shop = useCurrentShop();
  const { loading: loadingDiscountFlag, enabled: discountFlagEnabled } = useFlag(
    'enable_replenishment_discount'
  );
  const { rechargeAdminPanelDomainSufix } = useInitialState();

  useEffect(() => {
    async function fetchDiscountCodeSupport() {
      if (!support) {
        const { data } = await axios.get('/sms/shops/support_shopify_discount_codes');
        setSupport(data.support_shopify_discount_codes);
      }
      setLoading(false);
    }

    if (!loadingDiscountFlag) {
      if (discountFlagEnabled) {
        fetchDiscountCodeSupport();
      } else {
        setLoading(false);
      }
    }
  }, [loadingDiscountFlag, discountFlagEnabled]);

  const replenishmentIntegrationLink = `https://${shop.shopifyDomain.replace(
    '.myshopify.com',
    rechargeAdminPanelDomainSufix
  )}/settings/integrations/replenishment`;

  return (
    <ApplyCouponNode {...props} disabled={loading || !support} showEnabledToggle>
      {discountFlagEnabled && (
        <>
          {loading && <Loading />}
          {!loading && !support && (
            <DisabledMessageContainer>
              In order to provide discount codes on your workflows you'll first need to re-authorize
              Shopify.
              <ButtonGroup>
                <CustomButton target="_blank" href={replenishmentIntegrationLink}>
                  Authorize Shopify
                </CustomButton>
              </ButtonGroup>
            </DisabledMessageContainer>
          )}
        </>
      )}
    </ApplyCouponNode>
  );
};

const DisabledMessageContainer = styled.div`
  padding: ${(props) => props.theme.spacing.medium};
`;
