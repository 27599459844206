import React, { useRef } from 'react';
import find from 'lodash/find';
import remove from 'lodash/remove';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import axios from '@lib/axios';
import { ViewPage, ViewPageHandles } from '@components/Layout/ViewPage';
import { formatString } from '@utils/date';

import { Actions } from './Actions';
import { Badges } from './Badges';

const BASE_URL = '/sms/cancellation_reasons';

function View() {
  const ref = useRef<ViewPageHandles>();
  const navigate = useNavigate();

  const tableHeaders = [
    {
      label: 'Name',
      width: '45%',
    },
    {
      label: 'Churn Prevention',
      width: '15%',
    },
    {
      label: 'Last modified',
    },
    {
      label: 'Actions',
    },
  ];

  function tableRow(cancellationReason) {
    const data = [
      <strong>{cancellationReason.name}</strong>,
      <Badges winback={cancellationReason.winback} />,
      formatString(cancellationReason.updatedAt, 'MM/dd/yyyy'),
      <Actions cancellationReason={cancellationReason} handleDelete={handleDelete} />,
    ];

    return { data };
  }

  function handleDelete(id) {
    function onSuccess() {
      ref.current.setPage((page) => {
        const { data: cancellationReasons } = page;
        const cancellationReason = find(cancellationReasons, { id });

        remove(cancellationReasons, cancellationReason);

        return {
          ...page,
          data: cancellationReasons,
        };
      });

      return 'Reason successfully deleted!';
    }

    toast.promise(axios.delete(`${BASE_URL}/${id}`), {
      loading: 'Deleting...',
      success: onSuccess,
      error: 'Something went wrong! try again later.',
    });
  }

  function handleHeaderComponentClick() {
    navigate(`${BASE_URL}/new`);
  }

  return (
    <ViewPage
      ref={ref}
      url={BASE_URL}
      header={{
        description: 'Customize your end subscription cancellation reasons.',
        component: {
          text: 'Add new Cancellation Reason',
          onClick: handleHeaderComponentClick,
        },
      }}
      table={{
        headers: tableHeaders,
        row: tableRow,
      }}
      searchPlaceholder="Search by name"
    />
  );
}

export default View;
