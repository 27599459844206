import Tagify from '@yaireo/tagify';

export const convertToDoubleBrackets = (value: string) => {
  if (!value) return '';
  return value.replace(/\{{3}/g, '{{').replace(/\}{3}/g, '}}');
};

export const convertToTagifyPattern = (value: string, lockedVariables: any[] = []) => {
  if (!value) return '';

  return value.replace(/\{\{\{([\w_]+)\}\}\}/g, (_, variable) => {
    if (lockedVariables.includes(variable)) {
      return `[[{\"value\":\"${variable}\",\"readonly\":true}]]`;
    } else {
      return `[[{\"value\":\"${variable}\"}]]`;
    }
  });
};

export const parseOutput = (output: string) => {
  const replaceFromTwoToThreeBrackets = /\{\{([\w_]+)\}\}/g;
  const replaceTagifyPattern = /\[\[\{"value":"(\w+)"(?:,"readonly":true)?\}\]\]/g;

  return output
    .replace(replaceFromTwoToThreeBrackets, '{{{$1}}}')
    .replace(replaceTagifyPattern, '{{{$1}}}');
};

const Variablefy = (editField) => {
  if (!editField) {
    throw new Error('Field not specified!');
  }

  const variables = JSON.parse(editField.dataset.variables);

  /* replace variables with 3 curly brackets */
  editField.value = convertToDoubleBrackets(editField.value);

  const tagify = new Tagify(editField, {
    mode: 'mix',
    mixTagsInterpolator: ['{{', '}}'],
    pattern: /@/,
    whitelist: variables,
    dropdown: {
      enabled: 1,
      position: 'text',
      highlightFirst: true,
    },
    editTags: false,
    enforceWhitelist: true,
    duplicates: true,
  });

  tagify.on('input', (e) => {
    if (e.detail.prefix && e.detail.value.length > 1) {
      return tagify.dropdown.show.call(tagify, e.detail.value);
    }
  });

  tagify.parseOutput = (output) => parseOutput(output);

  tagify.addVariable = (targetVariable) =>
    tagify.addTags([variables.find((variable) => variable === targetVariable)]);

  return tagify;
};

export default Variablefy;
