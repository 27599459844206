import React from 'react';
import styled from '@emotion/styled';

import { WorkflowTitle } from './WorkflowTitle';
import { WorkflowToggle } from './WorkflowToggle';
import { Insights } from './Insights';

import { useWorkflowsContext } from '../WorkflowsContext';

export function Header() {
  const { currentWorkflow } = useWorkflowsContext();

  return (
    <Container>
      <WorkflowTitle />
      <Right>
        {currentWorkflow?.id !== 0 && (
          <>
            <Insights />
            <Separator />
            <WorkflowToggle />
          </>
        )}
      </Right>
    </Container>
  );
}

const Container = styled.header`
  align-items: center;
  background: ${(props) => props.theme.colors.light0};
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`;

const Right = styled.div`
  display: flex;
`;

const Separator = styled.div`
  background-color: #f2f3f5;
  height: 46px;
  margin: 0 20px;
  width: 1px;
`;
