import React from 'react';
import styled from '@emotion/styled';
import { useLayoutContext } from '@pages/Workflows/LayoutContext';

interface CloseSaveBodyProps {
  children: React.ReactNode;
  handleSave: () => void;
}

export default function CloseSaveBody({ children, handleSave }: CloseSaveBodyProps) {
  const { closeRightSidebar } = useLayoutContext();

  return (
    <CloseSaveBodyContainer>
      <div className="scroll-container">{children}</div>
      <div className="flex-grow" />
      <div className="modal-footer">
        <div
          onClick={closeRightSidebar}
          className="btn btn-outline-secondary"
          role="button"
          tabIndex={0}
        >
          Close
        </div>
        <div className="btn btn-secondary" role="button" onClick={() => handleSave()} tabIndex={0}>
          Confirm
        </div>
      </div>
    </CloseSaveBodyContainer>
  );
}

const CloseSaveBodyContainer = styled.div`
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
  .scroll-container {
    overflow-y: scroll;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .modal-footer {
    display: flex;
    position: sticky;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 -16px;
    width: calc(100% + 32px);
    bottom: 0;
    background-color: #fff;
    & > .btn {
      border-radius: 6px;
      flex-grow: 1;
      margin: 0 16px;
      &:first-of-type {
        margin-right: 8px;
      }
      &:last-of-type {
        margin-left: 8px;
      }
    }
  }
`;
