import React from 'react';
import styled from '@emotion/styled';

import { Winback } from '@typings/CancellationReason';
import { Badge } from '@components/Table/Row/Badge';

interface BadgeProps {
  winback: Winback;
}

export function Badges(props: BadgeProps) {
  const { winback } = props;

  const colorsByWinback = {
    not_defined: '#191d45',
    skip_order: '#f57400',
    swap_product: '#078fee',
    default_reason: '#30b643',
  };

  const formattedWinback = winback.replace(/_/g, ' ');

  return (
    <Container>
      <Badge color={colorsByWinback[winback]} textColor="#fff" label={formattedWinback} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;
