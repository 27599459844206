import { useEffect, useState, useMemo } from 'react';

import axios from '@lib/axios';
import { formatString } from '@utils/date';
import { useOverviewContext } from '@pages/Dashboard/Overview/OverviewContext';

const BASE_URL = '/sms/metrics';

export function useFetchMetric(url) {
  const [state, setState] = useState({ loading: true, data: undefined });
  const { period, dateRange } = useOverviewContext();

  const startDate = useMemo(
    () => dateRange && formatString(dateRange?.[0]?.toString(), 'MM-dd-yyyy'),
    [dateRange]
  );

  const endDate = useMemo(
    () => dateRange && formatString(dateRange?.[1]?.toString(), 'MM-dd-yyyy'),
    [dateRange]
  );

  useEffect(() => {
    fetchData();
  }, [url, period, dateRange]);

  async function fetchData() {
    if (!period && !dateRange) return;

    const params: any = {};

    if (period) {
      params.period = period;
    } else {
      params.start_date = startDate;
      params.end_date = endDate;
    }

    const { data } = await axios.get(`${BASE_URL}/${url}`, {
      params,
    });
    setState({ loading: false, data });
  }

  return state;
}
