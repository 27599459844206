import React from 'react';
import Flatpickr from 'react-flatpickr';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

interface DatePickerProps {
  value: Date[];
  onChange(dates: Date[]): void;
}

export function DatePicker(props: DatePickerProps) {
  const { value, onChange } = props;

  const options = {
    maxDate: new Date(),
    mode: 'range',
    altInput: true,
    wrap: true,
    onChange,
  };

  return (
    <Container data-input options={options} value={value}>
      <FontAwesomeIcon data-toggle icon={faCalendarAlt} />
      <Input type="text" placeholder="Set custom date range" data-input />
      <FontAwesomeIcon data-toggle icon={faChevronDown} />
    </Container>
  );
}

const Container = styled(Flatpickr)`
  display: flex;
  align-items: center;
  background: white;
  padding: 0 10px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
  height: 35px;
  cursor: pointer;
  border-radius: 6px;
`;

const Input = styled.input`
  &&& {
    background-color: white;
    border: 0;
    outline: 0;
    cursor: pointer;
    box-shadow: none;
    margin: 0 10px;
    min-width: 250px;
  }
`;
