import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faArchive } from '@fortawesome/free-solid-svg-icons/faArchive';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import axios from '@lib/axios';
import { ProductVariant } from '@typings/ProductVariant';
import { ConfirmationModal, ModalHandles } from '@components/Modal/ConfirmationModal';
import { Action } from '@components/Table/Row/Action';
import { DropdownOptions } from '@components/Dropdown/DropdownOptions';

interface ActionsProps {
  productVariant: ProductVariant;
  handleSearch(): void;
  replenishment: boolean;
  ignoreDisabled?: boolean;
}

export function Actions(props: ActionsProps) {
  const { productVariant, handleSearch, replenishment, ignoreDisabled = false } = props;
  const navigate = useNavigate();
  const modalRef = useRef<ModalHandles>(null);
  const baseUrl = `/sms/product_variants/${productVariant.id}`;
  const defaultErrorMessage = 'Something went wrong. Please try again later!';

  function handleToggleModal() {
    modalRef?.current?.toggleModal();
  }

  async function handleSync() {
    function onSuccess() {
      handleSearch();
      return 'Product variant successfully synced!';
    }

    toast.promise(axios.post(`${baseUrl}/sync`), {
      loading: 'Product variant will be synced soon!',
      success: onSuccess,
      error: defaultErrorMessage,
    });
  }

  async function handleArchive() {
    function onSuccess() {
      navigate('/sms/product_variants/archived');

      return 'Product variant successfully archived!';
    }

    toast.promise(axios.post(`${baseUrl}/archive`), {
      loading: 'Archiving...',
      success: onSuccess,
      error: defaultErrorMessage,
    });
  }

  async function handleUnarchive() {
    function onSuccess() {
      navigate('/sms/product_variants');

      return 'Product variant successfully unarchived!';
    }

    toast.promise(axios.post(`${baseUrl}/unarchive`), {
      loading: 'Unarchiving...',
      success: onSuccess,
      error: defaultErrorMessage,
    });
  }

  async function handleIgnore() {
    function onSuccess() {
      handleSearch();

      return 'Product variant successfully ignored!';
    }

    toast.promise(axios.post(`${baseUrl}/ignore`), {
      loading: 'Ignoring...',
      success: onSuccess,
      error: defaultErrorMessage,
    });
  }

  async function handleReactivate() {
    function onSuccess() {
      handleSearch();

      return 'Product variant successfully reactivated!';
    }

    toast.promise(axios.post(`${baseUrl}/unignore`), {
      loading: 'Reactivating...',
      success: onSuccess,
      error: defaultErrorMessage,
    });
  }

  if (replenishment) {
    return (
      <Container>
        <Action color="#191d45" icon={faSync} text="Sync" onClick={handleSync} />
      </Container>
    );
  }

  if (productVariant?.ignoredAt) {
    return (
      <Container className="justify-content-center">
        <button className="btn btn-outline-danger large" onClick={handleReactivate} type="button">
          Reactivate Variant
        </button>
      </Container>
    );
  }

  if (productVariant?.archivedAt) {
    return (
      <Container>
        <Action color="#ed534e" icon={faFolderOpen} text="Unarchive" onClick={handleUnarchive} />
      </Container>
    );
  }

  const dropdownOptions = [
    {
      label: 'Edit',
      icon: faPen,
      onClick: () => navigate(`/sms/product_variants/${productVariant.id}/edit`),
    },
    {
      label: 'Sync',
      icon: faSync,
      onClick: () => handleSync(),
    },
    {
      label: 'Archive',
      icon: faArchive,
      onClick: () => handleArchive(),
    },
  ];

  return (
    <Container>
      <Action
        className="ignore-product"
        color="#ed534e"
        icon={faBan}
        text="Ignore"
        onClick={handleToggleModal}
        disabled={ignoreDisabled}
      />
      <DropdownOptions
        id="segment-export-csv"
        className="mx-3"
        options={dropdownOptions}
        drop="down"
      />
      <ConfirmationModal
        ref={modalRef}
        title="Confirmation"
        description={`Are you sure you want to ignore ${productVariant.title}?`}
        onConfirm={handleIgnore}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
`;
