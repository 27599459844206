import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import axios from '@lib/axios';
import { Page } from '@components/Layout/Page';
import { IPage } from '@components/Layout/ViewPage';
import { useFetch } from '@hooks/useFetch';
import { RechargeCustomer } from '@typings/RechargeCustomer';
import { Segment } from '@typings/Segment';

import Form from './components/Form';

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useFetch<Segment>(`/sms/segments/${id}`);
  const { data: customers, loading: loadingCustomers } = useFetch<IPage<RechargeCustomer>>(
    `/sms/segments/${id}/recharge_customers`,
    {
      params: {
        limit: 100, // TODO limit manually created segments?
      },
    }
  );

  async function handleSubmit(data, selectedCustomers, setLoading, setErrors) {
    try {
      setLoading(true);

      const formData = {
        segment: {
          ...data,
          recharge_customers_ids: selectedCustomers.map((customer) => customer.id),
        },
      };

      await axios.put(`/sms/segments/${data.id}.json`, formData);

      navigate('/sms/segments');
    } catch (e) {
      setLoading(false);
      setErrors(e.response.data.errors);
    }
  }

  const { editable } = data || {};
  return (
    <Page
      loading={loading || loadingCustomers}
      headerDescription="Update segment's info and status."
    >
      {editable && (
        <Form segment={data} segmentCustomers={customers?.data} onSubmit={handleSubmit} />
      )}
    </Page>
  );
}

export default Edit;
