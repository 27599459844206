import React from 'react';
import styled from '@emotion/styled';

import productPlaceholderImage from '@assets/images/product-placeholder.png';

import { MetricBase, MetricBaseProps } from './MetricBase';

export function MetricProduct(props: MetricBaseProps) {
  return (
    <MetricBase {...props}>
      {(data) => (
        <Body>
          <Image src={data?.imageUrl || productPlaceholderImage} />
          <Content>
            <Subtitle>{data?.title || 'No Data yet'}</Subtitle>
            <p
              dangerouslySetInnerHTML={{
                __html: data ? data?.description : 'Not enough data to calculate this metric',
              }}
            />
          </Content>
        </Body>
      )}
    </MetricBase>
  );
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 272px;
`;

const Content = styled.div`
  border-left: 3px solid #f6f6f6;
  padding-left: 15px;
  margin-top: 1.5rem;

  > * {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;

    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
`;

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 1;
`;
