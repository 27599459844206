import { cx } from '@emotion/css';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import styled from '@emotion/styled';

import axios from '@lib/axios';
import { RechargeCustomer } from '@typings/RechargeCustomer';

interface ChatFormProps {
  rechargeCustomer?: RechargeCustomer;
  onSubmit?: ({ body: string }) => void;
  onSubmitSuccess?: () => void;
  onSubmitFailure?: (data: any) => void;
  submitUrl?: string;
  token?: string;
  disabled?: boolean;
}

export const ChatForm = ({
  rechargeCustomer,
  onSubmit: receivedOnSubmit,
  onSubmitSuccess,
  onSubmitFailure,
  submitUrl,
  token,
  disabled = false,
}: ChatFormProps) => {
  const bodyRef = useRef<HTMLTextAreaElement>();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const resetField = () => {
    reset({ body: '' });
    bodyRef.current.focus();
  };

  async function onSubmit(data) {
    try {
      resetField();

      if (receivedOnSubmit) {
        return receivedOnSubmit(data);
      }

      await axios.post(submitUrl || `/sms/recharge/customers/${rechargeCustomer.id}/send_sms`, {
        ...data,
        token,
      });

      if (onSubmitSuccess !== undefined) {
        return onSubmitSuccess();
      }
    } catch (e) {
      if (onSubmitFailure !== undefined) {
        return onSubmitFailure(e.response.data.error);
      }

      throw e;
    }
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(onSubmit)(e);
    }
  }

  const { ref, ...bodyRest } = register('body', { required: true });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Textarea
        name="body"
        className={cx('form-control', { 'is-invalid': !!errors?.body })}
        onKeyPress={handleKeyPress}
        placeholder="Type a message"
        autoComplete="off"
        minRows={1}
        ref={(e) => {
          ref(e);
          bodyRef.current = e;
        }}
        {...bodyRest}
      />
      <Send className="btn btn-primary" type="submit" disabled={disabled}>
        Send
      </Send>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  margin-top: 1.5rem;
`;

const Textarea = styled(TextareaAutosize)`
  min-height: 40px;
  border-radius: 6px;
  border: 1px solid #dee2e6;
  margin-right: 20px;
`;

const Send = styled.button`
  border-radius: 5px;
  font-weight: bold;
  width: 100px;
`;
