import styled from '@emotion/styled';
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import DraggableAction from './DraggableAction';
import AddableAction from './AddableAction';
import { useCustomActionsContext } from './CustomActionsContext';

const CustomActions = () => {
  const { visibleActions, handleDragEnd, removable } = useCustomActionsContext();

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="actions-list">
        {(provided) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            <Title>Customize actions:</Title>
            <ActionsContainer>
              {visibleActions.map((action, index) => (
                <DraggableAction
                  key={action.id}
                  index={index}
                  position={index + 1}
                  isRemovable={removable(action)}
                  {...action}
                />
              ))}
              {provided.placeholder}
              <AddableAction />
            </ActionsContainer>
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.colors.light1};
  padding: 15px;
`;

const Title = styled.strong`
  font-family: 'Greycliff';
  font-size: 14px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

export default CustomActions;
