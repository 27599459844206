import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ifStyle } from '@utils/styles';
import { ButtonGroup } from 'react-bootstrap';

import { MetricLineChart } from '@pages/Dashboard/Overview/components/MetricLineChart';
import { OverviewProvider, useOverviewContext } from '@pages/Dashboard/Overview/OverviewContext';
import { DatePicker } from '@components/Input';

import { Card } from './Card';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { Alert } from 'react-bootstrap';

function MessagingInsightsCardComponent() {
  const { period, dateRange, handleChangePeriod, handleChangeDate } = useOverviewContext();
  const shop = useCurrentShop();

  return (
    <Card containerStyle={{ padding: 32 }}>
      <Header>
        <Title>Messaging Insights</Title>
        <Controls>
          <PeriodButtons>
            <PeriodButton selected={period === 'day'} onClick={() => handleChangePeriod('day')}>
              Daily
            </PeriodButton>
            <PeriodButton selected={period === 'week'} onClick={() => handleChangePeriod('week')}>
              Weekly
            </PeriodButton>
            <PeriodButton selected={period === 'month'} onClick={() => handleChangePeriod('month')}>
              Monthly
            </PeriodButton>
          </PeriodButtons>
          <div className="ml-4">
            <DatePicker value={dateRange} onChange={handleChangeDate} />
          </div>
        </Controls>
      </Header>
      <MetricLineChart
        title=""
        url="sms"
        bodyStyle={{ boxShadow: '0px 0px 10px 1px rgba(25, 29, 72, 0.1)', height: '340px' }}
      />

      <ChartIntroBanner>
        <span>
          <strong>💬 Welcome.</strong> This chart shows the quantity of transactional SMS between
          your shop and subscribers. For more insights, filter for SMS under Action Source{' '}
          <a
            target="_blank"
            href={`https://${shop.shopifyDomain.replace(
              '.myshopify.com',
              ''
            )}-sp.admin.rechargeapps.com/merchant/analytics/actions`}
          >
            here
          </a>
          .
        </span>
      </ChartIntroBanner>
    </Card>
  );
}

export const MessagingInsightsCard = () => (
  <OverviewProvider>
    <MessagingInsightsCardComponent />
  </OverviewProvider>
);

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Controls = styled.div`
  display: flex;
`;

const Title = styled.h2`
  color: #191d48;
  font-size: 1.5rem;
  font-weight: bold;
`;

const PeriodButtons = styled(ButtonGroup)`
  box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
  border-radius: 6px;
  overflow: hidden;
`;

const PeriodButton = styled.button<{ selected?: boolean }>`
  background-color: #fff;
  border-color: #fff;
  color: #000;
  border: 0;
  height: 35px;
  padding: 0.375rem 0.75rem;

  &:hover {
    background-color: #f5f5f5;
  }

  ${ifStyle('selected')(css`
    background-color: #000;
    border-color: #000;
    color: #fff;

    &:hover {
      background-color: #111;
    }
  `)}
`;

const ChartIntroBanner = styled(Alert)`
  align-items: center;
  background: rgba(71, 81, 191, 0.2);
  border-radius: 3px;
  box-shadow: 0px 3px 6px rgba(198, 198, 198, 0.05);
  color: #191d48;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 25px 16px;
`;
