import React, { useRef } from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { ViewPage, ViewPageHandles } from '@components/Layout/ViewPage';
import { Action } from '@components/Table/Row/Action';
import { Badge } from '@components/Table/Row/Badge';
import { formatString } from '@utils/date';

const BASE_URL = '/sms/message_custom_keywords';

function View() {
  const ref = useRef<ViewPageHandles>();
  const navigate = useNavigate();

  const tableHeaders = [
    {
      label: 'Keywords',
      width: '30%',
    },
    {
      label: 'Response',
      width: '40%',
    },
    {
      label: 'Status',
    },
    {
      label: 'Created at',
    },
    {
      label: 'Actions',
    },
  ];

  function tableRow(customKeyword) {
    const data = [
      <BadgeContainer>
        {customKeyword.keywords.map((keyword) => (
          <KeywordBadge label={keyword} />
        ))}
      </BadgeContainer>,
      customKeyword.response,
      customKeyword.active ? (
        <Badge textColor="#FFF" color="#3902f0" label="ACTIVE" />
      ) : (
        <Badge textColor="#FFF" color="#ED534E" label="INACTIVE" />
      ),
      formatString(customKeyword.createdAt, 'MM/dd/yyyy'),
      <Action
        icon={faPen}
        color="#078FEE"
        href={`${BASE_URL}/${customKeyword.id}/edit`}
        text="Edit"
      />,
    ];

    return { data };
  }

  function handleHeaderComponentClick() {
    navigate(`${BASE_URL}/new`);
  }

  return (
    <ViewPage
      ref={ref}
      url={BASE_URL}
      header={{
        description: 'Add your own responses to customer messages.',
        component: {
          text: 'Add new Keyword(s)',
          onClick: handleHeaderComponentClick,
        },
      }}
      table={{
        headers: tableHeaders,
        row: tableRow,
      }}
      searchPlaceholder="Search by keyword"
    />
  );
}

const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const KeywordBadge = styled(Badge)`
  color: #fff;
  background: #191d45;
  border-radius: 0.15rem;
  width: fit-content;
`;

export default View;
