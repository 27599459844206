import styled from '@emotion/styled';

export const SaveButton = styled.button`
  border: 0;
  outline: 0;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid black;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  height: 34px;
  font-weight: bold;
  margin-left: auto;

  &:disabled {
    background: #b6b6bd;
  }
`;
