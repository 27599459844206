import React from 'react';

import { OptIn } from '@pages/Settings/Widgets/OptIn';

import { Back } from './components';
import { Pages } from './index';
import { InfoDismiss } from '../InfoDismiss';
import { Header, Content, Title } from '../Card';

interface LandingPageProps {
  setPage: (page: Pages) => void;
}

export function LandingPage(props: LandingPageProps) {
  const { setPage } = props;

  return (
    <>
      <Header>
        <Title>
          <Back onClick={() => setPage('initial')} />
          Landing Page Opt-in
        </Title>
      </Header>
      <Content>
        <InfoDismiss
          containerStyle={{ marginBottom: '3.2rem' }}
          text="Customize your landing page to match your brand’s tone and voice! Click “Save and Generate” down below when you’re ready to publish!"
        />
        <OptIn />
      </Content>
    </>
  );
}
