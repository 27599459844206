import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { useFetch } from '@hooks/useFetch';
import { useCurrentShop } from '@hooks/useCurrentShop';
import infoDarkIcon from '@assets/icons/info-dark.svg';

import { ShopCard } from './ShopCard';
import { InfoCard } from './InfoCard';

interface HomeInterface {
  metrics: {
    optedInCustomersPercentage?: number;
    pendingCustomersPercentage?: number;
    unreachableCustomersPercentage?: number;
  };
}

export function MetricsCards() {
  const shop = useCurrentShop();
  const { data, loading } = useFetch<HomeInterface>('/sms/home');
  const metrics = data?.metrics;

  return (
    <Row className="mt-3">
      <Col>
        <ShopCard />
      </Col>
      <Col>
        <InfoCard
          percentage={metrics?.optedInCustomersPercentage}
          description={`of ${shop.customName} total userbase using SMS.`}
          showHeader
          headerBackground="#FFE456"
          headerTooltipIcon={infoDarkIcon}
          headerTooltip="The total % of your subscribers who have opted-in to manage their subscription via text."
          loading={loading}
        />
      </Col>

      <Col>
        <InfoCard
          percentage={metrics?.pendingCustomersPercentage}
          description="of Subscribers are pending opt-in"
          loading={loading}
        />
      </Col>
      <Col>
        <InfoCard
          percentage={metrics?.unreachableCustomersPercentage}
          description="of Subscribers are Unreachable"
          loading={loading}
        />
      </Col>
    </Row>
  );
}
