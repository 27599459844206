import React from 'react';
import styled from '@emotion/styled';

export interface CardProps {
  children?: React.ReactNode;
  containerStyle?: React.CSSProperties;
}

export function Card(props: CardProps) {
  const { children, containerStyle, ...rest } = props;

  return (
    <Container style={containerStyle} {...rest}>
      {children}
    </Container>
  );
}

const Container = styled.div`
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  position: relative;
  margin-top: 1.5rem;
  background: #fff;
`;

export const Header = styled.div`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #d8dee8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;

export const Content = styled.div`
  padding: 1.5rem;
  background: #fafafa;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const Title = styled.h2`
  color: #191d48;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 5px 0 0 0;
`;

export const Subtitle = styled.h3`
  color: #333333;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 5px 0 0 0;
`;
