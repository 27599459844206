import { RoundedEdge } from './RoundedEdge';
import { LabelEdge } from './LabelEdge';
import { SpacedEdge } from './SpacedEdge';
import { SmoothstepEdge } from './SmoothstepEdge';

export const edgeTypes = {
  roundedEdge: RoundedEdge,
  labelEdge: LabelEdge,
  spacedEdge: SpacedEdge,
  smoothstep: SmoothstepEdge,
};
