import React from 'react';
import styled from '@emotion/styled';

interface CloseIconProps {
  onClose(): void;
}

export const CloseIcon = ({ onClose, ...props }: CloseIconProps) => (
  <Close {...props} onClick={onClose}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5627 0L7.74931 5.81199L1.93733 0L0 1.93733L5.81199 7.74931L0 13.5613L1.93733 15.4986L7.74931 9.68664L13.5627 15.4986L15.5 13.5613L9.68801 7.74931L15.5 1.93733L13.5627 0Z"
        fill="#9A9B9C"
      />
    </svg>
  </Close>
);

const Close = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  opacity: 0.65;
  transition: 150ms;

  &:hover {
    opacity: 1;
  }
`;
