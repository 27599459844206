import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { Modal as BModal, Button as BButton } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { useForm, FormProvider } from 'react-hook-form';

import { Drawer, MainDrawerHandles } from '@pages/Workflows/components/Drawer';
import { Button } from '@pages/Workflows/components/Button';
import Edit from '@assets/icons/edit-dark.svg';
import TimerIcon from '@pages/Workflows/assets/timer.svg';

import { EditInterval } from './EditInterval';
import BaseNode, { NodeProps } from '../BaseNode';

interface DelayNodeProps extends NodeProps {
  frequency: number;
  unit: string;
}

export const DelayNode = ({ data }: { data: DelayNodeProps }) => {
  const { handleChangeWorkflowStep } = useWorkflowsContext();
  const drawerRef = useRef<MainDrawerHandles>(null);

  const formProps = useForm({
    shouldUnregister: false,
    defaultValues: {
      frequency: data?.frequency || 0,
      unit: {
        value: data?.unit || 'day',
        label: data?.unit || 'day',
      },
    },
  });
  const { handleSubmit, reset } = formProps;

  function handleToggleModal() {
    drawerRef.current?.toggleDrawer();
  }

  function handleSave(formData) {
    const values = {
      frequency: formData.frequency,
      unit: formData.unit.value,
    };

    handleChangeWorkflowStep(Number(data.id), values, true);
    toast.success('Delay interval successfully saved!');
    handleToggleModal();
  }

  function handleCancel() {
    reset();
    handleToggleModal();
  }

  return (
    <>
      <BaseNode
        icon={TimerIcon}
        color="#222"
        title="Delay by..."
        bodyComponent={
          <EditButton onClick={handleToggleModal}>
            {data.frequency} {data.unit}(s) <img className="ml-2" alt="" src={Edit} />
          </EditButton>
        }
        {...data}
      />
      <Drawer
        ref={drawerRef}
        headerComponent={<BModal.Title>Edit Delay Interval</BModal.Title>}
        footerComponent={
          <>
            <BButton variant="outline-secondary" onClick={handleCancel}>
              Close
            </BButton>
            <BButton variant="secondary" onClick={handleSubmit(handleSave)}>
              Confirm
            </BButton>
          </>
        }
      >
        <FormProvider {...formProps}>
          <EditInterval />
        </FormProvider>
      </Drawer>
    </>
  );
};

const EditButton = styled(Button)`
  width: 150px;
  justify-content: center;

  svg {
    margin-left: 5px;
  }
`;
