import React from 'react';
import styled from '@emotion/styled';
import { CustomButton } from '@components/Button/CustomButton';
import Cookies from 'universal-cookie';

export default function ContactCardFeatureNotification() {
  const cookies = new Cookies();

  function setDismissCookie() {
    cookies.set('dismissed-contact-card-feature-notification', 'true', {
      path: '/',
      maxAge: 31536000,
    });
  }

  return (
    <Container>
      <ContentRow>
        <p className="title">Send a contact card</p>
        <p>You can now welcome your customers with a branded contact card</p>
        <p>Let customers easily save your number, email, website, or logo!</p>
        <p>
          <strong>Enable in Settings</strong>
        </p>
      </ContentRow>
      <ShowMeRow>
        <ShowMeButton href="/sms/settings?open=contact-card-modal" onClick={setDismissCookie}>
          Take me there
        </ShowMeButton>
      </ShowMeRow>
    </Container>
  );
}

const Container = styled.div`
  color: #191d48;
  .title {
    font-weight: 600;
  }
`;

const ShowMeButton = styled(CustomButton)`
  border-radius: 6px;
  border: none;
  font-family: Greycliff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  padding: 13px 20px 13px 20px;
  background: #191d48;
  width: 100%;

  &:disabled {
    background: #d7d7d7;
    color: #a7a7a7 !important;
  }
`;

const ContentRow = styled.div`
  padding: 24px 16px 8px 16px;
`;

const ShowMeRow = styled.div`
  box-shadow: 0px 0px 10px rgba(25, 29, 72, 0.1);
  padding: 16px;
`;
