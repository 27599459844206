import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Feature } from '@typings/Feature';

import axios from '@lib/axios';
import PreviewMessageMapper from '../pages/Settings/Features/previewMessageMapper';

interface FeaturesContextInterface {
  initialChat: string;
  selectedFeature: Feature;
  selectedPreview: string;
  currentState(featureKey: string): Feature;
  handleFeatureSelected(feature: Feature): void;
  handleFeatureChanged(featureKey: string): Promise<void>;
  handlePreviewChange(featureKey: string): void;
  shouldSimulatorReset: boolean;
  enableRecommended(): void;
}

const FeaturesContext = createContext<FeaturesContextInterface>(null);

interface FeaturesProviderProps {
  allFeatures: Feature[];
}

export function FeaturesProvider(props: PropsWithChildren<FeaturesProviderProps>) {
  const { allFeatures, children } = props;
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedPreview, setSelectedPreview] = useState(null);
  const [shouldSimulatorReset, setShouldSimulatorReset] = useState(false);
  const [initialChat, setInitialChat] = useState('info');
  const [features, setFeatures] = useState<Feature[]>(allFeatures);

  const enableRecommended = async () => {
    const { data: updatedFeatures } = await axios.put(`/sms/features/enable_recommended`);

    setFeatures((oldFeatures) =>
      oldFeatures.map((oldFeature) => {
        const updatedFeature = updatedFeatures.find(
          (updatedFeature) => updatedFeature.key === oldFeature.key
        );

        return updatedFeature || oldFeature;
      })
    );

    toast.success('Recommended features enabled!');
  };

  const handleFeatureSelected = (feature) => {
    const sameFeature = selectedFeature?.key === feature?.key;
    const newFeature = sameFeature ? null : feature;

    setSelectedFeature(newFeature);
  };

  const handlePreviewChange = (featureKey) => {
    const samePreview = selectedPreview === featureKey;
    if (samePreview) {
      setShouldSimulatorReset(true);
    } else {
      setSelectedPreview(featureKey);
      setInitialChat(PreviewMessageMapper[featureKey] || 'modify order');
    }

    setTimeout(() => {
      setShouldSimulatorReset(false);
    }, 1000);
  };

  const currentState = (featureKey: string) =>
    features && features.find((existingFeature) => existingFeature.key === featureKey);

  const handleFeatureChanged = async (featureKey: string) => {
    const feature = currentState(featureKey);
    const params = { feature: { enabled: !feature.enabled } };
    await axios.put(`/sms/features/${feature.key}`, params);

    setFeatures((oldFeatures) =>
      oldFeatures.map((oldFeature) => {
        if (oldFeature.key === feature.key) {
          return { ...oldFeature, enabled: !feature.enabled };
        }
        return oldFeature;
      })
    );
  };

  const value = {
    initialChat,
    selectedFeature,
    selectedPreview,
    currentState,
    handleFeatureSelected,
    handleFeatureChanged,
    handlePreviewChange,
    shouldSimulatorReset,
    enableRecommended,
  };

  return <FeaturesContext.Provider value={value}>{children}</FeaturesContext.Provider>;
}

export const useFeaturesContext = () => {
  const context = React.useContext(FeaturesContext);

  if (context === undefined) {
    throw new Error('useFeaturesContext must be used within a FeaturesProvider');
  }
  return context;
};
