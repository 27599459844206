import React, { useRef } from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';

import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import DropdownOptions from '@pages/Workflows/components/DropdownOptions';
import { DeleteWorkflowModal, ModalHandles } from '@pages/Workflows/components/DeleteWorkflowModal';
import { ProductVariant } from '@typings/ProductVariant';
import { ProductCard } from '@pages/Workflows/components/ProductCard';

interface WorkflowProps {
  id: number;
  templateType: string;
  title?: string;
  variants?: ProductVariant[];
}

export function Workflow({ id, templateType, title, variants }: WorkflowProps) {
  const { currentWorkflow, handleSelectWorkflow } = useWorkflowsContext();
  const deleteModalRef = useRef<ModalHandles>(null);

  const isSelected = Number(currentWorkflow?.id) === id;

  const dropdownOptions = [
    {
      label: 'Delete Workflow',
      onClick: () => deleteModalRef?.current?.toggleModal(),
      icon: faTrashAlt,
    },
  ];

  return (
    <>
      <ProductCard
        title={title}
        subtitle={variants?.length > 1 ? `applies to ${variants?.length} products` : ''}
        subtitleStyle={{ color: '#8a8a8a', fontSize: 10, textTransform: 'uppercase' }}
        variants={variants}
        showQuantity={false}
        selected={isSelected}
        handleClick={() => handleSelectWorkflow(id)}
        rightComponent={<DropdownOptions options={dropdownOptions} />}
      />
      <DeleteWorkflowModal ref={deleteModalRef} />
    </>
  );
}

export default Workflow;
