import styled from '@emotion/styled';
import { CustomButton } from '@components/Button/CustomButton';

export const ContinueButton = styled(CustomButton)<{ variant?: string }>`
  width: 100%;
  height: 40px;
  border-radius: 6px;
  border: 0px;

  ${(props) =>
    props.variant === 'green' &&
    `
    background-color: #17CC9D;

    &:hover {
      background-color: #0db88b;
      border: 0px;
    }
  `}
`;

export const SecondaryButton = styled.button`
  background: #1a1d45;
  border-radius: 6px;
  border: 1px solid #191d48;
  color: #191d48;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  height: 40px;
  width: 100%;
  padding: 0px;

  &:hover {
    background: transparent;
    color: #1a1d45;
    transition: all 0.2s ease-in-out;
  }

  &.outline {
    background: transparent;
    color: #1a1d45;

    &:hover {
      background: #1a1d45;
      color: #ffffff;
    }
  }
`;

export const PrimaryButton = styled(CustomButton)`
  width: 100%;
  height: 40px;
  border-radius: 6px;
  padding: 0px;
`;

export const ItemIcon = styled.img`
  margin-right: 10px;
  margin-bottom: 2px;
`;
