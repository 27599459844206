import React, { useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Tags from '@yaireo/tagify/dist/react.tagify';
import '@yaireo/tagify/dist/tagify.css';

import './custom_variables.scss';

const CustomVariablesInput = ({
  currentTemplate,
  variables,
  availableVariables,
  placeholder,
  changeHandler,
  variableAddedHandler,
}) => {
  const settings = {
    mode: 'mix',
    mixTagsInterpolator: ['{{', '}}'],
    pattern: /@/,
    whitelist: variables,
    dropdown: {
      enabled: 1,
      position: 'text',
      highlightFirst: true,
    },
    editTags: false,
    enforceWhitelist: true,
  };

  return (
    <>
      <Tags
        settings={settings}
        value={currentTemplate}
        onChange={changeHandler}
        placeholder={placeholder}
        className="mb-2 mt-2"
      />

      {availableVariables.length ? (
        <small className="form-text text-muted">
          Type @ to have the variables auto-completion or click on the variables below:
        </small>
      ) : null}

      {availableVariables.map((variable, index) => (
        <React.Fragment key={index}>
          <OverlayTrigger overlay={<Tooltip>Add to template</Tooltip>}>
            <Badge
              variant="light"
              className="custom-variables__btn-variable"
              key={index}
              onClick={() => {
                variableAddedHandler(variable);
              }}
            >
              {variable}
            </Badge>
          </OverlayTrigger>{' '}
        </React.Fragment>
      ))}
    </>
  );
};

export default CustomVariablesInput;
