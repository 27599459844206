import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import { CustomButton as Button } from '@components/Button/CustomButton';
import { CloseIcon } from '@components/Modal/CloseIcon';
import Modal from '@components/Modal/BaseModal';
import { Form } from 'react-bootstrap';
import { useState } from 'react';

export * from '@components/Modal/BaseModal';

interface ConfirmationModalProps {
  title: string;
  description: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  checkBoxConfirmLabel?: string;
  onConfirm(): void;
}

function ConfirmationModalComponent(props: ConfirmationModalProps, ref) {
  const {
    title,
    description,
    confirmButtonLabel = "Yes, I'm sure",
    cancelButtonLabel = 'Nevermind',
    checkBoxConfirmLabel = null,
    onConfirm,
  } = props;

  const [isConfirmButtonDisabled, setConfirmButtonDisabled] = useState(
    checkBoxConfirmLabel ? true : false
  );

  function toggleModal(e?: any) {
    e?.stopPropagation();

    ref?.current?.toggleModal();
  }

  function handleConfirm(e) {
    e.stopPropagation();

    onConfirm();
    toggleModal();
  }

  function onConfirmCheckboxChange(e) {
    setConfirmButtonDisabled(!e.target.checked);
  }

  return (
    <Modal ref={ref}>
      <Container>
        <Header>
          <Title>{title}</Title>
          <CloseIcon onClose={toggleModal} />
        </Header>

        <Description>
          {description}
          {checkBoxConfirmLabel && (
            <Form.Check
              label={checkBoxConfirmLabel}
              type="checkbox"
              onChange={(e) => onConfirmCheckboxChange(e)}
              style={{ marginTop: '15px' }}
            />
          )}
        </Description>

        <ButtonsContainer>
          <Button style={{ marginRight: '1rem' }} variant="secondary" onClick={toggleModal}>
            {cancelButtonLabel}
          </Button>

          <Button
            variant="outline-danger"
            onClick={handleConfirm}
            disabled={isConfirmButtonDisabled}
          >
            {confirmButtonLabel}
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
}

export const ConfirmationModal = forwardRef(ConfirmationModalComponent);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #191d48;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #191d48;
  margin-bottom: 30px;
  text-align: left;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
`;
