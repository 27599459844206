import React from 'react';
import styled from '@emotion/styled';

import { Badge } from '@components/Table/Row/Badge';
import { RechargeCustomer } from '@typings/RechargeCustomer';

interface CustomerBadgeProps {
  customer: RechargeCustomer;
}

export function CustomerBadge(props: CustomerBadgeProps) {
  const { customer } = props;

  const { status } = customer.optInStatus.transactional;
  const statuses = {
    0: 'Pending',
    1: 'Opt-in',
    2: 'Opt-out',
  };

  const colorsByStatus = {
    0: '#FB8309',
    1: '#17CC9D',
    2: '#EC3D10',
    unreachable: '#B6B6BD',
  };

  return (
    <Container>
      <Badge
        color={colorsByStatus[status]}
        textColor="#FFF"
        label={statuses[status]}
        upperCase={false}
      />

      {!customer.reachableViaSms && (
        <Badge
          color={colorsByStatus.unreachable}
          textColor="#FFF"
          label="Unreachable"
          upperCase={false}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
