import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, Col, Form as BForm, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import axios from '@lib/axios';
import { CancelLink } from '@components/Router/CancelLink';

import TokenInput from './components/TokenInput';

interface FormProps {
  messageCustomKeyword: any;
}

const MESSAGE_CUSTOM_KEYWORDS_PATH = '/sms/message_custom_keywords';

export function Form({ messageCustomKeyword }: FormProps) {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState(messageCustomKeyword?.keywords);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const formData = {
        message_custom_keyword: {
          ...data,
          keywords,
        },
      };

      if (messageCustomKeyword?.id) {
        await axios.put(`/sms/message_custom_keywords/${messageCustomKeyword.id}`, formData);
      } else {
        await axios.post('/sms/message_custom_keywords/', formData);
      }

      navigate(MESSAGE_CUSTOM_KEYWORDS_PATH);
    } catch (e) {
      setLoading(false);
      setErrors(e.response.data.errors);
    }
  };

  return (
    <BForm onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Card.Body>
          <p>
            The custom keyword(s) are going to be used for matching customer's messages into
            automatic responses. You can add as many keywords as you want, for each new keyword you
            should <b>press Enter</b> on the Keywords field below. Please don't worry about lower
            and upper case, this will be automatically handled by us. For example, you can add only{' '}
            <b>hello</b> and will it be enough for matching both <b>Hello</b> and <b>HELLO.</b>
          </p>
          <BForm.Group controlId="message_custom_keyword_keywords">
            <BForm.Label>Keywords</BForm.Label>
            <TokenInput tokens={keywords} onChange={setKeywords} />
          </BForm.Group>
          <BForm.Group controlId="message_custom_keyword_response">
            <BForm.Label>Response</BForm.Label>
            <BForm.Control
              name="response"
              as="textarea"
              rows={3}
              defaultValue={messageCustomKeyword?.response}
              {...register('response', { required: true })}
            />
          </BForm.Group>
          <BForm.Check
            name="active"
            id="message_custom_keyword_active"
            type="switch"
            label="Activate response to custom keyword(s)"
            defaultChecked={messageCustomKeyword?.active}
            {...register('active')}
          />
          {errors && <p className="text-danger mt-3">{errors}</p>}
        </Card.Body>
        <Card.Footer>
          <Row className="justify-content-end">
            <Col xs="auto">
              <CancelLink to={MESSAGE_CUSTOM_KEYWORDS_PATH} />
            </Col>
            <Col xs="auto">
              <Button type="submit" variant="primary" disabled={loading}>
                {loading ? 'Loading..' : 'Save'}
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </BForm>
  );
}
