import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import styled from '@emotion/styled';

import axios from '@lib/axios';
import { Option } from '@components/Input/Select';
import { Paginator } from '@components/Paginator';
import { PaginationInfo } from '@typings/PaginationInfo';
import { ProductVariant } from '@typings/ProductVariant';

import { ShopifyProduct } from '@typings/ShopifyProduct';
import { IgnoredProduct, SingleVariant, MultipleVariants } from './index';
import { useAppDispatch } from '@redux/hooks';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';

interface IPage {
  data: {
    shopifyProduct: ShopifyProduct;
    productVariants: (ProductVariant & {
      productSwap?: boolean;
      swapGroup?: boolean;
      subscription?: boolean;
      oneTimeItem?: boolean;
      productPresent?: boolean;
      multipleOption?: boolean;
    })[];
  }[];
  paginationInfo?: PaginationInfo;
}

export function ViewContainer(props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<IPage>();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<Option>();
  const [sort, setSort] = useState<Option>();
  const { children, baseUrl, replenishment, highlightIgnore = false } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    handleSearch();
  }, [search, filter, sort]);

  async function handleSearch(pageNumber = page?.paginationInfo?.current) {
    try {
      setLoading(true);

      const { data } = await axios.get(baseUrl, {
        params: {
          page: pageNumber || 1,
          limit: 10,
          search,
          feature: filter?.value,
          sort: sort?.value,
        },
      });

      dispatch(onboardingSlice.actions.setNumProducts(data.data.length));

      setPage(data);
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  function renderProducts() {
    return (
      <>
        {page?.data?.length > 0 ? (
          page.data.map(({ shopifyProduct, productVariants }) => {
            if (shopifyProduct.ignored && !replenishment)
              return (
                <IgnoredProduct
                  key={shopifyProduct.id}
                  shopifyProduct={shopifyProduct}
                  productVariants={productVariants}
                  handleSearch={handleSearch}
                />
              );

            return productVariants.length === 1 ? (
              <SingleVariant
                key={shopifyProduct.id}
                shopifyProduct={shopifyProduct}
                productVariants={productVariants}
                handleSearch={handleSearch}
                replenishment={replenishment}
                ignoreDisabled={highlightIgnore}
              />
            ) : (
              <MultipleVariants
                key={shopifyProduct.id}
                shopifyProduct={shopifyProduct}
                productVariants={productVariants}
                handleSearch={handleSearch}
                replenishment={replenishment}
                ignoreDisabled={highlightIgnore}
              />
            );
          })
        ) : (
          <p className="mt-3">No product variants were found.</p>
        )}

        <PaginatorContainer>
          <Paginator
            paginationInfo={page?.paginationInfo}
            onPageChange={(pageNumber) => handleSearch(pageNumber)}
          />
        </PaginatorContainer>
      </>
    );
  }

  return (
    <>
      {children({
        page,
        loading,
        setSearch,
        filter,
        setFilter,
        sort,
        setSort,
        renderProducts,
      })}
    </>
  );
}

const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
