import React from 'react';
import Dropzone from 'react-dropzone';
import styled from '@emotion/styled';

import { Loading } from '@components/Loading';

import { DragMessages, SingleFile } from './components';
import { IFile } from './ImageUploadContainer';

interface ImageUploadProps {
  loading: boolean;
  handleUpload(files: any): void;
  uploadedFile: IFile;
  setUploadedFile(value: IFile): void;
}

export function ImageUpload(props: ImageUploadProps) {
  const { loading, handleUpload, uploadedFile, setUploadedFile } = props;

  function Content({ loading, isDragActive, isDragReject }) {
    if (loading) return <Loading />;

    return uploadedFile?.url ? (
      <SingleFile fileUrl={uploadedFile?.url} setUploadedFile={setUploadedFile} />
    ) : (
      <DragMessages isDragActive={isDragActive} isDragReject={isDragReject} />
    );
  }

  return (
    <Dropzone accept="image/jpeg, image/png" multiple={false} onDropAccepted={handleUpload}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />

          <Content loading={loading} isDragActive={isDragActive} isDragReject={isDragReject} />
        </DropContainer>
      )}
    </Dropzone>
  );
}

const DropContainer = styled.div`
  cursor: pointer;
  transition: height 0.2s ease;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%239A9B9CFF' stroke-width='2' stroke-dasharray='20%2c 15' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 6px;
`;
