import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';

export function InfoModal() {
  return (
    <Container>
      <MessageFailure>
        <FontAwesomeIcon className="mr-1" icon={faInfoCircle} color="red" size="lg" />
        Message failure
      </MessageFailure>
      <Description>Description</Description>
      <Content>
        Not often, but occasionally our service sends an SMS to the end-user (your customer) and is
        filtered by a cell-carrier. Carriers are companies like Verizon, T-Mobile, etc and they have
        final say as to whether or not these messages are successfully delivered using their
        network.
        <br /> <br />
        Reasons why this message could’ve been filtered:
        <br />
        - Carrier marked message as spam <br />
        - Carrier network failed <br />
        - Carrier blocked traffic for an unknown reason <br />
        <br />
        If you have any additional questions, please visit our FAQ article here:
        <br />
        <br />
        – Messaging Team ⚡️
      </Content>
    </Container>
  );
}

const Container = styled.div`
  margin: 0;
`;

const MessageFailure = styled.div`
  width: 100%;
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
  padding: 1rem;
`;

const Description = styled.h2`
  font-weight: bold;
  margin: 1rem;
  font-size: 16px;
`;

const Content = styled.p`
  background: #f2f2f2;
  margin: 0 1rem;
  margin-bottom: 2rem;
  padding: 0.35rem;
  font-weight: 500;
  border-radius: 3px;
`;
