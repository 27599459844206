import React, { createContext, PropsWithChildren } from 'react';

interface TabsContextProps {
  setTab(tab: string): void;
}

const TabsContext = createContext<TabsContextProps>(null);

interface TabsContextProviderProps {
  setTab(tab: string): void;
}

export const TabsContextProvider = (props: PropsWithChildren<TabsContextProviderProps>) => {
  const { children, ...rest } = props;

  return <TabsContext.Provider value={rest}>{children}</TabsContext.Provider>;
};

export const useTabsContext = () => {
  const context = React.useContext(TabsContext);

  if (context === undefined) {
    throw new Error('useTabsContext must be used within a TabsContext');
  }
  return context;
};
