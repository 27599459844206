import React, { useEffect } from 'react';
import Home from '@pages/Home';
import { useNavigate } from 'react-router-dom';
import { FeatureSelectView } from '@components/Onboarding/v2/View/Feature/FeatureSelectView';
import { FeatureSimulatorView } from '@components/Onboarding/v2/View/Feature/FeatureSimulatorView';
import { FeatureRecommendSetupView } from '@components/Onboarding/v2/View/Feature/FeatureRecommendSetupView';
import { FeaturePlayAroundView } from '@components/Onboarding/v2/View/Feature/FeaturePlayAroundView';
import { useAppSelector } from '@redux/hooks';
import {
  FEATURE_1_SELECT_STEP,
  FEATURE_2_SIMULATOR_STEP,
  FEATURE_3_RECOMMEND_STEP,
  FEATURE_4_PLAY_AROUND_STEP,
} from '@components/Onboarding/v2/onboarding';

const FEATURE_VIEW_MAP = {
  [FEATURE_1_SELECT_STEP]: <FeatureSelectView />,
  [FEATURE_2_SIMULATOR_STEP]: <FeatureSimulatorView />,
  [FEATURE_3_RECOMMEND_STEP]: <FeatureRecommendSetupView />,
  [FEATURE_4_PLAY_AROUND_STEP]: <FeaturePlayAroundView />,
};

export const FeatureOnboardingView = () => {
  const onboardingState = useAppSelector((state) => state.onboarding);

  const navigate = useNavigate();

  useEffect(() => {
    if (!onboardingState.isDisplay || !onboardingState.isModalOpen) {
      navigate('/sms/settings');
    }
  }, [onboardingState]);

  return FEATURE_VIEW_MAP.hasOwnProperty(onboardingState.data.step) ? (
    FEATURE_VIEW_MAP[onboardingState.data.step]
  ) : (
    <FeatureSelectView />
  );
};
