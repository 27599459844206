import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Alert } from 'react-bootstrap';

interface InfoDismissProps {
  text: string | React.ReactNode;
  containerStyle?: React.CSSProperties;
}

export function InfoDismiss(props: InfoDismissProps) {
  const { text, containerStyle } = props;
  const [show, setShow] = useState(true);

  if (!show) return null;

  return (
    <Container style={containerStyle}>
      <span>{text}</span>
      <DismissButton onClick={() => setShow(false)}>Dismiss</DismissButton>
    </Container>
  );
}

const Container = styled(Alert)`
  align-items: center;
  background: rgba(71, 81, 191, 0.2);
  border-radius: 3px;
  box-shadow: 0px 3px 6px rgba(198, 198, 198, 0.05);
  color: #191d48;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 25px 16px;
`;

const DismissButton = styled.button`
  background: transparent;
  border: 0;
  color: #191d48;
  font-weight: bold;
  margin: 0;
`;
