import React from 'react';
import styled from '@emotion/styled';
import { useParams, Link } from 'react-router-dom';

import PenIcon from '@assets/icons/pen.svg?react';
import { Page } from '@components/Layout/Page';
import { Card } from '@components/Layout/Card';
import { ChatAsCustomer } from '@components/Chat/ChatAsCustomer';
import { RechargeCustomer } from '@typings/RechargeCustomer';
import { useFetch } from '@hooks/useFetch';

import { useInitialState } from '@hooks/useInitialState';
import { Events } from './Events';
import { Info } from './Info';
import { DevArea } from '../components/DevArea';

interface Subscription {
  variantId: string;
  variantTitle: string;
  variantUrl: string;
  variantImageUrl?: string;
  shopifyVariantId: string;
  shopifyProductId: string;
}

interface IRechargeCustomer extends RechargeCustomer {
  tempPhone: string;
  formattedAddress: string;
  rechargeUrl: string;
  externalId: string;
  optOutAt: string;
  nextChargeDate: string;
  phoneError: string;
  activeSubscriptions: Subscription[];
  inactiveSubscriptions: Subscription[];
}

export interface Data {
  rechargeCustomer: IRechargeCustomer;
  mainRechargeCustomer?: {
    email: string;
    emailUrl: string;
  };
  subRechargeCustomers: {
    id: string;
    email: string;
    emailUrl: string;
  }[];
  humanSupportActive?: boolean;
}

function Show() {
  const { id } = useParams();
  const { useLocalSmsAdapter } = useInitialState();
  const { data, loading } = useFetch<Data>(`/sms/recharge/customers/${id}`);

  return (
    <Page
      loading={loading}
      headerDescription="Analyze recharge customers that are interacting with your shop"
      headerComponent={
        <Link to={`/sms/recharge/customers/${id}/edit`} className="btn btn-outline-info">
          <PenIcon style={{ fill: 'currentColor' }} className="mr-2" />
          Edit Customer
        </Link>
      }
    >
      <CustomCard>
        <Info
          rechargeCustomer={data?.rechargeCustomer}
          mainRechargeCustomer={data?.mainRechargeCustomer}
          subRechargeCustomers={data?.subRechargeCustomers}
        />
      </CustomCard>
      <CustomCard className="my-4">
        <Events customerId={id} />
      </CustomCard>
      {useLocalSmsAdapter && <DevArea rechargeCustomer={data?.rechargeCustomer} />}
    </Page>
  );
}

const CustomCard = styled(Card)`
  overflow: initial;
  padding: 1rem;
`;

export default Show;
