import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ifStyle } from '@utils/styles';

export function Button(props) {
  const { children } = props;

  return <Touchable {...props}>{children}</Touchable>;
}

const Touchable = styled.button<{ inverse?: boolean }>`
  background: ${(props) => props.theme.colors.secondary};
  border: 1px solid ${(props) => props.theme.colors.secondary};
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #fffbf6;
  padding: 8px 16px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background: transparent;
    color: ${(props) => props.theme.colors.secondary};
  }

  ${ifStyle('inverse')(css`
    background: transparent;
    color: #191d48;

    &:hover {
      background: #191d48;
      color: #ffffff;
    }
  `)}
`;
