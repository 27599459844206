import React from 'react';

import styled from '@emotion/styled';

import InsightsIcon from '@assets/icons/insights.svg';

import { useLayoutContext } from '../LayoutContext';

export function Insights() {
  const { rightSidebar, setRightSidebar } = useLayoutContext();

  async function toggleInsights() {
    if (rightSidebar?.type === 'insights' && rightSidebar?.open) {
      setRightSidebar({ type: 'insights', open: false });
    } else {
      setRightSidebar({ type: 'insights', open: true, width: 350 });
    }
  }

  return (
    <Button type="button" onClick={toggleInsights}>
      <img alt="icon" src={InsightsIcon} />
    </Button>
  );
}

const Button = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
`;
