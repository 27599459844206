import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import { ButtonGroup } from '@components/Button/ButtonGroup';
import { Separator } from '@components/Layout/Separator';
import { WidgetPreview } from '.';

import { Card, Subtitle } from '../../Card';
import { WidgetConfigType } from '../types';

interface Props {
  pendingWidgetConfig: WidgetConfigType;
  customizationOpen: boolean;
  setShowManualInstall: (boolean) => void;
}

export function EasyInstallCard(props: Props) {
  const { customizationOpen, setShowManualInstall, pendingWidgetConfig } = props;

  function handleManualInstallClick() {
    setShowManualInstall(true);
  }

  return (
    <CustomCard>
      <Subtitle>
        <span>📝</span> Easy Install
      </Subtitle>
      <Separator />
      <SecondaryTitle>Info</SecondaryTitle>
      <p>
        This opt-in enables new subscribers to enroll into RechargeSMS before their purchase. This
        toggle will only display if there is a subscription product in the cart.
      </p>
      <SecondaryTitle className="mt-4">Widget Preview</SecondaryTitle>
      <div className="mt-3">
        <WidgetPreview
          backgroundColor="#f5f5f5"
          variant={pendingWidgetConfig?.variant}
          disableBranding={pendingWidgetConfig?.branding_disabled}
        />
      </div>
      <div style={{ flexGrow: 1 }} />
      {customizationOpen ? (
        <>
          <Separator />
          <ButtonGroup>
            <LinkButton variant="link" onClick={handleManualInstallClick}>
              Manually install instead?
            </LinkButton>
          </ButtonGroup>
        </>
      ) : (
        ''
      )}
    </CustomCard>
  );
}

const CustomCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 582px;
  padding: 20px;
  h3 span {
    margin-right: 8px;
  }
`;

const LinkButton = styled(Button)`
  color: #a7a7a7;
  font-weight: 700;
  text-decoration: none;
`;

const SecondaryTitle = styled.h5`
  font-weight: 700;
`;
