import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';

import VariablesInput, { VariableInputHandle } from '@components/Input/VariablesInput';

import CloseSaveBody from '../shared/CloseSaveBody';

// TODO: get the variables from the backend
const defaultVariables = [
  // customer
  'customer_first_name',
  'customer_last_name',
  'customer_email',
  'customer_phone',
  // product variant
  'product_title',
  'product_price',
  // shop
  'shop_name',
  'shop_domain',
  'shop_email',
  'shop_phone',
];

interface EditMessageBodyProps {
  variables?: string[];
  handleSave: () => void;
}

export function EditMessageBody({ variables = [], handleSave }: EditMessageBodyProps) {
  const { register, setValue, getValues } = useFormContext();
  let prefix = '';
  const variableInputRef = useRef<VariableInputHandle>();
  const availableVariables = [...new Set([...defaultVariables, ...variables])];
  let bodyValue = getValues(`${prefix}.body`);
  if (!bodyValue) {
    prefix = 'email';
    bodyValue = getValues(`${prefix}.body`);
  }

  return (
    <CloseSaveBody handleSave={handleSave}>
      {!prefix && (
        <FormRow>
          <Label>Title</Label>
          <FormControl {...register(`${prefix}.title`, { required: true })} />
        </FormRow>
      )}
      {prefix === 'email' && (
        <FormRow>
          <Label>Subject</Label>
          <FormControl
            {...register(`${prefix}.subject`, { required: true })}
            placeholder="Enter email subject title"
          />
        </FormRow>
      )}
      <FormRow>
        <Label style={{ marginBottom: 0 }}>Body</Label>
        <VariablesInputContainer>
          <VariablesInput
            ref={variableInputRef}
            value={bodyValue}
            variables={availableVariables}
            onChange={(body) => setValue(`${prefix}.body`, body)}
          />
        </VariablesInputContainer>
      </FormRow>
      <FormRow>
        <Label>Available Variables</Label>
        <VariablesContainer>
          {availableVariables?.map((variable) => (
            <VariableBadge
              key={variable}
              onClick={() => variableInputRef.current?.addVariable(variable)}
              className="btn btn-sm btn-secondary btn-variable"
            >
              {variable}
            </VariableBadge>
          ))}
        </VariablesContainer>
      </FormRow>
    </CloseSaveBody>
  );
}

const Label = styled(Form.Label)`
  color: #191d48;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 0.25rem;
`;

const FormRow = styled(Form.Row)`
  flex-direction: column;
  padding: 0.75rem 0;
  margin: 0;

  tags {
    background: ${(props) => props.theme.colors.light0};
  }
`;

const FormControl = styled(Form.Control)`
  border-radius: 0;
  border: 1px solid #b6b6bd;
  width: 100%;
`;

const VariablesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const VariableBadge = styled.div`
  text-transform: none;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
`;

const VariablesInputContainer = styled.div`
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);

  padding: 8px 16px;

  .tagify--mix {
    border: none !important;
  }
`;
