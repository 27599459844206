import React, { useState, useEffect } from 'react';
import { Col, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { Edit3 } from 'react-feather';

import EmailForm from '@components/Input/EmailForm/EmailForm';
import { updateCustomize, getFeatures as getSmsFeatures } from '@api/sms';
import { validateEmail } from '@components/Input/EmailForm/emailValidation';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { useFlag } from '@hooks/useFlag';
import { useFeature } from '@hooks/useFeature';

import { HoursRange } from './HoursRange';

import '@pages/Settings/Features/shared/FeatureSwitch/featureSwitch.scss';
import '@components/Input/EmailForm/storeEmail.scss';

export function StoreEmail({
  currentEmail,
  replenishment,
  formRegister,
  safeHours,
  setSafeHours,
  tagOptIns,
  setTagOptIns,
  isLoading,
}) {
  const [checked, setChecked] = useState(currentEmail !== null);
  const [showForm, setShowForm] = useState(false);
  const shop = useCurrentShop();
  const { rechargeApiKey } = shop;

  const [formErrors, setFormErrors] = useState({
    confirmation: false,
    format: false,
  });

  const [storeEmails, setStoreEmails] = useState({
    original: currentEmail,
    confirmation: currentEmail,
  });
  const [features, setFeatures] = useState([]);

  const smsTagOptedInShopifyCustomersFlag = useFlag('sms_tag_opted_in_shopify_customers');
  const smsTagOptedInShopifyCustomersFlagEnabled =
    !smsTagOptedInShopifyCustomersFlag.loading && smsTagOptedInShopifyCustomersFlag.enabled;
  const allowEdit = storeEmails.original !== null && checked && !showForm;
  const replenishmentSettingsShipmentTrackingNotificationsFlag = useFlag(
    'replenishment_settings_shipment_tracking_notifications'
  );
  const replenishmentShipmentTrackingFeature = features.find(
    (feature) => feature.key === 'replenishment_shipment_tracking'
  );
  const replenishmentShipmentTracking = useFeature(replenishmentShipmentTrackingFeature);

  useEffect(() => {
    async function getFeatures() {
      const { data } = await getSmsFeatures();

      setFeatures(data?.allFeatures);
    }

    getFeatures();
  }, []);

  const handleToggle = () => {
    const checkedStatus = !checked;

    if (!checkedStatus) {
      updateCustomize({ shop: { custom_email: null } }).then(() => setShowForm(false));
    }

    setChecked(checkedStatus);
    setShowForm(checkedStatus);
  };

  const handleEmailChanged = (email, emailType, comparisonEmailType) => {
    const emails = { ...storeEmails };
    emails[emailType] = email;

    setStoreEmails(emails);
    const errors = validateEmail(email, storeEmails[comparisonEmailType]);
    setFormErrors(errors);
  };

  const handleEditClick = () => {
    setShowForm(true);
  };

  const handleEmailUpdated = () => setShowForm(false);
  const renderShipmentTrackingFeatureToggle = (
    { loading, enabled },
    { checked, toggleFeature }
  ) => {
    if (loading || !enabled) return null;

    return (
      <>
        <h4 className="mt-4">Shipment Tracking</h4>
        <hr />
        <div className="feature__switch mb-3">
          <Col xs={4} className="px-0">
            <Form.Switch
              id="shipment-tracking-toggle"
              label="Send Shipment Tracking Messages"
              name="shipment-tracking-toggle"
              checked={checked}
              onChange={toggleFeature}
              className="mb-3 settings_shipment-tracking-switch"
            />
          </Col>
        </div>
      </>
    );
  };
  const title = replenishment ? 'Quiet Hours' : 'Functionality';

  return (
    <div data-testid="store-email-component" className="px-4 pt-3">
      <h4>Store Email</h4>
      <hr />

      <div className="feature__switch mb-3">
        <Col xs={4} className="px-0">
          <Form.Switch
            id="store-email-toggle"
            label="Set Preferred Store Email"
            name="store-email-toggle"
            checked={checked}
            onChange={handleToggle}
            className="mb-3 settings_email-form-switch"
          />

          {'  '}

          {allowEdit && (
            <Edit3 size={16} className="settings__edit-icon" onClick={handleEditClick} />
          )}

          {showForm && (
            <EmailForm
              emailUpdatedHandler={handleEmailUpdated}
              storeEmails={storeEmails}
              formErrors={formErrors}
              emailChangedHandler={handleEmailChanged}
            />
          )}

          {allowEdit && (
            <div className="settings__email-form-container">
              <p className="text-muted">
                {storeEmails.original} successfully saved{' '}
                <FontAwesomeIcon icon={faCheckCircle} className="settings__check-icon" />
              </p>
            </div>
          )}
        </Col>
      </div>

      <h4>{title}</h4>
      <hr />

      {!replenishment && (
        <div className="form-row">
          <div className="form-group col-6">
            <Form.Label>Max number of days the next charge can be updated</Form.Label>
            <Form.Control
              type="number"
              {...formRegister('next_charge_max_update_days')}
              defaultValue={shop?.nextChargeMaxUpdateDays}
            />
            <Form.Text className="text-muted">Leave it blank to be infinite</Form.Text>
          </div>
        </div>
      )}

      <div className="form-row">
        <div className="form-group col-6">
          <Form.Label>Send automated messages between the hours</Form.Label>
          <div className="form-row">
            <div className="col-6">
              <HoursRange min={8} max={20} onChange={setSafeHours} initialValues={safeHours} />
            </div>
          </div>
          <Form.Text className="text-muted">
            This range will apply to the customer’s timezone.
          </Form.Text>
        </div>
      </div>

      {!replenishment && (
        <div className="form-row">
          <div className="form-group col-6">
            <Form.Label>Max order price</Form.Label>
            <Form.Control
              type="number"
              {...formRegister('max_order_price')}
              defaultValue={shop?.maxOrderPrice}
            />
            <Form.Text className="text-muted">
              Default is $1000, please input the max price.
            </Form.Text>
          </div>
        </div>
      )}

      {!replenishment && smsTagOptedInShopifyCustomersFlagEnabled && (
        <div className="form-row">
          <div className="form-group col-6">
            <Form.Switch
              id="store-tag-opt-ins-toggle"
              label="Tag Shopify customers"
              name="store-tag-opt-ins"
              checked={tagOptIns}
              onChange={() => setTagOptIns(!tagOptIns)}
              className="mb-3 settings_email-form-switch"
            />
          </div>
        </div>
      )}

      {!replenishment && !!rechargeApiKey && (
        <>
          <h4 className="mt-4">Recharge Account</h4>
          <hr />
          <div className="form-row">
            <div className="form-group col-6">
              {!!rechargeApiKey && <Form.Control defaultValue={rechargeApiKey} disabled />}
            </div>
          </div>
        </>
      )}

      {replenishment &&
        renderShipmentTrackingFeatureToggle(
          replenishmentSettingsShipmentTrackingNotificationsFlag,
          replenishmentShipmentTracking
        )}
      {/* Need to only have the line break when not in replenishment */}
      {!replenishment && <hr />}
      <div className="form-actions text-right">
        <Button variant="primary" type="submit" disabled={isLoading}>
          Update
        </Button>
      </div>
    </div>
  );
}
