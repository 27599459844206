import React from 'react';

const DropdownItem = ({ variable, lockedVariables }) => {
  const locked = lockedVariables.includes(variable.value);

  if (locked) return null;

  return (
    <div className="tagify__dropdown__item" tabIndex={0} role="option">
      {variable.value}
    </div>
  );
};

export default DropdownItem;
