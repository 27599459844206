import React from 'react';

import { Loading } from '@components/Loading';
import colors from 'styles/theme/colors';

import styled from '@emotion/styled';
import { CreateWorkflowButton } from '@pages/Workflows/components/CreateWorkflowButton';
import { Workflow } from './components/Workflow';
import WorkflowSearch from './components/WorkflowSearch';
import BackButton from './components/BackButton';
import { useWorkflowsContext } from '../WorkflowsContext';

interface WorkflowsProps {
  showBackButton?: boolean;
  showTemplateTitle?: boolean;
}

export function Workflows(props: WorkflowsProps) {
  const { showBackButton = true, showTemplateTitle = true } = props;
  const { currentTemplate, workflows, workflowsLoading } = useWorkflowsContext();

  return (
    <Container>
      <ButtonsContainer>{showBackButton && <BackButton />}</ButtonsContainer>

      <Title>{showTemplateTitle ? currentTemplate?.label : 'Workflows'}</Title>
      <Subtitle>Edit a saved workflow or create a new one</Subtitle>

      <WorkflowSearch />

      <LineSeparator />

      {workflowsLoading && <Loading color={colors.secondary} style={{ marginTop: '15px' }} />}

      {!workflowsLoading &&
        workflows?.map((workflow) =>
          workflow.id !== 0 ? (
            <Workflow key={workflow.id} variants={workflow?.productVariants} {...workflow} />
          ) : null
        )}

      {!workflowsLoading && <CreateWorkflowButton />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
`;

const LineSeparator = styled.div`
  width: calc(100% + 2rem);
  transform: translateX(-1rem);
  border-bottom: 1px solid #191d45;
  margin: 10px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;

  & > :not(:first-of-type) {
    margin-left: 20px !important;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 10px;
  color: #fff;
`;

const Subtitle = styled.h3`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 20px;
  color: #fff;
`;
