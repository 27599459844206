import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import axios from '@lib/axios';

import Simulator from './Simulator';

const Playground = () => {
  const [productVariants, setProductVariants] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [initialChat, setInitialChat] = useState(null);

  const triggers = {
    order_processed_message: 'Order Processed Message',
    shipment_tracking_message: 'Shipment Tracking Message',
    upcoming_charge_message: 'Upcoming Charge Message',
    welcome_order: 'Welcome Order',
    charge_failed_message: 'Charge Failed Message',
  };

  const productVariantsOptions = productVariants.map((variant) => ({
    value: variant,
    label: variant.title,
  }));

  const selectedOptions = selectedVariants.map((variant) => ({
    value: variant,
    label: variant.title,
  }));

  const triggersOptions = Object.keys(triggers).map((trigger) => ({
    value: trigger,
    label: triggers[trigger],
  }));

  useEffect(() => {
    getProductVariants();
  }, []);

  async function getProductVariants() {
    const {
      data: { productVariants },
    } = await axios.get('/sms/simulator');

    setProductVariants([
      { title: 'Vanilla Cereal (Fake Product)', shopifyVariantId: 0 },
      ...productVariants,
    ]);
  }

  const disableTriggers = selectedVariants.length === 0;

  return (
    <>
      <div className="simulator">
        <Simulator
          initialMessage="Hey there 👋 Select a product (or multiple) to test your shop’s rcSMS configuration. Have fun - ⚡️ rcSMS Team"
          initialChat={initialChat}
          setInitialChat={setInitialChat}
          productVariants={selectedVariants}
          actions={
            <Actions>
              <Select
                placeholder="Select a product (or multiple)"
                options={productVariantsOptions}
                isMulti
                onChange={(options) => {
                  setSelectedVariants(options.map((option) => option.value));
                  setInitialChat('info');
                }}
                value={selectedOptions}
              />
              <Select
                placeholder={
                  disableTriggers
                    ? 'Select a product to enable action triggers'
                    : 'Trigger an action'
                }
                options={triggersOptions}
                onChange={(trigger) => setInitialChat(trigger.value)}
                value={null}
                isDisabled={disableTriggers}
              />
            </Actions>
          }
        />
      </div>
    </>
  );
};

const Actions = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
`;

export default Playground;
