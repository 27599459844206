import React, { useEffect } from 'react';
import { ProductView } from '@components/Onboarding/v2/components/ProductView';
import { useOnboardingV2 } from '@hooks/useOnboardingV2';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';
import {
  PRODUCT_2_IMPORT_STEP,
  PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP,
} from '@components/Onboarding/v2/onboarding';

export const ProductFormatTitleView = () => {
  const { updateOnboarding } = useOnboardingV2();
  const onboardingState = useAppSelector((state) => state.onboarding);

  const dispatch = useAppDispatch();

  const moveToStep = async (step) => {
    await updateOnboarding(onboardingState.data.id, {
      step,
    });
  };

  useEffect(() => {
    if (onboardingState.numProducts === 0) {
      moveToStep(PRODUCT_2_IMPORT_STEP);
    }
    if (onboardingState.isProductTitleFormatted) {
      dispatch(onboardingSlice.actions.setShowCompletedFormatTitleBanner(true));
      moveToStep(PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP);
    }
  }, [onboardingState]);

  return (
    <ProductView
      highlightFormatTitleButton={!onboardingState.isFormattingProductTitle}
      showFormatTitleModal={onboardingState.isFormattingProductTitle}
    />
  );
};
