import React from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { SaveButton } from '@components/Button/SaveButton';
import { ErrorMessage } from '@hookform/error-message';

import { HoursRange } from './HoursRange';

import '@pages/Settings/Features/shared/FeatureSwitch/featureSwitch.scss';
import '@components/Input/EmailForm/storeEmail.scss';

export function MessagingSettings({
  replenishment,
  formRegister,
  formErrors,
  safeHours,
  setSafeHours,
  isLoading,
}) {
  const shop = useCurrentShop();
  const {
    formState: { errors },
  } = useForm();

  return (
    <div data-testid="messaging-settings-component">
      <div className="d-flex align-items-center px-4 pt-3">
        <h4 className="font-weight-bold mb-0">Messaging Settings</h4>
        <SaveButton type="submit" disabled={isLoading}>
          Save settings
        </SaveButton>
      </div>
      <hr className="mb-0" />

      <div className="d-flex flex-column">
        {!replenishment && (
          <div className="form-row bg-light pt-3 px-4">
            <div className="form-group col-6">
              <Form.Label className="font-weight-bold">Set max delay interval</Form.Label>
              <Form.Text>Max number of days the next charge can be delayed</Form.Text>
              <Form.Control
                type="number"
                {...formRegister('next_charge_max_update_days', {
                  min: {
                    value: 1,
                    message: 'Min value is 1',
                  },
                  max: {
                    value: 365,
                    message: 'Max value is 365',
                  },
                })}
                defaultValue={shop?.nextChargeMaxUpdateDays}
                placeholder="180"
                name="next_charge_max_update_days"
              />
              <ErrorMessage errors={formErrors} name="next_charge_max_update_days" />
              <Form.Text className="text-muted">Leave it blank for no limit</Form.Text>
            </div>
          </div>
        )}

        <div className="form-row pt-3 px-4">
          <div className="form-group col-6">
            <Form.Label className="font-weight-bold">Set messaging safe hours</Form.Label>
            <Form.Text>
              Send automated messages between the hours. Drag the handles on the slider below.
            </Form.Text>
            <div className="form-row mt-1">
              <div className="col-6 ml-2">
                <HoursRange min={8} max={20} onChange={setSafeHours} initialValues={safeHours} />
              </div>
            </div>
            <Form.Text className="text-muted">
              This range will apply to the customer’s timezone.
            </Form.Text>
          </div>
        </div>

        {!replenishment && (
          <div className="form-row bg-light pt-3 px-4">
            <div className="form-group col-6">
              <Form.Label className="font-weight-bold">Set max order price</Form.Label>
              <Form.Text>Orders above this price will not be processed.</Form.Text>
              <Form.Control
                type="number"
                {...formRegister('max_order_price', {
                  min: {
                    value: 1,
                    message: 'Min value is 1',
                  },
                })}
                defaultValue={shop?.maxOrderPrice}
                placeholder="$1000"
                name="max_order_price"
              />
              <ErrorMessage errors={formErrors} name="max_order_price" />
              <Form.Text className="text-muted">
                Default is $1000, please input the max price.
              </Form.Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
