import React from 'react';
import { EmptyModalView } from '@components/Onboarding/v2/components/Modal/EmptyModalView';
import { VideoModalView } from '@components/Onboarding/v2/components/Modal/VideoModalView';
import Home from '@pages/Home';
import { useAppSelector } from '@redux/hooks';
import {
  WELCOME_1_STEP,
  WELCOME_2_STEP,
  WELCOME_3_STEP,
} from '@components/Onboarding/v2/onboarding';
import { useNavigate } from 'react-router-dom';

const WELCOME_VIEW_MAP = {
  [WELCOME_1_STEP]: (
    <>
      <Home />
      <EmptyModalView />
    </>
  ),
  [WELCOME_2_STEP]: (
    <>
      <Home />
      <VideoModalView />
    </>
  ),
  [WELCOME_3_STEP]: (
    <>
      <Home />
      <VideoModalView />
    </>
  ),
};

export const WelcomeOnboardingView = () => {
  const onboardingState = useAppSelector((state) => state.onboarding);

  const navigate = useNavigate();

  if (!onboardingState.isDisplay) navigate('/sms');
  if (!onboardingState.isModalOpen) return <Home />;

  return WELCOME_VIEW_MAP.hasOwnProperty(onboardingState.data.step) ? (
    WELCOME_VIEW_MAP[onboardingState.data.step]
  ) : (
    <Home />
  );
};
