export type OnboardingComponent = 'sidebar' | 'mainView';

export const WELCOME_1_STEP = 'welcome1';
export const WELCOME_2_STEP = 'welcome2';
export const WELCOME_3_STEP = 'welcome3';

export const FEATURE_1_SELECT_STEP = 'feature1_select';
export const FEATURE_2_SIMULATOR_STEP = 'feature2_simulator';
export const FEATURE_3_RECOMMEND_STEP = 'feature3_recommend_setup';
export const FEATURE_4_PLAY_AROUND_STEP = 'feature4_play_around';

export const PRODUCT_1_SETUP_STEP = 'product1_setup';
export const PRODUCT_2_IMPORT_STEP = 'product2_import';
export const PRODUCT_3_FORMAT_TITLE_STEP = 'product3_format_title';
export const PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP = 'product4_format_title_complete';
export const PRODUCT_5_IGNORE_PRODUCT_STEP = 'product5_ignore_product';
export const PRODUCT_6_PROCEED_ACTIVATION_STEP = 'product6_proceed_activation';

export const ACTIVATION_STEP = 'activation';

export const WELCOME_FLOW = 'welcome';
export const FEATURE_FLOW = 'feature';
export const PRODUCT_FLOW = 'product';
export const ACTIVATION_FLOW = 'activation';

export const DEFAULT_DELAY_TIME_MILLIS = 1250;
export const CONTINUE_BUTTON_DELAY_TIME_MILLIS = 1000;
export const MAX_TEST_SEGMENT_EMAILS = 20;

export const ENABLE_SMS_TEST_SEGMENT_NAME = '[Test Segment] Enable SMS for test subscribers';
export const ENABLE_SMS_TEST_SEGMENT_DESCRIPTION = 'Enable SMS for test subscribes';
export const ENABLE_SMS_TEST_SEGMENT_TARGET = 'onboarding_test_customers';

export const onboardingFlowSetting = [
  {
    flow: WELCOME_FLOW,
    position: 1,
    steps: [WELCOME_1_STEP, WELCOME_2_STEP, WELCOME_3_STEP],
  },
  {
    flow: FEATURE_FLOW,
    position: 2,
    steps: [
      FEATURE_1_SELECT_STEP,
      FEATURE_2_SIMULATOR_STEP,
      FEATURE_3_RECOMMEND_STEP,
      FEATURE_4_PLAY_AROUND_STEP,
    ],
  },
  {
    flow: PRODUCT_FLOW,
    position: 3,
    steps: [
      PRODUCT_1_SETUP_STEP,
      PRODUCT_2_IMPORT_STEP,
      PRODUCT_3_FORMAT_TITLE_STEP,
      PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP,
      PRODUCT_5_IGNORE_PRODUCT_STEP,
      PRODUCT_6_PROCEED_ACTIVATION_STEP,
    ],
  },
  {
    flow: ACTIVATION_FLOW,
    position: 4,
    steps: [ACTIVATION_STEP],
  },
];

export function getCurrentFlowFromStep(step) {
  const onboardingStep = onboardingFlowSetting.filter(
    (onboardingStep) => onboardingStep.steps.indexOf(step) >= 0
  );
  return onboardingStep[0];
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
