import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Page } from '@components/Layout/Page';
import { ifStyle } from '@utils/styles';

import { OptIn } from './OptIn';
import { NavTabs } from '../NavTabs';

interface View {
  key: 'opt-in' | 'replenishment';
  label: string;
  component: React.ReactNode;
  onboardingComponent?: React.ReactNode;
}

function Widgets() {
  const views: View[] = [
    {
      key: 'opt-in',
      label: 'Opt-in',
      component: <OptIn />,
    },
  ];
  const [selectedView, setSelectedView] = useState<View>(views[0]);

  return (
    <>
      <NavTabs />
      <Page
        headerDescription={
          <Header>
            Widget Customization
            {selectedView?.onboardingComponent && (
              <InfoButtonContainer>{selectedView?.onboardingComponent}</InfoButtonContainer>
            )}
          </Header>
        }
      >
        {/* {views.map((view) => (
        <Button
          selected={selectedView.key === view.key}
          onClick={() => setSelectedView(view)}
          key={view.key}
        >
          {view.label}
        </Button>
      ))} */}

        <Content>{selectedView.component}</Content>
      </Page>
    </>
  );
}

export default Widgets;

const Button = styled.button<{ selected: boolean }>`
  border: 0;
  outline: 0;
  border-radius: 23px;
  background: ${(props) => props.theme.colors.light0};
  height: 35px;
  padding: 5px 10px;
  font-size: 16px;
  margin-right: 20px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  ${ifStyle('selected')(css`
    background: #2d2d2d;
    color: #fff;
    border: 2px solid #717171;
  `)}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  margin: 0.5rem 0;
`;

const InfoButtonContainer = styled.div`
  margin: 0 0.25rem;
  display: flex;
`;
