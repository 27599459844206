import React from 'react';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Page } from '@components/Layout/Page';
import { DatePicker } from '@components/Input';
import { ifStyle } from '@utils/styles';

import {
  MetricVariation,
  MetricValue,
  MetricProduct,
  MetricLineChart,
  MetricBarChart,
  MostSkippedProductList,
} from './components';

import { OverviewProvider, useOverviewContext } from './OverviewContext';

function OverviewComponent() {
  const { period, dateRange, handleChangePeriod, handleChangeDate } = useOverviewContext();

  function HeaderComponent() {
    return (
      <Header>
        <PeriodButtons>
          <PeriodButton selected={period === 'day'} onClick={() => handleChangePeriod('day')}>
            Daily
          </PeriodButton>
          <PeriodButton selected={period === 'week'} onClick={() => handleChangePeriod('week')}>
            Weekly
          </PeriodButton>
          <PeriodButton selected={period === 'month'} onClick={() => handleChangePeriod('month')}>
            Monthly
          </PeriodButton>
        </PeriodButtons>
        <DatePicker value={dateRange} onChange={handleChangeDate} />
      </Header>
    );
  }

  return (
    <Page headerComponent={<HeaderComponent />}>
      <MetricLineChart title="" url="sms" />

      <Row className="my-4">
        <Col>
          <MetricBarChart title="Customer Analysis" url="customer" />
        </Col>
        <StackedCol>
          <Col>
            <MetricVariation title="Product swaps" url="product_swaps_variation" />
          </Col>
          <Col>
            <MetricVariation title="Skipped orders" url="skipped_orders_variation" />
          </Col>
        </StackedCol>
      </Row>

      <Row className="mb-4">
        <Col>
          <MetricVariation title="Automated CX tickets" url="automated_tickets_variation" />
        </Col>
        <Col>
          <MetricVariation title="Unsubscribes" url="unsubscribes_variation" />
        </Col>
        <Col>
          <MetricVariation title="Service opt-outs" url="opt_out_variation" />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <MetricValue
            title="Customer Service Cost Saved"
            url="customer_service_cost_saved"
            type="currency"
          />
        </Col>
        <Col>
          <MetricValue
            title="Add One-time Item Revenue"
            url="one_time_product_revenue"
            type="currency"
          />
        </Col>
        <Col>
          <MetricVariation title="Delayed Orders" url="delayed_orders_variation" />
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <MetricProduct title="Most swapped product from" url="most_swapped_product_from" />
        </Col>
        <Col>
          <MetricProduct title="Most swapped product to" url="most_swapped_product_to" />
        </Col>
        <Col>
          <MetricProduct title="Most skipped product" url="most_skipped_product" />
        </Col>
      </Row>

      <div className="mb-5">
        <MostSkippedProductList title="Overview" url="most_skipped_products" />
      </div>
    </Page>
  );
}

const Overview = () => (
  <OverviewProvider>
    <OverviewComponent />
  </OverviewProvider>
);

export default Overview;

const StackedCol = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const PeriodButtons = styled(ButtonGroup)`
  box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
  margin-right: 1rem;
`;

const PeriodButton = styled.button<{ selected?: boolean }>`
  background-color: #fff;
  border-color: #fff;
  color: #000;
  border: 0;
  height: 35px;
  padding: 0.375rem 0.75rem;

  &:first-of-type {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-of-type {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  ${ifStyle('selected')(css`
    background-color: #000;
    border-color: #000;
    color: #fff;

    &:hover {
      background-color: #111;
    }
  `)}
`;
