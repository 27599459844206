import styled from '@emotion/styled';

interface TagProps {
  type: 'primary' | 'light';
}

export const Tag = styled.span<TagProps>`
  background: ${(props) => backgrounds[props.type || 'primary']};
  border-radius: 6px;
  color: ${(props) => colors[props.type || 'primary']};
  font-size: 14px;
  font-weight: bold;
  padding: 6px;
`;

const backgrounds = {
  primary: 'var(--primary)',
  light: '#f4f4f7',
};

const colors = {
  primary: 'var(--white)',
  light: 'var(--black)',
};
