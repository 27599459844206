import React from 'react';
import styled from '@emotion/styled';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

import SadFace from '@assets/icons/sad-face.svg';
import Thumbnail from '@components/Image/Thumbnail';

function FallbackComponent() {
  return (
    <Container>
      <Thumbnail src={SadFace} alt="sad face" />
      <Description>Something went wrong,</Description>
      <Description>Please refresh the page or contact us if the problem persists.</Description>
    </Container>
  );
}

export function ErrorBoundary({ children }) {
  return <SentryErrorBoundary fallback={<FallbackComponent />}>{children}</SentryErrorBoundary>;
}

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Description = styled.h4`
  color: #9a9b9c;
  font-weight: 400;
`;
