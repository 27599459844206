import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { useFlag } from '@hooks/useFlag';

import VariablesInput, { VariableInputHandle } from '@components/Input/VariablesInput';

import { useBaseAppContext } from '@components/BaseAppContext';
import CustomActions from './CustomActions';
import { CustomActionProvider } from './CustomActions/CustomActionsContext';

function TemplateMessage({
  custom,
  customVariables,
  availableActions,
  variables,
  lockedVariables,
  addStartLockedVariable,
  addEndLockedVariable,
}) {
  const variableInputRef = useRef<VariableInputHandle>();
  const { register, setValue, getValues } = useFormContext();
  const { setShowContactCardModal } = useBaseAppContext();

  const handleBodyOnChange = (body) => setValue('body', body);
  const handleCustomActionsOnChange = (actions) => setValue('actions', actions);

  const availableVariables = variables?.filter((variable) => !lockedVariables.includes(variable));

  const bodyValue = getValues(`body`);
  const titleValue = getValues('title');

  const showVCardFeatureToggle = useFlag('show_v_card_feature_toggle');

  function configureContactCard() {
    setShowContactCardModal(true);
  }

  return (
    <>
      <Form.Row>
        <Form.Group className="col-md-6" controlId="template_message_title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            readOnly={!custom}
            name="title"
            required
            {...register('title', { required: true })}
          />
        </Form.Group>
        <Form.Group className="col-md-6" controlId="template_message_description">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" name="description" {...register('description')} />
        </Form.Group>
      </Form.Row>
      <hr />
      <Form.Row>
        <Col md={6}>
          <h5>Variables</h5>
          <p>
            A set of custom variables is provided to make your message more personal and informative
            for your customers. To use variables is quite simple, you need to select from the
            available variables set and include it to your message body using double brackets:
            <code>{'{{variable_goes_here}}'}</code>.
          </p>
          <p>Available variables:</p>
          {availableVariables?.map((variable) => (
            <VariableBadge
              key={variable}
              onClick={() => variableInputRef.current?.addVariable(variable)}
              className="btn btn-sm btn-secondary btn-variable"
            >
              {variable}
            </VariableBadge>
          ))}
        </Col>
        <Col md={6}>
          <VariablesInput
            ref={variableInputRef}
            value={bodyValue}
            variables={variables}
            lockedVariables={lockedVariables}
            onChange={handleBodyOnChange}
            addStartLockedVariable={addStartLockedVariable}
            addEndLockedVariable={addEndLockedVariable}
          />
          <small className="form-text text-muted">
            Type @ to have the variables auto-completion.
          </small>
          {availableActions?.length > 0 && (
            <CustomActionProvider
              initialActions={customVariables?.actions}
              availableActions={availableActions}
              onChange={handleCustomActionsOnChange}
            >
              <CustomActions />
            </CustomActionProvider>
          )}
          {titleValue === 'SMS Contact Card Message' && showVCardFeatureToggle.enabled && (
            <ConfigureLink href="#" onClick={configureContactCard}>
              Configure contact card
            </ConfigureLink>
          )}
        </Col>
      </Form.Row>
    </>
  );
}

const VariableBadge = styled.div`
  text-transform: none;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
`;

const ConfigureLink = styled.a`
  display: block;
  margin-top: 8px;
`;

export default TemplateMessage;
