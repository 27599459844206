import React from 'react';

import { CustomerInfo } from '@components/Table/Row/CustomerInfo';
import { formatString } from '@utils/date';

import { DashboardViewPage } from './DashboardViewPage';

function Swaps() {
  const tableHeaders = [
    {
      label: 'Customer',
      width: '32%',
    },
    {
      label: 'First order date',
    },
    {
      label: 'Swap request date',
    },
    {
      label: 'Swapped from',
    },
    {
      label: 'Swapped to',
    },
  ];

  function tableRow(productSwap) {
    const data = [
      <CustomerInfo customer={productSwap.rechargeCustomer} />,
      <p>{formatString(productSwap.rechargeCustomer.createdAt, 'dd MMM hh:mm')}</p>,
      <p>{formatString(productSwap.swappedAt, 'dd MMM hh:mm')}</p>,
      <p>{productSwap.swappedFrom}</p>,
      <p>{productSwap.swappedTo}</p>,
    ];

    return { data };
  }

  return (
    <DashboardViewPage
      baseUrl="/sms/recharge/product_swaps"
      headerDescription="Orders swapped"
      tableHeaders={tableHeaders}
      tableRow={tableRow}
      csvFilename="swapped-orders"
    />
  );
}

export default Swaps;
