import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Modal as BModal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';

import { Modal, ModalHandles } from '@pages/Workflows/components/Modal';
import { ProductCard } from '@pages/Workflows/components/ProductCard';

export function ViewProducts({ products, handleSave, handleDelete }) {
  const [hasChanged, setHasChanged] = useState(false);
  const modalRef = useRef<ModalHandles>(null);

  function handleTrashIconClick(variantId) {
    setHasChanged(true);
    handleDelete(variantId);
  }

  return (
    <>
      <CustomProductCard
        variants={products}
        showQuantity={false}
        title={
          <ViewProductsButton variant="link" onClick={() => modalRef.current?.toggleModal()}>
            View {products.length} products
          </ViewProductsButton>
        }
      />

      <Modal
        ref={modalRef}
        headerComponent={<BModal.Title>View Product Variants</BModal.Title>}
        onHide={() => hasChanged && handleSave()}
      >
        <ProductsList>
          {products?.map((variant) => (
            <ProductContainer key={variant.id}>
              <ProductCard
                title={variant.title}
                subtitle={variant.priceText}
                variants={[variant]}
              />
              <TrashIcon icon={faTrashAlt} onClick={() => handleTrashIconClick(variant.id)} />
            </ProductContainer>
          ))}
        </ProductsList>
      </Modal>
    </>
  );
}

const CustomProductCard = styled(ProductCard)`
  margin: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  cursor: grab;
  box-shadow: inset 0 7px 5px -7px ${(props) => props.theme.colors.dark0}44;
  background: #fff !important;
`;

const ProductsList = styled.div`
  height: 300px;
  overflow-y: auto;
  padding: 2px;
`;

const ProductContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TrashIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
  font-size: 16px;
  color: red;
  cursor: pointer;
  opacity: 0.7;
  transition: 250ms ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const ViewProductsButton = styled(Button)`
  &&& {
    box-shadow: none;
    text-decoration: none;
    color: #8a8a8a;
  }

  margin-left: auto;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
`;
