import { useState, useEffect } from 'react';

import axios from '@lib/axios';
import { Feature } from '@typings/Feature';

export function useFeature(feature: Feature) {
  const [checked, setChecked] = useState(feature?.enabled);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setChecked(feature?.enabled);
  }, [feature?.enabled]);

  async function toggleFeature() {
    setValue(!checked);
  }

  async function setValue(value: boolean) {
    try {
      setLoading(true);
      const params = { feature: { enabled: value } };
      await axios.put(`/sms/features/${feature.key}`, params);
      setChecked(!checked);
    } finally {
      setLoading(false);
    }
  }

  return { loading, checked, toggleFeature, setValue };
}
