import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import styled from '@emotion/styled';

import { CloseModalIcon } from '@components/Modal/CloseModalIcon';

export interface ModalHandles {
  toggleModal(): void;
}

export interface BaseModalProps extends ModalProps {
  children: React.ReactNode;
  showCloseIcon?: boolean;
  onShow?(): void;
  onHide?(): void;
}

const BaseModal = (props: BaseModalProps, ref) => {
  const { children, showCloseIcon, onShow, onHide, ...rest } = props;
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  function toggleModal() {
    if (show) onHide?.();
    else onShow?.();

    setShow((show) => !show);
  }

  if (!show) return null;

  return (
    <Modal centered show={show} onHide={toggleModal} {...rest}>
      {showCloseIcon && <CloseIcon onClose={toggleModal} />}
      {children}
    </Modal>
  );
};

const CloseIcon = styled(CloseModalIcon)`
  position: absolute;
  top: 10px;
  left: 10px;

  path {
    fill: ${(props) => props.theme.colors.dark1}bb;
  }

  &:hover {
    path {
      fill: ${(props) => props.theme.colors.dark1};
    }
  }
`;

export default forwardRef(BaseModal);
