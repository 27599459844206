import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Edit3 } from 'react-feather';

import { Feature } from '@typings/Feature';
import { ToggleAlt } from '@components/Input';
import { useFeaturesContext } from '@contexts/FeaturesContext';

interface CollapsableFeatureSwitchProps {
  feature: Feature;
  children?: React.ReactElement;
  name?: string;
  alt?: boolean;
}

export const CollapsableFeatureSwitch = (props: CollapsableFeatureSwitchProps) => {
  const { name, feature, children, alt } = props;

  const { currentState, handleFeatureChanged } = useFeaturesContext();
  const [loading, setLoading] = useState(false);
  const [childrenVisible, setChildrenVisible] = useState(false);

  const state = currentState(feature.key);

  useEffect(() => {
    if (!state.enabled) {
      setChildrenVisible(state.enabled);
    }
  }, [state]);

  const toggleCollapse = () => setChildrenVisible(!childrenVisible);
  const handleToggleFeature = async () => {
    setLoading(true);
    await handleFeatureChanged(feature.key);
    setLoading(false);
  };

  return (
    <div className="feature">
      {alt ? (
        <ToggleAlt checked={state.enabled} onChange={handleToggleFeature} disabled={loading} />
      ) : (
        <>
          <div className="col-12">
            <Form.Check
              type="switch"
              id={`${feature.key}-collapsable`}
              label={name || feature.name}
              checked={state.enabled}
              onChange={handleToggleFeature}
              disabled={loading}
              className="float-left mr-1"
            />
            <button
              type="button"
              onClick={() => setChildrenVisible(!childrenVisible)}
              aria-label="Edit"
              className="features__edit"
            >
              <Edit3 size={16} className="settings__edit-icon" />
            </button>
          </div>
          <div className="features__body col-12 col-offset-2">
            <p className="text-muted mt-2 mb-3">{feature?.description}</p>
            {childrenVisible && React.cloneElement(children, { toggleCollapse })}
          </div>
        </>
      )}
    </div>
  );
};
