import React from 'react';
import styled from '@emotion/styled';

export function AboutIntegrations() {
  return (
    <Container>
      <Title>About Integrations</Title>
      <Text>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita unde eos, voluptatibus
        possimus atque enim dicta similique, ad, delectus optio earum. Nostrum eaque corporis ab!
        Illo, praesentium ipsam? Saepe, dolores!
      </Text>
    </Container>
  );
}

const Container = styled.form`
  border-radius: 8px;
  padding: 32px;
  padding-top: 48px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  margin-bottom: 1rem;
  position: relative;
  height: 100%;
`;

const Title = styled.h3`
  background: #e6e0f9;
  border-radius: 50px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  position: absolute;
  padding: 10px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  text-align: center;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.47px;
  color: #000000;
  text-align: justify;
`;
