import React from 'react';
import { Link } from 'react-router-dom';

interface CancelLinkProps {
  to: string;
}

export function CancelLink(props: CancelLinkProps) {
  const { to } = props;

  return (
    <Link className="text-warning btn btn-link" to={to}>
      Cancel
    </Link>
  );
}
