import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from '@emotion/styled';

import BatchActionsModal from './BatchActionsModal';

interface RevertBatchActionsButtonProps {
  changeBatchActionsFeature(status): void;
}

const RevertBatchActionsButton = (props: RevertBatchActionsButtonProps) => {
  const { changeBatchActionsFeature } = props;
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const confirmationHandler = () => {
    toggleModal();
    changeBatchActionsFeature(false);
  };

  return (
    <>
      <RevertActionsButton variant="outline-danger" onClick={toggleModal}>
        Revert Batch Actions
      </RevertActionsButton>

      <BatchActionsModal
        show={showModal}
        onConfirm={confirmationHandler}
        onClose={toggleModal}
        enabled
      />
    </>
  );
};

const RevertActionsButton = styled(Button)`
  float: right;
  margin-bottom: -25px;
`;

export default RevertBatchActionsButton;
