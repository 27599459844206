import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { OverlayTrigger, Row } from 'react-bootstrap';
import Loading from '@components/Loading';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { useBaseAppContext } from '@components/BaseAppContext';
import Waves from '@components/Image/Waves';
import { CustomTooltip } from '@components/Layout/Sidebar/components/BaseSidebar';
import { useAppSelector } from '@redux/hooks';
import Cookies from 'universal-cookie';
import { useFlag } from '@hooks/useFlag';
import { useFeature } from '@hooks/useFeature';
import { useFetch } from '@hooks/useFetch';
import { Feature } from '@typings/Feature';
import NotificationCenter from './NotificationCenter';
import PhoneErrorNotification from './PhoneErrorNotification';
import ContactCardFeatureNotification from './ContactCardFeatureNotification';

export function StatusIndicator() {
  const cookies = new Cookies();

  const [status, setStatus] = useState(null);
  const [errorOveride, setErrorOveride] = useState<boolean>(null);
  const [updateOveride, setUpdateOveride] = useState<boolean>(false);
  const [showStatusMenu, setShowStatusMenu] = useState<boolean>(false);

  const shop = useCurrentShop();
  const [shopActive, setShopActive] = useState<boolean>(!!shop.activeAt);

  const navigationState = useAppSelector((state) => state.navigation);

  const sciCheckout = shop.checkoutPlatform !== 'recharge';

  const OFFLINE = 'Offline';
  const ONLINE = 'Online';
  const LIMITED = 'Limited';
  const OPTIMAL = 'Optimal';
  const UPDATE = 'Update';

  const {
    allSubscribersChecked: smsEnabledForAllCurrentSubscribers,
    cartWidgetConfig,
    checkoutScriptGeneralAttribute,
    statusRefresh,
  } = useBaseAppContext();

  const { enabled: vCardNotificationEnabled } = useFlag('show_v_card_feature_toggle');

  const { data: vCardFeature } = useFetch<Feature>('/sms/features/send_vcard');
  const { checked: vCardFeatureEnabled } = useFeature(vCardFeature);

  useEffect(() => {
    if (vCardNotificationEnabled === undefined || vCardFeatureEnabled === undefined) {
      return;
    }
    if (
      !vCardFeatureEnabled &&
      vCardNotificationEnabled &&
      !cookies.get('dismissed-contact-card-feature-notification')
    ) {
      setUpdateOveride(true);
    }
  }, [vCardNotificationEnabled, vCardFeatureEnabled]);

  const updateStatus = async () => {
    if (errorOveride !== false && shop.shouldDisplayPhoneCollectionError) {
      setErrorOveride(true);
      setStatus(OFFLINE);
      return;
    }

    if (updateOveride) {
      return setStatus(UPDATE);
    }

    if (!shopActive) {
      return setStatus(OFFLINE);
    }

    // shop.activeAt is inferred here on
    if (sciCheckout) {
      if (smsEnabledForAllCurrentSubscribers && cartWidgetConfig?.enabled) {
        return setStatus(OPTIMAL);
      }
      if (smsEnabledForAllCurrentSubscribers || cartWidgetConfig?.enabled) {
        return setStatus(ONLINE);
      }
    } else {
      if (smsEnabledForAllCurrentSubscribers && checkoutScriptGeneralAttribute?.enabled) {
        return setStatus(OPTIMAL);
      }
      if (smsEnabledForAllCurrentSubscribers || checkoutScriptGeneralAttribute?.enabled) {
        return setStatus(ONLINE);
      }
    }

    return setStatus(LIMITED);
  };

  useEffect(() => {
    updateStatus();
  }, [
    smsEnabledForAllCurrentSubscribers,
    cartWidgetConfig,
    checkoutScriptGeneralAttribute,
    shopActive,
    statusRefresh,
  ]);

  const handleClick = () => {
    setShowStatusMenu(!showStatusMenu);
  };

  function expanded() {
    return showStatusMenu ? 'expanded' : '';
  }

  function statusLabel() {
    if (errorOveride) {
      return 'Error';
    }
    if (updateOveride) {
      return 'Update';
    }
    return status;
  }

  const renderStatusIndicator = () => (
    <Container className={expanded()}>
      <StatusRow onClick={handleClick}>
        {status ? (
          <>
            <Waves size={36} status={status} />
            <StatusText>
              <Messaging>Messaging</Messaging>
              <Status>{statusLabel()}</Status>
            </StatusText>
          </>
        ) : (
          <Loading />
        )}
      </StatusRow>
      {(errorOveride && <PhoneErrorNotification setErrorOveride={setErrorOveride} />) ||
        (updateOveride && <ContactCardFeatureNotification />) || (
          <NotificationCenter
            updateStatus={updateStatus}
            shopActive={shopActive}
            setShopActive={setShopActive}
          />
        )}
    </Container>
  );

  return navigationState.isSideNavCollapsed ? (
    <OverlayTrigger
      placement="right"
      overlay={<CustomTooltip id="status">Messaging {statusLabel()}</CustomTooltip>}
    >
      {renderStatusIndicator()}
    </OverlayTrigger>
  ) : (
    <>{renderStatusIndicator()}</>
  );
}

const StatusRow = styled(Row)`
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  box-shadow: 0px 0px 10px rgba(25, 29, 72, 0.1);
  cursor: pointer;
`;

const Container = styled.div`
  z-index: 2000 !important;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(calc(100% - 76px));
  transition: transform 200ms ease-in-out;
  &.expanded {
    transform: translateY(0);
  }
`;

const StatusText = styled.div`
  height: 20px;
  margin-left: 12px;
`;

const Messaging = styled.span`
  font-family: 'Greycliff';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
`;

const Status = styled.span`
  font-family: 'Greycliff';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #191d48;
  margin-left: 4px;
`;
