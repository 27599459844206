import React from 'react';
import styled from '@emotion/styled';
import Button from 'react-bootstrap/Button';
import { useFeaturesContext } from '@contexts/FeaturesContext';

export const EnableRecommendedFeatures = () => {
  const { enableRecommended } = useFeaturesContext();

  return (
    <Container>
      <h3>Recommended Features ✨</h3>
      <p>
        After millions of messages acrosss many different shops, we've found these features to be
        the most effective for subscription management via text.
      </p>
      <p>
        Enabling these features has shown to increase average customer lifetime value (LTV) by 26%
        and will save you countless hours of customer support 🤝
      </p>
      <Button className="mt-2" onClick={enableRecommended} data-test-id="enable-features-button">
        Enable Features
      </Button>
    </Container>
  );
};

const Container = styled.div`
  background: linear-gradient(268.99deg, #2900b1 0%, #191d48 100%);
  border-radius: 6px;
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.04);
  color: white;
  padding: 24px;

  h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 22px;
  }

  p {
    font-family: SFProDisplay, sans-serif;
  }

  button {
    font-weight: 700;
    padding-left: 28px;
    padding-right: 28px;
  }
`;
