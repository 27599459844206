import React from 'react';
import styled from '@emotion/styled';

import { Card, CardProps } from './Card';

interface InfoCardProps extends CardProps {
  percentage: number;
  description: string;
  loading: boolean;
}

export function InfoCard(props: InfoCardProps) {
  const { percentage, description, loading, ...rest } = props;

  return (
    <Card {...rest}>
      {loading ? <Loading>Loading...</Loading> : <Percentage>{percentage}%</Percentage>}
      <Description>{description}</Description>
    </Card>
  );
}

const Loading = styled.h2`
  font-weight: bold;
  font-size: 22px;
  color: #000;
  line-height: 63px;
`;

const Percentage = styled.h2`
  font-weight: bold;
  font-size: 48px;
  line-height: 63px;
  color: #000;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #6b6b6b;
`;
