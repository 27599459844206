import React from 'react';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useFlag } from '@hooks/useFlag';

import { TextInput, FormLabel, ColorInput, ImageUpload } from '@components/Input';
import { SaveButton } from '@components/Button/SaveButton';

export function Branding(props) {
  const { handleSubmit, processUpload } = props;
  const {
    watch,
    formState: { isValid },
  } = useFormContext();
  const refactorSettingsPagesEnabled = useFlag('refactor_settings_pages_enabled');

  const subtitle = (newBranding: boolean) => (
    <Subtitle className={`${newBranding ? 'font-weight-bold' : ''} px-4`}>
      {newBranding
        ? 'Show us your true colors!'
        : 'To get started with the widget for your landing page, please complete the following fields:'}
    </Subtitle>
  );

  const description = (newBranding: boolean) => (
    <Description className="px-4">
      {newBranding ? (
        <span>
          These properties are required and will inform other areas of our service, such as your
          <Link to="/sms#opt-in-tools-card" data-testid="landing-page-widget-link">
            {' '}
            landing page widget
          </Link>
          .
        </span>
      ) : (
        <span>These properties are required and will inform other areas of our service!</span>
      )}
    </Description>
  );

  return (
    <Container onSubmit={handleSubmit} data-testid="branding-component">
      <div className="d-flex align-items-center px-4 pt-3">
        <Title>Shop Branding</Title>
        <SaveButton disabled={!isValid} type="submit" className="mb-3">
          Save styles
        </SaveButton>
      </div>
      <Separator />
      {subtitle(refactorSettingsPagesEnabled?.enabled)}
      {description(refactorSettingsPagesEnabled?.enabled)}
      <Row className="px-4 pb-4">
        <Col>
          <FormLabel>Shop Logo</FormLabel>
          <ImageUpload defaultFile={{ url: watch('logo_url') }} processUpload={processUpload} />
        </Col>
        <Col xs={7}>
          <TextInput
            name="custom_name"
            label="Shop Name"
            inputProps={{ placeholder: 'Your preferred store name', required: true }}
            registerOptions={{ required: true }}
          />
          <ColorInput
            name="main_color"
            label="Primary Color"
            inputProps={{ required: true }}
            registerOptions={{ required: true }}
          />
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.h3`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 5px;
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #6b6b6b;
  margin-bottom: 0;
`;

const Separator = styled.div`
  background-color: #d8dee8;
  height: 1px;
  margin-bottom: 1em;
`;
