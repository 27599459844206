import React from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '@lib/axios';
import { SwapGroupType } from '@typings/SwapGroup';
import { Page } from '@components/Layout/Page';

import Form from './components/Form';

interface NewProps {
  groupType: SwapGroupType;
}

function New(props: NewProps) {
  const { groupType } = props;
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    const path = paths[groupType];
    await axios.post(`${path}/`, data);

    navigate(path);
  };

  const typeTitles = titles[groupType];

  return (
    <Page>
      <Form
        subtitle={typeTitles.subtitle}
        onSubmit={handleSubmit}
        defaultValues={{ groupType } as any}
      />
    </Page>
  );
}

const paths = {
  swap: '/sms/swap_groups',
  quantity: '/sms/quantity_groups',
};

const titles = {
  swap: {
    title: 'New Swap Group',
    subtitle: 'Create new product swap group and add product variants to it',
  },
  quantity: {
    title: 'New Quantity Group',
    subtitle: 'Specify items available for quantity swap upgrades',
  },
};

export default New;
