import styled from '@emotion/styled';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { useFlag } from '@hooks/useFlag';
import { Select } from '@components/Input';

const selectCustomStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    boxShadow: 0,
    border: '1px solid #ced4da',
    backgroundColor: '#fff',
  }),
};

const options = [
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'week',
    label: 'Week',
  },
  {
    value: 'month',
    label: 'Month',
  },
];

const testOptions = [
  {
    value: 'second',
    label: 'Second',
  },
  {
    value: 'minute',
    label: 'Minute',
  },
  {
    value: 'hour',
    label: 'Hour',
  },
];

const allOptions = [...testOptions, ...options];

export function EditInterval() {
  const { register, watch, setValue } = useFormContext();
  const { enabled: allowTestWorkflowIntervals } = useFlag('allow_test_workflow_intervals');
  const allowedOptions = allowTestWorkflowIntervals ? allOptions : options;

  return (
    <Container>
      <FormRow>
        <Label>Frequency</Label>
        <Form.Control
          name="frequency"
          type="number"
          min={0}
          required
          {...register('frequency', { required: true })}
        />
      </FormRow>
      <FormRow>
        <Label>Unit</Label>
        <Select
          value={watch('unit')}
          onChange={(unit) => setValue('unit', unit)}
          options={allowedOptions}
          styles={selectCustomStyles}
        />
      </FormRow>
    </Container>
  );
}

const Container = styled.div`
  margin: 0.5rem 0;
`;

const Label = styled(Form.Label)`
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const FormRow = styled(Form.Row)`
  flex-direction: column;
  padding: 0.75rem 0;
  margin: 0;
`;
