import React from 'react';
import { SidebarFeatureFlow } from '@components/Onboarding/v2/Sidebar/SidebarFeatureFlow';
import { SidebarProductFlow } from '@components/Onboarding/v2/Sidebar/SidebarProductFlow';
import { SidebarActivationFlow } from '@components/Onboarding/v2/Sidebar/SidebarActivationFlow';
import SidebarContinueOnboarding from '@components/Onboarding/v2/components/Sidebar/SidebarContinueOnboarding';
import { useOnboardingV2 } from '@hooks/useOnboardingV2';
import { SidebarWelcomeFlow } from './Sidebar/SidebarWelcomeFlow';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  getCurrentFlowFromStep,
  WELCOME_FLOW,
  FEATURE_FLOW,
  PRODUCT_FLOW,
  ACTIVATION_FLOW,
} from './onboarding';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';
import { isBefore, parseISO, subDays } from 'date-fns';

const FLOW_TITLE = {
  welcome: 'Getting Started',
  feature: 'Feature Configuration',
  product: 'Product Setup',
};

const SIDEBAR_FLOW_MAP = {
  [WELCOME_FLOW]: <SidebarWelcomeFlow />,
  [FEATURE_FLOW]: <SidebarFeatureFlow />,
  [PRODUCT_FLOW]: <SidebarProductFlow />,
  [ACTIVATION_FLOW]: <SidebarActivationFlow />,
};

export function SidebarOnboarding() {
  const { updateOnboarding } = useOnboardingV2();
  const onboardingState = useAppSelector((state) => state.onboarding);
  const currentOnboardingFlow = getCurrentFlowFromStep(onboardingState.data?.step);

  if (!currentOnboardingFlow || onboardingState?.data.finished_at) return null;

  const dispatch = useAppDispatch();

  const getWelcomeProgressValue = () => {
    if (currentOnboardingFlow.flow === WELCOME_FLOW) {
      const index = currentOnboardingFlow.steps.indexOf(onboardingState.data.step) + 1;
      return (index / currentOnboardingFlow.steps.length) * 100;
    }
    return 100;
  };

  const getFeatureProgressValue = () => {
    if (currentOnboardingFlow.flow === FEATURE_FLOW) {
      const index = currentOnboardingFlow.steps.indexOf(onboardingState.data.step) + 1;
      return (index / currentOnboardingFlow.steps.length) * 100;
    }
    return currentOnboardingFlow.flow === WELCOME_FLOW ? 0 : 100;
  };

  const getProductProgressValue = () => {
    if (currentOnboardingFlow.flow === PRODUCT_FLOW) {
      const index = currentOnboardingFlow.steps.indexOf(onboardingState.data.step) + 1;
      return (index / currentOnboardingFlow.steps.length) * 100;
    }
    return 0;
  };

  const onContinueClick = async () => {
    await updateOnboarding(onboardingState.data?.id, {
      is_paused: false,
    });
  };

  const onCollapsedClick = () => {
    const isCollapsed = onboardingState.isSidebarCollapsed;
    dispatch(onboardingSlice.actions.setSidebarCollapsed(!isCollapsed));
  };

  const isAfter24Hours = () => {
    const pausedAt = parseISO(onboardingState.data.paused_at);
    const yesterday = subDays(new Date(), 1);

    const result = isBefore(pausedAt, yesterday);
    return result;
  };

  const getPausedOnboardingContent = () => {
    if (onboardingState.data?.paused_at === null) return null;

    return isAfter24Hours() ? (
      <>
        Hey there! We noticed you didn’t finish up your onboarding yet. Click below to pick up where
        you left off!
        <br />
        <br />
        If you click Later, Onboarding will pick up where you left off in 24 hours. (You may have to
        refresh.)
      </>
    ) : (
      <>
        Are you ready to launch RechargeSMS? You're almost there! Click{' '}
        <a style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onContinueClick()}>
          Continue
        </a>{' '}
        and complete your onboarding.
        <br />
        <br />
        If you need some help, our team is here for you.{' '}
        <a
          target="_blank"
          style={{ textDecoration: 'underline', color: '#191d48' }}
          href="https://support.rechargepayments.com/hc/en-us/requests/new"
        >
          Contact support
        </a>
      </>
    );
  };

  const getPausedOnboardingStepTitle = () => {
    if (onboardingState.data?.paused_at === null) return null;
    if (onboardingState.isSidebarCollapsed) return 'Continue onboarding!';

    return isAfter24Hours() ? 'Continue onboarding!' : 'Almost there!';
  };

  if (!onboardingState.isModalOpen) {
    return (
      <SidebarContinueOnboarding
        collapsed={onboardingState.isSidebarCollapsed}
        onCollapsedClick={onCollapsedClick}
        onboardingId={onboardingState.data.id}
        flowTitle={FLOW_TITLE[currentOnboardingFlow.flow]}
        stepTitle={getPausedOnboardingStepTitle()}
        content={getPausedOnboardingContent()}
        welcomeProgressValue={getWelcomeProgressValue()}
        featureProgressValue={getFeatureProgressValue()}
        productProgressValue={getProductProgressValue()}
        updateOnboarding={updateOnboarding}
      />
    );
  }

  return SIDEBAR_FLOW_MAP[currentOnboardingFlow.flow];
}
