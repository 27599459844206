import React from 'react';
import toast from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';

import { Page } from '@components/Layout/Page';
import { useFetch } from '@hooks/useFetch';
import axios from '@lib/axios';
import { CancellationReason } from '@typings/CancellationReason';

import { Form } from './Form';

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useFetch<CancellationReason>(`/sms/cancellation_reasons/${id}`);

  async function handleSubmit(values: CancellationReason) {
    await axios.put(`/sms/cancellation_reasons/${id}`, values);
    toast.success('Cancellation reason successfully updated');
    navigate('/sms/cancellation_reasons');
  }

  return (
    <Page loading={loading} headerDescription="Update cancellation reason data">
      <Form defaultValues={data} onSubmit={handleSubmit} />
    </Page>
  );
}

export default Edit;
