import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';

import { MetricContainer } from './MetricContainer';
import { useFetchMetric } from './useFetchMetric';

export interface MetricBaseProps {
  title: string;
  url: string;
  type?: string;
  bodyStyle?: CSSProperties;
  children?: any;
}

export function MetricBase(props: MetricBaseProps) {
  const { title, url, bodyStyle, children } = props;
  const { loading, data } = useFetchMetric(url);

  return (
    <MetricContainer title={title} loading={loading} bodyStyle={bodyStyle}>
      {!loading && children(data)}
    </MetricContainer>
  );
}

export const MetricText = styled.div`
  font-size: 60px;
  margin: 0;
  padding: 8px 0;
`;
