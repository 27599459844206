import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

import axios from '@lib/axios';
import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import { ProductVariant } from '@typings/ProductVariant';

import ProductSelectorBody from './ProductSelectorBody';
import Header from '../shared/Header';

export const ProductSelector = ({ closeHandler }: { closeHandler: () => void }) => {
  const {
    currentWorkflow,
    handleChangeWorkflow,
    selectedProducts,
    setSelectedProducts,
    setSavedProducts,
  } = useWorkflowsContext();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductVariant[]>([]);

  useEffect(() => {
    getProducts();
    setSelectedProducts(currentWorkflow?.productVariants);
  }, []);

  if (!currentWorkflow) return null;

  async function getProducts(search = '') {
    setProducts([]);
    setLoading(true);
    const productsPath =
      currentWorkflow?.templateType === 'replenishment'
        ? '/replenishment/products'
        : '/sms/product_variants';

    const { data } = await axios.get(`${productsPath}/available_for_workflows`, {
      params: {
        search,
        workflow_id: currentWorkflow?.id,
      },
    });

    setProducts(data?.data);
    setLoading(false);
  }

  const handleSearch = debounce((e) => {
    getProducts(e.target.value);
  }, 1000);

  function handleSelectProduct(product) {
    setSelectedProducts((oldProducts) => {
      const productIndex = oldProducts.findIndex(({ id }) => id === product.id);

      const newProducts = [...oldProducts];

      if (productIndex > -1) newProducts.splice(productIndex, 1);
      else newProducts.push(product);

      return newProducts;
    });
  }

  function handleSave(productVariants = selectedProducts) {
    setSavedProducts(productVariants);
    handleChangeWorkflow({ productVariants });
    closeHandler();
  }

  const selectedProductsIds = selectedProducts?.map((product) => product.id);
  const availableProducts = products.filter(
    (product) => !selectedProductsIds?.includes(product.id)
  );
  return (
    <>
      <Header onClose={closeHandler}>
        <div>
          <h4>
            Select products for this{' '}
            {currentWorkflow?.templateTag === 'replenishment_sms_email' ? 'SMS + Email' : 'SMS'}{' '}
            workflow
          </h4>
        </div>
      </Header>
      <ProductSelectorBody
        loading={loading}
        availableProducts={availableProducts}
        selectedProducts={selectedProducts}
        handleSelectProduct={handleSelectProduct}
        handleSearch={handleSearch}
        handleSave={handleSave}
      />
    </>
  );
};
