import React from 'react';
import styled from '@emotion/styled';

interface HowToCardProps {
  title: String;
  content: React.ReactElement;
}

export function HowToCard(props: HowToCardProps) {
  const { title, content } = props;

  return (
    <Container>
      <Title>{title}</Title>
      <Text>{content}</Text>
    </Container>
  );
}

const Container = styled.form`
  border-radius: 8px;
  padding: 48px 32px 0px 32px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  margin-bottom: 2rem;
  position: relative;
  height: 100%;
`;

const Title = styled.h3`
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  border-radius: 50px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  position: absolute;
  padding: 10px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  text-align: center;
`;

const Text = styled.p`
  font-size: 14px;
  letter-spacing: 0.47px;
  color: #000000;
  text-align: left;
`;
