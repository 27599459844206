import { Paginator } from '@components/Paginator';
import { Table } from '@components/Table/Table';
import styled from '@emotion/styled';
import { useFetch } from '@hooks/useFetch';
import { Event } from '@typings/Event';
import { Page } from '@typings/Page';
import { formatString } from '@utils/date';
import React, { useState } from 'react';

interface EventsProps {
  customerId: string;
}

export function Events(props: EventsProps) {
  const { customerId } = props;
  const [page, setPage] = useState(1);
  const { data, loading } = useFetch<Page<Event>>(`/sms/recharge/customers/${customerId}/events`, {
    params: {
      page,
      limit: 20,
    },
  });

  const tableHeaders = [
    {
      label: 'Action',
      width: '30%',
    },
    {
      label: 'Interacted at',
      width: '20%',
    },
    {
      label: 'Description',
    },
  ];

  function tableRow(event: Event) {
    const formatAction = (action) => action.replaceAll('_', ' ');

    const data = [
      <div style={{ textTransform: 'capitalize' }}>{formatAction(event?.action)}</div>,
      formatString(event?.createdAt, 'dd MMM hh:mm'),
      event?.description,
    ];

    return { data };
  }

  const tableRows = data?.data?.map(tableRow);
  return (
    <>
      <h4>Interactions</h4>
      <CustomTable loading={loading} headers={tableHeaders} rows={tableRows} />
      <PaginatorContainer>
        <Paginator paginationInfo={data?.paginationInfo} onPageChange={(page) => setPage(page)} />
      </PaginatorContainer>
    </>
  );
}

const CustomTable = styled(Table)`
  height: auto;

  tbody tr {
    height: 50px;
  }
`;

const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
`;
