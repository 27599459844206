import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import ReactDOMServer from 'react-dom/server';
import { MixedTags } from '@yaireo/tagify/dist/react.tagify';
import '@yaireo/tagify/dist/tagify.css';

import { convertToTagifyPattern, parseOutput } from '@lib/Variablefy';

import { useCurrentShop } from '@hooks/useCurrentShop';
import Variable from './Variable';
import DropdownItem from './DropdownItem';

export interface VariableInputHandle {
  addVariable(variable: any): void;
}

interface VariablesInputProps {
  value: string;
  variables: string[];
  lockedVariables?: string[];
  addStartLockedVariable?: boolean;
  addEndLockedVariable?: boolean;
  onChange?(output: string): void;
}

const lockedVariablesMap = {
  start: 'powered_by_recharge_sms',
  end: 'reply_stop_to_opt_out',
};

const VariablesInput = forwardRef<VariableInputHandle, VariablesInputProps>(
  (
    {
      value,
      variables,
      lockedVariables = [],
      addStartLockedVariable = false,
      addEndLockedVariable = false,
      onChange,
    },
    ref
  ) => {
    const tagifyRef = useRef<any>();
    const shop = useCurrentShop();

    const settings = {
      mode: 'mix',
      pattern: /@/,
      whitelist: [...variables, ...lockedVariables],
      dropdown: {
        enabled: 1,
        position: 'text',
        highlightFirst: true,
      },
      backspace: false,
      editTags: false,
      enforceWhitelist: true,
      duplicates: true,
      trim: true,
      templates: {
        tag: (variable) =>
          ReactDOMServer.renderToStaticMarkup(
            <Variable
              variable={variable}
              lockedVariables={lockedVariables}
              removeRcsmsBranding={shop.removeRcsmsBranding}
            />
          ),
        dropdownItem: (variable) =>
          ReactDOMServer.renderToStaticMarkup(
            <DropdownItem variable={variable} lockedVariables={lockedVariables} />
          ),
      },
    };

    useEffect(() => {
      addLockedVariables();
    }, []);

    useImperativeHandle(ref, () => ({
      addVariable,
    }));

    const addLockedVariables = () => {
      if (addStartLockedVariable) addLockedVariable(lockedVariablesMap.start);
      if (addEndLockedVariable) addLockedVariable(lockedVariablesMap.end, false);
    };

    const addLockedVariable = (variable, start = true) => {
      const variableAlreadyExists = tagifyRef.current.isTagDuplicate(variable) > 0;

      if (!variableAlreadyExists) {
        const inputElement = tagifyRef.current.DOM.input;
        const childCount = inputElement.childNodes.length;
        const position = start ? 0 : childCount;

        const range = new Range();
        range.setStart(inputElement, position);
        range.setEnd(inputElement, position);

        const tagElement = tagifyRef.current.createTagElem({ value: variable });

        tagifyRef.current.injectAtCaret(tagElement, range);
      }
    };

    const addVariable = (variable) => {
      const { input } = tagifyRef.current.DOM;
      // avoid crashing when clicking on the buttonm tags before clicking on the input
      if (!tagifyRef.current.state.selection) {
        input.focus();
        tagifyRef.current.setStateSelection();
      }
      setTimeout(() => {
        const tagElm = tagifyRef.current.createTagElem({ value: variable });
        tagifyRef.current.injectAtCaret(tagElm);
        const elm = tagifyRef.current.insertAfterTag(tagElm); //  <- adds space after the tag
        tagifyRef.current.placeCaretAfterNode(elm);
      });
    };

    const handleChange = (e) => {
      const { value } = e.detail;
      const parsedOutput = parseOutput(value);

      onChange(parsedOutput);
    };

    return (
      <MixedTags
        InputMode="textarea"
        tagifyRef={tagifyRef}
        settings={settings}
        value={convertToTagifyPattern(value, lockedVariables)}
        onChange={handleChange}
        className="my-2"
      />
    );
  }
);

export default VariablesInput;
