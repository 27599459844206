import { useEffect } from 'react';
import {
  ACTIVATION_FLOW,
  FEATURE_FLOW,
  getCurrentFlowFromStep,
  PRODUCT_FLOW,
  WELCOME_FLOW,
} from '@components/Onboarding/v2/onboarding';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@redux/hooks';
import './onboarding.scss';

export const OnboardingRouter = () => {
  const onboardingState = useAppSelector((state) => state.onboarding);

  const navigate = useNavigate();

  useEffect(() => {
    const currentOnboardingFlow = getCurrentFlowFromStep(onboardingState.data?.step);
    if (!getCurrentFlowFromStep(onboardingState.data?.step) || !onboardingState.isDisplay) {
      return;
    }
    if (currentOnboardingFlow.flow === WELCOME_FLOW) {
      navigate('/sms/onboarding/welcome');
    }

    if (currentOnboardingFlow.flow === FEATURE_FLOW) {
      navigate('/sms/onboarding/features');
    }

    if (currentOnboardingFlow.flow === PRODUCT_FLOW) {
      navigate('/sms/onboarding/product');
    }

    if (currentOnboardingFlow.flow === ACTIVATION_FLOW) {
      navigate('/sms/onboarding/activation');
    }
  }, [onboardingState]);

  return null;
};
