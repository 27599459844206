import zendeskLogo from './images/zendesk.png';
import gorgiasLogo from './images/gorgias.png';
import intercomLogo from './images/intercom.png';
import kustomerLogo from './images/kustomer.png';
import reamazeLogo from './images/reamaze.png';
import freshdeskLogo from './images/freshdesk.png';

export const integrations = [
  {
    title: 'Zendesk',
    description:
      'Zendesk is a web-based help desk support tool that makes it simple to provide great customer support',
    imageUrl: zendeskLogo,
    hrefUrl: 'https://support.zendesk.com/hc/en-us/articles/226022787-Generating-a-new-API-token-',
  },
  {
    title: 'Gorgias',
    description: `Helpdesk designed for Shopify stores. Provide multichannel customer service from a
                  single app. Gain productivity with automated responses to common requests.`,
    imageUrl: gorgiasLogo,
    hrefUrl:
      'https://docs.gorgias.com/developers/building-your-gorgias-integration#:~:text=They%20can%20be%20found%20here,%3E%20Settings%20%2D%3E%20Rest%20API',
  },
  {
    title: 'Intercom',
    description: `Intercom is a Conversational Relationship Platform that provides Customer Support through messaging and email.`,
    imageUrl: intercomLogo,
    hrefUrl:
      'https://developers.intercom.com/building-apps/docs/authentication-types#section-how-to-get-your-access-token',
  },
  {
    title: 'Kustomer',
    description: `Kustomer is a powerful omnichannel customer service solution.`,
    imageUrl: kustomerLogo,
    hrefUrl: 'https://support.kustomer.com/api-keys-SJs5YTIWX',
  },
  {
    title: 'Reamaze',
    description: `Reamaze is an all-in-one helpdesk for chat, social media, SMS, and email right from
                  your site or app.`,
    imageUrl: reamazeLogo,
    hrefUrl: 'https://www.reamaze.com/api',
  },
  {
    title: 'Freshdesk',
    description: `Freshdesk is an online helpdesk software with multiple channel customer service to
    support customers across email, phone, chat, web, Twitter and more.`,
    imageUrl: freshdeskLogo,
    hrefUrl:
      'https://support.freshdesk.com/support/solutions/articles/215517-how-to-find-your-api-key',
  },
];
