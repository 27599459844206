import { combineReducers } from 'redux';
import { onboardingSlice } from './reducer/onboardingSlice';
import { navigationSlice } from './reducer/navigationSlice';
import { customerSupportSlice } from './reducer/customerSupportSlice';
import { settingSlice } from './reducer/settingSlice';

export const rootReducer = combineReducers({
  onboarding: onboardingSlice.reducer,
  navigation: navigationSlice.reducer,
  customerSupport: customerSupportSlice.reducer,
  setting: settingSlice.reducer,
});
