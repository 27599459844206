import { Action } from '@typings/TemplateMessage';
import { createDelayOrder } from './createDelayOrder';

export default function createAction(selectedActions: Action[], actionToCreate: Action) {
  const selectedCreator = creators[actionToCreate.type] || createActionDefault;
  return selectedCreator(selectedActions, actionToCreate);
}

const creators: Record<string, ActionCreator> = {
  delay_order: createDelayOrder,
};

const createActionDefault: ActionCreator = (selectedActions: Action[], actionToCreate: Action) =>
  actionToCreate;

export interface ActionCreator {
  (selectedActions: Action[], actionToCreate: Action): Action;
}
