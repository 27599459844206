import styled from '@emotion/styled';
import Modal from 'react-bootstrap/Modal';
import { useCurrentShop } from '@hooks/useCurrentShop';
import launchCompleteBanner from '@assets/images/launch-complete-banner.png';
import congratulationsIcon from '@assets/images/launch-complete-icon.png';
import { Row } from 'react-bootstrap';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LaunchButtonCol,
  MoreOptionsCol,
  LaunchButton,
  MoreOptionsText,
  BottomContainer,
  TopContainer,
  Title,
  Description,
} from '@components/Onboarding/v2/components/styling/ActivationModalStyling';

const CompletedOnboardingModal = () => {
  const navigate = useNavigate();
  const currentShop = useCurrentShop();

  const sciCheckout = currentShop.checkoutPlatform !== 'recharge';

  return (
    <Modal show aria-labelledby="contained-modal-title-vcenter" centered>
      <BackgroundImage src={launchCompleteBanner} />
      <TopContainer center>
        <CongratulationsIcon src={congratulationsIcon} />
        <Title>Congratulations!</Title>
        <Description>
          Your messaging service is officially up and running! If you need to make any changes you
          can always manage your opt-in by clicking on the status indicator in the bottom lefthand
          corner.
        </Description>
      </TopContainer>

      <BottomContainer>
        {sciCheckout ? (
          <Row>
            <MoreOptionsCol sm={6}>
              <MoreOptionsText onClick={() => navigate('/?open=cart-widget-opt-in')}>
                Don't like your widget?
              </MoreOptionsText>
            </MoreOptionsCol>
            <LaunchButtonCol sm={6}>
              <LaunchButton onClick={() => navigate('/')}>Take me home</LaunchButton>
            </LaunchButtonCol>
          </Row>
        ) : (
          <Row>
            <CenterLaunchButtonCol sm={12}>
              <LaunchButton onClick={() => navigate('/')}>Take me home</LaunchButton>
            </CenterLaunchButtonCol>
          </Row>
        )}
      </BottomContainer>
    </Modal>
  );
};

export default CompletedOnboardingModal;

const CongratulationsIcon = styled.img`
  position: absolute;
  width: 58px;
  height: 58px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: -70px;
`;

const BackgroundImage = styled.img`
  width: 100%;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  height: 100%;
`;

const CenterLaunchButtonCol = styled(LaunchButtonCol)`
  justify-content: center;
`;
