import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';

import colors from 'styles/theme/colors';

interface LoadingProps {
  className?: string;
  color?: string;
  style?: CSSProperties;
}

export function Loading(props: LoadingProps) {
  const { color = colors.primary, ...rest } = props;

  return (
    <Spinner color={color} {...rest}>
      <div />
      <div />
      <div />
      <div />
    </Spinner>
  );
}

export function CenteredLoading(props) {
  return <Loading style={{ height: '100%', alignItems: 'center' }} {...props} />;
}

const Spinner = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid ${(props) => props.color};
    border-radius: 50%;
    animation: spin-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;

    &:nth-of-type(1) {
      animation-delay: -0.45s;
    }
    &:nth-of-type(2) {
      animation-delay: -0.3s;
    }
    &:nth-of-type(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loading;
