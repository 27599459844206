import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ActionProps {
  color: string;
  href?: string;
  onClick?(): void;
  icon: IconDefinition;
  text: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

export function Action(props: ActionProps) {
  const { href = '#', onClick, icon, text, disabled = false } = props;

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    if (disabled) return;
    onClick?.();
  };

  const renderTooltip = (props) => (
    <Tooltip id={`button-tooltip-${text}`} {...props}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" overlay={renderTooltip}>
      <Container {...props} to={href} onClick={handleOnClick}>
        <Icon icon={icon} />
      </Container>
    </OverlayTrigger>
  );
}

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;

const Container = styled(Link)<{ color: string }>`
  align-items: center;
  background: white;
  border-radius: 50%;
  border: 1px solid #f4f4f4;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
  width: 36px;

  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease-in-out;
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${Icon} {
    color: ${(props) => props.color};
  }
`;
