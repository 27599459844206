import { CustomButton } from '@components/Button/CustomButton';
import { ChatAsCustomer } from '@components/Chat/ChatAsCustomer';
import { Card } from '@components/Layout/Card';
import styled from '@emotion/styled';
import { RechargeCustomer } from '@typings/RechargeCustomer';
import React from 'react';

interface DevAreaProps {
  rechargeCustomer: RechargeCustomer;
}

export function DevArea(props: DevAreaProps) {
  const { rechargeCustomer } = props;

  function handleOpenSupportChat() {
    const popupReference = window.open(
      rechargeCustomer.chatLink,
      'popup',
      'location=off,width=550,height=650'
    );
    if (popupReference) {
      popupReference.focus();
    } else {
      alert(
        'The messaging popup was blocked. Make sure to select to always allow popups and refresh this page'
      );
    }
  }

  return (
    <CustomCard>
      <h4>Dev Area</h4>
      <ChatAsCustomer rechargeCustomer={rechargeCustomer} />
      <SupportButton onClick={handleOpenSupportChat}>Open Support Chat</SupportButton>
    </CustomCard>
  );
}

const CustomCard = styled(Card)`
  overflow: initial;
  padding: 1rem;
  margin-top: 1rem;
`;

const SupportButton = styled(CustomButton)`
  margin-top: 1rem;
`;
