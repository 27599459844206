import React, { useState, useRef } from 'react';
import { Collapse } from 'react-bootstrap';
import toast from 'react-hot-toast';

import axios from '@lib/axios';
import { ProductImage } from '@components/Image/ProductImage';
import { ConfirmationModal, ModalHandles } from '@components/Modal/ConfirmationModal';

import { ProductVariant } from './ProductVariant';

export function MultipleVariants(props) {
  const {
    shopifyProduct,
    productVariants,
    handleSearch,
    replenishment,
    ignoreDisabled = false,
  } = props;
  const firstThreeProductVariants = productVariants?.slice(0, 2);
  const [collapse, setCollapse] = useState(true);
  const modalRef = useRef<ModalHandles>(null);

  function handleToggleCollapse() {
    setCollapse((oldCollapse) => !oldCollapse);
  }

  function handleToggleModal() {
    modalRef?.current?.toggleModal();
  }

  async function handleIgnoreAll() {
    const ids = productVariants.map((variant) => variant.id);

    function onSuccess() {
      handleSearch();

      return 'Product variants successfully ignored!';
    }

    toast.promise(
      axios.post('/sms/product_variants/ignore_multiple', {
        product_variant: {
          ids,
        },
      }),
      {
        loading: 'Ignoring...',
        success: onSuccess,
        error: 'Something went wrong. Please try again later!',
      }
    );
  }

  return (
    <div className="product mt-3">
      <div className="product__header">
        <div className="row">
          <div className="col-4 product__info">
            <ProductImage
              className="product-image mx-3"
              src={shopifyProduct?.imageUrl}
              width={45}
              height={45}
            />

            <div className="d-flex flex-column justify-content-center" style={{ height: 118 }}>
              <h5 className="product__title">{shopifyProduct?.title || 'Product Not Found'}</h5>

              {shopifyProduct?.externalId && (
                <p className="text-muted mb-0">Product ID: #{shopifyProduct?.externalId}</p>
              )}
            </div>
          </div>
          <div className="btn-collapse mr-4 text-right col">
            <div className="variants-preview mt-4">
              {firstThreeProductVariants.map((productVariant) => (
                <ProductImage
                  key={productVariant.id}
                  className="product-image ml-2"
                  width={33}
                  height={33}
                  src={productVariant?.shopifyProduct?.imageUrl}
                />
              ))}
            </div>
            <div className="mt-3">
              {!replenishment && productVariants?.length > 1 && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm px-3 mr-2"
                  onClick={handleToggleModal}
                >
                  Ignore All
                </button>
              )}
              <button
                className="btn btn-secondary btn-sm px-3"
                type="button"
                onClick={handleToggleCollapse}
              >
                {collapse ? 'Hide all variants' : 'View all variants'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Collapse in={collapse}>
        <div className="product-variants">
          <table className="table table-borderless mb-0" cellSpacing="0">
            <tbody>
              {productVariants.map((productVariant) => (
                <ProductVariant
                  key={productVariant.id}
                  productVariant={productVariant}
                  handleSearch={handleSearch}
                  replenishment={replenishment}
                  ignoreDisabled={ignoreDisabled}
                />
              ))}
            </tbody>
          </table>
        </div>
      </Collapse>

      <ConfirmationModal
        ref={modalRef}
        title="Confirmation"
        description={`Are you sure you want to ignore ${shopifyProduct.title} and all its variants?`}
        onConfirm={handleIgnoreAll}
      />
    </div>
  );
}
