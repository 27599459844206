import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PauseOnboardingButton from '@components/Onboarding/v2/components/PauseOnboardingButton';
import styled from '@emotion/styled';
import { CustomButton } from '@components/Button/CustomButton';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { updateCustomize } from '@api/sms';

interface PhoneErrorNotificationProps {
  setErrorOveride: (boolean) => void;
}

export const PhoneErrorModal = ({ setErrorOveride }: PhoneErrorNotificationProps) => {
  const [wentToShopifySettings, setWentToShopifySettings] = useState<boolean>(false);
  const [disabledIveDoneThis, setDisabledIveDoneThis] = useState<boolean>(false);
  const shop = useCurrentShop();

  function clickedGoToShopifySettings() {
    setWentToShopifySettings(true);
  }

  async function clickedIveDoneThis() {
    setDisabledIveDoneThis(true);
    await updateCustomize({ dismissed_phone_error: new Date().toUTCString() });
    setErrorOveride(false);
  }

  return (
    <div className="onboarding__root">
      <CustomModal show backdrop="static" centered>
        <CustomModalHeader>
          <CustomModalTitle>How to collect customer phone numbers</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <IframeContainer>
            <CustomIframe
              src="https://www.loom.com/embed/9598e32357474781a1672b7ce60d83ee"
              title="How to collect customer phone numbers"
              frameBorder="0"
              allowFullScreen
            />
          </IframeContainer>
          <p className="title">Follow these steps:</p>
          <ul>
            <li>
              Open up{' '}
              <strong>
                <a
                  onClick={clickedGoToShopifySettings}
                  target="_blank"
                  href={`https://${shop.shopifyDomain}/admin/settings/checkout`}
                  rel="noreferrer"
                >
                  Shopify Checkout settings
                </a>
              </strong>
            </li>
            <li>
              Scroll down to <strong>Customer Information</strong>
            </li>
            <li>
              Locate the field titled <strong>Shipping Adress Phone Number</strong>
            </li>
            <li>
              Change the value from <strong>Don’t Include to Optional OR Required</strong>
            </li>
          </ul>
        </CustomModalBody>
        <CustomModalFooter>
          {(!wentToShopifySettings && (
            <GoToButton
              onClick={clickedGoToShopifySettings}
              target="_blank"
              href={`https://${shop.shopifyDomain}/admin/settings/checkout`}
              rel="noreferrer"
            >
              Go to Shopify Settings
            </GoToButton>
          )) || (
            <GoToButton disabled={disabledIveDoneThis} onClick={clickedIveDoneThis}>
              I've done this
            </GoToButton>
          )}
        </CustomModalFooter>
      </CustomModal>
      <PauseOnboardingButton />
    </div>
  );
};

const CustomModalTitle = styled(Modal.Title)`
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #191d48;
`;

const CustomModalHeader = styled(Modal.Header)`
  border-bottom: 0px;
  padding: 20px 28px 0px 28px;
  margin-bottom: 15px;
`;

const CustomIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CustomModalBody = styled(Modal.Body)`
  padding: 0px 28px 0px 28px;
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-top: 20px;
    margin-bottom: 8px;
  }
`;

const IframeContainer = styled(Modal.Body)`
  padding: 0px;
  box-shadow: 0px 0px 10px 2px rgb(25 29 72 / 20%);
  position: relative;
  padding-bottom: 60.70826306913997%;
  height: 0;
  background-color: #191d48;
`;

const CustomModalFooter = styled(Modal.Footer)`
  border-top: 0px;
  justify-content: flex-start;
  padding: 0px;
  border-top: 1px solid #f4f4f4;
  padding: 20px 28px;

  .prompt {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.47px;
    color: #000000;
  }
`;

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 548px;
  }

  .modal-content {
    width: 600px;
    padding: 0px;
    box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
    border-radius: 8px;
  }
`;

export const GoToButton = styled(CustomButton)`
  border-radius: 6px;
  border: none;
  font-family: Greycliff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  padding: 13px 20px 13px 20px;
  background: #191d48;
  width: 100%;
  margin: 0;
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #d7d7d7;
    color: #a7a7a7 !important;
  }
`;
