import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

import clearIcon from './clear.svg';

interface SearchBarProps {
  handleSearch(value): void;
  inputProps?: React.HTMLAttributes<HTMLInputElement>;
}

export function SearchBar(props: SearchBarProps) {
  const { handleSearch, inputProps, ...rest } = props;
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();

    handleSearch(value);
  }

  function handleClear() {
    setValue('');
    inputRef.current.focus();
  }

  return (
    <Container focused={focused} onSubmit={handleSubmit} {...rest}>
      <Input
        ref={inputRef}
        value={value}
        onChange={handleChange}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        {...inputProps}
      />
      {value && <ClearIcon onClick={handleClear} src={clearIcon} />}
      <IconContainer type="submit">
        <FontAwesomeIcon color="white" icon={faSearch} size="lg" />
      </IconContainer>
    </Container>
  );
}

const Container = styled.form<{ focused: boolean }>`
  display: flex;
  align-items: center;
  background: #f4f4f6;
  border-radius: 16px;
  width: 100%;
  height: 40px;
  overflow: hidden;
  flex: 1;

  border: ${(props) => props.focused && '1px solid #3901f1'};
`;

const Input = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 12px;
  padding: 2px 15px;
  font-size: 14px;
  line-height: 18px;
  color: #191d48;

  &::placeholder {
    color: #6b6b6b;
  }
`;

const IconContainer = styled.button`
  all: unset;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2d2d2d;
  cursor: pointer;
  width: 60px;
`;

const ClearIcon = styled.img`
  padding: 0 10px;
  cursor: pointer;
`;
