import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import axios from '@lib/axios/axios';
import { ToggleAlt } from '@components/Input';

import { useCurrentShop } from '@hooks/useCurrentShop';
import { useBaseAppContext } from '@components/BaseAppContext';

import { ENABLE_SMS_TEST_SEGMENT_NAME } from '@components/Onboarding/v2/onboarding';

interface NotificationCenterProps {
  updateStatus: () => void;
  shopActive: boolean;
  setShopActive: () => void;
}

export default function NotificationCenter({
  updateStatus,
  shopActive,
  setShopActive,
}: NotificationCenterProps) {
  const [testSegment, setTestSegment] = useState(null);
  const [testSegmentProcessing, setTestSegmentProcessing] = useState<boolean>(false);
  const [cartWidgetProcessing, setCartWidgetProcessing] = useState<boolean>(false);
  const [checkoutWidgetProcessing, setCheckoutWidgetProcessing] = useState<boolean>(false);
  const [toggleAllSubsProcessing, setToggleAllSubsProcessing] = useState<boolean>(false);

  const {
    cartWidgetConfig,
    updateCartWidgetConfig,
    allSubscribersChecked,
    toggleAllSubscribers,
    checkoutScriptGeneralAttribute,
    updateRechargeGeneralAttribute,
  } = useBaseAppContext();

  const shop = useCurrentShop();
  const sciCheckout = shop.checkoutPlatform !== 'recharge';

  function toggleCartWidget() {
    setCartWidgetProcessing(true);
    updateCartWidgetConfig({
      enabled: !cartWidgetConfig.enabled,
    });
  }

  async function getTestSegment() {
    const segments = await getSegmentsByName(ENABLE_SMS_TEST_SEGMENT_NAME);
    if (segments && segments.length) {
      setTestSegment(segments[0]);
    }
    setTestSegmentProcessing(false);
    updateStatus();
  }

  async function getSegmentsByName(name) {
    const response = await axios.get(`/sms/segments`, {
      params: {
        search: name,
      },
    });
    if (response.status === 200) return response.data.data;

    return [];
  }

  function toggleAllSubscribersHandler() {
    setToggleAllSubsProcessing(true);
    toggleAllSubscribers();
  }

  useEffect(() => {
    setCheckoutWidgetProcessing(false);
  }, [checkoutScriptGeneralAttribute]);

  useEffect(() => {
    setCartWidgetProcessing(false);
  }, [cartWidgetConfig]);

  useEffect(() => {
    setToggleAllSubsProcessing(false);
  }, [toggleAllSubscribers]);

  function toggleCheckoutWiget(e) {
    e.preventDefault();
    setCheckoutWidgetProcessing(true);
    updateRechargeGeneralAttribute(!checkoutScriptGeneralAttribute.enabled);
  }

  async function toggleTestSegment() {
    setTestSegmentProcessing(true);
    const formData = {
      segment: {
        active: !testSegment.active,
      },
    };

    await axios.put(`/sms/segments/${testSegment.id}.json`, formData);
    getTestSegment();
  }

  async function toggleActivation(e) {
    e.preventDefault();
    await axios.post('/sms/settings/shop_active_toggle');
    setShopActive(!shopActive);
  }

  useEffect(() => {
    getTestSegment();
  }, []);

  return (
    <>
      <SendingContainer>
        <SectionTitle>Sending to</SectionTitle>
        {testSegment && (
          <ToggleAlt
            checked={testSegment?.active || false}
            onClick={toggleTestSegment}
            disabled={testSegmentProcessing}
            label="Test Segment"
          >
            <div>
              Manage recipients in <CustomLink to="/sms/segments">Segments</CustomLink>
            </div>
          </ToggleAlt>
        )}
        {(sciCheckout && (
          <ToggleAlt
            checked={cartWidgetConfig?.enabled || false}
            onClick={toggleCartWidget}
            disabled={cartWidgetProcessing}
            label="Future Subscribers"
          >
            <div>
              Customize Opt-In on <CustomLink to="/sms?open=cart-widget-opt-in">My Shop</CustomLink>
            </div>
          </ToggleAlt>
        )) || (
          <ToggleAlt
            checked={checkoutScriptGeneralAttribute?.enabled || false}
            onClick={toggleCheckoutWiget}
            disabled={checkoutWidgetProcessing}
            label="Future Subscribers"
          />
        )}
        <ToggleAlt
          checked={allSubscribersChecked}
          onClick={toggleAllSubscribersHandler}
          disabled={toggleAllSubsProcessing}
          label="Current Subscribers"
        />
      </SendingContainer>
      <ServiceContainer>
        <SectionTitle>RechargeSMS Service</SectionTitle>
        <SectionSubtitle>Turning this off halts all messages.</SectionSubtitle>
        <ToggleAlt checked={shopActive || false} onClick={toggleActivation} label="RechargeSMS" />
      </ServiceContainer>
    </>
  );
}

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 8px;
`;

const SectionSubtitle = styled.div`
  font-size: 12px;
  color: rgba(107, 107, 107, 1);
  margin-bottom: 8px;
  margin-top: -8px;
`;

const SendingContainer = styled.div`
  background-color: #fff;
  padding: 16px;
  padding-top: 0;
  margin-top: 16px;
`;

const ServiceContainer = styled.div`
  background-color: #fafafa;
  padding: 16px;
`;

const CustomLink = styled(Link)`
  color: rgba(107, 107, 107, 1);
  text-decoration: underline;
`;
