import { CustomButton } from '@components/Button/CustomButton';
import { ProductImage } from '@components/Image/ProductImage';
import { Text } from '@components/Typography/Text';
import styled from '@emotion/styled';
import axios from '@lib/axios';
import { CollectionVariant } from '@typings/Collection';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import date from '@utils/date';

interface CollectionVariantItemProps {
  collectionVariant: CollectionVariant;
  onChange?(): void;
}

export const CollectionVariantItem = (props: CollectionVariantItemProps) => {
  const { collectionVariant, onChange } = props;
  const [loading, setLoading] = useState(false);

  async function toggleIgnored() {
    try {
      setLoading(true);
      await axios.post(`/sms/collection_variants/${collectionVariant.id}/toggle_ignored`);
      onChange?.();
    } finally {
      setLoading(false);
    }
  }

  return (
    <ItemRow ignored={collectionVariant.ignored}>
      <ItemCol>
        <ProductInfoContainer>
          <StyledThumbnail src={collectionVariant.productVariant?.imageUrl} alt="product image" />
          <ProductDetails>
            <Text size="small" weight="bold">
              {collectionVariant.productVariant?.title || 'Product not imported'}
              {collectionVariant.ignored && ' (Ignored)'}
            </Text>
            <Text size="xSmall">Product ID: #{collectionVariant.productExternalId}</Text>
            <Text size="xSmall">Variant ID: #{collectionVariant.productVariantExternalId}</Text>
          </ProductDetails>
        </ProductInfoContainer>
      </ItemCol>
      <ItemCol sm={1}>{collectionVariant.productVariant?.priceText}</ItemCol>
      <ItemCol sm={2}>
        {collectionVariant.productVariant &&
          date.formatString(collectionVariant.productVariant?.createdAt)}
      </ItemCol>
      <ItemCol sm={2}>
        <IgnoreButton variant="outline-danger" onClick={toggleIgnored} disabled={loading}>
          {collectionVariant.ignored ? 'Unignore' : 'Ignore'}
        </IgnoreButton>
      </ItemCol>
    </ItemRow>
  );
};

interface ItemRowProps {
  ignored: boolean;
}

const ItemRow = styled(Row)<ItemRowProps>`
  background-color: ${(props) => (props.ignored ? 'rgba(237, 83, 78, 0.1)' : 'transparent')};
`;

const ItemCol = styled(Col)`
  align-items: center;
  border-bottom: 1px solid #edebf0;
  display: flex;
  padding: ${(props) => props.theme.spacing.medium};
  &:first-of-type {
    padding-left: 0;
    margin-left: ${(props) => props.theme.spacing.medium};
  }
  &:last-of-type {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding-right: ${(props) => props.theme.spacing.large};
  }
`;

const ProductInfoContainer = styled.div`
  display: flex;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledThumbnail = styled(ProductImage)`
  border-radius: ${(props) => props.theme.radius.small};
  margin-right: ${(props) => props.theme.spacing.medium};
  width: 50px;
`;

const IgnoreButton = styled(CustomButton)`
  width: 106px;
  padding: 7px 0;
`;
