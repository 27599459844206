import { CancelLink } from '@components/Router/CancelLink';
import { ProductVariant } from '@typings/ProductVariant';
import React from 'react';
import { Button, Card, FormControl } from 'react-bootstrap';
import BootstrapForm from 'react-bootstrap/Form';
import { FormProvider, useForm } from 'react-hook-form';

export interface FormValues {
  product_variant: {
    id: number;
    title: string;
    product_swap: boolean;
    one_time_item: boolean;
    ignored: boolean;
  };
}

interface FormProps {
  productVariant: ProductVariant;
  onSubmit(values: FormValues): Promise<void>;
}

export function Form(props: FormProps) {
  const { onSubmit, productVariant } = props;
  const formProps = useForm({
    defaultValues: productVariant,
  });

  const { formState, handleSubmit, register } = formProps;

  const submitLabel = productVariant ? 'Update' : 'Save';

  function internalSubmit(values: ProductVariant) {
    return onSubmit({
      product_variant: {
        id: values.id,
        title: values.title,
        product_swap: values.productSwap,
        one_time_item: values.oneTimeItem,
        ignored: values.ignored,
      },
    });
  }

  return (
    <FormProvider {...formProps}>
      <BootstrapForm onSubmit={handleSubmit(internalSubmit)}>
        <Card>
          <Card.Body>
            <h4>Info</h4>
            <hr />
            <BootstrapForm.Row>
              <BootstrapForm.Group className="col-md-12">
                <BootstrapForm.Label>Name</BootstrapForm.Label>
                <FormControl
                  name="title"
                  defaultValue=""
                  isInvalid={!!formState.errors.title}
                  {...register('title', { required: true })}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  Title is a required field.
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </BootstrapForm.Row>
            <div className="mt-4">
              <h4>Features</h4>
              <hr />
              <BootstrapForm.Row>
                {productVariant.subscription && (
                  <BootstrapForm.Group className="col-md-4">
                    <BootstrapForm.Check
                      id="product-swap-toggle"
                      type="switch"
                      label="Available for Product Swap"
                      defaultChecked={productVariant.productSwap}
                      {...register('productSwap')}
                    />
                    <span className="form-text text-muted">
                      If you've created a product swap group containing this product, your customers
                      will only be able to swap it to items within that group.
                    </span>
                  </BootstrapForm.Group>
                )}
                <div className="form-group col-md-4">
                  <BootstrapForm.Check
                    id="one-time-toggle"
                    type="switch"
                    label="Available for Add One-time item"
                    defaultChecked={productVariant.oneTimeItem}
                    disabled={!productVariant.oneTimeAllowed}
                    {...register('oneTimeItem')}
                  />
                  {!productVariant.oneTimeAllowed && (
                    <span className="form-text text-muted">
                      This product is configured as <strong>Subscription Only</strong> on Recharge
                      and cannot be used as One-time item.
                    </span>
                  )}
                </div>
                <div className="form-group col-md-4">
                  <BootstrapForm.Check
                    id="ignored-toggle"
                    type="switch"
                    label="Ignore variant"
                    defaultChecked={productVariant.ignored}
                    {...register('ignored')}
                  />
                </div>
              </BootstrapForm.Row>
            </div>
          </Card.Body>
          <Card.Footer>
            <div className="form-actions text-right">
              <CancelLink to="/sms/product_variants" />
              <Button type="submit" variant="primary" disabled={formState.isSubmitting}>
                {formState.isSubmitting ? 'Loading..' : submitLabel}
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </BootstrapForm>
    </FormProvider>
  );
}
