import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import { ProductImage } from '@components/Image/ProductImage';

import { ProductVariant as ProductVariantType } from '@typings/ProductVariant';
import { ShopifyProduct } from '@typings/ShopifyProduct';
import { ProductVariant } from './ProductVariant';

interface IgnoredProductProps {
  productVariants: ProductVariantType[];
  shopifyProduct: ShopifyProduct;
  handleSearch(): void;
}

export function IgnoredProduct(props: IgnoredProductProps) {
  const { shopifyProduct, productVariants, handleSearch } = props;
  const firstThreeProductVariants = productVariants?.slice(0, 2);
  const [collapse, setCollapse] = useState(false);

  function handleToggleCollapse() {
    setCollapse((oldCollapse) => !oldCollapse);
  }

  return (
    <div className="product mt-3">
      <div className="product__header">
        <div className="row">
          <div className="col-4 product__info">
            <ProductImage
              className="product-image mx-3"
              src={shopifyProduct.imageUrl}
              width={45}
              height={45}
            />
            <div className="d-flex flex-column justify-content-center" style={{ height: 118 }}>
              <h5 className="product__title">{shopifyProduct.title || 'Product Not Found'}</h5>

              {shopifyProduct.externalId && (
                <p className="text-muted mb-0">Product ID: #{shopifyProduct?.externalId}</p>
              )}
            </div>
          </div>
          <div className="btn-collapse mr-4 text-right col">
            <div className="variants-preview mt-4">
              {firstThreeProductVariants.map((productVariant) => (
                <ProductImage
                  key={productVariant.id}
                  className="rounded shadow-sm product-image ml-2"
                  width={33}
                  height={33}
                  src={productVariant?.shopifyProduct?.imageUrl}
                />
              ))}
            </div>

            <div className="mt-2">
              <div className="badge badge-pill badge-danger align-middle mr-3 mt-2">IGNORED</div>
              <button
                className="btn btn-secondary btn-sm px-3 mt-2 collapsed"
                type="button"
                onClick={handleToggleCollapse}
              >
                {collapse ? 'Hide all variants' : 'View all variants'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Collapse in={collapse}>
        <div className="product-variants">
          <table className="table table-borderless mb-0" cellSpacing="0">
            <tbody>
              {productVariants.map((productVariant) => (
                <ProductVariant
                  key={productVariant.id}
                  productVariant={productVariant}
                  handleSearch={handleSearch}
                />
              ))}
            </tbody>
          </table>
        </div>
      </Collapse>
    </div>
  );
}
