import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';

import { Button } from '@pages/Home/components/Button';
import { Integration, Credentials, Status } from '@typings/Integration';

import {
  FreshdeskForm,
  ZendeskForm,
  GorgiasForm,
  IntercomForm,
  KustomerForm,
  ReamazeForm,
} from './Forms';

const formByService = {
  reamaze: ReamazeForm,
  zendesk: ZendeskForm,
  kustomer: KustomerForm,
  freshdesk: FreshdeskForm,
  gorgias: GorgiasForm,
  intercom: IntercomForm,
};

interface IntegrationFormProps {
  integration: Integration;
  setIntegration: (integration?: Integration) => void;
  handleConnect: (integration: Integration) => void;
}

export function IntegrationForm(props: IntegrationFormProps) {
  const { integration, setIntegration, handleConnect } = props;
  const [connected, setConnected] = useState(false);
  const { handleSubmit, reset } = useFormContext();

  const FormComponent = formByService[integration?.service];

  useEffect(() => {
    setConnected(false);
    reset(integration.credentials);
  }, [integration?.service]);

  function onSubmit(updatedCredentials: Credentials) {
    const credentials = {};
    Object.entries(updatedCredentials).forEach(([key, value]) => {
      credentials[key.replace(`${integration.service}_`, '')] = value;
    });

    const status: Status = 'enabled';
    const updatedIntegration = {
      ...integration,
      credentials,
      status,
    };
    handleConnect(updatedIntegration);
    setConnected(true);
  }

  function handleClose() {
    setConnected(false);
    setIntegration(null);
  }

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <img height={25} src={integration?.image} />

        {connected ? (
          <Button type="button" onClick={handleClose}>
            Close
          </Button>
        ) : (
          <Button type="submit">Confirm</Button>
        )}
      </Header>

      {connected && (
        <ConnectedContainer>
          <p>
            <FontAwesomeIcon icon={faCheckCircle} color="#17CC9D" className="mr-2" size="lg" />
            Integration Successfully connected
          </p>
        </ConnectedContainer>
      )}

      {!connected && integration && <FormComponent credentials={integration.credentials} />}
    </Container>
  );
}

const Container = styled.form`
  border-radius: 8px;
  padding: 16px 24px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  margin-bottom: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConnectedContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    background: #f4f4f6;
    border-radius: 100px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.47px;
    color: #000000;
    margin: 1.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
  }
`;
