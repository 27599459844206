import React from 'react';
import styled from '@emotion/styled';

import backIcon from '@assets/icons/back.svg';

export const Back = (props) => (
  <BackButton {...props}>
    <img alt="Back" src={backIcon} />
  </BackButton>
);

const BackButton = styled.button`
  background: transparent;
  border: none;
  margin-right: 14px;
  transform: translateY(-2px);
`;
