import React from 'react';

import { integrations } from './data';

export function Integrations() {
  return (
    <div className="card-body">
      <h4 className="mb-4 color-dark-2">Customer Support Integrations</h4>

      <p>
        Below are the current customer support integrations we support. Each will guide you on how
        to find your API credentials to use with our service. Email{' '}
        <a href="mailto:support@rechargeapps.com">support@rechargeapps.com</a> if you need
        additional support with your integration.
      </p>

      <div className="mt-2">
        {integrations.map((integration) => (
          <div className="media position-relative mt-3">
            <a
              className="stretched-link align-self-center mr-4"
              target="_blank"
              rel="noreferrer"
              href={integration.hrefUrl}
            >
              <img src={integration.imageUrl} width="64" className="img-fluid" />
            </a>
            <div className="media-body">
              <h5 className="mt-3">{integration.title}</h5>
              <p className="text-muted">{integration.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
