import React from 'react';

import Playground from '@components/Simulator/Playground';
import { Page } from '@components/Layout/Page';

function Simulator() {
  return (
    <Page headerDescription="Interact with the simulator" data-testid="simulator-page">
      <Playground />
    </Page>
  );
}

export default Simulator;
