import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

import logoBlue from '@assets/icons/logo.svg';
import shopifyLogo from '@assets/images/shopify-logo.png';
import syncImage from '@assets/icons/sync.svg';
import FeatureSwitch from '@pages/Settings/Features/shared/FeatureSwitch';
import { ModalText } from '@components/Onboarding/components/ModalText';
import { ModalTitle } from '@components/Onboarding/components/ModalTitle';
import { ButtonGroup } from '@components/Button/ButtonGroup';
import { CustomButton } from '@components/Button/CustomButton';
import { ModalBody } from '@components/Modal/ModalBody';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { useInitialState } from '@hooks/useInitialState';
import axios from '@lib/axios';
import { Feature } from '@typings/Feature';

import { Tag } from './Tag';

interface ShipmentTrackingFeatureSwitchProps {
  feature: Feature;
}

export const ShipmentTrackingFeatureSwitch = (props: ShipmentTrackingFeatureSwitchProps) => {
  const { feature } = props;
  const [support, setSupport] = useState(feature.enabled);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    async function fetchShipmentTrackingSupport() {
      if (!support) {
        const { data } = await axios.get('/sms/shops/support_shipment_tracking');
        setSupport(data.support_shipment_tracking);
      }
      setLoading(false);
    }

    fetchShipmentTrackingSupport();
  }, []);

  return (
    <>
      <FeatureSwitch
        {...props}
        disabled={!support}
        tags={
          !support &&
          !loading && (
            <StyledTag type="primary" onClick={() => setShowModal(true)}>
              Unlock Feature
            </StyledTag>
          )
        }
      />
      <UnlockFeatureModal show={showModal} onHide={() => setShowModal(false)} />
    </>
  );
};

interface UnlockFeatureModalProps {
  show?: boolean;
  onHide?(): void;
}

export function UnlockFeatureModal(props: UnlockFeatureModalProps) {
  const { show, onHide } = props;
  const shop = useCurrentShop();
  const { rechargeAdminPanelDomainSufix } = useInitialState();

  const rechargeIntegrationLink = `https://${shop.shopifyDomain.replace(
    '.myshopify.com',
    rechargeAdminPanelDomainSufix
  )}/settings/integrations/rechargesms`;

  return (
    <Modal centered show={show} onHide={onHide}>
      <CloseButton icon={faTimes} onClick={onHide} size="lg" />

      <ModalTitle>Authorize Shopify</ModalTitle>
      <CustomModalBody>
        <ImagesContainer>
          <SmsLogo src={logoBlue} />
          <SyncImage src={syncImage} />
          <ShopifyLogo src={shopifyLogo} />
        </ImagesContainer>
        <ModalText>
          In order to update your customer with accurate shipment tracking info you'll first need to
          authorize rechargeSMS with Shopify.
        </ModalText>
        <ButtonGroup>
          <CustomButton target="_blank" href={rechargeIntegrationLink}>
            Authorize Shopify
          </CustomButton>
        </ButtonGroup>
      </CustomModalBody>
    </Modal>
  );
}

const CustomModalBody = styled(ModalBody)`
  padding-top: 1rem;
`;

const CloseButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin: 16px;
  color: #9a9b9c;
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const StyledTag = styled(Tag)`
  cursor: pointer;
  margin-right: 1rem;
`;

const SmsLogo = styled.img`
  width: 23px;
`;

const SyncImage = styled.img`
  margin: 0 16px;
`;

const ShopifyLogo = styled.img`
  width: 33px;
`;
