import React, { createContext, useState } from 'react';
import { Elements } from 'react-flow-renderer';

import { Workflow } from '@typings/Workflow';

import { buildElements, getLoadingElement } from './MainArea/elements';

export interface ReactFlowContextProps {
  ReactFlowInstance: any;
  isDragabble: boolean;
  isSaved: boolean;
  elements: Elements;
  containerElement: ContainerElementProps;
  setReactFlowInstance: (instance: any) => void;
  setIsDragabble(isDragabble: boolean): void;
  setIsSaved(isSaved: boolean): void;
  setElements: (elements: Elements) => void;
  setContainerElement: (element: ContainerElementProps) => void;
  getElements: (workflow: Workflow) => void;
}

interface ContainerElementProps {
  offsetWidth: number;
  offsetHeight: number;
}

const ReactFlowContext = createContext<ReactFlowContextProps | undefined>(undefined);

export const ReactFlowProvider = ({ children }) => {
  const [ReactFlowInstance, setReactFlowInstance] = useState(null);
  const [isDragabble, setIsDragabble] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [elements, setElements] = useState<Elements>([]);
  const [containerElement, setContainerElement] = useState<ContainerElementProps>(null);

  function getElements(workflow) {
    if (!workflow) return;

    setElements(getLoadingElement(containerElement));

    const newElements = buildElements(workflow, containerElement?.offsetWidth);

    setTimeout(() => {
      setElements(newElements);
      setTimeout(() => ReactFlowInstance.fitView(), 0);
    }, 500);
  }

  const value = {
    ReactFlowInstance,
    setReactFlowInstance,
    isDragabble,
    setIsDragabble,
    isSaved,
    setIsSaved,
    elements,
    setElements,
    containerElement,
    setContainerElement,
    getElements,
  };
  return <ReactFlowContext.Provider value={value}>{children}</ReactFlowContext.Provider>;
};

export const useReactFlowContext = () => {
  const context = React.useContext(ReactFlowContext);

  if (context === undefined) {
    throw new Error('useReactFlowContext must be used within a WorkflowsProvider');
  }
  return context;
};
