import React from 'react';

import { Credentials } from '@typings/Integration';
import { TextInput } from '../TextInput';

interface FormProps {
  credentials: Credentials;
}

export function ReamazeForm(props: FormProps) {
  const { credentials } = props;

  return (
    <div>
      <TextInput
        name="reamaze_base_url"
        label="Account Subdomain"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.base_url ? credentials.base_url : '',
          placeholder: 'https://shopname.reamaze.com',
        }}
      />

      <TextInput
        name="reamaze_username"
        label="Username"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.username,
        }}
      />

      <TextInput
        name="reamaze_api_key"
        label="API Token"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.api_key,
        }}
      />
    </div>
  );
}
