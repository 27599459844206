import { css, cx } from '@emotion/css';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Variable = ({ variable, lockedVariables, removeRcsmsBranding }) => {
  const locked = lockedVariables.includes(variable.value);

  return (
    <tag
      title={variable.value}
      contenteditable="false"
      spellCheck="false"
      tabIndex={-1}
      className={cx('tagify__tag tagify--noAnim', {
        'px-1': locked,
        'd-none': removeRcsmsBranding && variable.value === 'powered_by_recharge_sms',
      })}
      value={variable.value}
    >
      <div>
        <span className={cx('tagify__tag-text', tagStyle)}>{variable.value}</span>
      </div>
      {locked ? (
        <FontAwesomeIcon icon={faLock} size="sm" className="mr-2" />
      ) : (
        <span
          title="Remove Tag"
          className={cx('tagify__tag__removeBtn', removeTagStyle)}
          role="button"
          aria-label="Remove Tag"
        />
      )}
    </tag>
  );
};

const tagStyle = css`
  color: #191d48;
  font-size: 13px;
  padding-top: 1px;
`;

const removeTagStyle = css`
  border: 1px solid #191d48;
  color: #191d48 !important;
`;

export default Variable;
