import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PhoneErrorModal } from '@components/Onboarding/v2/components/PhoneErrorNotification/PhoneErrorModal';
import { CustomButton } from '@components/Button/CustomButton';

interface PhoneErrorNotificationProps {
  setErrorOveride: (boolean) => void;
}

export default function PhoneErrorNotification({ setErrorOveride }: PhoneErrorNotificationProps) {
  const [showModal, setShowModal] = useState<boolean>(false);

  function showModalHandler() {
    setShowModal(true);
  }

  return (
    <>
      <PhoneErrorNotificationContainer>
        <ContentRow>
          <p className="title">Collect phone numbers!</p>
          <p>
            We noticed that a few of your customers are missing a phone number. Please check your
            Shopify checkout settings.
          </p>
          <p>Fortunately, it's an easy fix and we’ll walk you through it.</p>
        </ContentRow>
        <ShowMeRow>
          <ShowMeButton onClick={showModalHandler} disabled={showModal}>
            Show me how!
          </ShowMeButton>
        </ShowMeRow>
        {showModal && <PhoneErrorModal setErrorOveride={setErrorOveride} />}
      </PhoneErrorNotificationContainer>
    </>
  );
}

const PhoneErrorNotificationContainer = styled.div`
  color: #191d48;
  .title {
    font-weight: 600;
  }
`;

const ShowMeButton = styled(CustomButton)`
  border-radius: 6px;
  border: none;
  font-family: Greycliff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  padding: 13px 20px 13px 20px;
  background: #191d48;
  width: 100%;

  &:disabled {
    background: #d7d7d7;
    color: #a7a7a7 !important;
  }
`;

const ContentRow = styled.div`
  padding: 24px 16px 8px 16px;
`;

const ShowMeRow = styled.div`
  box-shadow: 0px 0px 10px rgba(25, 29, 72, 0.1);
  padding: 16px;
`;
