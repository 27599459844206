import React, { useEffect, useState } from 'react';
import { useCurrentShop } from '@hooks/useCurrentShop';
import Home from '@pages/Home';
import Modal from 'react-bootstrap/Modal';
import styled from '@emotion/styled';
import LaunchModal from '@components/Onboarding/v2/components/Activation/LaunchModal';
import MoreOptionsModal from '@components/Onboarding/v2/components/Activation/MoreOptionsModal';
import { useOnboardingV2 } from '@hooks/useOnboardingV2';
import CompletedOnboardingModal from '@components/Onboarding/v2/components/Activation/CompletedOnboardingModal';
import { useAppSelector } from '@redux/hooks';
import axios from '@lib/axios';

export const ActivationOnboardingView = () => {
  const onboardingState = useAppSelector((state) => state.onboarding);

  const { updateOnboarding } = useOnboardingV2();

  const [showMoreOptionsModal, setShowMoreOptionsModal] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);

  const currentShop = useCurrentShop();

  const sciCheckout = currentShop.checkoutPlatform !== 'recharge';

  useEffect(() => {
    setComplete(!!onboardingState.data.finished_at);
  }, [onboardingState]);

  const completeOnboarding = async () => {
    await updateOnboarding(onboardingState.data.id, {
      is_completed: true,
    });
  };

  const renderCompletedOnboardingModal = () => {
    if (complete) {
      return <CompletedOnboardingModal />;
    }

    return null;
  };

  const renderMoreOptionsModal = () => {
    if (!complete && showMoreOptionsModal) {
      return (
        <MoreOptionsModal
          handleGoBackClick={() => setShowMoreOptionsModal(false)}
          handleComplete={completeOnboarding}
        />
      );
    }
    return null;
  };

  const renderLaunchModal = () => {
    if (!complete && !showMoreOptionsModal) {
      return (
        <LaunchModal
          handleMoreOptionClick={() => setShowMoreOptionsModal(true)}
          handleComplete={completeOnboarding}
          completeButtonLabel="Launch"
          description={
            sciCheckout
              ? 'You’ve made it! Your subscribers are now ready to experience the benefits of subscription management via SMS. All that’s left is to enable your cart opt-in!'
              : 'You’ve made it! Your subscribers are now ready to experience the benefits of subscription management via SMS. All that’s left is to enable your opt-in!'
          }
          disableCompleteButton
        />
      );
    }
    return null;
  };

  if (!onboardingState.isModalOpen && !complete) return <Home />;

  return (
    <div>
      <Home />
      {renderCompletedOnboardingModal()}
      {renderMoreOptionsModal()}
      {renderLaunchModal()}
    </div>
  );
};

const LoadingModal = styled(Modal)`
  .modal-content {
    height: 300px;
  }
`;
