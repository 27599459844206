import React, { createContext, useState } from 'react';

type Period = 'day' | 'week' | 'month';

interface IOverviewContext {
  period: Period;
  dateRange: Date[];
  handleChangePeriod(period: Period): void;
  handleChangeDate(dates: Date[]): void;
}

const OverviewContext = createContext<IOverviewContext>(null);

export function OverviewProvider({ children }) {
  const [period, setPeriod] = useState<Period>('day');
  const [dateRange, setDateRange] = useState<Date[]>();

  function handleChangePeriod(newPeriod: Period) {
    setPeriod(newPeriod);
    setDateRange(null);
  }

  function handleChangeDate(dates: Date[]) {
    if (dates.length === 2) {
      setPeriod(null);
      setDateRange(dates);
    }
  }

  const value = {
    period,
    dateRange,
    handleChangePeriod,
    handleChangeDate,
  };

  return <OverviewContext.Provider value={value}>{children}</OverviewContext.Provider>;
}

export const useOverviewContext = () => {
  const context = React.useContext(OverviewContext);

  if (context === undefined) {
    throw new Error('useOverviewContext must be used within a OverviewProvider');
  }
  return context;
};
