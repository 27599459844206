import React from 'react';
import styled from '@emotion/styled';

import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { Action } from '@components/Table/Row/Action';
import { RechargeCustomer } from '@typings/RechargeCustomer';

interface CustomerActionsProps {
  customer: RechargeCustomer;
}

export function CustomerActions(props: CustomerActionsProps) {
  const { customer } = props;

  return (
    <Container>
      <Action
        color="#078fee"
        icon={faPen}
        text="Edit"
        href={`/sms/recharge/customers/${customer.id}/edit`}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;
