const validateEmail = (email, confirmationEmail) => {
  const invalidConfirmation = confirmationEmail !== email;
  const emailFormat = /\S+@\S+\.\S+/;
  const invalidFormat = !emailFormat.test(email);

  return {
    confirmation: invalidConfirmation,
    format: invalidFormat,
  };
};

export { validateEmail };
