import React, { PropsWithChildren, createContext, useState } from 'react';
import {
  RightSidebar,
  LeftSidebar,
  InsightsData,
  LayoutContextProps,
} from '@typings/RepurchaseLayout';

const LayoutContext = createContext<LayoutContextProps | undefined>(undefined);

export const LayoutProvider = ({ children }: PropsWithChildren<any>) => {
  const [rightSidebar, setRightSidebar] = useState<RightSidebar>();
  const [leftSidebar, setLeftSidebar] = useState<LeftSidebar>();
  const [insightsData, setInsightsData] = useState<InsightsData>(null);
  const [previousWorkflowId, setPreviousWorkflowId] = useState<number>(null);

  function closeRightSidebar() {
    const settings = { ...rightSidebar };
    settings.open = false;
    setRightSidebar(settings);
  }

  const value = {
    rightSidebar,
    setRightSidebar,
    leftSidebar,
    setLeftSidebar,
    insightsData,
    setInsightsData,
    closeRightSidebar,
    previousWorkflowId,
    setPreviousWorkflowId,
  };
  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export const useLayoutContext = () => {
  const context = React.useContext(LayoutContext);

  if (context === undefined) {
    throw new Error('useLayoutContext must be used within a LayoutProvider');
  }
  return context;
};
