import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import { useCurrentShop } from '@hooks/useCurrentShop';
import Button from 'react-bootstrap/Button';
import parsePhoneNumber from 'libphonenumber-js';

export function ShopPhoneNumberCard() {
  const shop = useCurrentShop();

  const onCopyPhoneNumberClick = () => {
    navigator.clipboard.writeText(shop.setting.twilio_from_number);
  };

  return shop.setting.twilio_from_number ? (
    <Container>
      <Row>
        <LeftCol sm={8}>
          <PhoneNumber>
            My Shop's Phone Number:{' '}
            {parsePhoneNumber(shop.setting.twilio_from_number).formatNational()}
          </PhoneNumber>
        </LeftCol>
        <RightCol sm={4}>
          <CopyButton onClick={onCopyPhoneNumberClick}>Copy Phone Number</CopyButton>
        </RightCol>
      </Row>
    </Container>
  ) : null;
}

const Container = styled.div`
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  position: relative;
  margin-top: 1.5rem;
  background: #fff;
  padding: 25px 34px;
`;

const PhoneNumber = styled.span`
  font-family: Greycliff;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #191d48;
`;

const LeftCol = styled(Col)`
  text-align: left;
`;

const RightCol = styled(Col)`
  text-align: right;
`;

const CopyButton = styled(Button)`
  background: transparent;
  border-radius: 6px;
  border: 1px solid #191d48;
  color: #191d48 !important;
  font-family: Greycliff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  padding: 11px 16px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: transparent;
    color: #1a1d45;
    transition: all 0.2s ease-in-out;
  }

  &:focus,
  &:active {
    background: transparent;
  }
`;
