import React from 'react';

export interface IAlert {
  message?: React.ReactChild;
  type?: string;
  onClose?(): void;
}

export function Alert(props: IAlert) {
  const { message, type = 'info', onClose } = props;

  if (!message) return null;

  return (
    <div className={`alert  alert-${type} fade show`}>
      {message}
      <button type="button" className="close" aria-label="Close" onClick={onClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}
