const baseColors = {
  primary: '#3902f0',
  secondary: '#191d48',
  info: '#078fee',
};

const darkColors = {
  dark0: '#000000',
  dark1: '#2f2f2f',
  dark2: '#8898aa',
  dark3: '#808fa5',
};

const lightColors = {
  light0: '#ffffff',
  light1: '#f6f6f7',
  light2: '#e9ecef',
  light3: '#edebf0',
  light4: '#e6e6e6',
};

const greenColors = {
  green0: '#00ff00',
  green1: '#00e364',
  green2: '#188A4A',
  green3: '#247990',
};

export default {
  ...baseColors,
  ...darkColors,
  ...lightColors,
  ...greenColors,
};
