// @ts-ignore
import useSWRInfinite from 'swr/infinite';

import { fetcher } from '@lib/axios';

const fetchWithToken = (url: string, token?: string) => fetcher(url, { params: { token } });

const getKey = (pageIndex, previousPageData, baseUrl, token) => {
  if (pageIndex + 1 > previousPageData?.paginationInfo?.totalPages) return null; // reached the end

  return [`${baseUrl}/sms_messages?page=${pageIndex + 1}`, token]; // SWR key
};

interface UseSmsMessagesProps {
  baseUrl: string;
  token?: string;
}

const REFRESH_INTERVAL = process.env.NODE_ENV !== 'development' ? 1000 : 0;

export function useSmsMessages({ baseUrl, token }: UseSmsMessagesProps) {
  return useSWRInfinite(
    (pageIndex, previousPageData) => getKey(pageIndex, previousPageData, baseUrl, token),
    fetchWithToken,
    { shouldRetryOnError: false, refreshInterval: REFRESH_INTERVAL }
  );
}
