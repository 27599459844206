import SwapGroupIcon from '@assets/icons/swap-group.svg?react';
import { MultipleSelect } from '@components/Input/MultipleSelect';
import styled from '@emotion/styled';
import axios from '@lib/axios';
import { ProductVariant } from '@typings/ProductVariant';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { bigSmsText } from './Form';
import VariantListItem from './VariantListItem';

interface AddonsProps {
  selectedAddons: ProductVariant[];
  onSelectedAddonsChange(selectedAddons: []): void;
}

export function Addons(props: AddonsProps) {
  const { selectedAddons, onSelectedAddonsChange } = props;
  const [enabled, setEnabled] = useState(selectedAddons.length > 0);
  const [addonPage, setAddonPage] = useState<any>();
  const [addonErrors, setAddonErrors] = useState(null);

  const searchAddons = async (search, page) => {
    try {
      const { data } = await axios.get('/sms/product_variants/search', {
        params: {
          search,
          page,
          feature: 'one_time_item',
          limit: 5,
          display_ignored: false,
        },
      });

      setAddonPage(data);
    } catch (e) {
      setAddonErrors('It was not possible to load variants.');
    }
  };

  function toggleEnabled() {
    setEnabled((old) => {
      if (old) {
        onSelectedAddonsChange([]);
      }
      return !old;
    });
  }

  const { data: addons, paginationInfo: addonsPaginationInfo } = addonPage || {};

  return (
    <>
      <span className="onboarding-swap-group-addons-toggle">
        <SwitchContainer className="mr-1">
          <Form.Check
            id="addons-toggle"
            type="switch"
            label="Add-On Items"
            onChange={toggleEnabled}
            checked={enabled}
          />
        </SwitchContainer>
      </span>
      <MultipleSelect
        disabled={!enabled}
        label={
          <div className="d-flex align-items-center">
            <span className="mr-1">Select Add-On items options</span>
          </div>
        }
        icon={SwapGroupIcon}
        paginationInfo={addonsPaginationInfo}
        placeholder="Search by Variant or Product Info"
        onSearch={searchAddons}
        options={addons}
        onSelectedOptionsChange={onSelectedAddonsChange}
        renderOption={(option, optionProps) => (
          <VariantListItem isAddon variant={option} {...optionProps} />
        )}
        selectedOptions={selectedAddons}
      />
      {addonErrors && <p className="text-danger mt-3">{addonErrors}</p>}
      {selectedAddons.length > 6 && (
        <Alert variant="warning" className="mt-3">
          {bigSmsText}
        </Alert>
      )}
    </>
  );
}

const SwitchContainer = styled.div`
  display: inline-block;
  flex-grow: 0;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;
