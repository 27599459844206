import React from 'react';
import styled from '@emotion/styled';

import { Card } from '@components/Layout/Card';

import { TextInput, ColorInput } from '@components/Input';

export function OptInForm() {
  return (
    <Container>
      <CustomCard>
        <Title>Promo Copy</Title>
        <ColorInput name="heroCopyFontColor" label="Hero Copy Font Color" />
        <TextInput name="heroCopy" label="Hero Copy" inputProps={{ as: 'textarea', rows: 4 }} />
      </CustomCard>

      <CustomCard style={{ flex: 1 }}>
        <Title>Call to Action</Title>
        <ColorInput name="ctaButtonFontColor" label="CTA Font Color" />
        <ColorInput name="ctaButtonColor" label="CTA Button Color" />
        <TextInput name="ctaButtonLabel" label="CTA" />
      </CustomCard>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  height: 100%;

  @media (min-width: 1200px) {
    flex-direction: column;
    width: calc(100% - 1rem);
    margin: 0;
  }
`;

const CustomCard = styled(Card)`
  padding: 1rem;
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  height: auto;
  overflow: auto;
`;

const Title = styled.h3`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`;
