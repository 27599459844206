import { SwapGroup } from '@typings/SwapGroup';
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import BootstrapForm from 'react-bootstrap/Form';
import { FormProvider, useForm } from 'react-hook-form';

import { Addons } from './Addons';
import { AddonsButtons } from './AddonsButtons';
import { SwapVariants } from './SwapVariants';
import { VariantsButtons } from './VariantsButtons';
import { PageTitle } from './PageTitle';

export type Routes = 'variants' | 'addons';

export const bigSmsText = `
Hey, so the character length of the SMS we'll
send to your customers will be pretty long with
these awesome products. Consider limiting the options
between 4-5 products or email us at sms@rechargepayments.com
for help
`;

interface FormProps {
  defaultValues?: SwapGroup;
  subtitle: string;
  onSubmit(values: any): Promise<void>;
}

const Form = (props: FormProps) => {
  const { onSubmit, defaultValues, subtitle } = props;
  const formProps = useForm({
    mode: 'onBlur',
    defaultValues,
    shouldUnregister: false,
    reValidateMode: 'onBlur',
  });
  const { handleSubmit } = formProps;
  const [submitErrors, setSubmitErrors] = useState(null);
  const [route, setRoute] = useState<Routes>('variants');
  const [selectedVariants, setSelectedVariants] = useState(
    (defaultValues && defaultValues.variants) || []
  );
  const [selectedAddons, setSelectedAddons] = useState(
    (defaultValues && defaultValues.addons) || []
  );

  async function internalOnSubmit(data) {
    try {
      const formData = {
        swap_group: {
          ...data,
          product_variants_ids: selectedVariants.map((cust) => cust.id),
          addons_ids: selectedAddons.map((addon) => addon.id),
          group_type: defaultValues.groupType,
        },
      };
      await onSubmit(formData);
    } catch (e) {
      setSubmitErrors(e.response.data.errors);
    }
  }

  const routeComponents = {
    variants: {
      buttons: (
        <VariantsButtons
          groupType={defaultValues?.groupType}
          selectedVariants={selectedVariants}
          setRoute={setRoute}
        />
      ),
      body: (
        <SwapVariants
          groupType={defaultValues?.groupType}
          selectedVariants={selectedVariants}
          swapGroupId={defaultValues?.id}
          onSelectedVariantsChange={setSelectedVariants}
        />
      ),
    },
    addons: {
      buttons: <AddonsButtons setRoute={setRoute} />,
      body: <Addons selectedAddons={selectedAddons} onSelectedAddonsChange={setSelectedAddons} />,
    },
  };

  return (
    <FormProvider {...formProps}>
      <BootstrapForm onSubmit={handleSubmit(internalOnSubmit)}>
        <PageTitle subtitle={subtitle} addon={routeComponents[route].buttons} />
        <Card>
          <Card.Body>
            {routeComponents[route].body}
            {submitErrors && <p className="text-danger mt-3">{submitErrors}</p>}
          </Card.Body>
        </Card>
      </BootstrapForm>
    </FormProvider>
  );
};

export default Form;
