import styled from '@emotion/styled';
import axios from '@lib/axios';
import { ProductVariant } from '@typings/ProductVariant';
import { SwapGroupType } from '@typings/SwapGroup';
import React, { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import BootstrapForm from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';
import { bigSmsText } from './Form';
import VariantListItem from './VariantListItem';
import GroupSelect from './GroupSelect';

interface SwapVariantsProps {
  swapGroupId?: string;
  groupType: SwapGroupType;
  selectedVariants: ProductVariant[];
  onSelectedVariantsChange(selectedAddons: []): void;
}

export function SwapVariants(props: SwapVariantsProps) {
  const { groupType, selectedVariants, swapGroupId, onSelectedVariantsChange } = props;

  const [errors, setErrors] = useState(null);
  const [variantPage, setVariantPage] = useState<any>();
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const { register, formState } = useFormContext();

  const searchVariants = async (search, page) => {
    try {
      const { data } = await axios.get('/sms/product_variants/search', {
        params: {
          search,
          page,
          limit: 5,
          except: selectedVariants.map((variant) => variant.id).join(', '),
          feature: 'subscription',
          available_for_swap_group: {
            id: swapGroupId || -1,
            group_type: groupType,
          },
          display_ignored: false,
        },
      });

      setCurrentSearchTerm(search);
      setVariantPage(data);
    } catch (e) {
      setErrors('It was not possible to load variants.');
    }
  };

  const pageContent = {
    swap: {
      title: 'Swap Groups',
      descriptionElement: null,
    },
    quantity: {
      title: 'Quantity Grouping (Optional)',
      descriptionElement: (
        <p>
          If your store utilizes SKUs to define quantity upgrades use this feature to group your
          related SKUs so users can easily swap between quantities.{' '}
          <strong>
            All items save within a group will have corresponding groups created for them.
          </strong>
        </p>
      ),
    },
  };

  const { data: variants, paginationInfo } = variantPage || {};

  useEffect(() => {
    searchVariants(currentSearchTerm, 1);
  }, [selectedVariants]);

  return (
    <>
      <GroupSelect
        className="onboarding-swap-group-options-select"
        title={pageContent[groupType].title}
        descriptionElement={pageContent[groupType].descriptionElement}
        label="Add swap options"
        paginationInfo={paginationInfo}
        placeholder="ex. Dry Roasted Coffee Beans"
        onSearch={searchVariants}
        options={variants}
        onSelectedOptionsChange={onSelectedVariantsChange}
        renderOption={(option, optionProps) => (
          <VariantListItem variant={option} {...optionProps} />
        )}
        selectedOptions={selectedVariants}
      >
        <BootstrapForm.Group controlId="swap_group_name">
          <BootstrapForm.Label className="d-flex align-items-center">
            <strong className="mr-1">Swap Group Name</strong>
          </BootstrapForm.Label>
          <InputGroup>
            <FormControl
              className="onboarding-swap-group-name-input"
              name="name"
              defaultValue=""
              placeholder="i.e. Ground coffee, Box (12 bottles), Hair products, etc"
              isInvalid={!!formState.errors.name}
              {...register('name', { required: true })}
            />
            <BootstrapForm.Control.Feedback type="invalid">
              Name is a required field.
            </BootstrapForm.Control.Feedback>
          </InputGroup>
        </BootstrapForm.Group>
      </GroupSelect>
      {errors && <p className="text-danger mt-3">{errors}</p>}
      {selectedVariants.length > 6 && (
        <Alert variant="warning" className="mt-3">
          {bigSmsText}
        </Alert>
      )}
    </>
  );
}

const FormControl = styled(BootstrapForm.Control)`
  border-radius: 5px;
  padding: 15px;
  background-color: #f6f6f7;
  box-shadow: 0 0 2px #9a9b9c;

  :focus {
    box-shadow: 0 0 3px #9a9b9c;
  }
`;
