import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Modal, Form } from 'react-bootstrap';

import { CustomButton } from '@components/Button/CustomButton';

interface ImportCustomerModalProps {
  handleSubmit(value: string): void;
  toggleModal(): void;
}

export function ImportCustomerModal(props: ImportCustomerModalProps) {
  const { handleSubmit, toggleModal } = props;
  const [value, setValue] = useState('');

  function onSubmit(e) {
    e.preventDefault();

    handleSubmit(value);
  }

  return (
    <Form onSubmit={onSubmit}>
      <Header>
        <h5>Import Recharge Customer</h5>
      </Header>

      <Modal.Body>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type="email"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <CustomButton variant="outline-warning" onClick={toggleModal}>
          Cancel
        </CustomButton>
        <CustomButton type="submit">Save</CustomButton>
      </Modal.Footer>
    </Form>
  );
}

const Header = styled(Modal.Header)`
  justify-content: center;
`;
