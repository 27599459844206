import axios from '@lib/axios';
import { OnboardingFormData } from '@typings/Onboarding';
import { useEffect } from 'react';
import { useAppDispatch } from '@redux/hooks';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';

export function useOnboardingV2() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetch() {
      dispatch(onboardingSlice.actions.setLoading(true));
      const { data } = await axios.get('/sms/shops/onboarding');

      dispatch(onboardingSlice.actions.setOnboardingData(data.onboarding));
    }
    fetch();
  }, []);

  async function updateOnboarding(id: string, formData: OnboardingFormData) {
    try {
      dispatch(onboardingSlice.actions.setLoading(true));

      const { data } = await axios.put(`/sms/onboardings/${id}`, {
        onboarding: formData,
      });

      dispatch(onboardingSlice.actions.setOnboardingData(data.onboarding));
    } catch (e) {
      dispatch(onboardingSlice.actions.setError(e?.response?.data?.error || 'unknown error'));
    }
  }

  return {
    updateOnboarding,
  };
}
