import React, { useState, useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';

import './token_input.scss';

const TokenInput = ({ onChange, tokens: initialTokens = [] }) => {
  const [tokens, setTokens] = useState(initialTokens);
  const [inputToken, setInputToken] = useState('');

  const inputRef = useRef();

  const handleTokenItemInput = (event) => {
    if (event.key !== 'Enter' || isEmpty(inputToken)) {
      return null;
    }

    event.preventDefault();

    setTokens([...tokens, inputToken]);
    setInputToken('');
  };

  const handleTokenInputClick = () => inputRef.current.focus();

  const onInputChange = ({ target }) => setInputToken(target.value);

  const onClickRemove = (index) => () => {
    const newTokens = [...tokens];

    newTokens.splice(index, 1);

    setTokens(newTokens);
  };

  useEffect(() => {
    if (isFunction(onChange)) {
      onChange(tokens);
    }
  }, [tokens]);

  return (
    <ul className="token-input" onClick={handleTokenInputClick}>
      {tokens.map((token, idx) => (
        <li className="token-item" key={idx}>
          <span className="token-item__remove" onClick={onClickRemove(idx)}>
            ✕
          </span>
          <span className="token-item__label">{token}</span>
        </li>
      ))}
      <li className="d-inline-flex">
        <div className="d-inline-flex">
          <input
            autoComplete="off"
            spellCheck="off"
            className="token-item__input"
            value={inputToken}
            onChange={onInputChange}
            onKeyPress={handleTokenItemInput}
            ref={inputRef}
          />
        </div>
      </li>
    </ul>
  );
};

export default TokenInput;
