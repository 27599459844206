import React from 'react';
import styled from '@emotion/styled';

interface PendingCustomerBannerProps {
  selectedFilter: any;
}

export function PendingCustomerBanner(props: PendingCustomerBannerProps) {
  const { selectedFilter } = props;

  if (selectedFilter?.value !== 'transactional_pending') return null;

  return (
    <TableRow>
      <Container>
        "Pending" customers are existing subscribers who are able to receive a
        <strong> Charge Reminder - Opt-in message</strong>
        <Bullet />
        <LearnMore
          target="_blank"
          href="https://support.rechargepayments.com/hc/en-us/articles/4410327511831-Managing-customers-in-RechargeSMS#h_01FHG393S83F8XKJ4FR1BN1KN3"
        >
          Learn more
        </LearnMore>
      </Container>
    </TableRow>
  );
}

const TableRow = styled.tr`
  &&& {
    height: auto;
  }
`;

const Container = styled.td`
  &&& {
    background: #fddf97;
    text-align: center;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 12px;
    font-weight: 500;

    * {
      font-family: 'SFProDisplay';
    }
  }

  strong {
    margin-left: 0.5ch;
  }
`;

const Bullet = styled.span`
  display: block;
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #000;
  margin: 0 5px;
`;

const LearnMore = styled.a`
  color: #000 !important;
  font-weight: 700;
  text-decoration: underline;
`;
