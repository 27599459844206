import React, { useEffect, useRef, CSSProperties } from 'react';
import styled from '@emotion/styled';

import { SmsMessage } from '@typings/SmsMessage';

import { Alert, IAlert } from './Alert';
import { Message } from './Message';

interface ChatHistoryProps {
  alert?: IAlert;
  smsMessages: SmsMessage[];
  style?: CSSProperties;
}

export const ChatHistory = (props: ChatHistoryProps) => {
  const { smsMessages, alert, style } = props;
  const historyRef = useRef<HTMLDivElement>();

  const scrollToBottom = () => {
    historyRef.current.scrollTop = historyRef.current.scrollHeight;
  };

  // Initial scroll to bottom with timeout
  useEffect(() => {
    const timer = setTimeout(scrollToBottom, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(scrollToBottom, [smsMessages.length]);

  return (
    <Container ref={historyRef} style={style}>
      {alert && <Alert {...alert} />}
      {smsMessages.map((smsMessage) => (
        <Message key={smsMessage.id} smsMessage={smsMessage} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #dee2e6;
  height: 420px;
  overflow-y: auto;
  padding: 1rem;
  padding-bottom: 0;
  scroll-behavior: smooth;
  border-radius: 6px;
`;
