import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import DonutGraph from './DonutGraph';
import DonutKey from './DonutKey';

import { CohortBreakdownProps } from './types';

export default React.memo(({ data }: CohortBreakdownProps) => {
  const totalUsers: number = data ? Object.values(data).reduce((a: number, b: number) => a + b) : 1;

  const VIEWED = 'Customers who viewed the enrollment offer';
  const ENROLLED = 'Enrollments';
  const PURCHASED = 'Customers who purchased';

  const graphData = useMemo(
    (): [string, number][] => [
      [VIEWED, data.viewed],
      [ENROLLED, data.enrolled],
      [PURCHASED, data.purchased],
    ],
    []
  );

  return (
    <CohortBreakdownContainer>
      <div className="section-title">Cohort Breakdown</div>
      {data.viewed > 0 ? (
        <div>
          <div className="flex center">
            <DonutGraph
              title={data.purchased.toLocaleString()}
              subtitle={`Customers who purchased ${data.purchased} (${
                Math.round(
                  10 * ((data.purchased / (data.viewed + data.enrolled + data.purchased)) * 100)
                ) / 10
              }%)`}
              data={graphData}
            />
          </div>
          <DonutKey data={graphData} totalUsers={totalUsers} />
        </div>
      ) : (
        <div>Not Found</div>
      )}
    </CohortBreakdownContainer>
  );
});

const CohortBreakdownContainer = styled.div`
  .section-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .center {
    justify-content: center;
  }
`;
