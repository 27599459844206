import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { Collapse } from 'react-bootstrap';

interface FeaturesDrawerProps {
  title: string;
  description?: string;
  icon: IconDefinition;
  iconCustom: string;
  iconColor: string;
  collapseComponent: React.ReactElement;
  forceCollapse?: number;
  disabled?: boolean;
  titleTestId: string;
  autoExpanded?: boolean;
  onShowCollapse?: () => void;
  onHideCollapse?: () => void;
}

function FeaturesDrawer({
  title,
  description,
  icon,
  iconCustom,
  iconColor,
  collapseComponent,
  forceCollapse = 0,
  disabled = false,
  titleTestId,
  autoExpanded = false,
  onShowCollapse,
  onHideCollapse,
}: FeaturesDrawerProps) {
  const [showCollapse, setShowCollapse] = useState(false);

  const toggleCollapse = () => {
    setShowCollapse((show) => !show);
    if (showCollapse && onHideCollapse) {
      onHideCollapse();
    }
    if (!showCollapse && onShowCollapse) {
      onShowCollapse();
    }
  };

  useEffect(() => {
    if (autoExpanded) {
      setShowCollapse(true);
    }
  }, [autoExpanded]);

  useEffect(() => {
    if (forceCollapse !== 0) setShowCollapse(true);
  }, [forceCollapse]);

  return (
    <OuterContainer>
      <Container onClick={toggleCollapse}>
        {iconCustom ? (
          <img src={iconCustom} />
        ) : (
          <FontAwesomeIcon icon={icon} color={iconColor} size="2x" />
        )}

        <TitleContainer>
          <Title data-test-id={titleTestId}>{title}</Title>
          {description && <Description>{description}</Description>}
        </TitleContainer>

        <BrowseText disabled={disabled}>EXPAND</BrowseText>
        <BrowseButton
          disabled={disabled}
          collapsed={!disabled && showCollapse}
          icon={faChevronDown}
        />
      </Container>
      {!disabled && <Collapse in={showCollapse}>{collapseComponent}</Collapse>}
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Container = styled.div`
  height: 100px;
  width: 100%;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 0 25px;
  z-index: 5;

  & > img,
  & > svg {
    width: 32px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;
`;

const Title = styled.h3`
  color: #000;
  margin: 0;
  font-weight: 700;
  font-size: 18px;
`;

const Description = styled.p`
  color: #6b6b6b;
  margin: 5px 0 0 0;
  font-size: 14px;
`;

const BrowseText = styled.p<{ disabled?: boolean }>`
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: ${(props) => (props.disabled ? '#A7A7A7' : '#191D45')};
`;

interface BrowseButtonProps {
  collapsed?: boolean;
  disabled?: boolean;
}

const BrowseButton = styled(FontAwesomeIcon)<BrowseButtonProps>`
  &&& {
    width: 20px;
  }

  background: ${(props) => (props.disabled ? '#A7A7A7' : '#191D45')};
  color: #fff;
  border-radius: 50%;
  height: 20px;
  padding: 8px;
  cursor: pointer;
  transition: 500ms ease-in-out;
  transform: ${(props) => props.collapsed && 'rotate(180deg)'};
`;

export default FeaturesDrawer;
