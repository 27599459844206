import React from 'react';

export function Setup() {
  return (
    <div className="card-body">
      <h3 className="mb-4 color-dark-2">Help Guide</h3>

      <p>
        For additional support configuring the service, visit our official help guide
        <a
          target="_blank"
          href="https://support.rechargepayments.com/hc/en-us/articles/360063219013-rechargeSMS"
        >
          <strong> here.</strong>
        </a>
      </p>

      <hr />

      <h3 className="my-4 color-dark-2">Uninstall</h3>
      <p>
        <strong>Steps:</strong>
      </p>
      <ol className="pl-3">
        <li>
          <p>
            From your Shopify admin, go to
            <a href="https://www.shopify.com/admin/apps">
              <strong> Apps.</strong>
            </a>
          </p>
        </li>
        <li>
          <p>
            In the row of the app that you want to uninstall, click <strong>Delete</strong>.
          </p>
        </li>
        <li>
          <p>
            In the message that appears, click <strong>Delete</strong>.
          </p>
        </li>
      </ol>
    </div>
  );
}
