import React, { useState } from 'react';

import { Range as RangeComponent, getTrackBackground } from 'react-range';

import colors from 'styles/theme/colors';

interface HoursRangeProps {
  min: number;
  max: number;
  initialValues: number[];
  format?: 12 | 24;
  onChange?: (values: number[]) => void;
}

const convertTo12Format = (value) => {
  const hour = value % 12 || 12;
  const ampm = value < 12 || value === 24 ? 'am' : 'pm';

  return `${hour}:00${ampm}`;
};

export const HoursRange = (props: HoursRangeProps) => {
  const { initialValues, min, max, format, onChange } = props;
  const [values, setValues] = useState(initialValues);

  const handleOnChange = (values) => {
    const [start, end] = values;

    if (start === end) return;

    setValues(values);
  };

  return (
    <RangeComponent
      step={1}
      min={min}
      max={max}
      values={values}
      onChange={handleOnChange}
      onFinalChange={onChange}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: '36px',
            display: 'flex',
            margin: '14px 0 18px',
            width: '100%',
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '2px',
              width: '100%',
              borderRadius: '4px',
              background: getTrackBackground({
                values,
                colors: ['#ccc', colors.primary, '#ccc'],
                min,
                max,
              }),
              alignSelf: 'center',
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged, index }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '22px',
            width: '22px',
            borderRadius: '4px',
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 6px #AAA',
            outlineColor: isDragged ? colors.primary : '#fff',
          }}
        >
          <div
            style={{
              position: 'absolute',
              transform: `translateY(${index === 0 ? '24px' : '-24px'})`,
              left: 0,
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '12px',
              fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
              borderRadius: 4,
              padding: '1px 0 2px',
              backgroundColor: colors.primary,
              minWidth: 55,
              textAlign: 'center',
            }}
          >
            {format === 24 ? values[index] : convertTo12Format(values[index])}
          </div>
          <div
            style={{
              height: '12px',
              width: '3px',
              backgroundColor: isDragged ? colors.primary : '#CCC',
            }}
          />
        </div>
      )}
    />
  );
};
