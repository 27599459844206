import React from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';

import { Page } from '@components/Layout/Page';
import { useFetch } from '@hooks/useFetch';
import axios from '@lib/axios';
import { OptInStatus } from '@typings/OptInStatus';
import { RechargeCustomer } from '@typings/RechargeCustomer';

import { Form, FormValues } from './components/Form';

interface CustomerPayload {
  rechargeCustomer: RechargeCustomer;
}

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, data } = useFetch<CustomerPayload>(`/sms/recharge/customers/${id}`);
  const { rechargeCustomer } = data || {};

  async function handleSubmit(values: FormValues) {
    await axios.put(`/sms/recharge/customers/${id}`, values);
    toast.success('Customer successfully updated.');
    navigate(`/sms/recharge/customers/${id}`);
  }

  async function handleUnsubscribe() {
    if (window.confirm('Are you sure you want to unsubscribe this customer?')) {
      await axios.post(
        `/sms/recharge/customers/${rechargeCustomer.id}/unsubscribe?key=${rechargeCustomer.safeId}`
      );
      toast.success('Customer successfully unsubscribed.');
      navigate(`/sms/recharge/customers/${id}`);
    }
  }

  return (
    <Page
      loading={loading}
      headerDescription="Update customer's primary info"
      headerComponent={
        rechargeCustomer?.optInStatus?.transactional?.status !== OptInStatus.OPT_OUT && (
          <Button variant="outline-danger" onClick={handleUnsubscribe}>
            Unsubscribe customer
          </Button>
        )
      }
    >
      <Form rechargeCustomer={rechargeCustomer} onSubmit={handleSubmit} />
    </Page>
  );
}

export default Edit;
