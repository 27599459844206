import React from 'react';
import styled from '@emotion/styled';

import { Page } from '@components/Layout/Page';
import { Loading } from '@components/Loading';
import { ViewContainer, Filters } from '@pages/Products/components';

import '@pages/Products/styles.scss';

function View() {
  return (
    <ViewContainer baseUrl="/sms/product_variants/archived">
      {({ page, loading, setSearch, filter, setFilter, sort, setSort, renderProducts }) => (
        <Page headerTitle="Archived" className="product-variants">
          <Container className="mt-3">
            <Filters
              filter={filter}
              setFilter={setFilter}
              setSearch={setSearch}
              sort={sort}
              setSort={setSort}
            />

            <ProductHeaders>
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th style={{ width: '35%' }}>Name</th>
                    <th style={{ width: '10%' }}>Price</th>
                    <th style={{ width: '22%' }} />
                    <th style={{ width: '12%' }}>Created At</th>
                    <th style={{ width: '21%' }}>Actions</th>
                  </tr>
                </thead>
              </table>
            </ProductHeaders>

            <ProductsContainer>
              {loading ? <Loading className="mt-3" /> : renderProducts(page)}
            </ProductsContainer>
          </Container>
        </Page>
      )}
    </ViewContainer>
  );
}

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  border-radius: 16px;
`;

const ProductHeaders = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding: 0 26px;
`;

const ProductsContainer = styled.div`
  background: #f4f4f6;
  padding: 20px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export default View;
