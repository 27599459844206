import React from 'react';
import styled from '@emotion/styled';

import { formatString } from '@utils/date';

import { useWorkflowsContext } from '../WorkflowsContext';
import { EditableTitle } from './EditableTitle';

export function WorkflowTitle() {
  const { currentWorkflow, handleChangeWorkflow } = useWorkflowsContext();

  return (
    <Container>
      <StyledEditableTitle
        successToastMessage="Workflow title has been successfully updated!"
        failureToastMessage="Workflow title has been successfully reverted!"
        initialTitle={currentWorkflow?.title}
        onChange={(title) => handleChangeWorkflow({ title })}
        overlayPlacement="bottom"
      />
      {currentWorkflow.updatedAt && (
        <LastModified>
          LAST MODIFIED: {formatString(currentWorkflow.updatedAt, 'MM/dd/yy')}
        </LastModified>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledEditableTitle = styled(EditableTitle)`
  font-size: 115%;
  border-bottom: 1px solid #cecccc;
`;

const LastModified = styled.p`
  margin: 0;
  color: #8a8a8a;
  font-weight: bold;
  font-size: 10px;
`;
