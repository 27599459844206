import parsePhoneNumber from 'libphonenumber-js';

export function format(phone: string) {
  if (!phone) return '';

  return parsePhoneNumber(phone).formatInternational();
}

export default {
  format,
};
