import React from 'react';
import styled from '@emotion/styled';

import { Loading } from '@components/Loading';
import { SearchBar } from '@pages/Workflows/components/SearchBar';
import { ProductCard } from '@pages/Workflows/components/ProductCard';
import { ProductVariant } from '@typings/ProductVariant';

interface ProductSelectorProps {
  loading: boolean;
  products: ProductVariant[];
  selectedProductId: number;
  handleSearch(event: any): void;
  handleSelectProduct(id: number): void;
}

export function ProductSelector(props: ProductSelectorProps) {
  const { loading, products, selectedProductId, handleSearch, handleSelectProduct } = props;

  return (
    <Container>
      <SearchBar
        handleSearch={handleSearch}
        inputProps={{
          placeholder: 'Search product catalog',
        }}
      />

      {loading && <Loading />}

      <ProductsList>
        {products?.map((variant) => (
          <ProductCard
            key={variant.id}
            title={variant.title}
            subtitle={variant.priceText}
            variants={[variant]}
            selected={selectedProductId === variant.id}
            handleClick={() => handleSelectProduct(variant.id)}
          />
        ))}
      </ProductsList>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 1rem;
`;

const ProductsList = styled.div`
  height: 300px;
  overflow-y: auto;
  padding: 2px;
`;
