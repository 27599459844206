import amplitude from 'amplitude-js';
import { Shop } from '@typings/Shop';

export function initializeAmplitude(shop: Shop, apiKey: string) {
  amplitude.getInstance().init(apiKey, `shop-${shop.id}`);
  amplitude.getInstance().setUserProperties({
    name: shop.name,
    type: 'shop',
  });
  amplitude.getInstance().logEvent('page_viewed', {
    path: window.location.pathname,
  });
}
