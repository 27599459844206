import React, { useState, useEffect } from 'react';
import { useOnboardingV2 } from '@hooks/useOnboardingV2';
import { useAppSelector, useAppDispatch } from '@redux/hooks';
import { ContinueButton } from '@components/Onboarding/v2/components/styling/SidebarStyling';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';
import { SidebarLayout } from '@components/Onboarding/v2/components/Sidebar/SidebarLayout';
import {
  ACTIVATION_STEP,
  PRODUCT_1_SETUP_STEP,
  PRODUCT_2_IMPORT_STEP,
  PRODUCT_3_FORMAT_TITLE_STEP,
  PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP,
  PRODUCT_5_IGNORE_PRODUCT_STEP,
  PRODUCT_6_PROCEED_ACTIVATION_STEP,
  PRODUCT_FLOW,
  CONTINUE_BUTTON_DELAY_TIME_MILLIS,
  sleep,
} from '@components/Onboarding/v2/onboarding';

export const SidebarProductFlow = () => {
  const { updateOnboarding } = useOnboardingV2();
  const onboardingState = useAppSelector((state) => state.onboarding);

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const onEditProductTitleClick = async () => {
    dispatch(onboardingSlice.actions.startFormattingProductTitle());
  };

  const onContinueClick = async (nextStep) => {
    setLoading(true);
    await sleep(CONTINUE_BUTTON_DELAY_TIME_MILLIS);

    await updateOnboarding(onboardingState.data.id, {
      step: nextStep,
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const contentMap = {
    [PRODUCT_1_SETUP_STEP]:
      onboardingState.numProducts === 0 ? (
        <>
          We noticed that no products have been imported.
          <br />
          <br />
          Please ensure that your Recharge store has products. Then come back and click on{' '}
          <b>Add Products</b>.
          <br />
          <br />
          For more help, see our guide.
        </>
      ) : (
        <>
          This is where you’ll view which products can be managed in RechargeSMS. You’ll be able to
          setup swap groups and add-on items here too, but that’s for later.
          <br />
          <br />
          If you don’t see all your Recharge products here, click <b>Add Products</b> to import
          them.
        </>
      ),

    [PRODUCT_2_IMPORT_STEP]: (
      <>
        Select how you want to import your products. We offer the capabiity to do it in bulk or by
        specific product SKUs.
      </>
    ),

    [PRODUCT_3_FORMAT_TITLE_STEP]: onboardingState.isFormattingProductTitle ? (
      <>
        Quickly format all product titles with our templates.
        <br />
        <br />
        You can also format each title individually by clicking on the product listing directly.
      </>
    ) : (
      <>
        Concise product titles are critical to the quality of your subscriber’s experience in
        RechargeSMS.
        <br />
        <br />
        Click <b>Edit Product Titles</b> to review our templates for formatting titles.
      </>
    ),

    [PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP]: (
      <>
        You now know how to import new products and properly format titles. Last but not least, you
        need to know how to ignore products.
        <br />
        <br />
        You can also format each title individually by clicking on the product listing directly.
      </>
    ),

    [PRODUCT_5_IGNORE_PRODUCT_STEP]: (
      <>
        Any products you don’t want subscribers managing with RechargeSMS can be easily ignored.
        Think free trial, shipping insurance, or gift products.
        <br />
        <br />
        Simply click on 🚫 to ensure our system ignores these products.
      </>
    ),

    [PRODUCT_6_PROCEED_ACTIVATION_STEP]: (
      <>
        You’ve made it to the finish line of your shop’s configuration!
        <br />
        <br />
        Remember, you can always return to these settings and modify them as you’d like.
        <br />
        <br />
        Now, let’s choose how you’d like to activate RechargeSMS.
      </>
    ),
  };

  const primaryButtonMap = {
    [PRODUCT_1_SETUP_STEP]: (
      <ContinueButton
        onClick={() =>
          onContinueClick(
            onboardingState.numProducts === 0 ? PRODUCT_2_IMPORT_STEP : PRODUCT_3_FORMAT_TITLE_STEP
          )
        }
      >
        Continue
      </ContinueButton>
    ),

    [PRODUCT_2_IMPORT_STEP]: (
      <ContinueButton
        onClick={() => onContinueClick(PRODUCT_3_FORMAT_TITLE_STEP)}
        disabled={onboardingState.numProducts === 0}
      >
        Begin import to continue
      </ContinueButton>
    ),

    [PRODUCT_3_FORMAT_TITLE_STEP]: onboardingState.isFormattingProductTitle ? (
      <ContinueButton disabled>Continue</ContinueButton>
    ) : (
      <ContinueButton onClick={() => onEditProductTitleClick()}>Edit Product Titles</ContinueButton>
    ),

    [PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP]: (
      <ContinueButton onClick={() => onContinueClick(PRODUCT_5_IGNORE_PRODUCT_STEP)}>
        Continue
      </ContinueButton>
    ),

    [PRODUCT_5_IGNORE_PRODUCT_STEP]: (
      <ContinueButton onClick={() => onContinueClick(PRODUCT_6_PROCEED_ACTIVATION_STEP)}>
        Continue
      </ContinueButton>
    ),

    [PRODUCT_6_PROCEED_ACTIVATION_STEP]: (
      <ContinueButton variant="green" onClick={() => onContinueClick(ACTIVATION_STEP)}>
        Proceed to Activation Step
      </ContinueButton>
    ),
  };

  const stepTitleMap = {
    [PRODUCT_1_SETUP_STEP]: onboardingState.numProducts === 0 ? 'Import Products' : 'Your Products',
    [PRODUCT_2_IMPORT_STEP]: 'Bulk or Single SKU?',
    [PRODUCT_3_FORMAT_TITLE_STEP]: onboardingState.isFormattingProductTitle
      ? 'Choose Template'
      : 'Format Titles for SMS',
    [PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP]: 'Almost There!',
    [PRODUCT_5_IGNORE_PRODUCT_STEP]: 'Ignoring Products',
    [PRODUCT_6_PROCEED_ACTIVATION_STEP]: 'Ready? Set...',
  };

  const stepProgressMap = {
    [PRODUCT_1_SETUP_STEP]: (100 * 1) / 6,
    [PRODUCT_2_IMPORT_STEP]: (100 * 2) / 6,
    [PRODUCT_3_FORMAT_TITLE_STEP]: (100 * 3) / 6,
    [PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP]: (100 * 4) / 6,
    [PRODUCT_5_IGNORE_PRODUCT_STEP]: (100 * 5) / 6,
    [PRODUCT_6_PROCEED_ACTIVATION_STEP]: 100,
  };

  return (
    <SidebarLayout
      flowTitle={onboardingState.numProducts === 0 ? 'Product import' : 'Product setup'}
      welcomeProgressValue={100}
      featureProgressValue={100}
      productProgressValue={stepProgressMap[onboardingState.data.step]}
      content={contentMap[onboardingState.data.step]}
      primaryButton={primaryButtonMap[onboardingState.data.step]}
      stepTitle={stepTitleMap[onboardingState.data.step]}
      loading={loading}
    />
  );
};
