import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

interface SearchBarProps {
  handleSearch: (e) => void;
  inputProps?: React.HTMLAttributes<HTMLInputElement>;
}

export function SearchBar(props: SearchBarProps) {
  const { handleSearch, inputProps } = props;

  return (
    <Container {...props}>
      <IconContainer>
        <FontAwesomeIcon icon={faSearch} />
      </IconContainer>
      <Input onChange={handleSearch} {...inputProps} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.light1};
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-bottom: 10px;
  width: 100%;
  height: 30px;
  padding: 2px 10px;
`;

const Input = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 12px;
`;

const IconContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
