import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import styled from '@emotion/styled';

import { FormLabel, FormControl } from './TextInput';

interface ColorInputProps {
  name: string;
  label: string;
  inputProps?: any;
  registerOptions?: RegisterOptions;
}

export function ColorInput(props: ColorInputProps) {
  const { name, label, inputProps, registerOptions } = props;
  const {
    register,
    setValue,
    setFocus,
    watch,
    formState: { errors },
  } = useFormContext();

  const handleColorChange = (nameValue, color) => {
    setValue(nameValue, color);
    setFocus(nameValue);
  };
  const validate = (color) => /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(color);

  return (
    <FormRow className="m-0">
      <FormLabel>{label}</FormLabel>
      <div className="d-flex align-items-center">
        <FormColorInputWrapper>
          <input
            type="color"
            value={watch(name)}
            onChange={(e) => handleColorChange(name, e.target.value)}
          />
        </FormColorInputWrapper>
        <CustomFormControl
          name={name}
          placeholder="#HEX"
          isInvalid={errors?.[name]}
          {...register(name, { validate, ...registerOptions })}
          {...inputProps}
        />
      </div>
    </FormRow>
  );
}

const FormRow = styled(Form.Row)`
  flex-direction: column;
`;

const FormColorInputWrapper = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 26px;
  overflow: hidden;
  margin-right: 10px;
  margin-left: 5px;
  box-shadow: 0 0 2px 1px #000, 0 0 0 6px white, 0 0 0 7px #b6b6bd;

  [type='color'] {
    border: 0;
    padding: 0;
    width: 200%;
    height: 200%;
    cursor: pointer;
    transform: translate(-25%, -25%);
  }
`;

const CustomFormControl = styled(FormControl)`
  flex: 1;
`;
