import { MessageNode } from './MessageNode';
import { ProductSelectorNode } from './ProductSelectorNode';
import { CreateOrderNode } from './CreateOrderNode';
import { DelayNode } from './DelayNode';
import { EndNode } from './EndNode';
import { LoadingNode } from './LoadingNode';
import { ConditionalNode } from './ConditionalNode';
import { ApplyCouponNode } from './ApplyCouponNode';
import { ApplyOnetimeNode } from './ApplyOnetimeNode';
import { CustomerFilterNode } from './CustomerFilterNode';
import { ApplyShopifyDiscountNode } from './ApplyShopifyCouponNode';
import { ChargeReminderNode } from './ChargeReminderNode';

const sendSmsNodeTypes = {
  send_sms_question: MessageNode,
  send_sms_notification: MessageNode,
  send_sms_shopify_checkout: MessageNode,
  charge_reminder: ChargeReminderNode,
};

const sendEmailNodeTypes = {
  send_email_notification: MessageNode,
  send_email_shopify_checkout: MessageNode,
};

const conditionalNodeTypes = {
  conditional: ConditionalNode,
  conditional_subscriptions: ConditionalNode,
};

export const nodeTypes = {
  select_product: ProductSelectorNode,
  create_order: CreateOrderNode,
  delay: DelayNode,
  apply_discount: ApplyCouponNode,
  apply_shopify_discount: ApplyShopifyDiscountNode,
  apply_onetime: ApplyOnetimeNode,
  filter_at_risk_customers: CustomerFilterNode,
  loading: LoadingNode,
  end: EndNode,
  ...sendSmsNodeTypes,
  ...sendEmailNodeTypes,
  ...conditionalNodeTypes,
};
