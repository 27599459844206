import React from 'react';
import styled from '@emotion/styled';

import SadFace from '@assets/icons/sad-face.svg';
import Thumbnail from '@components/Image/Thumbnail';

interface EmptyStateProps {
  description: string;
  iconURL?: string;
}

export const EmptyState = (props: EmptyStateProps) => {
  const { iconURL = SadFace, description } = props;

  return (
    <Container>
      <Thumbnail src={iconURL} alt="Empty Page Icon" />
      <Description dangerouslySetInnerHTML={{ __html: description }} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  height: 100%;
`;

const Description = styled.h4`
  color: #9a9b9c;
  font-weight: 400;
`;
