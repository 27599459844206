import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Modal as BModal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

import { ModalTitle } from '@components/Onboarding/components/ModalTitle';

import { ModalBody } from './ModalBody';

interface ModalProps {
  title?: string;
  show?: boolean;
  onHide?(): void;
}

export const Modal = (props: PropsWithChildren<ModalProps>) => {
  const { children, title, show, onHide } = props;

  return (
    <BModal centered show={show} onHide={onHide}>
      <CloseButton icon={faTimes} onClick={onHide} size="lg" />

      {title && <ModalTitle>{title}</ModalTitle>}
      <CustomModalBody>{children}</CustomModalBody>
    </BModal>
  );
};

const CloseButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin: 16px;
  color: #9a9b9c;
`;

const CustomModalBody = styled(ModalBody)`
  padding-top: 1rem;
`;
