import React, { useRef } from 'react';

import { ModalHandles } from '@components/Modal/BaseModal';
import { CreateReplenishmentWorkflowModal } from '@pages/Workflows/components/Replenishment/CreateReplenishmentWorkflowModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import styled from '@emotion/styled';
import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';

export function CreateWorkflowButton() {
  const createModalRef = useRef<ModalHandles>(null);
  const { defaultWorkflows, currentTemplate, handleSelectWorkflow } = useWorkflowsContext();

  return (
    <>
      {defaultWorkflows && currentTemplate && (
        <>
          {currentTemplate.type === 'replenishment' && defaultWorkflows.length > 1 ? (
            <>
              <CreateButton type="button" onClick={() => createModalRef?.current?.toggleModal()}>
                Create New Workflow <FontAwesomeIcon icon={faPlus} />
              </CreateButton>
              <CreateReplenishmentWorkflowModal ref={createModalRef} learnMoreLink="/" />
            </>
          ) : (
            <CreateButton
              type="button"
              onClick={() => handleSelectWorkflow(defaultWorkflows[0].id)}
            >
              Create New Workflow <FontAwesomeIcon icon={faPlus} />
            </CreateButton>
          )}
        </>
      )}
    </>
  );
}

const CreateButton = styled.button`
  background: #ffffff;
  border-radius: 2px;
  border: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  font-weight: 600;
  margin-top: 24px;
  padding: 10px 10px 6px;

  svg {
    margin-left: 6px;
  }
`;
