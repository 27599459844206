import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import axios from '@lib/axios';
import { Shop } from '@typings/Shop';

interface BrandingSettingsProps {
  shop: Shop;
}

export function BrandingSettings(props: BrandingSettingsProps) {
  const { shop } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [logoURL, setLogoURL] = useState(shop?.logoUrl);

  const validateLogoUrl = async (url: string) => {
    if (url.length === 0) return true;

    try {
      const { data } = await axios.post('/sms/api/shops/validate_logo', { url });
      return data.valid_logo;
    } catch (e) {
      return false;
    }
  };

  async function update(formData) {
    try {
      setIsLoading(true);
      await axios.put('/sms/customize', formData);
    } finally {
      setLogoURL(formData.shop.logo_url);
      setIsLoading(false);
    }
  }

  return (
    <div className="shop-branding-section">
      <h2 className="features__header mt-2">Branding</h2>
      <Form onSubmit={handleSubmit(update)}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control defaultValue={shop?.customName} {...register('shop.custom_name')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Primary Color</Form.Label>
              <Form.Control
                defaultValue={shop?.mainColor}
                {...register('shop.main_color')}
                placeholder="#CD45EF"
              />
              <Form.Text className="text-muted">It will be used to brand e-mails</Form.Text>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Secondary Color</Form.Label>
              <Form.Control
                defaultValue={shop?.secondaryColor}
                {...register('shop.secondary_color')}
                placeholder="#CD45EF"
              />
              <Form.Text className="text-muted">It will be used to brand e-mails</Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Logo URL</Form.Label>
              <Form.Control
                defaultValue={logoURL}
                isInvalid={errors?.shop?.logo_url}
                {...register('shop.logo_url', { validate: validateLogoUrl })}
              />
              {errors?.shop?.logo_url && (
                <Form.Control.Feedback type="invalid">
                  Logo could not be loaded.
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            {logoURL && (
              <Form.Group>
                <Form.Label>Logo Preview</Form.Label>
                <LogoPreview>
                  <img src={logoURL} alt="logo-url" />
                </LogoPreview>
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            <Button type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const LogoPreview = styled.div`
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  text-align: center;
  padding: 10px;

  img {
    max-width: 50%;
  }
`;
