import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';

import { ifStyle } from '@utils/styles';

interface Props {
  type: string;
}

export function GreaterOrLessThan(props: Props) {
  const { type } = props;
  const { register, watch, setValue } = useFormContext();
  const propertyName = `filters.${type}.operator`;

  const operator = watch(propertyName);
  const setOperator = (op) => setValue(propertyName, op);

  return (
    <Container {...register(propertyName)}>
      <Option selected={operator === '>='} onClick={() => setOperator('>=')}>
        ≥
      </Option>
      <Option selected={operator === '<='} onClick={() => setOperator('<=')}>
        ≤
      </Option>
    </Container>
  );
}

const Container = styled.div`
  background: #f4f4f7;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 0 0.5rem;
  display: flex;
`;

const Option = styled.span<{ selected?: boolean }>`
  padding: 0 0.35rem;
  border-radius: 3px;
  cursor: pointer;
  height: 100%;
  border: 0.75px solid transparent;
  transition: 250ms;
  font-weight: 600;

  ${ifStyle('selected')(css`
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  `)}
`;
