import launchDrawer from '@assets/images/launch-drawer.png';
import launchLogo from '@assets/images/launch-logo.png';
import React from 'react';
import styled from '@emotion/styled';

export const SidebarActivationFlow = () => (
  <Container>
    <LaunchDrawer src={launchDrawer} />
    <LaunchLogo src={launchLogo} />
    <LaunchTextContainer>
      <LaunchText>Initializing launch...</LaunchText>
    </LaunchTextContainer>
  </Container>
);

const Container = styled.div`
  height: 375px;
  width: 100%;
  z-index: 2000 !important;
  position: relative;
`;

const LaunchTextContainer = styled.div`
  position: absolute;
  top: 20%;
  margin: 0 auto;
  text-align: center;
  z-index: 2002 !important;
  width: 100%;
`;

const LaunchText = styled.span`
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.47px;
  color: #fffbf6;
`;

const LaunchDrawer = styled.img`
  width: 100%;
  z-index: 2001 !important;
`;

const LaunchLogo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2002 !important;
  width: 70%;
`;
