import React from 'react';
import { BarChart } from 'react-chartkick';

import { MetricBase, MetricBaseProps } from './MetricBase';

export function MetricBarChart(props: MetricBaseProps) {
  return (
    <MetricBase {...props}>
      {(data) => <BarChart discrete colors={['#31D75A']} data={data} />}
    </MetricBase>
  );
}
