import React from 'react';
import styled from '@emotion/styled';

import { ProductImage } from '@components/Image/ProductImage';
import { DemoProductVariant } from '../TitleEditor';

interface DemoVariantProps {
  demoProduct: DemoProductVariant;
}

const DemoVariant = ({ demoProduct }: DemoVariantProps) => {
  const { internal_product_title, image_url, product_title } = demoProduct;

  return (
    <Container>
      <Image src={image_url} alt="product" height={45} width={45} />
      <div>
        <Title className="mb-0">{internal_product_title}</Title>
        <Subtitle>{product_title}</Subtitle>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: #f4f4f4;
  border-radius: 8px;
  height: 64px;
  display: flex;
  align-items: center;
`;

const Image = styled(ProductImage)`
  border-radius: 6px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.45);
  margin: 0 16px 0 10px;
`;

const Title = styled.h6`
  font-size: 18px;
  font-weight: 600;
  margin-top: 4px;
`;

const Subtitle = styled.small`
  color: #6b6b6b;
`;

export default DemoVariant;
