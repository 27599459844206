import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faExchangeAlt, faMobileAlt, faBolt } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';

import { Card } from '@components/Layout/Card';

const BestPracticesGuides = () => {
  const guides = [
    {
      title: 'Managing Products',
      description: 'Import products and create swap groups, add-on items, and more.',
      href:
        'https://support.rechargepayments.com/hc/en-us/articles/1500008279682-Managing-products-in-RechargeSMS',
      icon: faArchive,
      styling: 'bg-light',
    },
    {
      title: 'Managing Customers',
      description: 'Import and manage customers in our app.',
      href:
        'https://support.rechargepayments.com/hc/en-us/articles/4410327511831-Managing-customers-in-RechargeSMS',
      icon: faUser,
    },
    {
      title: 'Managing Messages',
      description: 'Access and update SMS message templates.',
      href:
        'https://support.rechargepayments.com/hc/en-us/articles/4402659600919-Managing-SMS-messages-in-RechargeSMS',
      icon: faExchangeAlt,
      styling: 'bg-light',
    },
    {
      title: 'Testing RechargeSMS',
      description: 'Send SMS to yourself or a cohort of internal stakeholders as a test.',
      href:
        'https://support.rechargepayments.com/hc/en-us/articles/6355576167319-Testing-RechargeSMS',
      icon: faMobileAlt,
    },
    {
      title: 'RechargeSMS FAQs',
      description: 'Answers to our most frequently asked questions.',
      href: 'https://support.rechargepayments.com/hc/en-us/articles/4402052314263-RechargeSMS-FAQ',
      icon: faBolt,
      styling: 'bg-light',
    },
  ];

  return (
    <Card className="h-auto mb-4" data-testid="best-practices-guides-component">
      <div className="d-flex align-items-center px-4 pt-3">
        <h2 className="font-weight-bold mb-0 p-2">Best Practices & Guides</h2>
      </div>
      <hr className="mb-0" />
      <div className="d-flex flex-column">
        {guides.map(({ title, description, href, icon, styling = '' }) => (
          <Link
            key={title}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="best-practices-guides-link"
          >
            <div className={`form-row align-items-center p-4 ${styling}`}>
              <IconContainer>
                <FontAwesomeIcon icon={icon} />
              </IconContainer>
              <div className="d-flex flex-column ml-4">
                <h5 className="font-weight-bold">{title}</h5>
                <Text>{description}</Text>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Card>
  );
};

const IconContainer = styled.div`
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
`;

const Text = styled.div`
  font-size: 16px;
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

export default BestPracticesGuides;
