import React, { useState, useEffect } from 'react';

import { Card } from '../Card';

import { InitialPage } from './InitialPage';
import { CartPage } from './CartPage';
import { LandingPage } from './LandingPage';

export type Pages = 'initial' | 'cart' | 'landing';

const pagesMapping = {
  initial: InitialPage,
  cart: CartPage,
  landing: LandingPage,
};

export function OptInToolsCard() {
  const [page, setPage] = useState<Pages>('initial');

  const PageComponent = pagesMapping[page];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('open') === 'cart-widget-opt-in') {
      setPage('cart');
    }
  }, []);

  useEffect(() => {
    if (page === 'cart') {
      document.getElementById('opt-in-tools-card').scrollIntoView();
    }
  }, [PageComponent]);

  return (
    <div id="opt-in-tools-card">
      <Card>
        <PageComponent setPage={setPage} />
      </Card>
    </div>
  );
}
