import React, { useRef } from 'react';
import find from 'lodash/find';
import remove from 'lodash/remove';
import toast from 'react-hot-toast';

import axios from '@lib/axios';
import { ViewPage, ViewPageHandles } from '@components/Layout/ViewPage';
import { formatString } from '@utils/date';

import { TemplateMessagesActions } from './TemplateMessagesActions';

const BASE_URL = '/sms/template_messages';

function View() {
  const ref = useRef<ViewPageHandles>();

  const filterOptions = [
    {
      value: 'order_by_title',
      label: 'Order by title',
    },
    {
      value: 'order_by_usage',
      label: 'Order by usage',
    },
  ];

  const tableHeaders = [
    {
      label: 'Name',
      width: '55%',
    },
    {
      label: 'Last modified',
    },
    {
      label: 'Actions',
    },
  ];

  function tableRow(templateMessage) {
    const data = [
      <strong>{templateMessage.title}</strong>,
      formatString(templateMessage.updatedAt, 'MM/dd/yyyy'),
      <TemplateMessagesActions templateMessage={templateMessage} handleDelete={handleDelete} />,
    ];

    return { data };
  }

  function handleDelete(id) {
    function onSuccess() {
      ref.current.setPage((page) => {
        const { data: templateMessages } = page;
        const templateMessage = find(templateMessages, { id });

        remove(templateMessages, templateMessage);

        return {
          ...page,
          data: templateMessages,
        };
      });

      return 'Message successfully deleted!';
    }

    toast.promise(axios.delete(`${BASE_URL}/${id}`), {
      loading: 'Deleting...',
      success: onSuccess,
      error: 'Something went wrong! try again later.',
    });
  }

  return (
    <ViewPage
      ref={ref}
      url={`${BASE_URL}/custom`}
      header={{
        description: 'Customize your SMS messages',
      }}
      filter={{
        options: filterOptions,
      }}
      table={{
        headers: tableHeaders,
        row: tableRow,
      }}
      searchPlaceholder="Search by title or content"
      paginationLimit={25}
    />
  );
}

export default View;
