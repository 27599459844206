import { CancelLink } from '@components/Router/CancelLink';
import { RechargeCustomer } from '@typings/RechargeCustomer';
import React from 'react';
import { Button, Card, Form as BootstrapForm, FormControl } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

export interface FormValues {
  recharge_customer: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
}

interface FormProps {
  rechargeCustomer: RechargeCustomer;
  onSubmit: (values: FormValues) => Promise<any>;
}

export function Form(props: FormProps) {
  const { rechargeCustomer, onSubmit } = props;
  const { formState, handleSubmit, register } = useForm({
    defaultValues: rechargeCustomer,
  });

  function internalSubmit(values: RechargeCustomer) {
    return onSubmit({
      recharge_customer: {
        id: values.id,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
      },
    });
  }

  return (
    <BootstrapForm onSubmit={handleSubmit(internalSubmit)}>
      <Card>
        <Card.Body>
          <BootstrapForm.Row>
            <BootstrapForm.Group className="col-md-6">
              <BootstrapForm.Label>First name</BootstrapForm.Label>
              <FormControl
                name="firstName"
                defaultValue=""
                isInvalid={!!formState.errors.firstName}
                {...register('firstName', { required: true })}
              />
              <BootstrapForm.Control.Feedback type="invalid">
                First name is a required field.
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
            <BootstrapForm.Group className="col-md-6">
              <BootstrapForm.Label>Last name</BootstrapForm.Label>
              <FormControl
                name="lastName"
                defaultValue=""
                isInvalid={!!formState.errors.lastName}
                {...register('lastName', { required: true })}
              />
              <BootstrapForm.Control.Feedback type="invalid">
                Last name is a required field.
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </BootstrapForm.Row>
          <BootstrapForm.Row>
            <BootstrapForm.Group className="col-md-6">
              <BootstrapForm.Label>Email</BootstrapForm.Label>
              <FormControl
                name="email"
                defaultValue=""
                isInvalid={!!formState.errors.email}
                {...register('email', { required: true })}
              />
              <BootstrapForm.Control.Feedback type="invalid">
                Email is a required field.
              </BootstrapForm.Control.Feedback>
            </BootstrapForm.Group>
          </BootstrapForm.Row>
        </Card.Body>
        <Card.Footer>
          <div className="form-actions text-right">
            <CancelLink to="/sms/recharge/customers" />
            <Button variant="primary" type="submit" disabled={formState.isSubmitting}>
              {formState.isSubmitting ? 'Loading...' : 'Update'}
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </BootstrapForm>
  );
}
