import React from 'react';
import styled from '@emotion/styled';
import { getBezierPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useTextWidth } from '@imagemarker/use-text-width';

function Body({ label, keywords }) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={`${label}-keywords`}>{keywords}</Tooltip>}
    >
      <Container>
        <Label>{label}</Label>
      </Container>
    </OverlayTrigger>
  );
}

export function LabelEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  label,
  data,
  arrowHeadType,
  markerEndId,
}) {
  const { labelDescription } = data;

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const foreignObjectSize = {
    width: useTextWidth({ text: label, font: '40px Times' }),
    height: 40,
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />

      <foreignObject
        width={foreignObjectSize.width}
        height={foreignObjectSize.height}
        x={edgeCenterX - foreignObjectSize.width / 2}
        y={edgeCenterY - foreignObjectSize.height / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <Body label={label} keywords={labelDescription} />
      </foreignObject>
    </>
  );
}

const Container = styled.div`
  padding: 5px 0px;
  color: ${(props) => props.theme.colors.light0};
  background: ${(props) => props.theme.colors.dark0};
  border-radius: 5px;
  font-weight: 500;
  text-align: center;
  width: fit-content;
  height: 100%;
`;

const Label = styled.p`
  margin-left: 15px;
  margin-right: 15px;
  font-size: 20px;
`;
