import styled from '@emotion/styled';
import React, { useRef, useState, useEffect } from 'react';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { Modal as BModal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import debounce from 'lodash/debounce';

import axios from '@lib/axios';
import { CustomButton } from '@components/Button/CustomButton';
import { Modal, ModalHandles } from '@pages/Workflows/components/Modal';
import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import { Button } from '@pages/Workflows/components/Button';
import { ProductCard } from '@pages/Workflows/components/ProductCard';
import { ProductVariant } from '@typings/ProductVariant';

import { ProductSelector } from './ProductSelector';
import BaseNode, { NodeProps } from '../BaseNode';

interface ApplyOnetimeNodeProps extends NodeProps {
  shopify_variant_id: number | string;
  shopify_product_id: number | string;
}

export const ApplyOnetimeNode = ({ data }: { data: ApplyOnetimeNodeProps }) => {
  const modalRef = useRef<ModalHandles>(null);
  const { handleChangeWorkflowStep } = useWorkflowsContext();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductVariant[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductVariant>();
  const [selectedProductId, setSelectedProductId] = useState<number>();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      const product = products.find(
        (productItem) => productItem.externalId === data?.shopify_variant_id
      );

      if (product) {
        setSelectedProduct(product);
        setSelectedProductId(product.id);
      }
    }
  }, [products]);

  async function getProducts(search = '') {
    setProducts([]);
    setLoading(true);

    const { data } = await axios.get('/sms/product_variants/search', {
      params: {
        search,
        feature: 'one_time_item',
        display_ignored: false,
      },
    });

    setProducts(data?.data);
    setLoading(false);
  }

  const handleSearch = debounce((e) => {
    getProducts(e.target.value);
  }, 1000);

  function handleToggleModal() {
    modalRef.current?.toggleModal();
  }

  function handleSave() {
    const product = products.find((productItem) => productItem.id === selectedProductId);

    const params = {
      shopify_variant_id: Number(product.externalId),
      shopify_product_id: Number(product.shopifyProduct.externalId),
    };

    setSelectedProduct(product);
    handleChangeWorkflowStep(Number(data.id), params, true);
    toast.success('One-time item successfully saved!');
    handleToggleModal();
  }

  return (
    <>
      <BaseNode
        icon={faTag}
        color="#138D75"
        bodyComponent={
          <EditButton onClick={handleToggleModal}>
            {selectedProduct ? 'Change Product' : 'Select Product'}
          </EditButton>
        }
        showCollapse={!!selectedProduct}
        collapseComponent={
          <SelectedProduct
            title={selectedProduct?.title}
            subtitle={selectedProduct?.priceText}
            variants={[selectedProduct]}
          />
        }
        {...data}
      />

      <Modal
        ref={modalRef}
        headerComponent={<BModal.Title>Selete one-time item</BModal.Title>}
        footerComponent={<CustomButton onClick={() => handleSave()}>Save</CustomButton>}
      >
        <ProductSelector
          loading={loading}
          products={products}
          selectedProductId={selectedProductId}
          handleSearch={handleSearch}
          handleSelectProduct={(id) => setSelectedProductId(id)}
        />
      </Modal>
    </>
  );
};

const EditButton = styled(Button)`
  width: 150px;
  justify-content: center;

  svg {
    margin-left: 5px;
  }
`;

const SelectedProduct = styled(ProductCard)`
  margin: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  cursor: grab;
  box-shadow: inset 0 7px 5px -7px ${(props) => props.theme.colors.dark0}44;

  > div {
    width: 45px;
  }
`;
