import React from 'react';
import {
  getEdgeCenter,
  getMarkerEnd,
  Position,
  EdgeSmoothStepProps,
  getSmoothStepPath,
  EdgeText,
} from 'react-flow-renderer';

export function SmoothstepEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  labelStyle,
  labelShowBg,
  labelBgStyle,
  labelBgPadding = [10, 8],
  labelBgBorderRadius,
  style,
  sourcePosition = Position.Bottom,
  targetPosition = Position.Top,
  arrowHeadType,
  markerEndId,
  borderRadius = 5,
}: EdgeSmoothStepProps) {
  const [centerX, centerY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  const path = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius,
  });

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const text = label ? (
    <EdgeText
      x={centerX}
      y={centerY}
      label={label}
      labelStyle={{ fontWeight: 'bold', borderRadius: 3, ...labelStyle }}
      labelShowBg={labelShowBg}
      labelBgStyle={labelBgStyle}
      labelBgPadding={labelBgPadding}
      labelBgBorderRadius={labelBgBorderRadius}
    />
  ) : null;

  return (
    <>
      <path style={style} className="react-flow__edge-path" d={path} markerEnd={markerEnd} />
      {text}
    </>
  );
}
