import React, { useEffect, useState } from 'react';
import axios from '@lib/axios';
import styled from '@emotion/styled';
import { Alert } from 'react-bootstrap';

// telling typescript that this variable exists
declare global {
  const zafClient: any;
}

interface AppRootProps {
  origin: string;
  token: string;
}

export function AppRoot(props: AppRootProps) {
  const { origin, token } = props;

  const [loading, setLoading] = useState(true);
  const [chatLink, setChatLink] = useState<string>();

  useEffect(() => {
    async function createLink() {
      const zendeskData = await (zafClient as any).get('ticket.requester.email');
      const { data: link } = await axios.get('/sms/zendesk_app/customer_chat_link', {
        params: {
          email: zendeskData['ticket.requester.email'],
          origin,
          token,
        },
      });

      setChatLink(link);
      setLoading(false);
    }

    createLink();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!chatLink) {
    return <Alert variant="secondary">Customer not found</Alert>;
  }

  function openPopup() {
    const popupReference = window.open(chatLink, 'popup', 'location=off,width=550,height=650');
    popupReference.focus();
  }

  return (
    <ZendeskButton className="c-btn" onClick={openPopup}>
      Text via SMS
    </ZendeskButton>
  );
}

const ZendeskButton = styled.button`
  width: 100%;
`;
