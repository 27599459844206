import React, { useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { EditMessageBody } from './EditMessageBody';
import { useLayoutContext } from '../../LayoutContext';
import { useWorkflowsContext } from '../../WorkflowsContext';
import Header from '../shared/Header';

import { NodeProps } from '../../MainArea/components/Nodes/BaseNode';

interface MessageNodeProps extends NodeProps {
  variables?: string[];
  body?: string;
  // eslint-disable-next-line camelcase
  show_email_fallback?: boolean;
  // eslint-disable-next-line camelcase
  email_fallback?: boolean;
  email?: {
    title: string;
    subject: string;
    body: string;
  };
}

interface FormValues {
  id?: number;
  title: string;
  body: string;
  email?: {
    title: string;
    subject: string;
    body: string;
  };
  email_fallback?: boolean;
}

export function EditMessage({ closeHandler }) {
  const { rightSidebar } = useLayoutContext();
  const { handleChangeWorkflowStep } = useWorkflowsContext();

  useEffect(() => {
    if (rightSidebar?.type !== 'edit-message') {
      return;
    }
    handleEditMessage(rightSidebar.data);
  }, [rightSidebar?.data]);

  function handleEditMessage(data: MessageNodeProps) {
    if (!data?.id) {
      return;
    }
    formProps.reset({
      id: data?.id || null,
      title: data?.title,
      body: data?.body,
      email: data?.email,
      email_fallback: data?.email_fallback,
    });
  }

  const formProps = useForm<FormValues>({
    shouldUnregister: false,
  });

  const { handleSubmit } = formProps;

  function handleSave(formData) {
    handleChangeWorkflowStep(Number(formData.id), formData, true);
    closeHandler();
  }

  return (
    <>
      <Header onClose={closeHandler}>
        <div>
          <h4>Edit Template</h4>
        </div>
      </Header>
      <FormProvider {...formProps}>
        <EditMessageBody
          handleSave={handleSubmit(handleSave)}
          variables={rightSidebar.data?.variables}
        />
      </FormProvider>
    </>
  );
}
