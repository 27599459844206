import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { Modal as BModal, Form, Button as BButton } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { Modal, ModalHandles } from '@pages/Workflows/components/Modal';
import { Button } from '@pages/Workflows/components/Button';
import { Drawer, MainDrawerHandles } from '@pages/Workflows/components/Drawer';

import { InfoModal } from './InfoModal';
import { EditMessage } from './EditMessage';

export function EmailFallback(props) {
  const { variables, handleSave } = props;

  const editEmailModalRef = useRef<MainDrawerHandles>(null);
  const infoModalRef = useRef<ModalHandles>(null);
  const { register, setValue, reset } = useFormContext();

  function handleToggleModal() {
    editEmailModalRef.current?.toggleDrawer();
  }

  function handleToggleInfoModal() {
    infoModalRef.current?.toggleModal();
  }

  function handleEmailFallbackChange(e) {
    setValue('email_fallback', e.target.checked);
    handleSave();
  }

  function handleCancel() {
    reset();
    handleToggleModal();
  }

  return (
    <Container>
      <Form.Check
        type="checkbox"
        {...register('email_fallback')}
        onChange={handleEmailFallbackChange}
      />
      <TextContainer>
        <Text>If message fails, send email fallback...</Text>
        <EditButton onClick={handleToggleModal}>Edit Email Template</EditButton>
      </TextContainer>
      <InfoIcon icon={faInfoCircle} onClick={handleToggleInfoModal} size="1x" />

      <Drawer
        ref={editEmailModalRef}
        size="lg"
        headerComponent={<BModal.Title>Edit Email Template</BModal.Title>}
        footerComponent={
          <>
            <BButton variant="outline-secondary" onClick={handleCancel}>
              Close
            </BButton>
            <BButton variant="secondary" onClick={handleSave}>
              Confirm
            </BButton>
          </>
        }
      >
        <EditMessage variables={variables} prefix="email" />
      </Drawer>

      <Modal
        ref={infoModalRef}
        size="lg"
        headerComponent={<BModal.Title>Information</BModal.Title>}
        bodyStyle={{ padding: 0 }}
      >
        <InfoModal />
      </Modal>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 18px 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 5px;
`;

const Text = styled.p`
  color: #191d48;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  margin: 0;
`;

const EditButton = styled(Button)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #b6b6bd;
  font-size: 12px;
  padding: 0;
  text-decoration-line: underline;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  color: white;
  border-radius: 50%;
  border: 1px solid black;
  background: black;
  cursor: pointer;
`;
