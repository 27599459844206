import { createSlice } from '@reduxjs/toolkit';

interface State {
  loading: boolean;
  isLockChargeReminderToggle: boolean;
  error: string;
}

const initialState: State = {
  loading: false,
  isLockChargeReminderToggle: true,
  error: null,
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setLockChargeReminderToggle: (state, action) => {
      state.isLockChargeReminderToggle = action.payload;
    },
  },
});
