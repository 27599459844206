import React, { useEffect } from 'react';
import Home from '@pages/Home';
import { useNavigate } from 'react-router-dom';
import { ProductSetupView } from '@components/Onboarding/v2/View/Product/ProductSetupView';
import { ProductImportView } from '@components/Onboarding/v2/View/Product/ProductImportView';
import { ProductFormatTitleView } from '@components/Onboarding/v2/View/Product/ProductFormatTitleView';
import { ProductFormatTitleCompleteView } from '@components/Onboarding/v2/View/Product/ProductFormatTitleCompleteView';
import { useAppSelector } from '@redux/hooks';
import { ProductView } from '@components/Onboarding/v2/components/ProductView';
import {
  PRODUCT_1_SETUP_STEP,
  PRODUCT_2_IMPORT_STEP,
  PRODUCT_3_FORMAT_TITLE_STEP,
  PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP,
  PRODUCT_5_IGNORE_PRODUCT_STEP,
  PRODUCT_6_PROCEED_ACTIVATION_STEP,
} from '@components/Onboarding/v2/onboarding';

const PRODUCT_VIEW_MAP = {
  [PRODUCT_1_SETUP_STEP]: <ProductSetupView />,
  [PRODUCT_2_IMPORT_STEP]: <ProductImportView />,
  [PRODUCT_3_FORMAT_TITLE_STEP]: <ProductFormatTitleView />,
  [PRODUCT_4_FORMAT_TITLE_COMPLETE_STEP]: <ProductFormatTitleCompleteView />,
  [PRODUCT_5_IGNORE_PRODUCT_STEP]: <ProductView highlightIgnore />,
  [PRODUCT_6_PROCEED_ACTIVATION_STEP]: <ProductView />,
};

export const ProductOnboardingView = () => {
  const onboardingState = useAppSelector((state) => state.onboarding);

  const navigate = useNavigate();

  useEffect(() => {
    if (!onboardingState.isDisplay || !onboardingState.isModalOpen) {
      navigate('/sms/product_variants');
    }
  }, [onboardingState]);

  return PRODUCT_VIEW_MAP.hasOwnProperty(onboardingState.data.step) ? (
    PRODUCT_VIEW_MAP[onboardingState.data.step]
  ) : (
    <ProductView />
  );
};
