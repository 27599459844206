/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import styled from '@emotion/styled';

import EditIcon from '@assets/icons/edit.svg?react';
import axios from '@lib/axios';
import { CloseIcon } from '@components/Modal/CloseIcon';
import { CustomButton as Button } from '@components/Button/CustomButton';

import { useAppDispatch } from '@redux/hooks';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';
import CustomVariablesInput from './CustomVariablesInput';
import Template from './Template';
import DemoVariant from './editor/DemoVariant';
import useDemoProduct from './editor/useDemoProduct';

interface Template {
  id: string;
  included_variables: string[];
  label: string;
  selected: boolean;
  type: string;
  value: string;
}

export interface DemoProductVariant {
  product_title: string;
  variant_title: string;
  internal_product_title: string;
  image_url: string;
}

export interface TitleEditorProps {
  templates: Template[];
  variables: string[];
  demoProductVariant: DemoProductVariant;
  highlightButton?: boolean;
  showModal?: boolean;
  handleCloseEditProductTitle?: () => void;
}

export const TitleEditor = (props: TitleEditorProps) => {
  const {
    templates = [],
    variables = [],
    demoProductVariant,
    highlightButton = false,
    showModal = false,
    handleCloseEditProductTitle = null,
  } = props;

  const { defaultTemplates, customTemplate, currentTemplate } = Template.Group(templates);
  const [customTemplateSelected, setCustomTemplateSelected] = useState(
    currentTemplate.type === 'custom'
  );
  const initalTemplate = currentTemplate.id ? currentTemplate : defaultTemplates[0];
  const [template, setTemplate] = useState(initalTemplate);
  const [showEditor, setShowEditor] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setShowEditor(showModal);
  }, [showModal]);

  const availableVariables = variables?.filter(
    (variable) => !template.included_variables.includes(variable)
  );

  const demoProduct = useDemoProduct(demoProductVariant, template);

  const closeEditor = () => setShowEditor(false);

  const onTemplateTypeChange = (e) => {
    const radioButton = e.target;
    const isCustomTemplate = radioButton.id === 'custom-template';

    setCustomTemplateSelected(isCustomTemplate);

    const newTemplate =
      defaultTemplates.find((template) => template.id === radioButton.id) || customTemplate;

    setTemplate(newTemplate);
  };

  const handleCustomTemplateChange = (e) => {
    const newTemplateValue = e.detail.value;
    const parsedVariable = Template.BuildVariableInterpolation(newTemplateValue);
    const newCustomTemplateValue = {
      ...customTemplate,
      value: parsedVariable,
      included_variables: Template.ExtractVariable(parsedVariable),
    };

    setTemplate(newCustomTemplateValue);
  };

  const variableAddedHandler = (variable) => {
    const newCustomTemplateValue = {
      ...customTemplate,
      value: `${template.value} {{${variable}}}`,
      included_variables: [...template.included_variables, variable],
    };

    setTemplate(newCustomTemplateValue);
  };

  const updateAllProductTitles = async () => {
    try {
      const { data } = await axios.put(`/sms/product_variants/all`, { template });
      closeEditor();
      dispatch(onboardingSlice.actions.completeFormattingProductTitle());
      toast.success(data.message);
    } catch (error) {
      const { data } = error.response;
      toast.error(data.message);
    }
  };

  const updateProductsHandler = () => {
    updateAllProductTitles();
  };

  const getEditorClassName = () => (highlightButton ? 'highlight-button mr-2' : 'mr-2');

  const onEditProductTitleClick = () => {
    setShowEditor(!highlightButton);
    dispatch(onboardingSlice.actions.startFormattingProductTitle());
  };

  const onCloseClick = () => {
    setShowEditor(false);
    if (handleCloseEditProductTitle) {
      handleCloseEditProductTitle();
    }
  };

  return (
    <>
      <div className={getEditorClassName()}>
        <Button
          variant="secondary"
          className="onboarding-batch-variant-title-editor"
          onClick={() => onEditProductTitleClick()}
        >
          Edit Product Titles
          <EditIcon className="ml-2" style={{ transform: 'scale(1.25)' }} />
        </Button>
      </div>

      <Modal
        show={showEditor}
        onHide={closeEditor}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Container>
          <Header>
            <Title>Format Titles</Title>
            <CloseIcon onClose={onCloseClick} />
          </Header>

          <Body>
            <Form>
              {defaultTemplates.map((defaultTemplate) => (
                <Form.Check
                  id={defaultTemplate.id}
                  key={defaultTemplate.id}
                  type="radio"
                  label={defaultTemplate.label}
                  name="template"
                  value={defaultTemplate.value}
                  onChange={(e) => onTemplateTypeChange(e)}
                  checked={template.value === defaultTemplate.value}
                />
              ))}

              <Form.Check
                id={customTemplate.id}
                type="radio"
                label={customTemplate.label}
                name="template"
                value={template.value}
                checked={template.type === 'custom'}
                onChange={(e) => onTemplateTypeChange(e)}
              />

              {customTemplateSelected && (
                <CustomVariablesInput
                  id="custom-template-input"
                  name="custom-template-input"
                  variables={variables}
                  availableVariables={availableVariables}
                  placeholder="i.e. Variant Title + Product Title + Quantity"
                  changeHandler={handleCustomTemplateChange}
                  show={customTemplateSelected}
                  currentTemplate={template.value}
                  variableAddedHandler={variableAddedHandler}
                />
              )}
            </Form>
            <div className="mt-3">
              <DemoVariant demoProduct={demoProduct} />
            </div>
          </Body>
          <Footer>
            <div className="text-right">
              <Button variant="outline-secondary" onClick={onCloseClick}>
                Close
              </Button>
              <Button
                variant="secondary"
                disabled={!template.id}
                onClick={updateProductsHandler}
                className="ml-2"
              >
                Format Titles
              </Button>
            </div>
          </Footer>
        </Container>
      </Modal>
    </>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`;

const Title = styled.h2`
  color: #191d48;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
`;

const Body = styled.div`
  accent-color: #3901f1;
  width: 100%;
`;

const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;

  button {
    border-radius: 6px;
    padding: 0.5em 1em;
  }
`;
