import React, { CSSProperties } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import styled from '@emotion/styled';

interface TextInputProps {
  name: string;
  label: string;
  inputProps?: any;
  registerOptions?: RegisterOptions;
  labelStyle?: CSSProperties;
  controlStyle?: CSSProperties;
  labelComponent?: React.ReactNode;
}

export function TextInput(props: TextInputProps) {
  const {
    name,
    label,
    inputProps,
    registerOptions,
    labelStyle,
    labelComponent,
    controlStyle,
  } = props;
  const { register } = useFormContext();

  return (
    <Form.Row className="m-0">
      <FormLabel style={labelStyle}>
        {label}
        {labelComponent}
      </FormLabel>
      <FormControl
        style={controlStyle}
        name={name}
        {...register(name, registerOptions)}
        {...inputProps}
      />
    </Form.Row>
  );
}

export const FormLabel = styled(Form.Label)`
  font-size: 14px;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.35rem;
  line-height: 21px;
  color: #191d48;
  font-weight: 500;
`;

export const FormControl = styled(Form.Control)`
  border-radius: 6px;
  padding: 0.375rem 0.5rem;
  background: #ffffff;
  border: 1px solid #b6b6bd;
`;
