import React from 'react';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';
import { useOnboardingV2 } from '@hooks/useOnboardingV2';

export default function PauseOnboardingButton() {
  const { updateOnboarding } = useOnboardingV2();
  const onboardingState = useAppSelector((state) => state.onboarding);
  const dispatch = useAppDispatch();

  const onCloseClick = async () => {
    if (onboardingState.data?.id) {
      await updateOnboarding(onboardingState.data?.id, {
        is_paused: true,
      });

      dispatch(onboardingSlice.actions.setOnboardingModal(false));
      dispatch(onboardingSlice.actions.setSidebarCollapsed(true));
    }
  };

  return (
    <CloseButton onClick={() => onCloseClick()}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
          fill="#FFFBF6"
        />
      </svg>
    </CloseButton>
  );
}

const CloseButton = styled.button`
  background-color: transparent;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 2000 !important;

  border: none;
  margin: 0;
  padding: 0;
  opacity: 0.65;
  transition: 150ms;

  &:hover {
    opacity: 1;
  }
`;
