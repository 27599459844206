import React, { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faEye as faEyeSolid } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEye } from '@fortawesome/free-regular-svg-icons';

import { FeatureSwitchContainerProps } from './FeatureSwitchContainer';
import { AutoSelectFeatureSwitch } from '../../tabs/Messaging/components/AutoSelectFeatureSwitch';

export interface FeatureSwitchProps extends FeatureSwitchContainerProps {
  checked?: boolean;
  loading?: boolean;
  disabled?: boolean;
  showActions?: boolean;
  showPreview?: boolean;
  isFeatureSelected?: boolean;
  isPreviewSelected?: boolean;
  tags?: ReactNode;
  onPreview?(featureKey): void;
  handleToggle?: React.ChangeEventHandler<HTMLInputElement>;
}

const FeatureSwitch = ({
  feature,
  selectedFeature,
  checked,
  loading,
  disabled,
  show = true,
  showActions = true,
  showPreview = true,
  onInfo,
  onPreview,
  handleToggle,
  isFeatureSelected,
  isPreviewSelected,
  childNestedLevel = 0,
  children,
  tags,
}: PropsWithChildren<FeatureSwitchProps>) => (
  <>
    <OuterContainer show={show}>
      <Container>
        <Switch>
          <Form.Check
            type="switch"
            id={feature.key}
            name={feature.key}
            label={feature.name}
            checked={checked}
            onChange={handleToggle}
            disabled={disabled || loading}
            style={{
              marginLeft: `${childNestedLevel}rem`,
            }}
          />
        </Switch>
        <Options>
          {feature?.recommended && (
            <Recommended className="onboarding-features-recommended">RECOMMENDED</Recommended>
          )}
          {tags}

          {showActions && (
            <IconsContainer className="onboarding-features-list-actions">
              <Icon
                size="1x"
                icon={faInfoCircle}
                inverse={!isFeatureSelected}
                onClick={() => onInfo(feature)}
              />
              <Icon
                size="lg"
                icon={isPreviewSelected ? faEyeSolid : faEye}
                hide={childNestedLevel > 0 || !showPreview}
                onClick={() => onPreview(feature.key)}
              />
            </IconsContainer>
          )}
        </Options>
      </Container>
      {checked && children && <ChildrenContainer>{children}</ChildrenContainer>}
    </OuterContainer>
    {++childNestedLevel &&
      feature?.features?.map(
        (childFeature) =>
          checked && (
            <AutoSelectFeatureSwitch
              key={childFeature.id}
              feature={childFeature}
              selectedFeature={selectedFeature}
              childNestedLevel={childNestedLevel}
              onInfo={onInfo}
            />
          )
      )}
  </>
);

interface OuterContainerProps {
  show?: boolean;
}

const OuterContainer = styled.div<OuterContainerProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  border-bottom: 1px solid #dedede;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #fbfbfb;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 45px;
  padding: 0 15px;
`;

const Switch = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 2;
`;

const Options = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Recommended = styled.span`
  border-radius: 6px;
  font-size: 14px;
  padding: 6px;
  font-weight: bold;
  margin-right: 10px;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0.1em;
  color: #9a9b9c;
`;

const IconsContainer = styled.div`
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface IconProps {
  hide?: boolean;
  inverse?: boolean;
}

const Icon = styled(FontAwesomeIcon)<IconProps>`
  cursor: pointer;
  display: ${(props) => (props.hide ? 'none' : 'inline-block')};

  ${(props) =>
    props.inverse &&
    `
      background: #000000;
      border-radius: 50%;
      border: 1px solid #000000;
    `}
`;

const ChildrenContainer = styled.div`
  padding: 15px 0;
`;

export default FeatureSwitch;
