import React, { useRef } from 'react';
import styled from '@emotion/styled';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import axios from '@lib/axios';
import { ViewPage, ViewPageHandles } from '@components/Layout/ViewPage';
import { useFlag } from '@hooks/useFlag';
import { ProductImage } from '@components/Image/ProductImage';
import { formatString } from '@utils/date';
import { SwapGroupType } from '@typings/SwapGroup';

import { SwapGroupActions } from './SwapGroupActions';
import { SmartSwapSection } from './SmartSwapSection';

interface ViewProps {
  groupType: SwapGroupType;
}

function View(props: ViewProps) {
  const { groupType } = props;
  const navigate = useNavigate();
  const ref = useRef<ViewPageHandles>();
  const { enabled: smartSwapEnabled } = useFlag('smart_swap');
  const isSwapGroupsType = groupType === 'swap';
  const baseUrl = isSwapGroupsType ? '/sms/swap_groups' : '/sms/quantity_groups';

  const tableHeaders = [
    {
      label: 'Title',
      width: '60%',
    },
    {
      label: 'Created at',
    },
    {
      label: 'Actions',
    },
  ];

  function tableRow(swapGroup) {
    const data = [
      <div className="d-flex">
        <ProductImage src={swapGroup.variants[0].imageUrl} width={80} height={80} />

        <TitleContainer>
          <h4 className="h5 font-weight-bold">{swapGroup.name}</h4>
          <p className="text-muted mb-0">{swapGroup.variants.length} items</p>
        </TitleContainer>
      </div>,
      formatString(swapGroup.createdAt, 'MM/dd/yyyy'),
      <SwapGroupActions swapGroup={swapGroup} baseUrl={baseUrl} handleDelete={handleDelete} />,
    ];

    return { data };
  }

  function HeaderDescription() {
    return (
      <div className="d-flex">
        <span className="mr-1">
          List and view details of your product {isSwapGroupsType ? 'swap' : 'quantity'} groups
        </span>
      </div>
    );
  }

  function handleDelete(id) {
    function onSuccess() {
      ref.current.handleSearch();

      return 'Group successfully deleted!';
    }

    toast.promise(axios.delete(`${baseUrl}/${id}`), {
      loading: 'Deleting...',
      success: onSuccess,
      error: 'Something went wrong! try again later.',
    });
  }

  return (
    <>
      <ViewPage
        ref={ref}
        url={baseUrl}
        header={{
          description: <HeaderDescription />,
          component: {
            text: isSwapGroupsType ? 'New Swap Group' : 'New Quantity Group',
            className: 'onboarding-new-swap-group',
            onClick: () => {
              navigate(`${baseUrl}/new`);
            },
          },
        }}
        table={{
          headers: tableHeaders,
          row: tableRow,
        }}
        paginationLimit={5}
      >
        {isSwapGroupsType && (
          <SmartSwapContainer>{smartSwapEnabled && <SmartSwapSection />}</SmartSwapContainer>
        )}
      </ViewPage>
    </>
  );
}

export default View;

const SmartSwapContainer = styled.div`
  padding: 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 1rem;
`;
