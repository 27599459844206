import { css, cx } from '@emotion/css';
import React, { ComponentProps, PropsWithChildren } from 'react';
import Button from 'react-bootstrap/Button';

type CustomButtonProps = ComponentProps<typeof Button>;

export function CustomButton(props: PropsWithChildren<CustomButtonProps>) {
  const { children, className, ...rest } = props;
  return (
    <Button className={cx(buttonClass, className)} {...rest}>
      {children}
    </Button>
  );
}

const buttonClass = css`
  &&& {
    font-weight: bold;
  }

  &:disabled {
    background-color: #c4c4c4;
    border-color: #c4c4c4;
    color: var(--white);
    cursor: not-allowed;
  }

  &.btn-outline-primary {
    color: #000000;
  }
`;
