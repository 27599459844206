import colors from './colors';
import radius from './radius';
import spacing from './spacing';

const theme = {
  colors,
  radius,
  spacing,
};

declare module '@emotion/react' {
  export interface Theme {
    colors: typeof colors;
    radius: typeof radius;
    spacing: typeof spacing;
  }
}

export default theme;
