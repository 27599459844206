import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ActivateButton } from '@components/Button/ActivateButton';
import { ActivateModal } from '@components/Replenishment/ActivateModal/ActivateModal';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { ExtensionDisabledWarningToast } from './ExtensionDisabledWarningToast';

export const Activate = () => {
  const shop = useCurrentShop();
  const [modalActive, setModalActive] = useState(false);

  const extensionEnabled = shop.postPurchaseExtensionEnabled;
  const activationState = extensionEnabled ? 'active' : 'pending_activation';
  const activationStateText = extensionEnabled ? 'ACTIVE' : 'INACTIVE';

  function toggleModal() {
    setModalActive((old) => !old);
  }

  return (
    <StatusIndicator>
      <ActivateButton
        activationState={activationState}
        onClick={toggleModal}
        cursorStyle={extensionEnabled ? 'default' : 'pointer'}
      >
        {activationStateText}
      </ActivateButton>

      <ActivateModal show={!extensionEnabled && modalActive} onHide={toggleModal} />

      {!extensionEnabled && <ExtensionDisabledWarningToast />}
    </StatusIndicator>
  );
};

const StatusIndicator = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  bottom: 7px;
  right: 10px;
  z-index: 99;
`;
