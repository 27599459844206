import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from 'react-bootstrap';
import { Toggle } from '@components/Input';
import infoIcon from '@assets/images/info-icon.png';
import styled from '@emotion/styled';
import api from '@components/Onboarding/activate/components/ActivateModal/MoreOptions/api';
import {
  InstallOptInCol,
  LaunchButtonCol,
  MoreOptionsCol,
  LaunchButton,
  MoreOptionsText,
  InfoIcon,
  TopBackground,
  Title,
  Description,
  BottomContainer,
  InstallOptInText,
  InstallOptInNote,
  TopContainer,
  LoadingModal,
} from '@components/Onboarding/v2/components/styling/ActivationModalStyling';
import {
  RowMt16,
  RowMt20,
  RowMt30,
  ColPl0,
  ColPr0,
  CenterLoading,
} from '@components/Onboarding/v2/components/styling/OnboardingViewStyling';
import {
  MAX_TEST_SEGMENT_EMAILS,
  ENABLE_SMS_TEST_SEGMENT_NAME,
  ENABLE_SMS_TEST_SEGMENT_DESCRIPTION,
  ENABLE_SMS_TEST_SEGMENT_TARGET,
} from '@components/Onboarding/v2/onboarding';
import axios from '@lib/axios';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { useBaseAppContext } from '@components/BaseAppContext';

interface MoreOptionsModalProps {
  handleGoBackClick(): void;
  handleComplete(): void;
}

const MoreOptionsModal = (props: MoreOptionsModalProps) => {
  const { handleGoBackClick, handleComplete } = props;

  const [enable, setEnable] = useState<boolean>(false);
  const [segmentEmailsInput, setSegmentEmailsInput] = useState('');
  const [segmentEmailsInputError, setSegmentEmailsInputError] = useState('');
  const [loading, setLoading] = useState<boolean>(true);

  const [segmentId, setSegmentId] = useState<number>(null);

  const currentShop = useCurrentShop();

  const { updateRechargeGeneralAttribute } = useBaseAppContext();

  const isSegmentEmailsValid = () => segmentEmailsInput && !segmentEmailsInputError;

  const onChangeTestSegmentEmails = (segmentEmails) => {
    setSegmentEmailsInput(segmentEmails);
    const emails = segmentEmailsInput.split(',');
    if (emails.length > MAX_TEST_SEGMENT_EMAILS) {
      setSegmentEmailsInputError(
        `Too many recipients. Please limit to ${MAX_TEST_SEGMENT_EMAILS}.`
      );
    } else {
      setSegmentEmailsInputError('');
    }
  };

  useEffect(() => {
    getSegment();
  }, []);

  async function getSegmentsByName(name) {
    const response = await axios.get(`/sms/segments`, {
      params: {
        search: name,
      },
    });
    if (response.status === 200) return response.data.data;

    return [];
  }

  async function getSegment() {
    setLoading(true);

    const segments = await getSegmentsByName(ENABLE_SMS_TEST_SEGMENT_NAME);
    if (segments.length !== 0) {
      setSegmentId(segments[0].id);
    }

    setLoading(false);
  }

  const onLaunchClick = async () => {
    let updateSegmentId = segmentId;
    if (!updateSegmentId) {
      const newSegment = await axios.post('/sms/segments/', {
        segment: {
          name: ENABLE_SMS_TEST_SEGMENT_NAME,
          description: ENABLE_SMS_TEST_SEGMENT_DESCRIPTION,
          target: ENABLE_SMS_TEST_SEGMENT_TARGET,
          recharge_customers_ids: [],
        },
      });
      updateSegmentId = newSegment.data.id;
      setSegmentId(updateSegmentId);
    }

    const response = enable
      ? await api.saveCustomerToSegment(updateSegmentId, segmentEmailsInput.split(','))
      : await api.removeCustomerFromSegment(updateSegmentId, segmentEmailsInput.split(','));

    if (response?.status === 200) {
      api.updateSegment(updateSegmentId, {
        inactive_at: null,
      });
      activate();
      handleComplete();
    }
  };

  // this modal can only be confirmed when active
  async function activate() {
    // activate the GA rc but do not activate the checkout script for rcs or sci
    updateRechargeGeneralAttribute(false);

    // flag store active_at in sms
    axios.post('/sms/settings/shop_activate');

    // temp set so status indicator is updated
    currentShop.activeAt = Date.now();
  }

  return loading ? (
    <LoadingModal show aria-labelledby="contained-modal-title-vcenter" centered>
      <TopBackground />
      <CenterLoading />
    </LoadingModal>
  ) : (
    <Modal show aria-labelledby="contained-modal-title-vcenter" centered>
      <TopBackground />
      <TopContainer>
        <Title>More Options</Title>
        <Description>
          You can enable the service for a group of subscribers. For example, a handful of your own
          team-members which you can create below.
        </Description>
      </TopContainer>

      <BottomContainer>
        <Row>
          <Col sm={5}>
            <Toggle
              disabled={!isSegmentEmailsValid()}
              checked={enable}
              onChange={() => setEnable(!enable)}
            />
          </Col>
          <InstallOptInCol sm={7}>
            <InstallOptInText>Enable SMS for subscribers in a segment</InstallOptInText>
          </InstallOptInCol>
        </Row>
        <RowMt16>
          <ColPr0 sm={1}>
            <InfoIcon src={infoIcon} width={10} height={10} />
          </ColPr0>
          <ColPl0 sm={8}>
            <InstallOptInNote>
              This will activate RechargeSMS for groups of subscribers. Usually enabled for testing
              purposes.
            </InstallOptInNote>
          </ColPl0>
        </RowMt16>
        <RowMt20>
          <Col>
            <CreateTestSegmentTitle>Create Test Segment</CreateTestSegmentTitle>
          </Col>
        </RowMt20>
        <RowMt16>
          <Col>
            <CreateTestSegmentForm
              className="form-control"
              rows={5}
              placeholder={`Enter valid email addresses and separate by commas. Test segments are limited to ${MAX_TEST_SEGMENT_EMAILS} email addresses.`}
              value={segmentEmailsInput}
              onChange={(e) => onChangeTestSegmentEmails(e.target.value)}
            />
            {segmentEmailsInputError && (
              <TestSegmentInputError>{segmentEmailsInputError}</TestSegmentInputError>
            )}
          </Col>
        </RowMt16>
        <RowMt30>
          <MoreOptionsCol sm={6}>
            <MoreOptionsText onClick={handleGoBackClick}>Go back?</MoreOptionsText>
          </MoreOptionsCol>
          <LaunchButtonCol sm={6}>
            <LaunchButton
              disabled={!isSegmentEmailsValid() || !enable}
              onClick={() => onLaunchClick()}
            >
              Launch
            </LaunchButton>
          </LaunchButtonCol>
        </RowMt30>
      </BottomContainer>
    </Modal>
  );
};

export default MoreOptionsModal;

const CreateTestSegmentTitle = styled.div`
  font-family: Greycliff;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

const CreateTestSegmentForm = styled.textarea`
  border: 1px solid #a7a7a7;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #f4f4f4;
`;

const TestSegmentInputError = styled.div`
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.47px;
  color: #ec3d10;
  margin-top: 10px;
`;
