import Modal from 'react-bootstrap/Modal';
import { useCurrentShop } from '@hooks/useCurrentShop';
import axios from '@lib/axios/axios';
import { WidgetPreview } from '@pages/Home/components/OptInToolsCard/components';
import { Col, Row } from 'react-bootstrap';
import { Toggle } from '@components/Input';
import infoIcon from '@assets/images/info-icon.png';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  InstallOptInCol,
  LaunchButtonCol,
  MoreOptionsCol,
  LaunchButton,
  MoreOptionsText,
  InfoIcon,
  TopBackground,
  Title,
  Description,
  BottomContainer,
  InstallOptInText,
  InstallOptInNote,
  TopContainer,
  LoadingModal,
} from '@components/Onboarding/v2/components/styling/ActivationModalStyling';
import {
  RowMt16,
  RowMt30,
  ColPl0,
  ColPr0,
  CenterLoading,
} from '@components/Onboarding/v2/components/styling/OnboardingViewStyling';
import { sleep, DEFAULT_DELAY_TIME_MILLIS } from '@components/Onboarding/v2/onboarding';
import { useBaseAppContext } from '@components/BaseAppContext';

interface LaunchModalProps {
  handleMoreOptionClick(): void;
  handleComplete(): void;
  description: string;
  completeButtonLabel: string;
  disableCompleteButton: boolean;
}

const LaunchModal = (props: LaunchModalProps) => {
  const {
    handleMoreOptionClick,
    handleComplete,
    description,
    completeButtonLabel,
    disableCompleteButton,
  } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const [enable, setEnable] = useState<boolean>(false);
  const [optInText, setOptInText] = useState<string>('');
  const currentShop = useCurrentShop();
  const {
    cartWidgetConfig,
    updateCartWidgetConfig,
    updateRechargeGeneralAttribute,
  } = useBaseAppContext();

  const sciCheckout = currentShop.checkoutPlatform !== 'recharge';

  async function updateWidgetConfig() {
    if (sciCheckout) {
      setShowAnimation(true);
      await updateCartWidgetConfig({
        enabled: !cartWidgetConfig.enabled,
      });
      setEnable(!cartWidgetConfig.enabled);
    }
  }

  useEffect(() => {
    if (!sciCheckout) {
      setEnable(!!currentShop.activeAt);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!sciCheckout) {
      if (enable) {
        setOptInText('Widget installed');
      } else {
        setOptInText('Install opt-in checkbox');
      }
    }
  }, [enable]);

  useEffect(() => {
    if (sciCheckout && cartWidgetConfig) {
      setEnable(cartWidgetConfig.enabled);
      renderOptInText(cartWidgetConfig.enabled);
      setLoading(false);
    }
  }, [cartWidgetConfig]);

  const renderOptInText = async (isEnable) => {
    if (!sciCheckout) {
      return;
    }

    if (isEnable) {
      if (showAnimation) {
        setOptInText('Installing widget...');
        await sleep(DEFAULT_DELAY_TIME_MILLIS);
        setOptInText('Checking for widget...');
        await sleep(DEFAULT_DELAY_TIME_MILLIS);
        setOptInText('Widget installed!');
        setShowAnimation(false);
      } else {
        setOptInText('Widget installed');
      }
    } else {
      setOptInText('Install cart opt-in checkbox');
      setShowAnimation(false);
    }
  };

  async function completeButtonHandler() {
    activate();
    handleComplete();
  }

  async function activate() {
    // This call sets the 2 general attributes in recharge
    // this first that sms is installed and the second using this active param that is for the checkout script
    if (sciCheckout) {
      updateRechargeGeneralAttribute(false);
    } else {
      updateRechargeGeneralAttribute(enable);
    }

    // This sets the shop as active in sms db
    if (!enable) {
      axios.post('/sms/settings/shop_deactivate');
    }
    if (enable) {
      axios.post('/sms/settings/shop_activate');
    }

    // hacky fix to update status indicator, ideally would refresh the currentshop value, but I have no idea how to
    currentShop.activeAt = enable ? Date.now() : null;
  }

  return loading ? (
    <LoadingModal show aria-labelledby="contained-modal-title-vcenter" centered>
      <TopBackground />
      <CenterLoading />
    </LoadingModal>
  ) : (
    <Modal show aria-labelledby="contained-modal-title-vcenter" centered>
      <TopBackground />
      <TopContainer>
        <Title>Launch RechargeSMS</Title>
        <Description>{description}</Description>

        {sciCheckout ? (
          <div>
            <SciOptInTitle>Example of the default opt-in for cart</SciOptInTitle>
            <SciOptInContainer>
              <WidgetPreview />
            </SciOptInContainer>
          </div>
        ) : (
          <div>
            <RcsOptInTitle>Example of the default opt-in</RcsOptInTitle>
            <RcsOptInContainer>
              <label id="label" htmlFor="checkbox">
                <span>Allow me to modify my subscription via text</span>
                <input id="checkbox" type="checkbox" checked />
                <span id="checkmark" />
              </label>
            </RcsOptInContainer>
          </div>
        )}
      </TopContainer>

      <BottomContainer>
        <Row>
          <Col sm={5}>
            {sciCheckout ? (
              <Toggle
                checked={cartWidgetConfig.enabled}
                onChange={() => updateWidgetConfig({ enabled: !cartWidgetConfig.enabled })}
                disabled={showAnimation}
              />
            ) : (
              <Toggle checked={enable} onChange={() => setEnable(!enable)} />
            )}
          </Col>
          <InstallOptInCol sm={7}>
            <InstallOptInText>{optInText}</InstallOptInText>
          </InstallOptInCol>
        </Row>

        <RowMt16>
          <ColPr0 sm={1}>
            <InfoIcon src={infoIcon} width={10} height={10} />
          </ColPr0>
          <ColPl0 sm={11}>
            <InstallOptInNote>
              This will allow you to effortlessly introduce new subscribers directly into
              RechargeSMS.{' '}
              <b>
                This will also enable the service for all future subscribers who opted in on
                check-out.
              </b>
            </InstallOptInNote>
          </ColPl0>
        </RowMt16>

        <RowMt30>
          <MoreOptionsCol sm={6}>
            <MoreOptionsText onClick={handleMoreOptionClick}>I’d like to test</MoreOptionsText>
          </MoreOptionsCol>
          <LaunchButtonCol sm={6}>
            <LaunchButton
              disabled={disableCompleteButton && !enable}
              onClick={completeButtonHandler}
            >
              {completeButtonLabel}
            </LaunchButton>
          </LaunchButtonCol>
        </RowMt30>
      </BottomContainer>
    </Modal>
  );
};

export default LaunchModal;

const assetDomain = document.domain.includes('admin.rechargeapps.com')
  ? 'https://app.electricsms.com'
  : document.domain.includes('admin.stage.rechargeapps.com')
  ? 'https://sms.stage.rechargeapps.com'
  : '';

const SciOptInTitle = styled.div`
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.47px;
  color: #9a9b9c;
  text-align: center;
`;

const RcsOptInTitle = styled.div`
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.47px;
  color: #9a9b9c;
  text-align: center;
  margin-top: 47px;
`;

const SciOptInContainer = styled.div`
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  border-radius: 8px;
  padding: 5px 20px 5px 20px;
  margin-top: 10px;
`;

const RcsOptInContainer = styled.div`
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 47px;
  text-align: center;
  font-family: SF Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;

  #label {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    align-items: center;
    color: var(--rcsms-color-dark);
    cursor: pointer;
    display: flex;
    font-size: 12px;
    margin-bottom: 0;
    position: relative;
    user-select: none;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: none;
    justify-content: center;

    #checkbox {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }

    #checkmark {
      border: 1px solid #000;
      border-radius: 2px;
      margin-left: 8px;
      height: 15px;
      width: 15px;
      background-color: #000;
      background-image: url(${assetDomain}/cart-widget/assets/check.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60%;
    }
  }
`;
