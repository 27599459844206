import React, { forwardRef } from 'react';

import { ConfirmationModal } from '@components/Modal/ConfirmationModal';
import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';

export * from '@components/Modal/BaseModal';

export function DeleteWorkflowModalComponent(props, ref) {
  const { currentWorkflow, handleDeleteWorkflow } = useWorkflowsContext();

  return (
    <ConfirmationModal
      ref={ref}
      title="Delete Workflow?"
      description="Are you sure you want to delete this workflow? This action is irreversible and will disable Replenishment for specified products."
      onConfirm={() => handleDeleteWorkflow(currentWorkflow.id)}
    />
  );
}

export const DeleteWorkflowModal = forwardRef(DeleteWorkflowModalComponent);
