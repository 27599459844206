import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from '@emotion/styled';
import toast from 'react-hot-toast';

import axios from '@lib/axios';
import { Card } from '@components/Layout/Card';
import { SearchBar } from '@components/Input/SearchBar';
import { Page } from '@components/Layout/Page';
import { Paginator } from '@components/Paginator';
import { NewButton } from '@components/Button/NewButton';
import { Select } from '@components/Input';
import { Table, TableHeader, TableData } from '@components/Table/Table';
import { DropdownOptions } from '@components/Dropdown/DropdownOptions';
import { PaginationInfo } from '@typings/PaginationInfo';

export interface IPage<T = any> {
  data: T[];
  paginationInfo: PaginationInfo;
}

interface HeaderProps {
  description?: string | React.ReactNode;
  component?: {
    text?: string;
    className?: string;
    onClick?(): void;
    custom?: React.ReactNode;
  };
}

interface FilterProps {
  options: any[];
  placeholder?: string;
  selectedFilter?: any;
}

interface DropdownProps {
  id: string;
  options: any[];
}

interface TableProps {
  headers: TableHeader[];
  row(data: any): TableData;
  extraRows?: TableData[];
  banner?: React.ReactNode;
}

interface ViewPageProps {
  url: string;
  header?: HeaderProps;
  filter?: FilterProps;
  dropdown?: DropdownProps;
  table?: TableProps;
  searchPlaceholder?: string;
  paginationLimit?: number;
  params?: any;
  children?: React.ReactNode;
}

export interface ViewPageHandles {
  page: IPage;
  setPage: React.Dispatch<React.SetStateAction<IPage>>;
  searchParam: string;
  selectedFilter: any;
  handleSearch(): void;
}

export const ViewPage = forwardRef((props: ViewPageProps, ref) => {
  const {
    url,
    header,
    filter,
    dropdown,
    table,
    searchPlaceholder,
    paginationLimit,
    params,
    children,
  } = props;

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<IPage>();
  const [searchParam, setSearchParam] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(
    filter?.selectedFilter || filter?.options?.[0]
  );

  const tableRows = (table?.row && page?.data?.map(table.row)) || [];
  const tableExtraRows = table?.extraRows || [];
  const showHeader = searchPlaceholder || filter || dropdown;

  useImperativeHandle(
    ref,
    () => ({
      page,
      setPage,
      searchParam,
      selectedFilter,
      handleSearch,
    }),
    [page]
  );

  useEffect(() => {
    handleSearch();
  }, [params, url]);

  async function handleSearch({
    search = searchParam,
    pageNumber = page?.paginationInfo?.current || 1,
    filter = selectedFilter,
  } = {}) {
    try {
      setLoading(true);

      const { data } = await axios.get(url, {
        params: {
          search,
          page: pageNumber,
          limit: paginationLimit || 10,
          filter: filter?.value,
          ...params,
        },
      });

      setPage(data);
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  function handleSearchChange(search) {
    setSearchParam(search);
    handleSearch({ search });
  }

  function handleFilterChange(filter) {
    setSelectedFilter(filter);
    handleSearch({ filter });
  }

  return (
    <Page
      headerDescription={header?.description}
      headerComponent={
        header?.component &&
        (header.component?.custom || (
          <NewButton
            text={header?.component.text}
            handleClick={header?.component.onClick}
            className={header?.component?.className}
          />
        ))
      }
    >
      <Card>
        {showHeader && (
          <Header>
            {searchPlaceholder && (
              <SearchBar
                handleSearch={handleSearchChange}
                inputProps={{
                  placeholder: searchPlaceholder,
                }}
              />
            )}

            <FilterContainer>
              {filter && (
                <Select
                  onChange={handleFilterChange}
                  value={selectedFilter}
                  options={filter.options}
                  placeholder={filter.placeholder}
                />
              )}
              {dropdown && (
                <DropdownOptions id={dropdown.id} drop="down" options={dropdown.options} />
              )}
            </FilterContainer>
          </Header>
        )}

        {children}

        {table && (
          <DynamicTable
            loading={loading}
            headers={table.headers}
            rows={[...tableRows, ...tableExtraRows]}
            banner={table?.banner}
          />
        )}
        <PaginatorContainer>
          <Paginator
            paginationInfo={page?.paginationInfo}
            onPageChange={(pageNumber) => handleSearch({ pageNumber })}
          />
        </PaginatorContainer>
      </Card>
    </Page>
  );
});

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

const FilterContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DynamicTable = styled(Table)`
  height: fit-content;
  flex-grow: 1;
`;
