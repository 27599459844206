import React from 'react';
import { CancellationReason } from '@typings/CancellationReason';
import BootstrapForm from 'react-bootstrap/Form';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, FormControl } from 'react-bootstrap';
import { CancelLink } from '@components/Router/CancelLink';

interface FormProps {
  defaultValues?: CancellationReason;
  onSubmit(values: CancellationReason): Promise<void>;
}

export function Form(props: FormProps) {
  const { onSubmit, defaultValues } = props;
  const formProps = useForm({
    defaultValues,
  });

  const { formState, handleSubmit, register } = formProps;

  const submitLabel = defaultValues ? 'Update' : 'Save';

  return (
    <FormProvider {...formProps}>
      <BootstrapForm onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Card.Body>
            <BootstrapForm.Row>
              <BootstrapForm.Group className="col-md-8">
                <BootstrapForm.Label>Name</BootstrapForm.Label>
                <FormControl
                  name="name"
                  defaultValue=""
                  isInvalid={!!formState.errors.name}
                  {...register('name', { required: true })}
                />
                <BootstrapForm.Control.Feedback type="invalid">
                  Name is a required field.
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
              <BootstrapForm.Group className="col-md-4">
                <BootstrapForm.Label>Churn Prevention</BootstrapForm.Label>
                <FormControl
                  name="winback"
                  as="select"
                  {...register('winback', { required: true })}
                >
                  <option value="not_defined">Not defined</option>
                  <option value="default_reason">Default reason</option>
                  <option value="skip_order">Skip order</option>
                  <option value="swap_product">Swap product</option>
                </FormControl>
                <BootstrapForm.Control.Feedback type="invalid">
                  Churn Prevention is a required field.
                </BootstrapForm.Control.Feedback>
              </BootstrapForm.Group>
            </BootstrapForm.Row>
          </Card.Body>
          <Card.Footer>
            <div className="form-actions text-right">
              <CancelLink to="/sms/cancellation_reasons" />
              <Button type="submit" variant="primary" disabled={formState.isSubmitting}>
                {formState.isSubmitting ? 'Loading..' : submitLabel}
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </BootstrapForm>
    </FormProvider>
  );
}
