import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';

import { useFetch } from '@hooks/useFetch';
import { useFeature } from '@hooks/useFeature';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { Feature } from '@typings/Feature';
import axios from '@lib/axios';

// apologies to the typescript gods, will come back to this eventually (maybe)
export interface BaseAppContextProps {
  allSubscribersFeature: any;
  allSubscribersChecked: boolean;
  toggleAllSubscribers: any;
  loadingFeature: boolean;
  cartWidgetConfig: WidgetConfigType;
  createCartWidgetConfig: any;
  updateCartWidgetConfig: any;
  getCheckoutScriptGeneralAttribute: any;
  checkoutScriptGeneralAttribute: any;
  updateRechargeGeneralAttribute: any;
  statusRefresh: number;
  segmentTriggerStatusRefresh: any;
  showContactCardModal: any;
  setShowContactCardModal: any;
}

export interface WidgetConfigType {
  enabled: boolean;
  branding_disabled?: boolean;
  variant?: 'auto' | 'midnight' | 'paper' | 'cobalt';
  x_margin?: number;
  y_margin?: number;
}

const BaseAppContext = createContext<BaseAppContextProps | undefined>(undefined);

export function BaseAppProvider({ children }: PropsWithChildren<any>) {
  const shop = useCurrentShop();
  const sciCheckout = shop.checkoutPlatform !== 'recharge';

  /**
   *    Enable SMS for all current subscribers:
   *    Shared between Status Indicator and Segements View
   */
  const {
    loading: loadingFeature,
    data: allSubscribersFeature,
    refetch: allSubscribersRefetch,
  } = useFetch<Feature>('/sms/features/send_sms_existent_customers');

  const { toggleFeature: toggleAllSubscribers, checked: allSubscribersChecked } = useFeature(
    allSubscribersFeature
  );

  useEffect(() => {
    if (typeof allSubscribersChecked === 'boolean') {
      allSubscribersRefetch();
    }
  }, [allSubscribersChecked]);

  /**
   *    SCI Cart Opt In Widget
   *    Shared between Status Indicator Cart Widget Component(s)
   */
  const [cartWidgetConfig, setCartWidgetConfig] = useState(null);

  if (sciCheckout) {
    useEffect(() => {
      getCartWidgetConfig();
    }, []);
  }

  async function getCartWidgetConfig() {
    const { data } = await axios.get('/sms/cart_widget_configs');
    if (data === null) {
      const { data: createdData } = await createCartWidgetConfig();
      setCartWidgetConfig(createdData);
    } else {
      setCartWidgetConfig(data);
    }
  }

  async function createCartWidgetConfig() {
    const defaultConfig = {
      enabled: false,
      branding_disabled: false,
      y_margin: 12,
    };
    return axios.post('/sms/cart_widget_configs', defaultConfig);
  }

  async function updateCartWidgetConfig(pendingWidgetConfig) {
    const { data } = await axios.patch('/sms/cart_widget_configs', pendingWidgetConfig);
    setCartWidgetConfig(data);
  }

  /**
   *    RCS Checkout Opt In Widget
   *    Shared between Status Indicator Activation Component
   */
  const [checkoutScriptGeneralAttribute, setCheckoutScriptGeneralAttribute] = useState(null);

  if (!sciCheckout) {
    useEffect(() => {
      getCheckoutScriptGeneralAttribute();
    }, []);
  }

  async function getCheckoutScriptGeneralAttribute() {
    const { data } = await axios.get('/sms/shops/checkout_script_general_attribute');
    setCheckoutScriptGeneralAttribute(data);
  }

  async function updateRechargeGeneralAttribute(active) {
    // This call sets the 2 general attributes in recharge
    // this first that sms is installed and always true
    // the second using this active param that is for the checkout script
    await axios.put('/sms/shops/activate', { active });
    setCheckoutScriptGeneralAttribute({ enabled: active });
  }

  /**
   *    RCS Checkout Opt In Widget
   *    Shared between Status Indicator Activation Component
   */
  const [statusRefresh, setStatusRefresh] = useState<number>(null);

  function segmentTriggerStatusRefresh() {
    setStatusRefresh(Date.now());
  }

  /**
   *    Contact Card Model
   */
  const [showContactCardModal, setShowContactCardModal] = useState<boolean>(null);

  /**
   *    Exports
   */

  const value = {
    allSubscribersFeature,
    allSubscribersChecked,
    toggleAllSubscribers,
    loadingFeature,
    getCartWidgetConfig,
    cartWidgetConfig,
    createCartWidgetConfig,
    updateCartWidgetConfig,
    getCheckoutScriptGeneralAttribute,
    checkoutScriptGeneralAttribute,
    updateRechargeGeneralAttribute,
    statusRefresh,
    segmentTriggerStatusRefresh,
    showContactCardModal,
    setShowContactCardModal,
  };

  return <BaseAppContext.Provider value={value}>{children}</BaseAppContext.Provider>;
}

export const useBaseAppContext = () => {
  const context = React.useContext(BaseAppContext);

  if (context === undefined) {
    throw new Error('useBaseAppContext must be used within a BaseAppProvider');
  }
  return context;
};
