import React, { useEffect, useState } from 'react';
import {
  ContinueButton,
  ItemIcon,
} from '@components/Onboarding/v2/components/styling/SidebarStyling';
import { useOnboardingV2 } from '@hooks/useOnboardingV2';
import { OnboardingData, OnboardingFormData } from '@typings/Onboarding';
import { useAppSelector } from '@redux/hooks';
import ReplenishmentSmsIcon from '@assets/images/replenishment-sms.png';
import WritingIcon from '@assets/images/writing-action.png';
import RechargeYellowIcon from '@assets/images/recharge-yellow.png';
import {
  FEATURE_1_SELECT_STEP,
  WELCOME_1_STEP,
  WELCOME_2_STEP,
  WELCOME_3_STEP,
  CONTINUE_BUTTON_DELAY_TIME_MILLIS,
  sleep,
} from '@components/Onboarding/v2/onboarding';
import { SidebarLayout } from '@components/Onboarding/v2/components/Sidebar/SidebarLayout';

export interface SidebarWelcomeStepProps {
  onboarding: OnboardingData;
  updateOnboarding(id: string, data: OnboardingFormData): Promise<void>;
}

export const SidebarWelcomeFlow = () => {
  const { updateOnboarding } = useOnboardingV2();
  const onboardingState = useAppSelector((state) => state.onboarding);

  const [loading, setLoading] = useState(false);

  const onContinueClick = async (nextStep) => {
    setLoading(true);
    await sleep(CONTINUE_BUTTON_DELAY_TIME_MILLIS);

    await updateOnboarding(onboardingState.data.id, {
      step: nextStep,
    });

    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const contentMap = {
    [WELCOME_1_STEP]: (
      <>
        In just three short stages, you can start engaging your customers over SMS and lowering your support costs.
        <br />
        <br />
        <b>Let's get you acquainted with RechargeSMS and set up your store!</b>
      </>
    ),

    [WELCOME_2_STEP]: (
      <>
        <b>Nine out of ten</b> consumers from a survey of 6,000 wish they were already receiving
        texts.
        <br />
        <br />
        We prepared a quick video about how you can engage your customers. Check it out!
        <br />
        <br />
        Click <b>Continue</b> when you’re ready to move on.
      </>
    ),

    [WELCOME_3_STEP]: (
      <>
        We’ll guide you through this simple setup process:
        <br />
        <br />
        <ItemIcon src={ReplenishmentSmsIcon} height={15} />
        <span>Select messaging features</span>
        <br />
        <br />
        <ItemIcon src={WritingIcon} height={15} />
        <span>Format products for SMS</span>
        <br />
        <br />
        <ItemIcon src={RechargeYellowIcon} height={15} />
        <span>Add the opt-in for new subscribers</span>
      </>
    ),
  };

  const primaryButtonMap = {
    [WELCOME_1_STEP]: (
      <ContinueButton onClick={() => onContinueClick(WELCOME_2_STEP)}>Continue</ContinueButton>
    ),
    [WELCOME_2_STEP]: (
      <ContinueButton onClick={() => onContinueClick(WELCOME_3_STEP)}>Continue</ContinueButton>
    ),
    [WELCOME_3_STEP]: (
      <ContinueButton onClick={() => onContinueClick(FEATURE_1_SELECT_STEP)}>
        Continue
      </ContinueButton>
    ),
  };

  const stepTitleMap = {
    [WELCOME_1_STEP]: 'Welcome!',
    [WELCOME_2_STEP]: 'Ready to go?',
    [WELCOME_3_STEP]: 'Easy as 1, 2, 3',
  };

  const stepProgressMap = {
    [WELCOME_1_STEP]: (100 * 1) / 3,
    [WELCOME_2_STEP]: (100 * 2) / 3,
    [WELCOME_3_STEP]: 100,
  };

  return (
    <SidebarLayout
      flowTitle="Getting started"
      welcomeProgressValue={stepProgressMap[onboardingState.data.step]}
      featureProgressValue={0}
      productProgressValue={0}
      content={contentMap[onboardingState.data.step]}
      primaryButton={primaryButtonMap[onboardingState.data.step]}
      stepTitle={stepTitleMap[onboardingState.data.step]}
      loading={loading}
    />
  );
};
