import { Loading } from '@components/Loading';
import { useTheme } from '@emotion/react';
import axios from '@lib/axios';
import { Collection } from '@typings/Collection';
import React, { useEffect, useState } from 'react';

import { CollectionListItem } from './components/CollectionListItem';

export const CollectionsList = () => {
  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState<Collection[]>();
  const theme = useTheme();

  async function fetchCollections() {
    const { data } = await axios.get('/sms/collections/best_sellers');
    setCollections([data]);
    setLoading(false);
  }

  useEffect(() => {
    fetchCollections();
  }, []);

  if (loading) return <Loading color={theme.colors.primary} />;

  return (
    <>
      {collections.map((collection) => (
        <CollectionListItem
          key={collection.id}
          collection={collection}
          onChange={fetchCollections}
        />
      ))}
    </>
  );
};
