import React, { memo } from 'react';
import styled from '@emotion/styled';
import { PieChart } from 'react-chartkick';
import 'chartkick/chart.js';

import { DonutGraphProps, DonutContainerProps } from './types';

function DonutGraph({
  data,
  colors = ['#0069A4', '#1DCAE1', '#000'],
  height = '200px',
  width = '300px',
  title,
  subtitle,
}: DonutGraphProps) {
  if (!data) {
    return <div>no data</div>;
  }
  const chartOptions = {
    cutout: '87%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label(item) {
            const { dataset } = item;
            const total = dataset.data.reduce((a, b) => a + b);
            const currentValue = dataset.data[item.dataIndex];
            const percentage = Math.round(10 * ((currentValue / total) * 100)) / 10;
            return `${item.label}: ${percentage}%`;
          },
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    animation: { easing: 'easeOutSine' },
    layout: {
      padding: {
        right: 50,
        left: 50,
      },
    },
  };

  return (
    <DonutContainer height={height} width={width}>
      <div className="graph">
        <PieChart
          donut
          width={width}
          height={height}
          data={data}
          colors={colors}
          library={chartOptions}
        />
      </div>
      <div className="graph-title">{title}</div>
      <div className="graph-subtitle">{subtitle}</div>
    </DonutContainer>
  );
}

export const DonutGraphMemo = memo(DonutGraph);

export default DonutGraphMemo;

const DonutContainer = styled.div<DonutContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  .graph {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .graph-title {
    font-family: 'SFProDisplay';
    text-align: center;
    font-size: ${(props) => parseInt(props.height.replace('px', ''), 10) * 0.15}px;
    font-weight: 900;
  }
  .graph-subtitle {
    text-align: center;
    font-size: 10px;
    color: #8a8a8a;
  }
`;
