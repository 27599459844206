import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Card } from '@components/Layout/Card';
import { Table, TableHeader, TableData } from '@components/Table/Table';
import { CollapsableFeatureSwitch } from '@pages/Settings/Features/shared/CollapsableFeatureSwitch';
import date from '@utils/date';

import './FallbackTemplates.scss';

export const FallbackTemplates = ({ isLoading, emailFeatures, emailTemplates }) => {
  const headers: TableHeader[] = [
    { label: 'Email template', width: '40%' },
    { label: 'Last modified' },
    { label: 'Status' },
    { label: 'Actions' },
  ];

  const emailTemplatesMapper = {
    enable_opt_out_email: ['opt_out'],
    enable_unreachable_handset_email: ['unreachable_handsets'],
    enable_charge_reminder_fallback_email: [
      'charge_reminder_fallback',
      'multiple_charge_reminder_fallback',
    ],
  };

  const featurePathMapper = {
    enable_opt_out_email: '/sms/settings/email/opt_out',
    enable_unreachable_handset_email: '/sms/settings/email/unreachable_phone',
    enable_charge_reminder_fallback_email: '/sms/settings/email/charge_reminder',
  };

  const emailTemplateFromFeature = (emailFeatureKey) => {
    const templateKey = emailTemplatesMapper[emailFeatureKey];
    const emailTemplate = emailTemplates.find((template) =>
      templateKey.includes(template?.emailType)
    );

    return emailTemplate;
  };

  const featureNameMapper = {
    enable_charge_reminder_fallback_email: 'Charge Reminder Email Fallback',
    enable_opt_out_email: 'Opt-out Confirmation Email',
    enable_unreachable_handset_email: 'Unreachable Phone Number Fallback Email',
  };

  const rows: TableData[] = emailFeatures.map((emailFeature) => ({
    data: [
      <div>{featureNameMapper[emailFeature.key]}</div>,
      <div>
        {date.formatString(emailTemplateFromFeature(emailFeature.key)?.updated_at, 'MM/dd/yyyy')}
      </div>,
      <CollapsableFeatureSwitch key={emailFeature.key} feature={emailFeature} alt />,
      <Container to={featurePathMapper[emailFeature.key]}>
        <FontAwesomeIcon icon={faPencilAlt} size="1x" />
      </Container>,
    ],
  }));

  return (
    <Card className="mb-4" data-testid="fallback-templates-component">
      <div className="d-flex align-items-center px-4 pt-3 mb-4">
        <h4 className="font-weight-bold mb-0">Email Fallback Templates</h4>
      </div>

      <Table loading={isLoading} headers={headers} rows={rows} />
    </Card>
  );
};

const Container = styled(Link)`
  align-items: center;
  background: white;
  border-radius: 50%;
  border: 1px solid #f4f4f4;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  width: 36px;
  color: black;

  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease-in-out;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;
