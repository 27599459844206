import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';

export const InfoText = (props: PropsWithChildren<unknown>) => {
  const { children } = props;
  return <Root>{children}</Root>;
};

const Root = styled.div`
  color: #6b6b6b;
  vertical-align: middle;
  padding: ${(props) => props.theme.spacing.small} ${(props) => props.theme.spacing.xSmall};
`;
