import styled from '@emotion/styled';
import { Col } from 'react-bootstrap';
import { CustomButton } from '@components/Button/CustomButton';
import Modal from 'react-bootstrap/Modal';

export const TopContainer = styled.div<{ center?: boolean }>`
  position: relative;
  padding: 16px 37px 16px 37px;
  ${(props) => props.center && `text-align: center`}
`;

export const TopBackground = styled.div`
  width: 100%;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  height: 16px;
  background: linear-gradient(90deg, #17cc9d 6%, #b3fff7 50.02%, #76dfc6 94.64%);
`;

export const Title = styled.div`
  font-family: Greycliff;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
`;

export const BottomContainer = styled.div`
  border-top: 1px solid #a7a7a7;
  padding: 16px 37px 16px 37px;
  background: #f4f4f4;
`;

export const InstallOptInText = styled.div`
  font-family: Greycliff;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
`;

export const InstallOptInNote = styled.div`
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.47px;
  color: #6b6b6b;
`;

export const MoreOptionsCol = styled(Col)`
  display: flex;
  justify-content: start;
`;

export const LaunchButtonCol = styled(Col)`
  display: flex;
  justify-content: end;
`;

export const InstallOptInCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const LaunchButton = styled(CustomButton)`
  border-radius: 6px;
  border: none;
  font-family: Greycliff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  padding: 13px 20px 13px 20px;
  background: #191d48;

  &:disabled {
    background: #b6b6bd;
  }
`;

export const MoreOptionsText = styled.div`
  font-family: Greycliff;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #a7a7a7;
  cursor: pointer;

  &:hover {
    color: #3901f1;
  }
`;

export const InfoIcon = styled.img`
  margin-top: -5px;
`;

export const LoadingModal = styled(Modal)`
  .modal-content {
    height: 300px;
  }
`;
