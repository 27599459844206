import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useFormContext } from 'react-hook-form';
import copy from 'copy-to-clipboard';

import { useCurrentShop } from '@hooks/useCurrentShop';

import { OptInPage } from '../OptInPage';
import { Installation } from './Installation';
import cssString from '!!raw-loader!../OptInPage/OptInPage.css';

export function InstallationContainer() {
  const shop = useCurrentShop();
  const { getValues, watch } = useFormContext();
  const [codeCopied, setCodeCopied] = useState(false);

  const optInWidgetFrameScript = buildOptInWidgetFrame();

  const optInPageHtml = ReactDOMServer.renderToString(
    <OptInPage watch={watch} showWidget={false} logoUrl={shop?.logoUrl} />
  );

  const htmlString = `
    <!-- Page start -->
    ${optInPageHtml}
    <!-- Page end -->

    <!-- Page CSS start -->
    <style>
    ${cssString}
    </style>
    <!-- Page CSS end -->

    <!-- Page JS start -->
    <script>
      ${optInWidgetFrameScript}
    </script>
    <!-- Page JS end -->
  `;

  function handleCopyCode() {
    setCodeCopied(true);

    copy(htmlString);

    setTimeout(() => {
      setCodeCopied(false);
    }, 2000);
  }

  function buildOptInWidgetFrame() {
    const getColor = (name) => getValues(name).replace('#', '');

    const params = new URLSearchParams({
      heroCopyFontColor: getColor('heroCopyFontColor'),
      heroCopy: getValues('heroCopy'),
      ctaButtonLabel: getValues('ctaButtonLabel'),
      ctaButtonColor: getColor('ctaButtonColor'),
      ctaButtonFontColor: getColor('ctaButtonFontColor'),
    });

    const src = `${document.location.origin}/sms/opt-in/${shop.id}/?${params.toString()}`;

    return `
      var searchParams = new URLSearchParams(document.location.search);
      var searchParamsEmail = searchParams.get('email') || '';
      var iframe = document.getElementById('replace-opt-in-widget-frame');
      iframe.src = '${src}&email=' + searchParamsEmail;
    `;
  }

  return (
    <Installation htmlString={htmlString} codeCopied={codeCopied} handleCopyCode={handleCopyCode} />
  );
}
