import smartSwapArrowsImage from '@assets/images/smart-swap-arrows.svg';
import { ModalText } from '@components/Onboarding/components/ModalText';
import { ButtonGroup } from '@components/Button/ButtonGroup';
import { CustomButton } from '@components/Button/CustomButton';
import { Modal } from '@components/Modal/Modal';
import { Text } from '@components/Typography/Text';
import styled from '@emotion/styled';
import axios from '@lib/axios';
import React, { useState } from 'react';

interface SmartSwapBannerProps {
  onEnable(): Promise<void>;
}

export const SmartSwapBanner = (props: SmartSwapBannerProps) => {
  const { onEnable } = props;
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal((old) => !old);
  }

  async function enableSmartSwap() {
    await axios.put('/sms/collections/best_sellers');
    await onEnable();
    toggleModal();
  }

  return (
    <>
      <Banner>
        <Side>
          <div>
            <Text size="xLarge" weight="bold">
              Smart Swaps
            </Text>
          </div>
          Best selling items
        </Side>
        <Middle>
          Offer you subscribers compelling product alternatives instantly by enabling our <br />
          Smart Swap group. Upselling your best sellers have never been easier.
        </Middle>
        <Side>
          <CustomButton variant="outline-light" onClick={toggleModal}>
            Try it!
          </CustomButton>
        </Side>
      </Banner>
      <EnableModal show={showModal} onEnable={enableSmartSwap} onHide={toggleModal} />
    </>
  );
};

const Banner = styled.div`
  align-items: center;
  background: url(${smartSwapArrowsImage}) no-repeat right 300px center,
    ${(props) => props.theme.colors.green3};
  border-radius: ${(props) => props.theme.radius.small};
  color: ${(props) => props.theme.colors.light0};
  display: flex;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 1.5rem;
`;

const Side = styled.div``;
const Middle = styled.div`
  flex-grow: 1;
  margin: 0 8rem;
`;

interface EnableModalProps {
  show: boolean;
  onEnable(): void;
  onHide(): void;
}

export function EnableModal(props: EnableModalProps) {
  const { show, onEnable, onHide } = props;

  return (
    <Modal show={show} onHide={onHide} title="Try Smart Swaps">
      <ModalText>
        Smart Swaps saves you time by automating swap group creation with your best selling items.
      </ModalText>
      <ButtonGroup>
        <CustomButton variant="primary" onClick={onEnable}>
          Let's do it!
        </CustomButton>
      </ButtonGroup>
    </Modal>
  );
}
