import React, { useContext } from 'react';

interface InitialState {
  amplitudeApiKey: string;
  environment: string;
  /**
   * in minutes
   */
  humanSupportDuration: number;
  rechargeAdminPanelDomainSufix: string;
  sentryDsn: string;
  useLocalSmsAdapter: boolean;
}

export const InitialStateContext = React.createContext<InitialState>(undefined);

export function useInitialState() {
  return useContext(InitialStateContext);
}
