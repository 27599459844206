import React from 'react';
import styled from '@emotion/styled';

import { RechargeCustomer } from '@typings/RechargeCustomer';

interface CustomerInfoProps {
  customer: RechargeCustomer;
}

export function CustomerInfo(props: CustomerInfoProps) {
  const { customer } = props;

  return (
    <div>
      <Name>{customer.name}</Name>
      <Info>
        {customer.phone} | {customer.email}
      </Info>
    </div>
  );
}

const Name = styled.h6`
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
`;

const Info = styled.span`
  font-size: 11px;
  line-height: 13px;
`;
