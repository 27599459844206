import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { Loading } from '@components/Loading';
import { Separator } from '@components/Layout/Separator';
import { WidgetConfigType } from '../types';

import { Card, Subtitle } from '../../Card';

interface OptInCardProps {
  setHideCustomization: (boolean) => void;
  isLoading: boolean;
  widgetConfig: WidgetConfigType;
  updateCartWidgetConfig: any;
  setPendingWidgetConfig: (WidgetConfigType) => void;
}

export function OptInCard(props: OptInCardProps) {
  const {
    setHideCustomization,
    isLoading,
    setPendingWidgetConfig,
    widgetConfig,
    updateCartWidgetConfig,
  } = props;
  const [pendingUpdate, setPendingUpdate] = useState(false);

  async function handleOptInToggle() {
    setPendingUpdate(true);
    try {
      const { data } = await updateCartWidgetConfig({
        enabled: !widgetConfig.enabled,
      });

      setPendingWidgetConfig(data);
    } catch (e) {
      console.log(e);
    } finally {
      setPendingUpdate(false);
    }
  }

  const setCustomize = () => {
    setHideCustomization(false);
  };

  if (isLoading) {
    return (
      <ToggleCard>
        <Subtitle>Loading Configuration</Subtitle>
        <Separator />
        <Loading />
      </ToggleCard>
    );
  }

  return (
    <ToggleCardAlt enabled={widgetConfig.enabled}>
      <Subtitle>Widget {widgetConfig.enabled ? 'Enabled' : 'Disabled'}</Subtitle>
      <Separator />
      <Subtitle>
        <OptInContainer id="cart-widget-opt-in">
          <div>Enable Cart Widget Opt-in</div>
          <div>
            <Form.Switch
              disabled={pendingUpdate}
              checked={widgetConfig.enabled}
              onChange={handleOptInToggle}
              className="alt"
            />
          </div>
        </OptInContainer>
      </Subtitle>
      {widgetConfig.enabled ? (
        <>
          <br />
          <p>
            If you don't see it immediately, try refreshing.
            <br />
            The widget can take a few minutes to appear.
          </p>
          <InfoNote onClick={setCustomize}>
            Don't like how it looks? Customize it!&nbsp;{' '}
            <FontAwesomeIcon icon={faPencilAlt} size="1x" />
          </InfoNote>
        </>
      ) : (
        ''
      )}
    </ToggleCardAlt>
  );
}

interface EnabledProps {
  enabled: boolean;
}

const ToggleCard = styled(Card)`
  padding: 20px;
  max-width: 345px;
  margin-top: 130px;
  h3 {
    font-size: 16px;
    text-align: center;
  }
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
`;

const ToggleCardAlt = styled(ToggleCard)<EnabledProps>`
  border: solid 1px ${(props) => (props.enabled ? '#18CC9D' : '#EC3D10')};
`;

const OptInContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const InfoNote = styled.div`
  color: #a7a7a7;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  &:hover {
    color: #3901f1;
  }
`;
