import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';

import { Select } from '@components/Input';

import { useCustomActionsContext } from '../CustomActionsContext';
import { availableDelayOptions } from '../createAction/createDelayOrder';

interface ActionDelayProps {
  id: string;
  params: any;
}

const ActionDelay = ({ id, params }: ActionDelayProps) => {
  const { actions, handleChangeAction } = useCustomActionsContext();
  const [selectedOption, setSelectedOption] = useState(findOption(availableDelayOptions, params));

  function findOption(options, optionParams) {
    return options.find((option) => isEqual(optionParams, option.params));
  }

  const handleChange = (option) => {
    setSelectedOption(option);
    handleChangeAction(id, { params: option.params });
  };

  const filterOptions = (option) => {
    if (isEqual(selectedOption.params, option.params)) return true;
    if (findOption(actions, option.params)) return false;

    return true;
  };

  return (
    <Select
      value={selectedOption}
      onChange={(option) => handleChange(option)}
      options={availableDelayOptions.filter(filterOptions)}
      styles={{
        control: () => ({
          background: '#fff',
          borderRadius: 5,
          minWidth: 150,
          height: 30,
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        }),
        singleValue: () => ({
          fontSize: 12,
          color: '#000',
        }),
      }}
    />
  );
};

export default ActionDelay;
