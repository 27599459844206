import React from 'react';
import styled from '@emotion/styled';
import { Col } from 'react-bootstrap';

import Simulator from '@components/Simulator/Simulator';

import { useFeaturesContext } from '@contexts/FeaturesContext';

const GenericPage = ({ children }) => {
  const { selectedFeature, initialChat, shouldSimulatorReset } = useFeaturesContext();

  return (
    <Container className="generic-page-container">
      <Col xs={12} lg={6}>
        {children}
      </Col>
      <Col lg={6}>
        <Simulator initialChat={initialChat} shouldReset={shouldSimulatorReset} />
        {selectedFeature && (
          <InfoContainer>
            <p>
              <strong>{selectedFeature.name}</strong> - {selectedFeature.description}
            </p>
          </InfoContainer>
        )}
      </Col>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding: 1rem;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  width: 400px;
  min-height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 1rem;
  color: #000000;
`;

export default GenericPage;
