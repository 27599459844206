import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import useUndo from 'use-undo';
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import { RotateCcw } from 'react-feather';
import toast from 'react-hot-toast';

import Edit from '@assets/icons/edit.svg';

interface EditableTitleProps {
  initialTitle: string;
  onChange(value: string): void;
  successToastMessage?: string;
  failureToastMessage?: string;
  overlayPlacement?: Placement;
}

export function EditableTitle(props: EditableTitleProps) {
  const {
    initialTitle,
    onChange,
    successToastMessage = 'Title successfully updated!',
    failureToastMessage = 'Title successfully reverted!',
    overlayPlacement = 'top',
    ...rest
  } = props;
  const [{ past, present: title }, historic] = useUndo(initialTitle);
  const [fieldValue, setFieldValue] = useState(initialTitle);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (initialTitle !== fieldValue) {
      setFieldValue(initialTitle);
      historic.reset(initialTitle);
    }
  }, [initialTitle]);

  function handleEdit() {
    const newTitle = fieldValue;

    if (newTitle !== title) {
      historic.set(newTitle);
      onChange?.(newTitle);

      toast.success(successToastMessage);
    }

    setIsEditing(false);
  }

  function handleRevert() {
    const previousTitle = past[past.length - 1];
    setFieldValue(previousTitle);
    historic.undo();
    onChange?.(previousTitle);

    toast.success(failureToastMessage);
  }

  function handleEnter(e) {
    return e.key === 'Enter' && handleEdit();
  }

  return isEditing ? (
    <Form.Control
      name="title"
      value={fieldValue}
      onChange={(e) => setFieldValue(e.target.value)}
      onBlur={handleEdit}
      onKeyDown={handleEnter}
      autoComplete="off"
      autoFocus
    />
  ) : (
    <Container {...rest}>
      <TitleContainer tabIndex={0} onFocus={() => setIsEditing(true)}>
        <strong>{title}</strong>
      </TitleContainer>
      <ActionsContainer>
        {historic.canUndo && (
          <OverlayTrigger
            placement={overlayPlacement}
            overlay={<Tooltip id="revert-title">Revert</Tooltip>}
          >
            <IconContainer onClick={handleRevert}>
              <RotateCcw size={16} />
            </IconContainer>
          </OverlayTrigger>
        )}
        <OverlayTrigger
          placement={overlayPlacement}
          overlay={<Tooltip id="edit-title">Edit</Tooltip>}
        >
          <IconContainer onClick={() => setIsEditing(true)} tabIndex={-1}>
            <img src={Edit} />
          </IconContainer>
        </OverlayTrigger>
      </ActionsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  margin-left: 10px;
`;

const IconContainer = styled.button`
  all: unset;
  width: 25px;
  text-align: center;
`;
