import React from 'react';
import styled from '@emotion/styled';

export function Footer() {
  return (
    <Container>
      <Text>Copyright &copy; rechargeSMS {new Date().getFullYear()}</Text>
    </Container>
  );
}

const Container = styled.footer`
  padding: 1rem 0;
  height: 50px;
  text-align: center;
`;

const Text = styled.span`
  font-weight: 500;
  color: #333;
`;
