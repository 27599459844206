import React, { PropsWithChildren, createContext, useState, useEffect } from 'react';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import assign from 'lodash/assign';
import unionBy from 'lodash/unionBy';
import remove from 'lodash/remove';
import filter from 'lodash/filter';

import { Keyword, Template, Workflow } from '@typings/Workflow';
import { ProductVariant } from '@typings/ProductVariant';

import api from './api';
import { useReactFlowContext, ReactFlowContextProps } from './ReactFlowContext';
import { useLayoutContext } from './LayoutContext';

type Views = 'templates' | 'workflows';

interface WorkflowsContextProps extends ReactFlowContextProps {
  keywords: Keyword[];
  selectedView: Views;
  templates: Template[];
  currentTemplate: Template;
  workflows: Workflow[];
  defaultWorkflows: Workflow[];
  workflowsLoading: boolean;
  currentWorkflow: Workflow;
  setSelectedView: (view: Views) => void;
  handleSelectTemplate: (type: string) => void;
  handleSelectWorkflow: (id: number, templateTag?: string, empty?: boolean) => void;
  handleChangeWorkflow: (params: any) => void;
  handleChangeWorkflowStep: (id: number, params: any, refresh?: boolean) => void;
  handleRemoveWorkflowStep: (id: number) => void;
  handleSearchWorkflows: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSaveWorkflow: () => void;
  handleDeleteWorkflow: (id: number) => void;
  handleResetWorkflow: () => void;
  handleToggleWorkflowActive(): void;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProducts: ProductVariant[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<ProductVariant[]>>;
  savedProducts: ProductVariant[];
  setSavedProducts: (type: ProductVariant[]) => void;
}

interface WorkflowsProviderProps {
  keywords: Keyword[];
  templates: Template[];
  selectedTemplate?: Template;
}

const WorkflowsContext = createContext<WorkflowsContextProps | undefined>(undefined);

export const WorkflowsProvider = ({
  children,
  keywords,
  templates,
  selectedTemplate,
}: PropsWithChildren<WorkflowsProviderProps>) => {
  const {
    ReactFlowInstance,
    setIsSaved,
    setElements,
    getElements,
    setIsDragabble,
  } = useReactFlowContext();

  const [selectedView, setSelectedView] = useState<Views>(
    selectedTemplate ? 'workflows' : 'templates'
  );

  const [currentTemplate, setCurrentTemplate] = useState<Template>(selectedTemplate);

  const [workflows, setWorkflows] = useState<Workflow[]>();
  const [defaultWorkflows, setDefaultWorkflows] = useState<Workflow[]>();
  const [workflowsLoading, setWorkflowsLoading] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState<Workflow>();
  const [currentWorkflowLastSaved, setCurrentWorkflowLastSaved] = useState<Workflow>();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [selectedProducts, setSelectedProducts] = useState<ProductVariant[]>();
  const [savedProducts, setSavedProducts] = useState<ProductVariant[]>();
  const {
    previousWorkflowId,
    setPreviousWorkflowId,
    closeRightSidebar,
    rightSidebar,
  } = useLayoutContext();

  useEffect(() => {
    if (currentTemplate) {
      getWorkflows(currentTemplate);
    }
  }, [currentTemplate]);

  useEffect(() => {
    getElements(currentWorkflow);
    setSelectedProducts(currentWorkflow?.productVariants);
    setSavedProducts(currentWorkflow?.productVariants);
    conditionallyCloseRightSidebar();
  }, [currentWorkflow?.id]);

  function conditionallyCloseRightSidebar() {
    if (
      !previousWorkflowId ||
      !currentWorkflow?.id ||
      (currentWorkflow?.id !== previousWorkflowId && rightSidebar?.type !== 'insights')
    ) {
      closeRightSidebar();
    }
    setPreviousWorkflowId(currentWorkflow?.id);
  }

  async function getWorkflows(template) {
    setWorkflowsLoading(true);

    const data =
      template.tag !== null
        ? await api.getWorkflowsByTag(template.type)
        : await api.getWorkflows(template.type);

    const defaultWorkflowsValue = data.filter((workflow) => workflow.id === 0);
    defaultWorkflowsValue.sort((a, b) => {
      const orderingA = a.ordering ? a.ordering : 0;
      const orderingB = b.ordering ? b.ordering : 0;
      return orderingA - orderingB;
    });

    setDefaultWorkflows(defaultWorkflowsValue);
    setWorkflows(data);
    setWorkflowsLoading(false);
  }

  function handleSelectTemplate(type) {
    const template = find(templates, { type });

    setCurrentTemplate(template);
    setSelectedView('workflows');
  }

  function handleSelectWorkflow(id, templateTag = null, empty = false) {
    if (empty) {
      setStateWithoutReference(setCurrentWorkflowLastSaved, null);
      setCurrentWorkflow(null);
      setIsSaved(true);
      setElements([]);
    } else {
      const workflow =
        id === 0 && templateTag !== null
          ? find(defaultWorkflows, { templateTag })
          : find(workflows, { id });

      setStateWithoutReference(setCurrentWorkflowLastSaved, workflow);
      setCurrentWorkflow(workflow);
      setIsSaved(true);
    }
  }

  const handleSearchWorkflows = debounce(async (e) => {
    setWorkflows([]);
    setWorkflowsLoading(true);

    const data = await api.searchWorkflows(currentTemplate.type, e.target.value);

    setWorkflows(data);
    setWorkflowsLoading(false);
  }, 1000);

  async function handleSaveWorkflow() {
    if (currentWorkflow.id !== 0) return handleUpdateWorkflow();

    const workflowParams = buildWorkflow();
    const workflow = await api.createWorkflow(workflowParams);
    const newWorkflow = { ...workflowParams, ...workflow };

    await getWorkflows(currentTemplate);
    setCurrentWorkflow(newWorkflow);
    setStateWithoutReference(setCurrentWorkflowLastSaved, newWorkflow);
    setIsSaved(true);
  }

  async function handleToggleWorkflowActive() {
    await api.toggleWorkflowActive(currentWorkflow.id);

    const newChecked = !currentWorkflow.activeAt;
    const activeAt = newChecked ? new Date().toISOString() : null;

    const newWorkflow: Workflow = {
      ...currentWorkflow,
      activeAt,
    };

    setWorkflows((data) =>
      data.map((workflow) => {
        if (workflow.id === currentWorkflow.id) {
          return newWorkflow;
        }
        return workflow;
      })
    );

    setCurrentWorkflow(newWorkflow);
    setIsSaved(false);
  }

  async function handleUpdateWorkflow(workflow = buildWorkflow()) {
    await api.updateWorkflow(workflow);

    setIsSaved(true);
    setStateWithoutReference(setCurrentWorkflowLastSaved, workflow);
    await getWorkflows(currentTemplate);
  }

  async function handleDeleteWorkflow(id) {
    await api.deleteWorkflow(id);

    await getWorkflows(currentTemplate);
    setCurrentWorkflow(null);
    setCurrentWorkflowLastSaved(null);
    setElements([]);
    setIsSaved(true);
  }

  function handleChangeWorkflow(params) {
    const newWorkflow = {
      ...buildWorkflow(),
      ...params,
    };

    setCurrentWorkflow(newWorkflow);

    setIsSaved(false);
  }

  function handleChangeWorkflowStep(id, params, refresh = false) {
    const newWorkflow = refresh ? buildWorkflow() : currentWorkflow;
    const step = find(newWorkflow.steps, { id });

    assign(step.params, params);
    unionBy([step], newWorkflow.steps, 'id');

    setCurrentWorkflow(newWorkflow);

    if (refresh) setIsDragabble(true);

    setIsSaved(false);
  }

  function handleRemoveWorkflowStep(id) {
    const newWorkflow = { ...currentWorkflow };
    const step = find(newWorkflow.steps, { id });

    remove(newWorkflow.steps, step);
    assign(newWorkflow.steps, buildStepsWithoutUnusedEdges(newWorkflow, id));

    setCurrentWorkflow(newWorkflow);
    setIsSaved(false);
  }

  function buildWorkflow() {
    const newWorkflow = { ...currentWorkflow };
    const elements = ReactFlowInstance.getElements();

    const getElement = (id) => find(elements, { id });
    const getEdges = (id) => filter(elements, { source: id });

    const steps = newWorkflow.steps.map((step) => {
      const element = getElement(String(step.id));
      const edges = getEdges(String(step.id));

      assign(step.params, { edges, position: element.position });

      return step;
    });

    return {
      ...newWorkflow,
      steps,
    };
  }

  function buildStepsWithoutUnusedEdges(workflow, stepId) {
    return workflow.steps.map((step) => {
      const edges = step.params?.edges?.filter(
        (edge) => ![edge.source, edge.target].includes(String(stepId))
      );

      assign(step.params, { ...step.params, edges });

      return step;
    });
  }

  function handleResetWorkflow() {
    setStateWithoutReference(setCurrentWorkflow, currentWorkflowLastSaved);
    setIsSaved(true);
  }

  function setStateWithoutReference(setStateFunction, newState) {
    setStateFunction(JSON.parse(JSON.stringify(newState)));
  }

  const value = {
    ...useReactFlowContext(),
    keywords,
    selectedView,
    setSelectedView,
    templates,
    currentTemplate,
    workflows,
    defaultWorkflows,
    workflowsLoading,
    currentWorkflow,
    handleSelectTemplate,
    handleSelectWorkflow,
    handleChangeWorkflow,
    handleChangeWorkflowStep,
    handleRemoveWorkflowStep,
    handleSearchWorkflows,
    handleSaveWorkflow,
    handleDeleteWorkflow,
    handleResetWorkflow,
    handleToggleWorkflowActive,
    expanded,
    setExpanded,
    selectedProducts,
    setSelectedProducts,
    savedProducts,
    setSavedProducts,
  };
  return <WorkflowsContext.Provider value={value}>{children}</WorkflowsContext.Provider>;
};

export const useWorkflowsContext = () => {
  const context = React.useContext(WorkflowsContext);

  if (context === undefined) {
    throw new Error('useWorkflowsContext must be used within a WorkflowsProvider');
  }
  return context;
};
