import { ButtonGroup } from '@components/Button/ButtonGroup';
import { CustomButton } from '@components/Button/CustomButton';
import styled from '@emotion/styled';
import { ProductVariant } from '@typings/ProductVariant';
import { SwapGroupType } from '@typings/SwapGroup';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Routes } from './Form';

interface VariantsButtonsProps {
  groupType?: SwapGroupType;
  selectedVariants: ProductVariant[];
  setRoute(route: Routes): void;
}

export function VariantsButtons(props: VariantsButtonsProps) {
  const { groupType = 'swap', selectedVariants, setRoute } = props;

  const {
    formState: { isSubmitting },
    watch,
  } = useFormContext();

  function goToAddons() {
    setRoute('addons');
  }

  const hasEnoughVariants = selectedVariants.length > 1;
  const hasName = watch('name')?.length !== 0;
  const valid = hasEnoughVariants && hasName;

  return (
    <ButtonGroup>
      <WrappedButton valid={valid} isSubmitting={isSubmitting} />
      {groupType === 'swap' && (
        <CustomButton
          variant="primary"
          disabled={!valid}
          onClick={goToAddons}
          className="onboarding-swap-group-addons-button"
        >
          Continue to Add-On Items
        </CustomButton>
      )}
    </ButtonGroup>
  );
}

interface WrappedButtonProps {
  valid: boolean;
  isSubmitting: boolean;
}

const WrappedButton = (props: WrappedButtonProps) => {
  const { valid, isSubmitting } = props;

  const button = (
    <CustomButton variant="outline-primary" type="submit" disabled={!valid || isSubmitting}>
      {isSubmitting ? 'Loading..' : 'Save & Exit'}
    </CustomButton>
  );

  if (valid) return button;

  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="tooltip-group-save-error">
          You should select at leats two variants and type a name.
        </Tooltip>
      }
      placement="bottom"
    >
      <Wrapper>{button}</Wrapper>
    </OverlayTrigger>
  );
};

const Wrapper = styled.div`
  display: inline-block;
  & [disabled] {
    pointer-events: none;
  }
`;
