import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ifStyle } from '@utils/styles';
import { Button } from '@pages/Home/components/Button';
import { ConfirmationModal, ModalHandles } from '@components/Modal/ConfirmationModal';
import { Integration } from '@typings/Integration';
import { useIntegration } from '@hooks/useIntegration';
import { useAppSelector } from '@redux/hooks';

interface IntegrationConnectedProps {
  handleDisconnect: () => void;
  handleEdit: (integration: Integration) => void;
}

export function IntegrationConnected(props: IntegrationConnectedProps) {
  const { handleDisconnect, handleEdit } = props;
  const modalRef = useRef<ModalHandles>(null);

  const customerSupportState = useAppSelector((state) => state.customerSupport);

  const disconnectClickHandler = (e) => {
    e.stopPropagation();
    modalRef.current.toggleModal();
  };

  return (
    <div>
      <Title>Your Integrations</Title>

      {customerSupportState.connectedIntegration?.integration_sync === false ? (
        <Warning>
          This integration is currently unavailable. Please try again in a few minutes or reconnect.
        </Warning>
      ) : null}

      <Box hasIntegration={!!customerSupportState.connectedIntegration}>
        {customerSupportState.connectedIntegration ? (
          <>
            <img height={25} src={customerSupportState.connectedIntegration?.image} />
            <Button inverse onClick={disconnectClickHandler}>
              Disconnect
            </Button>
          </>
        ) : (
          <Text>No integrations currently connected</Text>
        )}
      </Box>

      <ConfirmationModal
        ref={modalRef}
        title="Disconnect Integration?"
        description="Disconnecting your current active integration will halt all SMS based customer support response for your customers. Are you sure you want to disconnect?"
        cancelButtonLabel="Cancel"
        confirmButtonLabel="I'm sure"
        onConfirm={handleDisconnect}
      />
    </div>
  );
}

const Box = styled.div<{ hasIntegration?: boolean }>`
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 24px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%239A9B9CFF' stroke-width='2' stroke-dasharray='20%2c 15' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");

  ${ifStyle('hasIntegration')(css`
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
    cursor: pointer;
  `)}
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 1rem;
  margin-left: 5px;
`;

const Text = styled.p`
  margin: 0 auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6b6b6b;
`;

const Warning = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #ff0000;
  margin-top: 5px;
`;
