import React, { useState, PropsWithChildren, ReactNode } from 'react';

import { Feature } from '@typings/Feature';

import { useFeaturesContext } from '@contexts/FeaturesContext';
import { useBaseAppContext } from '@components/BaseAppContext';
import FeatureSwitch from './FeatureSwitch';

export interface FeatureSwitchContainerProps {
  disabled?: boolean;
  feature: Feature;
  selectedFeature?: Feature;
  childNestedLevel?: number;
  show?: boolean;
  showActions?: boolean;
  tags?: ReactNode;
  onInfo?(feature): void;
}

function FeatureSwitchContainer(props: PropsWithChildren<FeatureSwitchContainerProps>) {
  const { feature } = props;

  const {
    selectedFeature,
    selectedPreview,
    currentState,
    handleFeatureSelected,
    handlePreviewChange,
    handleFeatureChanged,
  } = useFeaturesContext();

  const { setShowContactCardModal } = useBaseAppContext();

  const state = currentState(feature.key);
  const [loading, setLoading] = useState(false);
  const isFeatureSelected = selectedFeature?.key === feature?.key;
  const isPreviewSelected = selectedPreview === feature?.key;

  const toggle = async () => {
    setLoading(true);

    if (feature.key === 'send_vcard' && state?.enabled === false) {
      setShowContactCardModal(true);
    }

    await handleFeatureChanged(feature.key);

    setLoading(false);
  };

  return (
    <FeatureSwitch
      {...props}
      checked={state?.enabled}
      loading={loading}
      handleToggle={toggle}
      isFeatureSelected={isFeatureSelected}
      isPreviewSelected={isPreviewSelected}
      onInfo={handleFeatureSelected}
      onPreview={handlePreviewChange}
    />
  );
}

export default FeatureSwitchContainer;
