import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';

import { InfoText } from './InfoText';

interface UpdateIntervalTextProps {
  collectionName: string;
}

export const UpdateIntervalText = (props: UpdateIntervalTextProps) => {
  const { collectionName } = props;
  return (
    <InfoText>
      <FontAwesomeIcon className="mr-1" icon={faSyncAlt} />
      {collectionName} lists are updated on a daily basis.
    </InfoText>
  );
};
