import axios from '@lib/axios';
import { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useState } from 'react';

export interface RefetchOptions {
  useLoading?: boolean;
}

export type Refetch = (options?: RefetchOptions) => Promise<void>;

export function useFetch<T>(url: string, config?: AxiosRequestConfig) {
  const [data, setData] = useState<T>(null);
  const [loading, setLoading] = useState(true);

  const fetchData: Refetch = useCallback(
    async (options?: RefetchOptions) => {
      const { useLoading = true } = options || {};
      try {
        if (!loading && useLoading) setLoading(true);

        const response = await axios.get(`${url}.json`, config);

        setData(response.data);
      } finally {
        if (useLoading) setLoading(false);
      }
    },
    [url, config?.params?.page]
  );

  useEffect(() => {
    fetchData();
  }, [url, config?.params?.page]);

  return { data, loading, refetch: fetchData };
}
