import React, { useEffect } from 'react';
import { ProductView } from '@components/Onboarding/v2/components/ProductView';
import { useOnboardingV2 } from '@hooks/useOnboardingV2';
import { useAppSelector } from '@redux/hooks';
import { PRODUCT_3_FORMAT_TITLE_STEP } from '@components/Onboarding/v2/onboarding';

export const ProductImportView = () => {
  const { updateOnboarding } = useOnboardingV2();
  const onboardingState = useAppSelector((state) => state.onboarding);

  const moveToFormatTitleStep = async () => {
    await updateOnboarding(onboardingState.data.id, {
      step: PRODUCT_3_FORMAT_TITLE_STEP,
    });
  };

  useEffect(() => {
    if (onboardingState.numProducts > 0) moveToFormatTitleStep();
  }, [onboardingState]);

  return <ProductView showImportModal />;
};
