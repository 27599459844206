import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import styled from '@emotion/styled';

import axios from '@lib/axios';
import { ChatHistory, ChatForm } from '@components/Chat/components';

const buildSmsMessage = (payload) => ({
  id: +new Date(),
  category: 'inbound',
  createdAt: new Date().toDateString(),
  ...payload,
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

interface SimulatorProps {
  initialChat?: string | string[];
  initialMessage?: string;
  setInitialChat?: (chat: string) => void;
  productVariants?: any[];
  slowMessages?: number;
  shouldReset?: boolean;
  actions?: React.ReactNode;
}

const Simulator = (props: SimulatorProps) => {
  const {
    initialChat = [],
    initialMessage,
    setInitialChat,
    productVariants,
    slowMessages,
    shouldReset = false,
    actions,
  } = props;
  const [smsMessages, setSmsMessages] = useState([]);
  const previousMessageRef = useRef(null);
  const shopifyVariantIds = productVariants?.map((variant) => variant.shopifyVariantId);

  const resetChat = () => {
    previousMessageRef.current = null;
    setSmsMessages([]);
  };

  const sendMessage = async (body) => {
    await sleep(slowMessages);
    setSmsMessages((prevSmsMessages) => [...prevSmsMessages, buildSmsMessage({ body })]);

    const previousMessage = previousMessageRef?.current
      ? { type: previousMessageRef.current.type, metadata: previousMessageRef.current.metadata }
      : null;

    const { data } = await axios.post('/sms/api/simulator', {
      body,
      previous_message: previousMessage,
      initial_options: {
        shopify_variant_ids: shopifyVariantIds,
      },
    });

    const newMessages = data.map(buildSmsMessage).filter((message) => message?.body);

    previousMessageRef.current = newMessages[newMessages.length - 1];

    await sleep(slowMessages);

    setSmsMessages((prevSmsMessages) => [...prevSmsMessages, ...newMessages]);
  };

  async function loadMessages() {
    resetChat();

    if (!initialChat || !initialChat.length) return;

    if (Array.isArray(initialChat)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const body of initialChat) {
        // eslint-disable-next-line no-await-in-loop
        await sendMessage(body);
      }

      return;
    }

    await sendMessage(initialChat);
  }

  useEffect(() => {
    loadMessages();
  }, [initialChat, productVariants]);

  useEffect(() => {
    if (shouldReset) {
      loadMessages();
    }
  }, [shouldReset]);

  useEffect(() => {
    if (initialMessage) {
      setSmsMessages([buildSmsMessage({ body: initialMessage, category: 'outbound' })]);
    }
  }, []);

  return (
    <Container>
      <Actions>
        {actions}
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => {
            resetChat();
            if (initialChat !== 'info' && setInitialChat) {
              setInitialChat('info');
            } else {
              sendMessage('info');
            }
          }}
        >
          Reset Chat
        </Button>
      </Actions>
      <ChatHistory smsMessages={smsMessages} style={{ height: 500 }} />
      <ChatForm onSubmit={({ body }) => sendMessage(body)} />
    </Container>
  );
};

export default Simulator;

const Container = styled.div`
  background-color: #ffffff;
  padding: 16px 21px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
