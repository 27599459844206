import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { useIntegration } from '@hooks/useIntegration';
import { Integration } from '@typings/Integration';

import {
  IntegrationsList,
  IntegrationForm,
  IntegrationConnected,
  AboutIntegrations,
} from './components';
import { Card, Header, Content, Title } from '../Card';
import { schema } from './components/Forms';
import { HowToCard } from './components/HowToCard';
import { useAppSelector } from '@redux/hooks';

export function CustomerIntegrationsCard() {
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>();

  const { availableIntegrations, connectIntegration, disconnectIntegration } = useIntegration();

  const customerSupportState = useAppSelector((state) => state.customerSupport);

  const formProps = useForm({
    resolver: yupResolver(schema[selectedIntegration?.service]),
  });

  const handleDisconnect = () => {
    disconnectIntegration();
    setSelectedIntegration(null);
  };

  const defaultHowtoConnectContent = (
    <>
      By connecting with your CS integration, you are supercharging rcSMS even more. Any message our
      system cannot automate (invalid keywords) will be pushed into a support ticket with all the
      context you need to address the customer’s concerns.
      <br />
      <br />
      For more information, read through our official knowledge base article{' '}
      <a
        target="_blank"
        href="https://support.rechargepayments.com/hc/en-us/articles/6935714600855-Customer-support-integrations"
      >
        here
      </a>
    </>
  );

  const integrationHowToContent = {
    kustomer: (
      <>
        Provide the authentication info below to connect RechargeSMS to Kustomer. You’ll need to
        generate an API Key in Kustomer with 2 features: ‘org’ as the role and no expiration. For
        more details, see our{' '}
        <a
          target="_blank"
          href="https://support.rechargepayments.com/hc/en-us/articles/6935714600855-Customer-support-integrations"
        >
          guide
        </a>
        .
      </>
    ),
    zendesk: (
      <>
        Provide the authentication info below to connect RechargeSMS to Zendesk. You’ll need to
        provide your login information and Base API URL, but first you’ll need to enable
        Password-Access for Zendesk APIs. Follow our step-by-step{' '}
        <a
          target="_blank"
          href="https://support.rechargepayments.com/hc/en-us/articles/6935714600855-Customer-support-integrations"
        >
          guide
        </a>
        .
      </>
    ),
    intercom: (
      <>
        Provide the authentication info below to connect RechargeSMS to Intercom. You’ll need to
        provide the email address that registered for Intercom. Then, you’ll need to go to the
        developer hub to generate an API Key. It’s not hard and just takes a few clicks. We’re here
        to help — follow our step-by-step{' '}
        <a
          target="_blank"
          href="https://support.rechargepayments.com/hc/en-us/articles/6935714600855-Customer-support-integrations"
        >
          guide
        </a>
        .
      </>
    ),
    gorgias: (
      <>
        Provide the authentication info below to connect RechargeSMS to Gorgias. The Gorgias Base
        API URL, username, and API Key can all be found in Gorgias on the REST API page under
        Settings. For more details, see our{' '}
        <a
          target="_blank"
          href="https://support.rechargepayments.com/hc/en-us/articles/6935714600855-Customer-support-integrations"
        >
          guide
        </a>
        .
      </>
    ),
    freshdesk: (
      <>
        Provide the authentication info below to connect RechargeSMS to Freshdesk. The Freshdesk
        Portal URL or Account Subdomain is what you see in your browser’s address bar without
        anything after .com. It looks like this:
        <br />
        http://yourshopname.freshdesk.com.
        <br />
        The API Key can be found in your settings. For more details, see our{' '}
        <a
          target="_blank"
          href="https://support.rechargepayments.com/hc/en-us/articles/6935714600855-Customer-support-integrations"
        >
          guide
        </a>
        .
      </>
    ),
    reamaze: (
      <>
        Provide the authentication info below to connect RechargeSMS to re:amaze. The Account
        Subdomain (AKA Base API URL) is what you see in your browser’s address bar without anything
        after .com. It looks like this:
        <br />
        http://yourshopname.reamaze.com.
        <br />
        The API Token can be found in your settings. For more details, see our see our{' '}
        <a
          target="_blank"
          href="https://support.rechargepayments.com/hc/en-us/articles/6935714600855-Customer-support-integrations"
        >
          guide
        </a>
        .
      </>
    ),
  };

  const getHowToCardTitle = () => {
    return !!customerSupportState.connectedIntegration ? 'You’re All Set!' : 'How to Connect';
  };

  return (
    <FormProvider {...formProps}>
      <Card>
        <Header>
          <Title>Customer Support Integrations</Title>
        </Header>
        <CustomContent>
          <CustomRow>
            <LeftCol md={4}>
              <Container>
                {selectedIntegration && (
                  <HowToCard
                    title={getHowToCardTitle()}
                    content={integrationHowToContent[selectedIntegration.service]}
                  />
                )}
                {!selectedIntegration && (
                  <HowToCard title={getHowToCardTitle()} content={defaultHowtoConnectContent} />
                )}

                {selectedIntegration && (
                  <IntegrationForm
                    integration={selectedIntegration}
                    setIntegration={setSelectedIntegration}
                    handleConnect={connectIntegration}
                  />
                )}

                {!selectedIntegration && !IntegrationConnected && <AboutIntegrations />}

                <IntegrationConnected
                  handleEdit={setSelectedIntegration}
                  handleDisconnect={handleDisconnect}
                />
              </Container>
            </LeftCol>
            <RightCol md={8}>
              <IntegrationsList
                selectedIntegration={selectedIntegration}
                integrations={availableIntegrations}
                setIntegration={setSelectedIntegration}
              />
            </RightCol>
          </CustomRow>
        </CustomContent>
      </Card>
    </FormProvider>
  );
}

const CustomContent = styled(Content)`
  padding: 0px;
`;

const CustomRow = styled(Row)`
  margin: 0px;
`;

const LeftCol = styled(Col)`
  background-color: white;
  padding: 1.5rem;
`;

const RightCol = styled(Col)`
  position: relative;
  padding: 1.5rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 4rem);
  margin-top: 2.5rem;
`;
