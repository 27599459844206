import styled from '@emotion/styled';

export const Card = styled.div`
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  min-width: 0;
  height: 100%;
  word-wrap: break-word;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
