import React, { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer';

import { Header } from '../Header';
import { edgeTypes } from './components/Edges/edgeTypes';
import { nodeTypes } from './components/Nodes/nodeTypes';
import { WorkflowStatusBar } from './components/WorkflowStatusBar';
import { useWorkflowsContext } from '../WorkflowsContext';
import RightSidebar from '../RightSidebar';

export function MainArea() {
  const containerRef = useRef(null);
  const {
    currentWorkflow,
    isDragabble,
    elements,
    setReactFlowInstance,
    setContainerElement,
    isSaved,
    setIsSaved,
  } = useWorkflowsContext();

  useEffect(() => {
    setContainerElement(containerRef?.current);
  }, [containerRef]);

  return (
    <Container ref={containerRef}>
      {currentWorkflow && <Header />}
      <WorkflowStatusBar />
      <ReactFlowProvider>
        <FlowContainer>
          <ReactFlow
            elements={elements}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodesDraggable={isDragabble}
            defaultZoom={0.7}
            snapToGrid
            snapGrid={[20, 20]}
            onLoad={setReactFlowInstance}
            onNodeDrag={() => isSaved && setIsSaved(false)}
          />
          <RightSidebar />
        </FlowContainer>
      </ReactFlowProvider>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  flex: 1;
  background-color: ${(props) => props.theme.colors.light1};
  background-image: radial-gradient(#c4c4c455 2px, transparent 2px);
  background-size: 30px 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .react-flow {
    flex: 1;

    &__node-delay {
      z-index: 15 !important;
    }

    &__edge-textbg {
      fill: #000;
    }

    &__edge-text {
      fill: #fff;
    }
  }
`;

const FlowContainer = styled.div`
  display: flex;
  height: 100%;
`;
