import { useFetch } from '@hooks/useFetch';
import { Filters, ViewContainer } from '@pages/Products/components';
import { Page } from '@components/Layout/Page';
import { Import, TitleEditor } from '@pages/Products/ProductVariants/View/components';
import { Loading } from '@components/Loading';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { EmptyModalView } from '@components/Onboarding/v2/components/Modal/EmptyModalView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { Button } from 'react-bootstrap';
import { useAppDispatch } from '@redux/hooks';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';
import './ProductView.scss';

interface ProductViewProps {
  highlightIgnore?: boolean;
  highlightFormatTitleButton?: boolean;
  highlightImportButton?: boolean;
  showImportModal?: boolean;
  showFormatTitleModal?: boolean;
  showCompletedFormatTitleBanner?: boolean;
  handleAddProduct?: () => void;
}

export const ProductView = (props: ProductViewProps) => {
  const {
    highlightFormatTitleButton = false,
    highlightImportButton = false,
    highlightIgnore = false,
    showImportModal = false,
    showFormatTitleModal = false,
    showCompletedFormatTitleBanner = false,
    handleAddProduct = null,
  } = props;
  const [showUpdateProductTitleSuccessBanner, setShowUpdateProductTitleSuccessBanner] = useState(
    false
  );

  const dispatch = useAppDispatch();

  const baseUrl = '/sms/product_variants';
  const { data: templateEditorSettings } = useFetch<any>(
    '/sms/product_variants/template_editor_settings'
  );

  useEffect(() => {
    setShowUpdateProductTitleSuccessBanner(showCompletedFormatTitleBanner);
  }, [showCompletedFormatTitleBanner]);

  const onDismissSuccessBanner = () => {
    setShowUpdateProductTitleSuccessBanner(false);
    dispatch(onboardingSlice.actions.setShowCompletedFormatTitleBanner(false));
  };

  const handleCloseEditProductTitle = () => {
    dispatch(onboardingSlice.actions.setOnboardingModal(false));
  };

  return (
    <ViewContainer baseUrl={baseUrl} replenishment={false} highlightIgnore={highlightIgnore}>
      {({ page, loading, setSearch, filter, setFilter, sort, setSort, renderProducts }) => {
        const title = (
          <>
            <span>Products</span>&nbsp;
            <SubTitle>({page?.data?.length} total products)</SubTitle>
          </>
        );

        const isShowEmptyModalView =
          highlightImportButton || highlightFormatTitleButton || highlightIgnore;

        return (
          <Page
            loading={loading}
            headerTitle={title}
            headerComponent={
              <HeaderComponent>
                {templateEditorSettings && (
                  <TitleEditor
                    highlightButton={highlightFormatTitleButton}
                    showModal={showFormatTitleModal}
                    handleCloseEditProductTitle={handleCloseEditProductTitle}
                    {...templateEditorSettings}
                  />
                )}
                <Import
                  highlightButton={highlightImportButton}
                  showModal={showImportModal}
                  handleAddProduct={handleAddProduct}
                />
              </HeaderComponent>
            }
            className="product-variants product-view"
          >
            {isShowEmptyModalView && <EmptyModalView />}

            <Container className="mt-3">
              <Filters
                filter={filter}
                setFilter={setFilter}
                setSearch={setSearch}
                sort={sort}
                setSort={setSort}
                replenishment={false}
              />

              <ProductHeaders>
                <table className="table table-borderless m-0">
                  <thead>
                    <tr>
                      <th style={{ width: '35%' }}>Name</th>
                      <th style={{ width: '10%' }}>Price</th>
                      <th style={{ width: '22%' }} />
                      <th style={{ width: '12%' }}>Created At</th>
                      <th style={{ width: '21%' }}>Actions</th>
                    </tr>
                  </thead>
                </table>
              </ProductHeaders>

              <ProductsContainer highlightIgnore={highlightIgnore}>
                {!loading && showUpdateProductTitleSuccessBanner && (
                  <ProductTitleUpdateSuccessContainer>
                    <ProductTitleUpdateLeftColumn>
                      <FontAwesomeIcon icon={faCheck} /> &nbsp;
                      <span>All product titles have been successfully updated!</span>
                    </ProductTitleUpdateLeftColumn>
                    <ProductTitleUpdateRightColumn>
                      <ProductTitleUpdateDimissButton onClick={() => onDismissSuccessBanner()}>
                        Dismiss
                      </ProductTitleUpdateDimissButton>
                    </ProductTitleUpdateRightColumn>
                  </ProductTitleUpdateSuccessContainer>
                )}
                {loading ? <Loading className="mt-3" /> : renderProducts(page)}
              </ProductsContainer>
            </Container>
          </Page>
        );
      }}
    </ViewContainer>
  );
};

const SubTitle = styled.span`
  font-weight: normal !important;
`;

const HeaderComponent = styled.div`
  display: flex;
  align-items: center;

  .highlight-button {
    padding: 5px;
    background-color: white;
    z-index: 2000 !important;
    border-radius: 5px;
  }
`;

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  border-radius: 16px;
`;

const ProductHeaders = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding: 0 26px;
`;

const ProductsContainer = styled.div<{ highlightIgnore?: boolean }>`
  background: #f4f4f6;
  padding: 20px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  .product-variants > table > tbody > tr:first-child a.ignore-product {
    z-index: ${(props) => (props.highlightIgnore ? '2000' : 'auto')} !important;
    cursor: auto;
  }
`;

const ProductTitleUpdateSuccessContainer = styled.div`
  background: #17cc9d;
  padding: 15px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #ffffff;
`;

const ProductTitleUpdateLeftColumn = styled.div`
  flex: 1;
`;

const ProductTitleUpdateRightColumn = styled.div`
  flex: 1;
`;

const ProductTitleUpdateDimissButton = styled(Button)`
  padding: 6px 20px 6px 20px;
  background: transparent;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  float: right;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  &:hover {
    background: transparent;
    background: transparent;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;
