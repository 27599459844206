import React from 'react';
import { useParams } from 'react-router-dom';

import axios from '@lib/axios';
import { useFetch } from '@hooks/useFetch';
import { Page } from '@components/Layout/Page';

import Form from './Form';

const PATH = '/sms/template_messages';

function Edit() {
  const { id } = useParams();
  const { data: templateMessage, loading } = useFetch(`${PATH}/${id}`);

  async function handleSubmit(data) {
    return axios.put(`${PATH}/${id}.json`, data);
  }

  return (
    <Page loading={loading} headerDescription="Edit the SMS message body to your own style.">
      <Form onSubmit={handleSubmit} defaultValues={templateMessage} />
    </Page>
  );
}

export default Edit;
