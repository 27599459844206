import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { CenteredLoading } from '@components/Loading';
import { useFetch } from '@hooks/useFetch';
import axios from '@lib/axios';
import { useCurrentShop } from '@hooks/useCurrentShop';

import { OptIn } from './OptIn';

export function OptInContainer() {
  const { loading, data, refetch } = useFetch<LandingPageOptInConfig>(
    '/sms/widget_configs/landing_page_opt_in'
  );

  async function handleSubmit(config: LandingPageOptInConfig) {
    await axios.put('/sms/widget_configs/landing_page_opt_in', {
      config,
    });
    await refetch({ useLoading: false });
  }

  if (loading) {
    return <CenteredLoading />;
  }

  return <InnerContainer currentConfig={data} onSubmit={handleSubmit} />;
}

const placeholders: LandingPageOptInConfigV1['data'] = {
  heroCopyFontColor: '#000000',
  heroCopy: '📲 Simplify your subscriptions with automated SMS reminders and order management',

  // Call to action
  ctaButtonLabel: "Let's do it 🙏",
  ctaButtonColor: '#3902f0',
  ctaButtonFontColor: '#ffffff',

  // page
  logoUrl: `${window.location.origin}/sms/opt-in/rechargeSMS_logo.png`,
};

interface InnerContainerProps {
  currentConfig?: LandingPageOptInConfig;
  onSubmit(values: LandingPageOptInConfig): Promise<void>;
}

export function InnerContainer(props: InnerContainerProps) {
  const shop = useCurrentShop();
  const { currentConfig, onSubmit } = props;
  const { data = placeholders } = currentConfig || {};

  const formProps = useForm({
    shouldUnregister: false,
    defaultValues: {
      ...data,
      logoUrl: shop?.logoUrl,
    },
  });

  function internalOnSubmit(values: LandingPageOptInConfigV1['data']) {
    return onSubmit({
      version: 'v1',
      data: values,
    });
  }

  return (
    <FormProvider {...formProps}>
      <OptIn onSubmit={formProps.handleSubmit(internalOnSubmit)} />
    </FormProvider>
  );
}
