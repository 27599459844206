import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextInput as Input, ErrorMessage } from '@components/Input';

export function TextInput(props) {
  const { name, customControlStyle = {} } = props;
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Input
        controlStyle={{
          background: '#F4F4F6',
          borderRadius: 20,
          border: 0,
          paddingLeft: 15,
          ...customControlStyle,
        }}
        labelStyle={{
          color: '#000000',
          fontFamily: 'SFProDisplay, serif',
          letterSpacing: 0.47,
        }}
        {...props}
      />
      <ErrorMessage errors={errors} name={name} />
    </>
  );
}
