import styled from '@emotion/styled';
import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import messageIcon from '@pages/Workflows/assets/message.svg';
import Edit from '@assets/icons/edit-dark.svg';
import { Button as WorkflowButton } from '@pages/Workflows/components/Button';
import colors from 'styles/theme/colors';

import { useLayoutContext } from '@pages/Workflows/LayoutContext';
import BaseNode, { NodeProps } from '../BaseNode';
import { EmailFallback } from './EmailFallback';

interface MessageNodeProps extends NodeProps {
  edit_message_title: string;
  variables?: string[];
  body?: string;
  // eslint-disable-next-line camelcase
  show_email_fallback?: boolean;
  // eslint-disable-next-line camelcase
  email_fallback?: boolean;
  email?: {
    title: string;
    subject: string;
    body: string;
  };
}

export const MessageNode = ({ data }: { data: MessageNodeProps }) => {
  const { handleChangeWorkflowStep } = useWorkflowsContext();
  const { setRightSidebar } = useLayoutContext();

  const formProps = useForm({
    shouldUnregister: false,
    defaultValues: {
      title: data?.title,
      body: data?.body,
      email: data?.email,
      email_fallback: data?.email_fallback,
    },
  });
  const { handleSubmit } = formProps;

  function handleToggleEditMessageModal() {
    setRightSidebar({ type: 'edit-message', open: true, data, width: 500 });
  }

  function handleSave(formData) {
    if (formData.email && formData.email.body) {
      // eslint-disable-next-line no-param-reassign
      formData.email.body = formData.email.body.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }

    handleChangeWorkflowStep(Number(data.id), formData, true);
    toast.success('Message successfully saved!');
    handleToggleEditMessageModal();
  }

  return (
    <BaseNode
      icon={messageIcon}
      color={colors.primary}
      bodyComponent={
        <EditButton onClick={handleToggleEditMessageModal}>
          Edit Template <img alt="icon" src={Edit} />
        </EditButton>
      }
      showCollapse={data?.show_email_fallback}
      collapseComponent={
        <EmailFallback variables={data?.variables} handleSave={handleSubmit(handleSave)} />
      }
      {...data}
    />
  );
};

const EditButton = styled(WorkflowButton)`
  width: 150px;
  justify-content: space-between;
  width: 150px;

  svg {
    margin-left: 5px;
  }
`;
