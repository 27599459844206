import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import FeatureSwitch from '@pages/Settings/Features/shared/FeatureSwitch';
import { updateCustomize } from '@api/sms';

const SendChargeFailedNotificationsSwitch = (props) => {
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const initialMaxNumberOfChargeTries = props?.shop?.maxNumberOfChargeTries;

  async function update(formData) {
    try {
      setIsLoading(true);
      await updateCustomize({ shop: formData });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FeatureSwitch {...props}>
      <div className="ml-3">
        <Form onSubmit={handleSubmit(update)}>
          <Row>
            <Col lg={5}>
              <Form.Group controlId="max-number-of-charge-tries">
                <Form.Label>Max Number of Charges Tries</Form.Label>
                <Form.Control
                  name="max_number_of_charge_tries"
                  type="number"
                  min="0"
                  max="8"
                  step="1"
                  defaultValue={initialMaxNumberOfChargeTries}
                  {...register('max_number_of_charge_tries', { required: true })}
                />
                <Form.Text className="text-muted">
                  We'll use this to control how many times your user will receive the notification.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={5} className="text-right">
              <Button type="submit" disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </FeatureSwitch>
  );
};

export default SendChargeFailedNotificationsSwitch;
