import React, { memo } from 'react';

import questionIcon from '@pages/Workflows/assets/question.svg';

import BaseNode, { NodeProps } from './BaseNode';

export const ConditionalNode = memo(({ data }: { data: NodeProps }) => (
  <BaseNode
    icon={questionIcon}
    color="#FF9A62"
    title="System receives the following answer"
    {...data}
  />
));
