import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';

import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import { useLayoutContext } from '@pages/Workflows/LayoutContext';
import { ModalHandles } from '@pages/Workflows/components/DeleteWorkflowModal';
import { ConfirmationModal } from '@components/Modal/ConfirmationModal';

export function WorkflowStatusBar() {
  const {
    isSaved,
    currentWorkflow,
    handleResetWorkflow,
    handleSaveWorkflow,
  } = useWorkflowsContext();
  const { rightSidebar } = useLayoutContext();

  const modalRef = useRef<ModalHandles>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const isNewWorkflow = currentWorkflow?.id === 0;
  const isWorkflowSaved = isNewWorkflow ? false : isSaved;

  const saveWorkflow = async () => {
    try {
      setIsUpdating(true);
      await handleSaveWorkflow();
    } finally {
      setTimeout(() => setIsUpdating(false), 1000);
    }
  };

  function getTranformStyle() {
    if (rightSidebar?.open) {
      return `translateX(calc(-50% - ${rightSidebar?.width / 2}px))`;
    }
  }

  if (!currentWorkflow) return null;

  return (
    <>
      <Container updated={!isUpdating && isWorkflowSaved} style={{ transform: getTranformStyle() }}>
        {(isUpdating && 'Updating new changes...') ||
          (isWorkflowSaved ? 'All changes saved.' : 'You have unsaved changes.')}
        <Separator />
        <ResetButton
          disabled={isUpdating || isWorkflowSaved}
          onClick={() => modalRef?.current?.toggleModal()}
        >
          <FontAwesomeIcon icon={faUndo} />
        </ResetButton>
        <SaveButton
          type="button"
          className="ml-3"
          disabled={isUpdating || isWorkflowSaved}
          onClick={saveWorkflow}
        >
          {isUpdating ? <Spinner icon={faSyncAlt} /> : 'Save'}
        </SaveButton>
      </Container>
      <ConfirmationModal
        ref={modalRef}
        title="Reset Workflow"
        description={`Are you sure you want to reset this workflow to its ${
          isNewWorkflow ? 'initial' : 'previous saved'
        } state?`}
        onConfirm={() => handleResetWorkflow()}
      />
    </>
  );
}

const Container = styled.div<{ updated?: boolean }>`
  align-items: center;
  background: #ffffff;
  border: ${(props) => (props.updated ? '2px solid #17CC9D' : 'none')};
  border-radius: 30px;
  bottom: 2rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
  color: #6b6b6b;
  display: flex;
  height: 50px;
  justify-content: center;
  left: 50%;
  padding: 8px 16px;
  position: absolute;
  transform: translateX(-50%);
  transition: transform 0.5s ease-in-out;
  z-index: 5;
`;

const Separator = styled.div`
  align-self: center;
  background-color: #f2f3f5;
  height: 34px;
  margin: 0 16px;
  width: 1px;
`;

const ResetButton = styled.button`
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid #f2f3f5;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  height: 34px;
  padding: 8px;
  width: 34px;
`;

const SaveButton = styled.button`
  background: #17cc9d;
  border-radius: 30px;
  border: 1px solid #f2f3f5;
  box-sizing: border-box;
  color: white;
  height: 34px;
  padding: 6px 20px;

  &:disabled {
    background-color: #efeff1;
    color: #6b6b6b;
  }
`;

const Spinner = styled(FontAwesomeIcon)`
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
