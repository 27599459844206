import React from 'react';

import { Page } from '@components/Layout/Page';

import { Form } from './Form';

function New(props) {
  return (
    <Page headerDescription="Add your own responses to customer messages.">
      <Form {...props} />
    </Page>
  );
}

export default New;
