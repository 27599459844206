import styled from '@emotion/styled';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export function EditPercentage() {
  const { register, watch, setValue } = useFormContext();

  const percentageMask = createNumberMask({
    allowDecimal: false,
    integerLimit: 2,
    prefix: '',
  });

  return (
    <Container>
      <FormRow>
        <Label>Discount Percentage</Label>
        <MaskedInput
          mask={percentageMask}
          name="percentage"
          {...register('percentage', { valueAsNumber: true })}
          defaultValue={watch('percentage')}
          onChange={(e) => setValue('percentage', e.target.value)}
          render={(ref, props) => <Form.Control ref={ref} {...props} />}
        />
      </FormRow>
    </Container>
  );
}

const Container = styled.div`
  margin: 0.5rem 0;
`;

const Label = styled(Form.Label)`
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const FormRow = styled(Form.Row)`
  flex-direction: column;
  padding: 0.75rem 0;
  margin: 0;
`;
