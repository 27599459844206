import React, { forwardRef, useState } from 'react';

import Modal from '@components/Modal/BaseModal';
import { CustomButton as Button } from '@components/Button/CustomButton';
import styled from '@emotion/styled';
import { CloseModalIcon } from '@components/Modal/CloseModalIcon';
import { Workflow } from '@typings/Workflow';
import { NavLink } from 'react-bootstrap';
import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import ReplenishmentSmsEmailIcon from '@assets/images/replenishment-sms-email.png';
import ReplenishmentSmsIcon from '@assets/images/replenishment-sms.png';

export * from '@components/Modal/BaseModal';

const iconMap = {
  replenishment_sms_email: ReplenishmentSmsEmailIcon,
  replenishment_sms: ReplenishmentSmsIcon,
};

const iconSizeMap = {
  replenishment_sms_email: 25,
  replenishment_sms: 30,
};

interface CreateReplenishmentWorkflowModalProps {
  learnMoreLink: string;
}

export function CreateReplenishmentWorkflowModalComponent(
  props: CreateReplenishmentWorkflowModalProps,
  ref
) {
  const { learnMoreLink } = props;
  const { defaultWorkflows, handleSelectWorkflow } = useWorkflowsContext();

  const [currentSelectWorkflow, setCurrentSelectWorkflow] = useState<Workflow>();

  function toggleModal() {
    ref?.current?.toggleModal();
  }

  return (
    <Modal ref={ref}>
      <Container>
        <CloseIcon onClose={toggleModal} />

        <Title>Create a Replenishment Workflow</Title>
        <Subtitle>Enter the capabilities of workflows at a high level</Subtitle>

        <HorizontalLineSeparator />

        <TemplateContainer>
          {defaultWorkflows?.map((workflow, index) => (
            <TemplateItem
              key={`${workflow.id}_${index}`}
              selected={currentSelectWorkflow?.templateTag === workflow.templateTag}
              onClick={() => setCurrentSelectWorkflow(workflow)}
            >
              <TemplateItemIconContainer>
                <TemplateItemIcon
                  src={iconMap[workflow.templateTag]}
                  height={iconSizeMap[workflow.templateTag]}
                />
              </TemplateItemIconContainer>

              <VerticalLineSeparator />

              <TemplateItemContentContainer>
                <TemplateItemTitle>{workflow.title}</TemplateItemTitle>
                <TemplateItemDescription>{workflow.description}</TemplateItemDescription>
              </TemplateItemContentContainer>
            </TemplateItem>
          ))}
        </TemplateContainer>

        <HorizontalLineSeparator />

        <ActionContainer>
          <NavLink style={{ paddingLeft: '0px', flex: 1 }} href={learnMoreLink}>
            Learn more
          </NavLink>
          <ButtonContainer>
            <Button
              variant="primary"
              disabled={!currentSelectWorkflow}
              onClick={() => {
                handleSelectWorkflow(currentSelectWorkflow.id, currentSelectWorkflow.templateTag);
                toggleModal();
              }}
            >
              Confirm
            </Button>
          </ButtonContainer>
        </ActionContainer>
      </Container>
    </Modal>
  );
}

export const CreateReplenishmentWorkflowModal = forwardRef(
  CreateReplenishmentWorkflowModalComponent
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px 20px 30px;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
`;

const CloseIcon = styled(CloseModalIcon)`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const HorizontalLineSeparator = styled.div`
  width: 100%;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.dark3}44`};
  margin: 10px 0;
`;

const VerticalLineSeparator = styled.div`
  width: 1px;
  border-left: ${(props) => `1px solid ${props.theme.colors.dark3}44`};
  margin: 0px 15px;
`;

const TemplateItemTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
`;

const TemplateItemDescription = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;

interface TemplateItemProps {
  selected: boolean;
}

const TemplateItem = styled.div<TemplateItemProps>`
  display: flex;
  flex-direction: row;
  background: ${(props) => props.theme.colors.light0};
  border: ${(props) =>
    props?.selected ? `1px solid ${props.theme.colors.dark0}` : '1px solid transparent'};
  box-shadow: ${(props) => `0px 0px 2px ${props.theme.colors.dark0}44`};
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  cursor: pointer;
  padding: 10px;

  &:hover {
    border: ${(props) => `1px solid ${props.theme.colors.dark0}`};
  }
`;

const TemplateItemIcon = styled.img`
  margin: 0px;
  align-self: center;
`;

const TemplateItemIconContainer = styled.div`
  margin-left: 5px;
  width: 60px;
  text-align: center;
  align-self: center;
`;

const TemplateItemContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
