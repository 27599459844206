import React from 'react';

import { Credentials } from '@typings/Integration';

import { TextInput } from '../TextInput';
import { OverlayTrigger } from 'react-bootstrap';
import { CustomTooltip } from '@components/Layout/Sidebar/components';
import styled from '@emotion/styled';

interface FormProps {
  credentials: Credentials;
}

export function KustomerForm(props: FormProps) {
  const { credentials } = props;

  return (
    <div>
      <OverlayTrigger
        placement="right"
        overlay={
          <CustomTooltip id="kustomerForm">
            Generate an API Key with an 'org' role and no expiration.
          </CustomTooltip>
        }
      >
        <Label>API Key</Label>
      </OverlayTrigger>
      <TextInput
        name="kustomer_api_key"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.api_key,
        }}
      />
    </div>
  );
}

const Label = styled.div`
  font-size: 14px;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.35rem;
  line-height: 21px;
`;
