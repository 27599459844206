import React from 'react';
import { Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ActivateFooterProps {
  rightButtonText?: string;
  rightButtonIcon?: IconDefinition;
  rightButtonOnClick?(): void;
}

export function ActivateFooter(props: ActivateFooterProps) {
  const { rightButtonText, rightButtonIcon, rightButtonOnClick } = props;

  return (
    <ButtonsContainer>
      <ActivateButton onClick={rightButtonOnClick}>
        {rightButtonText || 'Activate'}
        {rightButtonIcon && <FontAwesomeIcon className="ml-1" icon={rightButtonIcon} />}
      </ActivateButton>
    </ButtonsContainer>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 1rem;
`;

const ActivateButton = styled(Button)`
  filter: grayscale(${(props) => (props.disabled ? 1 : 0)});
  cursor: initial;

  &:enabled {
    cursor: pointer;
  }
`;
