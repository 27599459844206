import React from 'react';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { SmsMessage } from '@typings/SmsMessage';
import ChatLogo from '@assets/images/chat-logo.svg';

interface MessageProps {
  smsMessage: SmsMessage;
}

export function Message({ smsMessage }: MessageProps) {
  const templateMessage = smsMessage?.metadata?.template_message;
  let smsMessageEl = <div />;

  if (smsMessage.category === 'outbound') {
    smsMessageEl = (
      <Outbound>
        <div className="outbound__img">
          <img src={ChatLogo} alt="rechargeSMS" />
        </div>
        <div className="outbound__wrapper">
          <div className="outbound__message">
            {smsMessage.deliveryFailureCode && (
              <span className="message__failed_tag">
                The cell carrier failed to deliver this message
              </span>
            )}
            <p className="body">{smsMessage.body}</p>
            <span className="message__created-at">{smsMessage.createdAt}</span>
          </div>
          {!!templateMessage && (
            <Link
              className="message__template-title text-info d-flex"
              to={`/sms/template_messages/${templateMessage.id}/edit`}
              target="_blank"
            >
              {templateMessage.title}
              <ExternalLink className="ml-1" size={12} />
            </Link>
          )}
        </div>
      </Outbound>
    );
  }

  if (smsMessage.category === 'inbound') {
    smsMessageEl = (
      <Inbound>
        <div className="inbound__message">
          <p className="body">{smsMessage.body}</p>
          <span className="message__created-at">{smsMessage.createdAt}</span>
        </div>
      </Inbound>
    );
  }

  return <Container>{smsMessageEl}</Container>;
}

const Container = styled.div`
  @keyframes showIn {
    0% {
      transform: translateY(1rem);
      opacity: 0;
      transform: scale(0);
    }

    100% {
      transform: translateY(0);
      opacity: 1;
      transform: scale(1);
    }
  }

  animation-duration: 0.5s;
  animation-name: showIn;
  animation-play-state: running;
  animation-fill-mode: forwards;

  .message__created-at {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 6px 0 10px;
  }
`;

const Inbound = styled.div`
  overflow: hidden;
  margin: 26px 0;

  .inbound__message {
    float: right;
    width: 46%;

    .body {
      white-space: pre-wrap;
      background: var(--primary) none repeat scroll 0 0;
      border-radius: 3px;
      font-size: 14px;
      margin: 0;
      color: #fff;
      padding: 5px 10px 5px 12px;
      width: 100%;
    }
  }
`;

const Outbound = styled.div`
  display: flex;
  width: 90%;

  .outbound__img {
    display: inline-block;

    img {
      border-radius: 50% !important;
      width: 32px;
    }
  }

  .outbound__message {
    margin-bottom: 5px;

    .body {
      white-space: pre-wrap;
      background: #ebebeb none repeat scroll 0 0;
      border-radius: 3px;
      color: #646464;
      font-size: 14px;
      margin: 0;
      padding: 5px 10px 5px 12px;
      width: 100%;
    }
  }

  .outbound__wrapper {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
    margin-bottom: 10px;
  }

  .message__template-title {
    font-size: 13px;
    margin-top: 5px;
  }

  .message__failed_tag {
    color: #dc3c3c;
    display: block;
    font-size: 12px;
    margin: 6px 0 10px;
  }
`;
