export default {
  add_one_time_item: ['modify order', 'one time item'],
  delay_order: ['modify order', 'delay'],
  skip_order: ['modify order', 'skip'],
  swap_product: ['modify order', 'swap'],
  update_billing_info: ['modify order', 'account', 'billing'],
  update_interval: ['modify order', 'account', 'update interval', '1'],
  update_next_charge_date: ['modify order', 'next charge'],
  update_quantity: ['modify order', 'account', 'update quantity', '2'],
  update_shipping_address: ['modify order', 'account', 'address'],
  end_subscription: 'end subscription',
  charge_reminder: 'upcoming_charge_message',
  shipment_tracking: 'shipment_tracking_message',
  send_welcome: 'welcome_order',
  send_charge_failed_notifications: 'charge_failed_message',
  order_processed_sms: 'order_processed_message',
};
