import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

import { Text } from '@components/Typography/Text';

interface NewButtonProps {
  text: string;
  className?: string;
  handleClick(): void;
}

export function NewButton(props: NewButtonProps) {
  const { text, className, handleClick } = props;

  return (
    <Root className={className}>
      <Button onClick={handleClick}>
        <StyledText size="small" weight="bold">
          {text}
        </StyledText>
        <StyledIcon icon={faPlus} />
      </Button>
    </Root>
  );
}

const StyledText = styled(Text)`
  height: 19px;
  overflow: hidden;
  margin-right: ${(props) => props.theme.spacing.xSmall};
  transition: 150ms ease-in-out;
  transition-delay: 250ms;
  opacity: 0;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.light0};
  font-size: 16px;
  margin-right: 2px;
`;

const Button = styled.div`
  background-color: ${(props) => props.theme.colors.dark0};
  border-radius: 100px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  color: ${(props) => props.theme.colors.light0};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 33px;
  overflow: hidden;
  padding: 7px;
  transition: 250ms ease-in-out;
  width: 33px;
  align-items: center;

  &:hover {
    width: 100%;

    ${StyledText} {
      opacity: 1;
    }
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
