import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import copy from 'copy-to-clipboard';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';

import { Toggle } from '@components/Input/Toggle';

import { useBaseAppContext } from '@components/BaseAppContext';
import { Separator } from '@components/Layout/Separator';
import { Card, Header, Content, Title, Subtitle } from '../Card';
import { Back } from './components';
import { Pages } from './index';
import { ManualInstallationCard } from './components/ManualInstallationCard';
import { EasyInstallCard } from './components/EasyInstallCard';
import { OptInCard } from './components/OptInCard';
import { WidgetConfigType } from './types';

interface CartPageProps {
  setPage: (page: Pages) => void;
}

export function CartPage(props: CartPageProps) {
  const { setPage } = props;
  const [copied, setCodeCopied] = useState(false);
  const variantOptions = [
    { label: 'Auto', value: 'auto' },
    { label: 'Cobalt', value: 'cobalt' },
    { label: 'Paper', value: 'paper' },
    { label: 'Midnight', value: 'midnight' },
  ];
  const [scriptVariant, setScriptVariant] = useState<{ label: string; value: string }>(
    variantOptions[0]
  );
  const [hideCustomization, setHideCustomization] = useState<boolean>(true);
  const [showManualInstall, setShowManualInstall] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pendingWidgetConfig, setPendingWidgetConfig] = useState<WidgetConfigType>(null);
  const { cartWidgetConfig, updateCartWidgetConfig } = useBaseAppContext();

  const scriptHtml = `<div id="rcsms-cart-widget"></div>`;

  function handleScriptCopy() {
    copy(scriptHtml);
    setCodeCopied(true);
  }

  function handleXMarginChange(event) {
    const x_margin = parseInt(event.target.value, 10);
    setPendingWidgetConfig({ ...pendingWidgetConfig, x_margin });
  }

  function handleYMarginChange(event) {
    const y_margin = parseInt(event.target.value, 10);
    setPendingWidgetConfig({ ...pendingWidgetConfig, y_margin });
  }

  async function handleSave() {
    updateCartWidgetConfig(pendingWidgetConfig);
  }

  function handleToggleBranding(val) {
    setPendingWidgetConfig({ ...pendingWidgetConfig, branding_disabled: val });
  }

  useEffect(() => {
    if (copied) {
      const copiedTimer = setTimeout(() => setCodeCopied(false), 2000);
      return () => clearTimeout(copiedTimer);
    }
  }, [copied]);

  useEffect(() => {
    if (cartWidgetConfig) {
      setPendingWidgetConfig(cartWidgetConfig);
      setIsLoading(false);
      if (cartWidgetConfig?.variant) {
        setScriptVariant(variantOptions.find((x) => x.value === cartWidgetConfig.variant));
      }
    }
  }, [cartWidgetConfig]);

  function handleVariantChange(option) {
    setScriptVariant(option);
    setPendingWidgetConfig({ ...pendingWidgetConfig, variant: option.value });
  }

  return (
    <>
      <Header>
        <CustomTitle>
          <div className="d-flex align-items-center my-1">
            <Back onClick={() => setPage('initial')} />
            Cart Widget Opt-in
          </div>
          {!hideCustomization && (
            <CustomButton
              disabled={JSON.stringify(cartWidgetConfig) === JSON.stringify(pendingWidgetConfig)}
              variant="secondary"
              onClick={handleSave}
            >
              Save and update
            </CustomButton>
          )}
        </CustomTitle>
      </Header>
      <Content style={{ paddingTop: '0' }}>
        <Row>
          <Col lg={4}>
            {showManualInstall ? (
              <ManualInstallationCard setShowManualInstall={setShowManualInstall} />
            ) : (
              <EasyInstallCard
                pendingWidgetConfig={pendingWidgetConfig}
                customizationOpen={!hideCustomization}
                setShowManualInstall={setShowManualInstall}
              />
            )}
          </Col>
          <Col lg={8}>
            <CustomCard>
              <Subtitle>🛠 Customize Cart Widget</Subtitle>
              <Separator />
              <Widget>
                <Row>
                  <Col lg={6}>
                    <div className="mt-1">
                      <h3>Widget Theme</h3>
                      <VariantSelect
                        isSearchable={false}
                        options={variantOptions}
                        onChange={handleVariantChange}
                        value={scriptVariant}
                      />
                    </div>
                    <div className="mt-4">
                      <h3>Disable Branding</h3>
                      <ControlsWrap>
                        <CustomToggle
                          disabledLabel="False"
                          enabledLabel="True"
                          onChange={handleToggleBranding}
                          checked={pendingWidgetConfig?.branding_disabled}
                        />
                      </ControlsWrap>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mt-1">
                      <h3>Adjust Margins (Horizontal)</h3>
                      <NumberInput
                        type="number"
                        value={pendingWidgetConfig?.x_margin || 0}
                        onChange={handleXMarginChange}
                      />
                    </div>
                    <div className="mt-4">
                      <h3>Adjust Margins (Vertical)</h3>
                      <NumberInput
                        type="number"
                        value={pendingWidgetConfig?.y_margin || 0}
                        onChange={handleYMarginChange}
                      />
                    </div>
                  </Col>
                </Row>
                {showManualInstall && (
                  <div className="mt-4">
                    <h3>Widget HTML</h3>
                    <Row>
                      <Col sm={12}>
                        <textarea value={scriptHtml} rows={2} readOnly />
                        <CustomButton
                          className="copy"
                          variant="secondary"
                          onClick={handleScriptCopy}
                        >
                          {copied ? 'Copied!' : `Copy </>`}
                        </CustomButton>
                      </Col>
                    </Row>
                  </div>
                )}
              </Widget>
              <div style={{ flexGrow: 1 }} />
              <Separator />
              <InfoNote>
                <FontAwesomeIcon icon={faInfoCircle} size="1x" /> &nbsp;Margin adjustments are not
                supported by a live preview. Review adjustments after you’ve saved your changes.
              </InfoNote>
              <InfoNote>
                <FontAwesomeIcon icon={faInfoCircle} size="1x" /> &nbsp;Watermark color responds to
                cart theme, if set to auto.
              </InfoNote>
              <OpaqueOverlay disabled={!hideCustomization}>
                {hideCustomization && (
                  <OptInCard
                    isLoading={isLoading}
                    setHideCustomization={setHideCustomization}
                    widgetConfig={cartWidgetConfig}
                    updateCartWidgetConfig={updateCartWidgetConfig}
                    setPendingWidgetConfig={setPendingWidgetConfig}
                  />
                )}
              </OpaqueOverlay>
            </CustomCard>
          </Col>
        </Row>
      </Content>
    </>
  );
}

const CustomTitle = styled(Title)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Widget = styled.div`
  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #121533;
  }

  textarea {
    background: #f4f4f4;
    border-radius: 8px;
    border: 1px solid #d7d7d7;
    box-shadow: 0px 0px 2px #ffffff;
    padding: 12px;
    width: 100%;
  }
`;

const CustomButton = styled(Button)`
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  border-radius: 6px;
  padding: 8px 24px;
  color: #fefbf6;
  font-weight: 700;
  &.copy {
    position: absolute;
    right: 30px;
    top: 14px;
  }
`;

const ControlsWrap = styled.div`
  background-color: #f4f4f4;
  border-radius: 20px;
  padding: 4px;
  width: fit-content;
`;

const CustomToggle = styled(Toggle)`
  box-shadow: none;
  height: 36px;

  .slider {
    background-color: #121533;
    border: none;
    border-radius: 20px;
  }

  .checked {
    color: #606060;
  }

  .unchecked {
    color: #ffffff;
  }

  input:checked {
    ~ .checked {
      color: #ffffff;
    }

    ~ .unchecked {
      color: #606060;
    }
  }
`;

const CustomCard = styled(Card)`
  padding: 20px;
  overflow: hidden;
  min-height: 582px;
  display: flex;
  flex-direction: column;
`;

interface OpaqueOverlayProps {
  disabled: boolean;
}

const OpaqueOverlay = styled.div<OpaqueOverlayProps>`
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  transition: opacity 400ms ease-in-out;
  background-color: rgba(175, 175, 175, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const NumberInput = styled(Form.Control)`
  border-radius: calc((1.5em + 0.75rem + 2px) / 2);
  width: 112px;
`;

const VariantSelect = styled(Select)`
  width: 180px;
`;

const InfoNote = styled.div`
  opacity: 0.6;
  font-size: 12px;
`;
