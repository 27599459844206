import imageCompression from 'browser-image-compression';
import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import styled from '@emotion/styled';

import { TextInput } from '@components/Input';

import { useFormContext } from 'react-hook-form';

function ImageInput() {
  const { setValue, getValues } = useFormContext();

  const [base64Value, setBase64Value] = useState(getValues('vcard_picture'));
  const [processing, setProcessing] = useState(false);

  async function handleImageSelected(e) {
    const imageFile = e.target.files[0];
    if (imageFile) {
      try {
        setProcessing(true);
        const compressedImage = await imageCompression(imageFile, {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 256,
        });
        const base64Url = await imageCompression.getDataUrlFromFile(compressedImage);
        setBase64Value(base64Url);
        setValue('vcard_picture', base64Url);
      } finally {
        setProcessing(false);
      }
    }
  }

  function handleRemoveImage() {
    setBase64Value(undefined);
    setValue('vcard_picture', '');
  }
  return (
    <ImageInputRoot>
      {processing && <Alert variant="info">Processing image.</Alert>}
      {!base64Value && !processing && (
        <Form.File onChange={handleImageSelected} accept="image/jpg, image/png, image/gif" />
      )}
      {base64Value && (
        <>
          <ImageInputPreview
            role="button"
            tabIndex={0}
            aria-label="remove logo"
            style={{ backgroundImage: `url(${base64Value})` }}
            onClick={handleRemoveImage}
          />
          <TextInput
            label=""
            name="vcard_picture"
            inputProps={{ type: 'hidden', value: base64Value }}
          />
        </>
      )}
    </ImageInputRoot>
  );
}

const ImageInputRoot = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const ImageInputPreview = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
  border-radius: 100%;
  height: 100px;
  width: 100px;
  &:hover {
    &:after {
      content: 'Remove';
      position: absolute;
      height: 100px;
      width: 100px;
      line-height: 100px;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 100%;
      font-size: 14px;
      font-weight: 900;
      color: #fff;
    }
  }
`;

export default ImageInput;
