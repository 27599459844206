import React from 'react';
import styled from '@emotion/styled';

import imagePlaceholder from './image-placeholder.svg';

interface DragMessagesProps {
  isDragActive: boolean;
  isDragReject: boolean;
}

export function DragMessages(props: DragMessagesProps) {
  const { isDragActive, isDragReject } = props;

  let message = 'Drop file...';
  if (!isDragActive) message = 'Drop or upload file';
  if (isDragReject) message = 'File format not supported';

  return (
    <Container>
      <img src={imagePlaceholder} />
      <p>{message}</p>
      <span>Supports: jpg, png, jpeg</span>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 120px;

  p {
    font-size: 12px;
    line-height: 14px;
    margin: 0.25rem 0;
  }

  span {
    font-size: 10px;
    line-height: 12px;
    color: #6b6b6b;
  }
`;
