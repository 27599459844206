import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { css, cx } from '@emotion/css';

export interface MainDrawerHandles {
  toggleDrawer(): void;
}

export interface MainDrawerProps extends ModalProps {
  children: React.ReactNode;
  onShow?(): void;
  onHide?(): void;
}

const MainDrawer = (props: MainDrawerProps, ref) => {
  const { children, onShow, onHide, ...rest } = props;
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleDrawer,
  }));

  function toggleDrawer() {
    if (show) {
      onHide?.();
      setShow(false);
      setVisible(false);
    } else {
      onShow?.();
      setShow(true);
      setTimeout(() => setVisible(true), 400);
    }
  }

  if (!show) return null;

  return (
    <Modal
      className={cx(customModalClass, { visible })}
      show={show}
      onHide={toggleDrawer}
      {...rest}
    >
      {children}
    </Modal>
  );
};

const customModalClass = css`
  &.visible .modal-content {
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s linear;
  }

  .modal-dialog {
    height: 100%;
    margin: 0;
    max-width: 100%;
    min-height: 100%;
  }

  .modal-header {
    border-bottom: 1px solid #eeeeee;
  }

  .modal-title {
    color: #191d48;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  .modal-body {
    padding: 0 1em;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    max-width: 35%;
    min-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.2s linear;
  }

  .modal-footer {
    background-color: #fff;
    border: none;
    bottom: 0;
    box-shadow: 0px -6px 20px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: sticky;
    width: 100%;

    & > .btn {
      border-radius: 6px;
    }
  }
`;

export default forwardRef(MainDrawer);
