import { createSlice } from '@reduxjs/toolkit';
import { Integration } from '@typings/Integration';

interface State {
  loading: boolean;
  connectedIntegration: Integration;
  error: string;
}

const initialState: State = {
  loading: false,
  connectedIntegration: null,
  error: null,
};

export const customerSupportSlice = createSlice({
  name: 'customerSupport',
  initialState,
  reducers: {
    setConnectedIntegration: (state, action) => {
      state.connectedIntegration = action.payload;
    },
  },
});
