import React from 'react';

import { useCurrentShop } from '@hooks/useCurrentShop';
import { useFlag } from '@hooks/useFlag';
import FeatureSwitch from '@pages/Settings/Features/shared/FeatureSwitch';

import { FeatureSwitchContainerProps } from '@pages/Settings/Features/shared/FeatureSwitch/FeatureSwitchContainer';
import { ShipmentTrackingFeatureSwitch } from './ShipmentTrackingFeatureSwitch';
import SendChargeFailedNotificationsSwitch from './SendChargeFailedNotificationsSwitch';

type AutoSelectFeatureSwitchProps = FeatureSwitchContainerProps;

export function AutoSelectFeatureSwitch(props: AutoSelectFeatureSwitchProps) {
  const { feature, ...rest } = props;
  const shop = useCurrentShop();
  const showVCardFeatureToggle = useFlag('show_v_card_feature_toggle');

  if (feature.key === 'send_charge_failed_notifications') {
    return <SendChargeFailedNotificationsSwitch feature={feature} {...rest} shop={shop} />;
  }

  if (feature.key === 'shipment_tracking') {
    return <ShipmentTrackingFeatureSwitch feature={feature} {...rest} />;
  }

  if (feature.key === 'send_vcard' && !showVCardFeatureToggle.enabled) {
    return '';
  }

  return <FeatureSwitch feature={feature} {...rest} />;
}
