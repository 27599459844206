import { faBoxOpen } from '@fortawesome/free-solid-svg-icons/faBoxOpen';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons/faProjectDiagram';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';

export const routes = [
  {
    path: '/replenishment/workflows',
    title: 'Workflows',
    icon: faProjectDiagram,
  },

  {
    path: '/replenishment/products',
    title: 'Products',
    icon: faBoxOpen,
  },
  {
    path: '/replenishment/settings',
    title: 'Settings',
    icon: faCog,
  },
];
