import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { ActivationState } from '@hooks/useActivationState';

interface ActivateButtonProps {
  activationState: ActivationState;
  cursorStyle?: 'default' | 'pointer';
}

const statusIndicatorShadow = {
  from: 'inset 0px 0px 0px 2px',
  to: 'inset 0px 0px 0px 3px',
};

const statusIndicatorAnimation = (color: ColorConfig) => keyframes`
  from { box-shadow: ${statusIndicatorShadow.from} ${color.secondary}; }
  to { { box-shadow: ${statusIndicatorShadow.to} ${color.secondary}; } }
`;

export const ActivateButton = styled.button<ActivateButtonProps>`
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 20px;
  box-shadow: 1px 1px 2px 1px #ccc;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 13px;
  height: 36px;
  cursor: ${(props) => props.cursorStyle || 'pointer'} !important;

  &:before {
    background-color: ${(props) => colors[props.activationState].default};
    animation: ${(props) => statusIndicatorAnimation(colors[props.activationState])} 0.8s infinite
      alternate;
    border: 1px solid ${(props) => colors[props.activationState].default};
    border-radius: 50%;
    box-shadow: ${statusIndicatorShadow.to} ${(props) => colors[props.activationState].secondary};
    content: '';
    display: inline-block;
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }
`;

const colors: Record<ActivationState, ColorConfig> = {
  pending_onboarding: {
    default: '#FFCC4D',
    secondary: '#FFEE8F',
  },
  pending_activation: {
    default: '#E95743',
    secondary: '#FFD0C6',
  },
  active: {
    default: '#3FBF3D',
    secondary: '#A2EAA0',
  },
};

interface ColorConfig {
  default: string;
  secondary: string;
}
