import styled from '@emotion/styled/macro';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

import DottedBorder from '@assets/icons/dotted-border.svg';

import { useCustomActionsContext } from '../CustomActionsContext';

const ActionAdd = () => {
  const { hiddenActions, handleAddAction } = useCustomActionsContext();

  if (!hiddenActions?.length) return null;

  return (
    <Dropdown>
      <DropdownToggle id="add-action">
        <PlusIconContainer>
          <PlusIcon icon={faPlus} />
        </PlusIconContainer>
        <Title>Add Action</Title>
      </DropdownToggle>
      <DropdownMenu>
        {hiddenActions.map((action, index) => (
          <DropdownItem key={index} onClick={() => handleAddAction(action)}>
            {action.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const Title = styled.span`
  font-size: 12px;
  color: #000;
  margin-left: 5px;
`;

const PlusIconContainer = styled.div`
  border: 1px solid #000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlusIcon = styled(FontAwesomeIcon)`
  font-size: 0.5rem;
  color: #000;
`;

const DropdownToggle = styled(Dropdown.Toggle)`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px 15px;
  width: 150px;
  background-color: transparent !important;
  background: url(${DottedBorder});
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  transition: background-color 250ms;
  opacity: 0.75;

  &::after {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    background-color: white !important;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  min-width: 150px;
`;

const DropdownItem = styled(Dropdown.Item)`
  color: #212529 !important;

  &:active {
    background-color: #f8f9fa;
  }
`;

export default ActionAdd;
