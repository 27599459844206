import styled from '@emotion/styled';
import { CollectionVariant } from '@typings/Collection';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CollectionVariantItem } from './CollectionVariantItem';

interface CollectionVariantsListProps {
  collectionVariants: CollectionVariant[];
  showIgnored: boolean;
  onChange?(): void;
}

export const CollectionVariantsList = (props: CollectionVariantsListProps) => {
  const { collectionVariants, showIgnored, onChange } = props;

  if (collectionVariants.length === 0) {
    return <EmptyListText>No products to display</EmptyListText>;
  }

  return (
    <ProductsList showIgnored={showIgnored}>
      <Container fluid>
        <TableHeader>
          <Col />
          <Col sm={1}>Price</Col>
          <Col sm={2}>Created at</Col>
          <ActionsHeader sm={2}>Actions</ActionsHeader>
        </TableHeader>
        {collectionVariants.map((collectionVariant) => (
          <CollectionVariantItem
            key={collectionVariant.id}
            collectionVariant={collectionVariant}
            onChange={onChange}
          />
        ))}
      </Container>
    </ProductsList>
  );
};

interface ProductsListProps {
  showIgnored: boolean;
}

const ProductsList = styled.div<ProductsListProps>`
  height: ${(props) => (props.showIgnored ? '436px' : 'auto')};
  overflow: ${(props) => (props.showIgnored ? 'auto' : 'inherit')};
`;

const TableHeader = styled(Row)`
  font-size: 14px;
  font-weight: bold;
`;

const ActionsHeader = styled(Col)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-right: 4rem;
`;

const EmptyListText = styled.div`
  border-bottom: 1px solid #edebf0;
  color: #6b6b6b;
  padding: ${(props) => props.theme.spacing.medium};
  padding-left: 0;
  margin-left: ${(props) => props.theme.spacing.medium};
  vertical-align: middle;
`;
