import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { PlusCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import axios from '@lib/axios';

const IMPORT_PRODUCT_VARIANT_PATH = '/sms/product_variants/import';

interface ImportProps {
  highlightButton?: boolean;
  showModal?: boolean;
  handleAddProduct?: () => void;
}

export const Import = (props: ImportProps) => {
  const { highlightButton = false, showModal = false, handleAddProduct = null } = props;
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(showModal);
  const [allVariants, setAllVariants] = useState(true);
  const refreshPageTimeoutRef = useRef(null);
  const refreshToastIdRef = useRef(null);

  useEffect(() => {
    setModalShow(showModal);
  }, [showModal]);

  useEffect(
    () => () => {
      if (refreshToastIdRef.current) {
        toast.dismiss(refreshToastIdRef.current);
      }

      clearTimeout(refreshPageTimeoutRef?.current);
    },
    []
  );

  function refreshPage(ms: number) {
    refreshPageTimeoutRef.current = setTimeout(() => {
      window.location.reload();
    }, ms);
  }

  function showRefreshToast() {
    refreshToastIdRef.current = toast.loading('Refresh the page in a few moments...');
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const params = allVariants ? {} : { external_id: data.external_id };

      await axios.get(IMPORT_PRODUCT_VARIANT_PATH, {
        params,
      });

      setLoading(false);
      setModalShow(false);
      showRefreshToast();
      refreshPage(5000);
    } catch (e) {
      setLoading(false);

      const errorMessages = {
        404: 'Product variant not found.',
        422: 'The shop is inactive.',
        default: 'It was not possible to import Product Variants. Please try again later.',
      };

      toast.error(
        e.response.data.error || errorMessages[e.response.status] || errorMessages.default
      );
    }
  };

  const onAddProductClick = () => {
    if (handleAddProduct) {
      handleAddProduct();
    } else {
      setModalShow(!highlightButton);
    }
  };

  return (
    <>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Add Product(s)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Check
              type="radio"
              label="All Products"
              name="all-products"
              id="import-all-products"
              onClick={() => setAllVariants(true)}
              checked={allVariants}
            />
            <Form.Check
              type="radio"
              label="Specified Products"
              name="specified-products"
              id="import-specified-products"
              onClick={() => setAllVariants(false)}
              checked={!allVariants}
            />
            {!allVariants && (
              <div className="mt-3">
                <Form.Group controlId="segments_name">
                  <Form.Label>Shopify Product ID</Form.Label>
                  <Form.Control
                    name="external_id"
                    defaultValue=""
                    placeholder="For multiple products, separate IDs by commas"
                    {...register('external_id', { required: !allVariants })}
                  />
                </Form.Group>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={loading}>
              <PlusCircle size={20} className="mr-2" />
              {loading ? 'Loading..' : 'Import'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <div className={highlightButton ? 'highlight-button' : ''}>
        <Button
          variant="secondary"
          className="onboarding-import-products font-weight-bold"
          onClick={() => onAddProductClick()}
        >
          Add Product(s)
          <PlusCircle size={20} className="ml-2" />
        </Button>
      </div>
    </>
  );
};
