import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { Position } from 'react-flow-renderer';

import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import DropdownOptions, { DropdownOption } from '@pages/Workflows/components/DropdownOptions';
import { ifStyle } from '@utils/styles';

import Handle from './Handle';

export interface NodeProps {
  id: number;
  title: string;
  body?: string;
  hidden?: string;
  deletable?: boolean;
}

interface BaseNodeProps extends NodeProps {
  color: string;
  icon: string | IconProp;
  titleComponent?: React.ReactNode;
  showTitleComponent?: boolean;
  bodyComponent?: React.ReactNode;
  bodyComponentStyle?: CSSProperties;
  showBodyComponent?: boolean;
  showCollapse?: boolean;
  collapseComponent?: React.ReactNode;
  dropdownOptions?: DropdownOption[];
}

const BaseNode = ({
  id,
  title,
  hidden,
  deletable,
  icon,
  color,
  titleComponent,
  showTitleComponent = !!titleComponent,
  bodyComponent,
  bodyComponentStyle,
  showBodyComponent = !!bodyComponent,
  showCollapse,
  collapseComponent,
  dropdownOptions,
  ...props
}: BaseNodeProps) => {
  const { handleRemoveWorkflowStep } = useWorkflowsContext();

  if (hidden) return null;

  return (
    <>
      <OuterContainer color={color} {...props}>
        {deletable && (
          <TrashIconContainer onClick={() => handleRemoveWorkflowStep(id)}>
            <TrashIcon icon={faTrashAlt} />
          </TrashIconContainer>
        )}
        <Container showCollapse={showCollapse}>
          {typeof icon === 'string' ? (
            <img className="mx-3" width={28} src={icon} alt="icon" />
          ) : (
            <FontAwesomeIcon className="mx-3" icon={icon} color={color} size="2x" />
          )}

          <Body>
            <Title>
              {title} {showTitleComponent && titleComponent}
            </Title>
            {showBodyComponent && (
              <BodyComponentContainer style={bodyComponentStyle}>
                {bodyComponent}
              </BodyComponentContainer>
            )}
          </Body>
          {dropdownOptions && <DropdownOptions options={dropdownOptions} />}
        </Container>
        {showCollapse && <CollapsibleContainer>{collapseComponent}</CollapsibleContainer>}
      </OuterContainer>

      <Handle type="source" position={Position.Top} id="source-top" />
      <Handle type="source" position={Position.Bottom} id="source-bottom" />
      <Handle type="source" position={Position.Left} id="source-left" />
      <Handle type="source" position={Position.Right} id="source-right" />

      <Handle type="target" position={Position.Top} id="target-top" />
      <Handle type="target" position={Position.Bottom} id="target-bottom" />
      <Handle type="target" position={Position.Left} id="target-left" />
      <Handle type="target" position={Position.Right} id="target-right" />
    </>
  );
};

const TrashIconContainer = styled.div`
  visibility: hidden;
  position: absolute;
  right: -12px;
  top: -12px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.light0};
  box-shadow: ${(props) => `0px 1px 4px  ${props.theme.colors.dark0}22`};

  &:hover {
    visibility: visible;
  }
`;

const TrashIcon = styled(FontAwesomeIcon)`
  color: red;
  font-size: 16px;
`;

interface OuterContainerProps {
  color: string;
}

const OuterContainer = styled.div<OuterContainerProps>`
  border-bottom: 3px solid ${(props) => props.color};
  border-radius: ${(props) => props.theme.radius.small};
  box-shadow: ${(props) => `0px 1px 4px  ${props.theme.colors.dark0}22`};
  display: flex;
  flex-direction: column;
  width: 485px;
`;

interface ContainerProps {
  showCollapse?: boolean;
}

const Container = styled.div<ContainerProps>`
  align-items: center;
  background: ${(props) => props.theme.colors.light0};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  min-height: 70px;

  ${ifStyle('showCollapse')(css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #c4c4c4;
  `)}
`;

const CollapsibleContainer = styled.div`
  background: #f9f9f9;
  border-radius: 6px;
  width: 100%;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-right: 10px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

const BodyComponentContainer = styled.div`
  margin: 0 10px;
`;

export default BaseNode;
