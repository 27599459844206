import axios from 'axios';

interface FeaturesAPI {
  UpdateStatus: typeof updateFeatureStatus;
}

const updateFeatureStatus = async (key: string, status: boolean) => {
  const params = { feature: { enabled: status } };
  await axios.put(`/sms/features/${key}`, params);
};

const API: FeaturesAPI = {
  UpdateStatus: updateFeatureStatus,
};

export default API;
