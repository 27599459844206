import React from 'react';

import { Card } from '@components/Layout/Card';
import { Page } from '@components/Layout/Page';
import { EmailsPayload } from '@typings/Email';
import { useFetch } from '@hooks/useFetch';
import { FeaturesProvider } from '@contexts/FeaturesContext';
import { EditEmailTemplate } from '@pages/Settings/Features/tabs/EmailSettings/components/EditEmailTemplate';
import { CollapsableFeatureSwitch } from '@pages/Settings/Features/shared/CollapsableFeatureSwitch';

const ChargeReminder = () => {
  const { data, loading } = useFetch<EmailsPayload>('/sms/features');
  const { allFeatures, emailFeatures, emailTemplates } = data || {};
  const feature = emailFeatures?.find(
    (emailFeature) => emailFeature.key === 'enable_charge_reminder_fallback_email'
  );
  const emailTemplateTitleMapper = {
    charge_reminder_fallback: 'Single Subscription',
    multiple_charge_reminder_fallback: 'Multiple Subscriptions',
  };
  const emailTemplatesMapper = {
    enable_charge_reminder_fallback_email: [
      'charge_reminder_fallback',
      'multiple_charge_reminder_fallback',
    ],
  };

  return (
    <Page loading={loading} data-testid="charge-reminder-page">
      <FeaturesProvider allFeatures={allFeatures}>
        {emailTemplates
          ?.filter((template) => emailTemplatesMapper[feature.key].includes(template.emailType))
          .map((template, idx) => (
            <div key={template.id}>
              {!idx && (
                <div className="d-flex align-items-center mt-2 mb-4">
                  <CollapsableFeatureSwitch key={feature.key} feature={feature} alt />
                  <div className="ml-2">
                    Sends your customers an email in the case the charge reminder SMS message is not
                    delivered to them.
                  </div>
                </div>
              )}
              <Card className="mb-4 h-auto" data-testid="charge-reminder-edit-email-template">
                <EditEmailTemplate
                  template={template}
                  title={`Charge Reminder Fallback Email - ${
                    emailTemplateTitleMapper[template.emailType]
                  }`}
                />
              </Card>
            </div>
          ))}
      </FeaturesProvider>
    </Page>
  );
};

export default ChargeReminder;
