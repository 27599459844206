import React, { useRef } from 'react';
import styled from '@emotion/styled';

import Modal, { ModalHandles } from '@components/Modal/BaseModal';
import { useCurrentShop } from '@hooks/useCurrentShop';
import OptInPageWidgetImage from '@assets/images/opt-in-page-widget.svg';
import OptInCartWidgetImage from '@assets/images/opt-in-cart-widget.svg';
import { Branding } from '@components/Branding';

import { Pages } from './index';
import { Header, Content, Title } from '../Card';

interface InitialPageProps {
  setPage: (page: Pages) => void;
}

export function InitialPage(props: InitialPageProps) {
  const { setPage } = props;
  const shop = useCurrentShop();
  const sciStore = shop.checkoutPlatform !== 'recharge';
  const brandingModalRef = useRef<ModalHandles>(null);

  function brandingModalOnSuccess() {
    brandingModalRef.current?.toggleModal();
    setPage('landing');
  }

  return (
    <>
      <Header>
        <Title>Opt-in Tools</Title>
      </Header>
      <Content>
        {sciStore && (
          <Tool className="mt-5">
            <div className="content">
              <div className="label green">New Subscriber Flow</div>
              <h3 className="title">Cart Widget Opt-in</h3>
              <div className="description">
                Capture SMS opt-in consent from the Cart when a subscription product is added
              </div>
              <button className="btn" type="button" onClick={() => setPage('cart')}>
                Set up your SMS Opt-in Widget
              </button>
            </div>
            <aside className="aside">
              <div style={{ backgroundImage: `url(${OptInCartWidgetImage})` }} />
            </aside>
          </Tool>
        )}
        <Tool className="mt-5">
          <div className="content">
            <div className="label purple">Existing Subscriber Flow</div>
            <h3 className="title">Landing Page Opt-in</h3>
            <div className="description">
              Set up a landing page for customers where subscribers can Opt-In to RechargeSMS!
            </div>
            <button
              className="btn outline"
              type="button"
              onClick={() => brandingModalRef.current?.toggleModal()}
            >
              Create your Opt-In Landing Page
            </button>
          </div>
          <aside className="aside">
            <div style={{ backgroundImage: `url(${OptInPageWidgetImage})` }} />
          </aside>
        </Tool>
      </Content>

      <Modal ref={brandingModalRef} show>
        <Branding onSuccess={brandingModalOnSuccess} />
      </Modal>
    </>
  );
}

const Tool = styled.div<{ hidden?: boolean }>`
  display: ${(props: any) => (props.hidden ? 'none' : 'flex')};
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  border-radius: 8px;
  position: relative;
  height: 240px;

  .content {
    padding: 32px;
  }

  .label {
    border-radius: 50px;
    color: #333333;
    font-family: 'SFProDisplay';
    font-size: 16px;
    font-weight: 600;
    left: 30px;
    min-width: 250px;
    padding: 8px 34px;
    position: absolute;
    text-align: center;
    top: -20px;

    &.green {
      background: #d6f3f0;
    }

    &.purple {
      background: #e6e0f9;
    }
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    line-height: 32px;
    margin-top: 22px;
  }

  .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-top: 16px;
    max-width: 360px;
  }

  .btn {
    background: #1a1d45;
    border-radius: 6px;
    border: 1px solid #1a1d45;
    box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
    color: #ffffff;
    font-weight: 600;
    margin-top: 34px;
    min-width: 280px;
    padding: 12px 40px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: transparent;
      color: #1a1d45;
      transition: all 0.2s ease-in-out;
    }

    &.outline {
      background: transparent;
      color: #1a1d45;

      &:hover {
        background: #1a1d45;
        color: #ffffff;
      }
    }
  }

  .content,
  .aside {
    width: 50%;
  }

  .aside {
    & > div {
      background-position: center;
      background-size: cover;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      height: 100%;
      width: 100%;
    }
  }
`;
