import React, { useState, useEffect } from 'react';

import { RechargeCustomer } from '@typings/RechargeCustomer';
import { useSmsMessages } from '@hooks/useSmsMessages';

import axios from '@lib/axios';
import { useInitialState } from '@hooks/useInitialState';
import addMinutes from 'date-fns/addMinutes';
import { ChatForm, ChatHistoryInfinite, IAlert, RefreshButton } from './components';

export interface ChatProps {
  rechargeCustomer: RechargeCustomer;
  baseUrl?: string;
  title?: string;
  token?: string;
}

export const Chat = ({
  rechargeCustomer,
  baseUrl = `/sms/recharge/customers/${rechargeCustomer.id}`,
  title = 'Messages',
  token,
}: ChatProps) => {
  const [humanSupportAt, setHumanSupportAt] = useState(
    rechargeCustomer.humanSupportAt && new Date(rechargeCustomer.humanSupportAt)
  );
  const { data, setSize, error } = useSmsMessages({
    baseUrl,
    token,
  });

  const { humanSupportDuration } = useInitialState();

  useEffect(() => {
    if (humanSupportAt) {
      const delayInMs = humanSupportDuration * 60 * 1000;
      const timeout = setTimeout(() => setHumanSupportAt(undefined), delayInMs);

      return () => clearTimeout(timeout);
    }
  }, [humanSupportAt]);

  async function handleEndHumanSupportMode() {
    await axios.post(`${baseUrl}/end_human_support_mode`, { token });
    setHumanSupportAt(undefined);
  }

  const alert: IAlert = humanSupportActive(humanSupportAt, humanSupportDuration)
    ? {
        message: 'You are in human support mode.',
        onClose: handleEndHumanSupportMode,
      }
    : {};

  return (
    <div>
      <h6>
        <strong>{title}</strong>
        {/* <RefreshButton onClick={revalidate} /> */}
      </h6>
      <ChatHistoryInfinite id="chat" alert={alert} data={data} setSize={setSize} />
      <ChatForm
        rechargeCustomer={rechargeCustomer}
        onSubmitSuccess={() => setHumanSupportAt(new Date())}
        submitUrl={`${baseUrl}/send_sms`}
        token={token}
      />
      {error && (
        <div className="text-danger text-sm ml-1">Error updating chat, will retry in a moment.</div>
      )}
    </div>
  );
};

function humanSupportActive(humanSupportAt: Date | undefined, humanSupportDuration: number) {
  return humanSupportAt && addMinutes(humanSupportAt, humanSupportDuration) > new Date();
}
