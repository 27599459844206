import React from 'react';
import styled from '@emotion/styled';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { GreaterOrLessThan } from './GreaterOrLessThan';

interface Props {
  type: string;
  maskOptions?: any;
}

export function Filter(props: Props) {
  const { type, maskOptions } = props;
  const { register, watch, setValue } = useFormContext();
  const currencyMask = createNumberMask({
    allowDecimal: true,
    integerLimit: 7,
    ...maskOptions,
  });

  const filterName = `filters.${type}`;

  // TODO: change types on the back-end
  const labelsByType = {
    upcoming_charge_value: 'Lifetime spend is',
    total_orders: 'Number of successful orders is',
    total_charge_amount: 'Upcoming order amount is',
  };
  const label = labelsByType[type];

  return (
    <Container enabled={watch(`${filterName}.enabled`)}>
      <Form.Check
        type="checkbox"
        id={type}
        name={`${filterName}.enabled`}
        {...register(`${filterName}.enabled`)}
      />
      <TextContainer>
        <Text>{label}</Text>
        <GreaterOrLessThan type={type} />
        <Text>or equal to</Text>
        <AmountInput
          mask={currencyMask}
          name={`${filterName}.amount`}
          {...register(`${filterName}.amount`)}
          defaultValue={watch(`${filterName}.amount`)}
          onChange={(e) => setValue(`${filterName}.amount`, e.target.value)}
        />
      </TextContainer>
    </Container>
  );
}

const Container = styled.div<{ enabled: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  opacity: ${(props) => (props.enabled ? 1 : 0.7)};
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  margin: 0;
  font-weight: 300px;
  font-size: 12px;
`;

const AmountInput = styled(MaskedInput)`
  width: 70px;
  height: 30px;
  background: #f4f4f7;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  border: 0;
  margin-left: 0.25rem;
  text-align: center;
`;
