import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';

import { Card } from '@components/Layout/Card';

import { OptInForm } from './components/OptInForm';
import { OptInPage } from './components/OptInPage';
import { Instructions, Installation } from './components/Installation';

type Views = 'customization' | 'installation';

interface OptInProps {
  onSubmit?(): Promise<void>;
}

export function OptIn(props: OptInProps) {
  const { onSubmit } = props;
  const [view, setView] = useState<Views>('customization');
  const isCustomizationView = view === 'customization';

  function toggleView() {
    if (isCustomizationView) {
      onSubmit();
    }
    setView(() => (isCustomizationView ? 'installation' : 'customization'));
  }

  const viewsMapping = {
    customization: (
      <>
        <Col className="p-0" xs={12} xl={2}>
          <OptInForm />
        </Col>
        <Col className="p-0" xs={12} xl={10}>
          <CustomCard>
            <OptInPage />
          </CustomCard>
        </Col>
      </>
    ),
    installation: (
      <>
        <Col className="p-0" xs={12} md={4} xl={3}>
          <Instructions />
        </Col>
        <Col className="p-0" xs={12} md={8} xl={9}>
          <CustomCard>
            <Installation />
          </CustomCard>
        </Col>
      </>
    ),
  };

  return (
    <>
      <Container>
        <Col className="p-0 save-generate-btn" xs={12} xl={12}>
          <SaveButton onClick={toggleView}>
            {isCustomizationView ? 'Save and Generate' : 'Back'}
          </SaveButton>
        </Col>
        {viewsMapping[view]}
      </Container>
    </>
  );
}

const Container = styled(Row)`
  margin: 0;
  position: relative;

  .save-generate-btn {
    position: absolute;
    top: -60px;
    right: 0;
  }

  @media (min-width: 1400px) {
    .col-xl-2 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-xl-10 {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
`;

const SaveButton = styled.button`
  margin-top: 1rem;
  float: right;
  border-radius: ${(props) => props.theme.radius.small};
  font-weight: 600;
  font-size: 14px;
  background: #17cc9d;
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  border: 0;
  padding: 0.5rem 2rem;
  color: #fff;
  width: 200px;
`;

const CustomCard = styled(Card)`
  padding: 1rem 0;
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  height: 100%;
`;
