import React from 'react';
import { Credentials } from '@typings/Integration';
import { TextInput } from '../TextInput';

interface FormProps {
  credentials: Credentials;
}

export function IntercomForm(props: FormProps) {
  const { credentials } = props;

  return (
    <div>
      <TextInput
        name="intercom_username"
        label="Email"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.username,
        }}
      />

      <TextInput
        name="intercom_api_key"
        label="Access Token"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.api_key,
        }}
      />
    </div>
  );
}
