import { useMemo } from 'react';
import Mustache from 'mustache';

const useDemoProduct = (demoProductVariant, template) => {
  const demoProduct = useMemo(() => {
    const templateValue = template.value;
    const internalProductTitle =
      templateValue.length > 1
        ? Mustache.render(templateValue, demoProductVariant)
        : demoProductVariant.internal_product_title;

    return { ...demoProductVariant, internal_product_title: internalProductTitle };
  }, [template]);

  return demoProduct;
};

export default useDemoProduct;
