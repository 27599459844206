import React from 'react';

import { formatters } from './formatters';
import { MetricBase, MetricBaseProps, MetricText } from './MetricBase';

export function MetricValue(props: MetricBaseProps) {
  const { type = 'number' } = props;

  return (
    <MetricBase {...props}>
      {(data) => <MetricText>{formatters[type](data)}</MetricText>}
    </MetricBase>
  );
}
