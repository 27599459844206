let containerWidth = 0;

const defaultEdgeOptions = {
  sourceHandle: 'source-bottom',
  targetHandle: 'target-top',
  arrowHeadType: 'arrow',
};

function buildNodes(steps) {
  return steps.map((step) => ({
    id: step.id,
    type: step.type,
    data: {
      ...step?.params,
      id: step.id,
    },
    position: buildPosition(step?.params?.position),
  }));
}

function buildPosition(position) {
  let x = position?.x || 0;
  const y = position?.y || 0;

  if (position?.middle) {
    const middlePosition = containerWidth / 2;
    const xOffset = position?.xOffset || 0;

    x = middlePosition + xOffset;
  }

  return { x, y };
}

function buildEdges(steps) {
  const edges = steps
    .map((step) => step?.params?.edges)
    .flat(1)
    .filter((step) => !!step);

  return edges.map((edge) => ({
    ...defaultEdgeOptions,
    ...edge,
  }));
}

export function buildElements(workflow, width) {
  if (!workflow) return;

  containerWidth = width;

  const nodes = buildNodes(workflow.steps);
  const edges = buildEdges(workflow.steps);

  return [...nodes, ...edges];
}

export function getLoadingElement({ offsetWidth, offsetHeight }) {
  return [
    {
      id: 'random-id',
      type: 'loading',
      position: {
        x: offsetWidth / 2 + 175,
        y: offsetHeight / 2 + 100,
      },
    },
  ];
}
