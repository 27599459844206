import Mustache from 'mustache';

const Template = {
  BuildVariableInterpolation: (variable) => {
    const parsedVariable = variable.replace(/\{\"value\"\:\"|\"\}/g, '');
    return parsedVariable.replace(/\",\"prefix\":\"@/g, '');
  },
  ExtractVariable: (template) =>
    Mustache.parse(template)
      .filter((variable) => variable[0] === 'name')
      .map((variable) => variable[1]),
  Group: (templates) => {
    const emptyTemplate = {
      value: ' ',
      included_variables: [],
    };

    return {
      defaultTemplates: templates?.filter((template) => template.type === 'default'),
      customTemplate: templates?.find((template) => template.type === 'custom'),
      currentTemplate: templates?.find((template) => template.selected) || emptyTemplate,
    };
  },
};

export default Template;
