import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

import { Page } from '@components/Layout/Page';
import styled from '@emotion/styled';
import { EmailTemplate } from '@typings/EmailTemplate';
import { EmailsPayload } from '@typings/Email';
import { useFetch } from '@hooks/useFetch';
import { useFlag } from '@hooks/useFlag';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { updateCustomize } from '@api/sms';

import { FeaturesProvider } from '@contexts/FeaturesContext';
import { EmailSettings } from '@pages/Settings/Features/tabs';
import { validateEmail } from '@components/Input/EmailForm/emailValidation';
import { PreferredStoreEmail } from './components/PreferredStoreEmail';
import { FallbackTemplates } from './components/FallbackTemplates';

import { NavTabs } from '../NavTabs';
import '@pages/Settings/Features/features.scss';

const Emails = () => {
  const refactorSettingsPagesEnabled = useFlag('refactor_settings_pages_enabled');
  const { data, loading } = useFetch<EmailsPayload>('/sms/features');
  const emailTemplateResponse = useFetch<EmailTemplate[]>('/sms/email_templates');
  const emailTemplatesData: EmailTemplate[] = emailTemplateResponse.data ?? [];
  // Previously using the email templates that were in email features which use `emailType` instead of `email_type`, renaming the key here.
  const emailTemplates = emailTemplatesData.map((t) => {
    const template = { ...t };
    template.emailType = template.email_type;
    delete template.email_type;
    return template;
  });
  const [isLoading, setIsLoading] = useState(false);
  const { allFeatures, emailFeatures, shop } = data || {};
  const currentShop = useCurrentShop();
  const { customEmail: currentEmail = '' } = currentShop;
  const [storeEmails, setStoreEmails] = useState({
    original: currentEmail,
    confirmation: currentEmail,
  });
  const [formErrors, setFormErrors] = useState({
    confirmation: false,
    format: false,
  });
  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await updateCustomize({ shop: { custom_email: storeEmails?.original ?? null } });
      currentShop.refetch({ useLoading: false });
      toast.success('Email preferences successfully update!');
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChanged = (email, emailType, comparisonEmailType) => {
    const emails = { ...storeEmails };
    emails[emailType] = email;

    setStoreEmails(emails);
    const errors = validateEmail(email, storeEmails[comparisonEmailType]);
    setFormErrors(errors);
  };

  const clearEmails = () => {
    setStoreEmails({
      original: null,
      confirmation: null,
    });
  };

  return (
    <>
      <NavTabs />
      <Page
        loading={loading}
        headerComponent={<Header>Manage emails settings for your store.</Header>}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {refactorSettingsPagesEnabled.enabled && (
            <>
              <PreferredStoreEmail
                isLoading={isLoading}
                storeEmails={storeEmails}
                handleEmailChanged={handleEmailChanged}
                clearEmails={clearEmails}
                formErrors={formErrors}
              />

              <FeaturesProvider allFeatures={allFeatures}>
                <FallbackTemplates
                  isLoading={isLoading}
                  emailFeatures={emailFeatures}
                  emailTemplates={emailTemplates}
                />
              </FeaturesProvider>
            </>
          )}
        </form>

        {!refactorSettingsPagesEnabled.enabled && (
          <FeaturesProvider allFeatures={allFeatures}>
            <Card>
              <EmailSettings
                shop={shop}
                emailFeatures={emailFeatures}
                emailTemplates={emailTemplates}
              />
            </Card>
          </FeaturesProvider>
        )}
      </Page>
    </>
  );
};

const Header = styled.span`
  color: #9a9b9c;
  font-size: 16px;
`;

export default Emails;
