import * as yup from 'yup';
import axios from 'axios';

import { CustomerService } from '@typings/CustomerService';

const requiredMessage = 'Must be present.';
const urlMessage = 'Must be a valid URL.';

const checkKustomerApiKeyRole = async (apiKey: string) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/kustomer', {
      api_key: apiKey,
    });

    return data;
  } catch {
    return false;
  }
};

const checkFreshdeskCustomFields = async (apiURL: string, apiKey: string) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/freshdesk', {
      api_key: apiKey,
      api_url: apiURL,
    });

    return data;
  } catch {
    return false;
  }
};

const checkIntercomConnection = async (apiKey: string, username: string) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/intercom', {
      api_key: apiKey,
      email: username,
    });

    return data;
  } catch {
    return false;
  }
};

const checkGorgiasConnection = async (apiURL: string, username: string, password: string) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/gorgias', {
      api_url: apiURL,
      username,
      password,
    });

    return data;
  } catch {
    return false;
  }
};

const checkReamazeConnection = async (apiURL: string, username: string, apiKey: string) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/reamaze', {
      api_url: apiURL,
      username,
      api_key: apiKey,
    });

    return data;
  } catch {
    return false;
  }
};

const checkZendeskConnection = async (apiURL: string, username: string, password: string) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/zendesk', {
      api_url: apiURL,
      username,
      password,
    });

    return data;
  } catch {
    return false;
  }
};

export const schema: Record<CustomerService, any> = {
  zendesk: yup.object().shape({
    zendesk_base_url: yup.string().required(requiredMessage).url(urlMessage),
    zendesk_username: yup.string().required(requiredMessage),
    zendesk_password: yup
      .string()
      .required(requiredMessage)
      .test(
        'checkZendeskConnection',
        "Couldn't connect to Zendesk, check your credentials.",
        (zendesk_password, context) =>
          checkZendeskConnection(
            context.parent.zendesk_base_url,
            context.parent.zendesk_username,
            zendesk_password
          )
      ),
  }),
  reamaze: yup.object().shape({
    reamaze_base_url: yup.string().required(requiredMessage).url(urlMessage),
    reamaze_username: yup.string().required(requiredMessage),
    reamaze_api_key: yup
      .string()
      .required(requiredMessage)
      .test(
        'checkReamazeConnection',
        "Couldn't connect to Reamaze, check your credentials.",
        (reamaze_api_key, context) =>
          checkReamazeConnection(
            context.parent.reamaze_base_url,
            context.parent.reamaze_username,
            reamaze_api_key
          )
      ),
  }),
  gorgias: yup.object().shape({
    gorgias_base_url: yup.string().required(requiredMessage).url(urlMessage),
    gorgias_username: yup.string().required(requiredMessage),
    gorgias_password: yup
      .string()
      .required(requiredMessage)
      .test(
        'checkGorgiasConnection',
        "Couldn't connect to Gorgias, check your credentials.",
        (gorgias_password, context) =>
          checkGorgiasConnection(
            context.parent.gorgias_base_url,
            context.parent.gorgias_username,
            gorgias_password
          )
      ),
  }),
  freshdesk: yup.object().shape({
    freshdesk_base_url: yup.string().required(requiredMessage).url(urlMessage),
    freshdesk_api_key: yup
      .string()
      .required(requiredMessage)
      .test(
        'checkFreshdeskCustomFields',
        'Could not connect to Freshdesk, check your credentials.',
        (freshdesk_api_key, context) =>
          checkFreshdeskCustomFields(context.parent.freshdesk_base_url, freshdesk_api_key)
      ),
  }),
  kustomer: yup.object().shape({
    kustomer_api_key: yup
      .string()
      .required(requiredMessage)
      .test(
        'checkKustomerApiKeyRole',
        'Api key should have "org" or "org.admin" role.',
        checkKustomerApiKeyRole
      ),
  }),
  intercom: yup.object().shape({
    intercom_username: yup.string().email().required(requiredMessage),
    intercom_api_key: yup
      .string()
      .required(requiredMessage)
      .test(
        'checkIntercomConnection',
        "Couldn't connect to Intercom, check your credentials.",
        (intercom_api_key, context) =>
          checkIntercomConnection(intercom_api_key, context.parent.intercom_username)
      ),
  }),
};
