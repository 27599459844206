import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import findIndex from 'lodash/findIndex';
import toast from 'react-hot-toast';
import FileDownload from 'js-file-download';
import { useNavigate } from 'react-router-dom';

import axios from '@lib/axios';
import { Toggle } from '@components/Input/Toggle';
import { ViewPage, ViewPageHandles } from '@components/Layout/ViewPage';
import { Action } from '@components/Table/Row/Action';
import { formatString } from '@utils/date';
import { Segment } from '@typings/Segment';
import { useBaseAppContext } from '@components/BaseAppContext';

const BASE_URL = '/sms/segments';

function View() {
  const ref = useRef<ViewPageHandles>();
  const navigate = useNavigate();

  const { segmentTriggerStatusRefresh } = useBaseAppContext();

  const filterOptions = [
    {
      value: 'most_recent',
      label: 'Most recent',
    },
    {
      value: 'active',
      label: 'Active',
    },
  ];

  const tableHeaders = [
    {
      label: 'Name',
      width: '50%',
    },
    {
      label: 'Status',
      width: '20%',
    },
    {
      label: 'Created at',
    },
    {
      label: 'Actions',
      width: '20%',
    },
  ];

  function tableRow(segment: Segment) {
    const data = [
      <SegmentName>{segment.name}</SegmentName>,
      <Toggle checked={!segment.inactiveAt} onChange={() => handleActivation(segment)} />,
      formatString(String(segment.createdAt), 'MM/dd/yyyy'),
      <ActionsContainer>
        {segment.editable && (
          <Action
            icon={faPen}
            color="#F7963F"
            href={`${BASE_URL}/${segment.id}/edit`}
            text="Edit"
          />
        )}
        <Action
          icon={faDownload}
          color="#191d45"
          onClick={() => handleExportCSV(segment)}
          text={
            <>
              Export
              <br />
              CSV
            </>
          }
        />
      </ActionsContainer>,
    ];

    function onClick() {
      navigate(`${BASE_URL}/${segment.id}`);
    }

    return { data, onClick };
  }

  function handleHeaderComponentClick() {
    navigate('/sms/segments/new');
  }

  async function handleActivation(segment: Segment) {
    const segmentId = segment.id;

    const loadingMessage = segment.inactiveAt ? 'Activating...' : 'Deactivating...';
    const successMessage = segment.inactiveAt ? 'activated' : 'deactivated';

    function onSuccess() {
      segmentTriggerStatusRefresh();
      ref.current.setPage((page) => {
        const { data: segments } = page;
        const newSegment = { ...segment, inactiveAt: segment.inactiveAt ? null : new Date() };

        const index = findIndex(segments, { id: segmentId });
        segments.splice(index, 1, newSegment);

        return {
          ...page,
          data: segments,
        };
      });

      return `Segment successfully ${successMessage}!`;
    }

    toast.promise(axios.post(`${BASE_URL}/${segmentId}/toggle_active`), {
      loading: loadingMessage,
      success: onSuccess,
      error: 'Something went wrong! try again later.',
    });
  }

  async function handleExportCSV(segment: Segment) {
    function onSuccess(response) {
      FileDownload(response.data, `${segment.name}-segment-${new Date().toLocaleDateString()}.csv`);

      return 'CSV successfully exported!';
    }

    toast.promise(
      axios.get(`${BASE_URL}/${segment.id}`, {
        headers: {
          Accept: 'text/csv',
        },
        responseType: 'blob',
      }),
      {
        loading: 'Exporting CSV...',
        success: onSuccess,
        error: 'Something went wrong, try again later.',
      }
    );
  }

  return (
    <>
      <ViewPage
        ref={ref}
        url={BASE_URL}
        header={{
          description: 'Manage activation status and view details pertaining to your segments',
          component: {
            text: 'Add new segment',
            onClick: handleHeaderComponentClick,
          },
        }}
        filter={{
          options: filterOptions,
        }}
        table={{
          headers: tableHeaders,
          row: tableRow,
        }}
        searchPlaceholder="Search by name"
      />
    </>
  );
}

const SegmentName = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: black;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default View;
