import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { Page } from '@components/Layout/Page';

import { useFlag } from '@hooks/useFlag';
import { MessagingInsightsCard } from './components/MessagingInsightsCard';
import { OptInToolsCard } from './components/OptInToolsCard';
import { MetricsCards } from './components/MetricsCards';
import { CustomerIntegrationsCard } from './components/CustomerIntegrationsCard';
import { ShopPhoneNumberCard } from './components/ShopPhoneNumberCard';

function Home() {
  const { enabled: metricsEnabled } = useFlag('home_metrics');
  const { enabled: customerSupportEnabled } = useFlag('home_customer_support');
  const location = useLocation();

  useEffect(() => {
    const { hash, pathname } = location;

    if (pathname === '/sms' && hash) {
      const element = document.getElementById(hash.replace('#', ''));
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <Page>
      <Title>My Shop</Title>
      <ShopPhoneNumberCard />
      {metricsEnabled && <MetricsCards />}
      <MessagingInsightsCard />
      <OptInToolsCard />
      {customerSupportEnabled && <CustomerIntegrationsCard />}
    </Page>
  );
}

const Title = styled.h2`
  font-size: 28px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.secondary};
`;

export default Home;
