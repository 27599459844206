import React from 'react';
import styled from '@emotion/styled';

import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';
import { SearchBar } from '@pages/Workflows/components/SearchBar';

const WorkflowSearch = () => {
  const { handleSearchWorkflows } = useWorkflowsContext();

  return (
    <StyledSearchBar
      handleSearch={handleSearchWorkflows}
      inputProps={{
        placeholder: 'Search your workflows',
      }}
    />
  );
};

const StyledSearchBar = styled(SearchBar)`
  border-radius: 30px;
  height: 35px;

  input {
    font-size: 14px;
    line-height: 14px;
  }
`;

export default WorkflowSearch;
