import React from 'react';
import { getMarkerEnd } from 'react-flow-renderer';

export function SpacedEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  arrowHeadType,
  markerEndId,
}) {
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const rightSide = targetX > sourceX;
  const spacing = -40;

  return (
    <path
      id={id}
      style={style}
      className="react-flow__edge-path"
      markerEnd={markerEnd}
      d={`M${sourceX}, ${sourceY} H${
        rightSide ? sourceX - spacing : sourceX + spacing
      } V${targetY} H${targetX}`}
    />
  );
}
