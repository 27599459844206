import React from 'react';
import { ProductView } from '@components/Onboarding/v2/components/ProductView';
import { useAppSelector } from '@redux/hooks';
import { useOnboardingV2 } from '@hooks/useOnboardingV2';
import { PRODUCT_2_IMPORT_STEP } from '@components/Onboarding/v2/onboarding';

export const ProductSetupView = () => {
  const onboardingState = useAppSelector((state) => state.onboarding);
  const { updateOnboarding } = useOnboardingV2();

  const handleAddProduct = async () => {
    await updateOnboarding(onboardingState.data.id, {
      step: PRODUCT_2_IMPORT_STEP,
    });
  };

  return onboardingState.numProducts === 0 ? (
    <ProductView handleAddProduct={handleAddProduct} highlightImportButton />
  ) : (
    <ProductView />
  );
};
