import React from 'react';

import { Card } from '@components/Layout/Card';
import { Page } from '@components/Layout/Page';
import { EmailsPayload } from '@typings/Email';
import { useFetch } from '@hooks/useFetch';
import { FeaturesProvider } from '@contexts/FeaturesContext';
import { EditEmailTemplate } from '@pages/Settings/Features/tabs/EmailSettings/components/EditEmailTemplate';
import { CollapsableFeatureSwitch } from '@pages/Settings/Features/shared/CollapsableFeatureSwitch';

const OptOutConfirmation = () => {
  const { data, loading } = useFetch<EmailsPayload>('/sms/features');
  const { allFeatures, emailFeatures, emailTemplates } = data || {};
  const feature = emailFeatures?.find(
    (emailFeature) => emailFeature.key === 'enable_opt_out_email'
  );
  const emailTemplateTitleMapper = {
    opt_out: 'Opt-out Confirmation Email',
  };
  const emailTemplatesMapper = {
    enable_opt_out_email: ['opt_out'],
  };

  return (
    <Page loading={loading} data-testid="opt-out-confirmation-email-page">
      <FeaturesProvider allFeatures={allFeatures}>
        {emailTemplates
          ?.filter((template) => emailTemplatesMapper[feature.key].includes(template.emailType))
          .map((template, idx) => (
            <div key={template.id}>
              {!idx && (
                <div className="d-flex align-items-center mt-2 mb-4">
                  <CollapsableFeatureSwitch key={feature.key} feature={feature} alt />
                  <div className="ml-2">
                    Sends your customers an opt-out confirmation email when they reply "stop" to
                    opt-out of SMS messages.
                  </div>
                </div>
              )}
              <Card className="mb-4 h-auto" data-testid="opt-out-confirmation-email-template">
                <EditEmailTemplate
                  template={template}
                  title={emailTemplateTitleMapper[template.emailType]}
                />
              </Card>
            </div>
          ))}
      </FeaturesProvider>
    </Page>
  );
};

export default OptOutConfirmation;
