import React from 'react';
import { Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import { ButtonGroup } from '@components/Button/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { Separator } from '@components/Layout/Separator';

import { Card, Subtitle } from '../../Card';

interface Props {
  setShowManualInstall: (boolean) => void;
}

export function ManualInstallationCard(props: Props) {
  const { setShowManualInstall } = props;

  const shop = useCurrentShop();

  function handleTryAutoInjectionClick() {
    setShowManualInstall(false);
  }

  function navigateToShopifyTheme() {
    window.open(`https://${shop.shopifyDomain}/admin/themes`, '_blank');
  }

  return (
    <CustomCard>
      <Subtitle>📝 Manual Installation Guide</Subtitle>
      <Separator />
      <p>
        For detailed instructions on how to add the rcSMS opt-in checkbox to your shop’s cart, watch
        our installation guide below:
      </p>
      <div style={{ position: 'relative', paddingBottom: '62.5%', height: 0 }}>
        <iframe
          title="rcSMS Cart Widget Installation Guide"
          src="https://www.loom.com/embed/1a386f58756b41ed8c65e494bb023641?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true&autoplay=1"
          frameBorder="0"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      <Separator />
      <div className="text-center">
        <CustomButton variant="secondary" onClick={navigateToShopifyTheme}>
          Configure Shopify Theme &nbsp;
          <FontAwesomeIcon icon={faExternalLinkAlt} size="1x" />
        </CustomButton>
      </div>
      <Separator />
      <ButtonGroup>
        <LinkButton variant="link" onClick={handleTryAutoInjectionClick}>
          Try auto-injection?
        </LinkButton>
      </ButtonGroup>
    </CustomCard>
  );
}

const CustomButton = styled(Button)`
  box-shadow: 0px 0px 10px 1px rgba(25, 29, 72, 0.1);
  border-radius: 6px;
  padding: 8px;
  width: 100%;
  font-weight: 700;
`;

const CustomCard = styled(Card)`
  padding: 20px;
  min-height: 582px;
`;

const LinkButton = styled(Button)`
  color: #a7a7a7;
  font-weight: 700;
  text-decoration: none;
`;
