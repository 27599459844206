import React from 'react';
import { Col } from 'react-bootstrap';
import { EmptyModalView } from '@components/Onboarding/v2/components/Modal/EmptyModalView';
import { useFetch } from '@hooks/useFetch';
import { Feature } from '@typings/Feature';
import Loading from '@components/Loading';
import { FeaturesView } from '@components/Onboarding/v2/components/FeaturesView';
import { SimulatorView } from '@components/Onboarding/v2/components/SimulatorView';
import { FeatureContainer } from '@components/Onboarding/v2/components/styling/OnboardingViewStyling';

interface FeaturesPayload {
  allFeatures: Feature[];
  generalFeatures: Feature[];
  accountSettingsFeatures: Feature[];
  modifyOrderFeatures: Feature[];
  bundledSubscriptionsFeatures: string[];
  modifyAllFeatures: Feature[];
  otherSmsFeatures: Feature[];
  emailFeatures: Feature[];
}

export const FeatureRecommendSetupView = () => {
  const { data, loading } = useFetch<FeaturesPayload>('/sms/features');
  const { allFeatures } = data || {};

  if (loading || !allFeatures) return <Loading />;

  return (
    <>
      <EmptyModalView />
      <FeatureContainer highlightSimulator={false}>
        <Col sm={6}>
          <FeaturesView {...data} disabled={false} />
        </Col>
        <Col sm={6} className="simulator-onboarding">
          <SimulatorView />
        </Col>
      </FeatureContainer>
    </>
  );
};
