import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'react-bootstrap';
import { ProSidebar, SidebarHeader, SidebarContent, Menu } from 'react-pro-sidebar';

import { Shop } from '@typings/Shop';

import { SidebarOnboarding } from '@components/Onboarding/v2/SidebarOnboarding';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { StatusIndicator } from '@components/Onboarding/v2/components/StatusIndicator';
import { Logo } from './Logo';
import CollapseIcon from '@assets/icons/collapse-btn.svg';
import { navigationSlice } from '@redux/reducer/navigationSlice';

interface BaseSidebarV2Props {
  shop: Shop;
  homeUrl: string;
}

export function BaseSidebarV2(props: PropsWithChildren<BaseSidebarV2Props>) {
  const { shop, homeUrl, children } = props;

  const onboardingState = useAppSelector((state) => state.onboarding);
  const navigationState = useAppSelector((state) => state.navigation);

  const dispatch = useAppDispatch();

  const activationStateText = onboardingState?.data?.finished_at
    ? 'pending_activation'
    : 'pending_onboarding';

  const renderSidebarModal = () => {
    if (onboardingState.isDisplay && onboardingState.isModalOpen) {
      return <SidebarModal className="fade modal-backdrop show" />;
    }
    return null;
  };

  const renderSidebarOnboarding = () =>
    !onboardingState?.data?.finished_at ? <SidebarOnboarding /> : null;

  const renderStatusIndicator = () => {
    if (onboardingState?.data?.finished_at) {
      return <StatusIndicator />;
    }
  };

  const onCollapseButtonClick = () => {
    dispatch(navigationSlice.actions.setSideNavCollapsed(true));
  };

  return (
    <Container collapsed={navigationState.isSideNavCollapsed}>
      <Header>
        <Logo activationState={activationStateText} shop={shop} homeUrl={homeUrl} />
        {/* {!navigationState.isSideNavCollapsed && (
          <CollapseButtonContainer onClick={onCollapseButtonClick}>
            <CollapseButton src={CollapseIcon} />
          </CollapseButtonContainer>
        )} */}
      </Header>
      <SidebarContent>
        <Menu>{children}</Menu>
      </SidebarContent>
      {renderSidebarModal()}
      {renderSidebarOnboarding()}
      {renderStatusIndicator()}
    </Container>
  );
}

const Container = styled(ProSidebar)`
  z-index: auto !important;

  .pro-sidebar-layout,
  .pro-sidebar-inner {
    z-index: auto !important;
    overflow: hidden !important;
  }

  .pro-inner-list-item {
    padding-left: 20px !important;
  }

  .pro-inner-list-item > div > ul {
    padding: 8px 0 !important;
  }

  .pro-icon-wrapper {
    margin: 0 10px !important;
  }

  .pro-inner-item {
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-radius: 3px;
  }

  .pro-arrow-wrapper {
    right: 10px !important;
  }
`;

const Header = styled(SidebarHeader)`
  border: 0 !important;
  z-index: 2000 !important;
`;

const CollapseButton = styled.img`
  width: 16px;
  height: 12px;
`;

const CollapseButtonContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 15px;
  cursor: pointer;
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-inner {
    color: white;
    background-color: #191d45;
    opacity: 1;
    font-weight: bold;
    border-radius: 3px;
  }

  .arrow::before {
    opacity: 1;
    border-right-color: #191d45;
  }
`;

const SidebarModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
