import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

import { initializeAmplitude } from '@config/amplitude';
import { sentrySetup } from '@config/sentry';
import { useCurrentShop } from '@hooks/useCurrentShop';
import { useInitialState } from '@hooks/useInitialState';

export default function AppGlobals() {
  const shop = useCurrentShop();
  const { amplitudeApiKey, environment, sentryDsn } = useInitialState();

  useEffect(() => {
    initializeAmplitude(shop, amplitudeApiKey);
    sentrySetup.initialize(sentryDsn, environment);
    sentrySetup.setShop(shop);
  }, [amplitudeApiKey, shop, sentryDsn]);

  return (
    <Toaster
      toastOptions={{
        style: {
          textAlign: 'center',
        },
      }}
    />
  );
}
