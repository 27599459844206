import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useForm, FormProvider } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import axios from '@lib/axios';

import BaseModal, { ModalHandles } from '@components/Modal/BaseModal';
import { useBaseAppContext } from '@components/BaseAppContext';
import { ContactCardModalBody } from '@components/Modal/ContactCardModal/ContactCardModalBody';
import { CustomButton as Button } from '@components/Button/CustomButton';

import EditIcon from '@assets/icons/edit.svg?react';

import { useCurrentShop } from '@hooks/useCurrentShop';
import { useFeature } from '@hooks/useFeature';
import { useFetch } from '@hooks/useFetch';
import { Feature } from '@typings/Feature';

export function ContactCardModal() {
  const { showContactCardModal, setShowContactCardModal } = useBaseAppContext();

  const contactCardModalRef = useRef<ModalHandles>(null);

  const shop = useCurrentShop();

  const { data: welcomeFeature, refetch: refetchWelcomeFeature } = useFetch<Feature>(
    '/sms/features/send_welcome'
  );
  const { data: vCardFeature, refetch: refetchVCardFeature } = useFetch<Feature>(
    '/sms/features/send_vcard'
  );

  const { toggleFeature: toggleVCardFeature, checked: vCardFeatureChecked } = useFeature(
    vCardFeature
  );
  const { toggleFeature: toggleWelcomeFeature, checked: welcomeFeatureChecked } = useFeature(
    welcomeFeature
  );

  useEffect(() => {
    if (showContactCardModal) {
      refetchWelcomeFeature();
      refetchVCardFeature();
      contactCardModalRef.current?.toggleModal();
    }
  }, [showContactCardModal]);

  function handleModalHide() {
    setShowContactCardModal(false);
  }

  const formProps = useForm({
    shouldUnregister: false,
    defaultValues: {
      vcard_email: shop?.setting?.vcard.vcard_email,
      vcard_website: shop?.setting?.vcard.vcard_website,
      vcard_company_name: shop?.setting?.vcard.vcard_company_name,
      vcard_picture: shop?.setting?.vcard.vcard_picture,
    },
    mode: 'all',
  });

  const { handleSubmit } = formProps;

  async function update(formData) {
    try {
      await axios.put(`/sms/settings/${shop?.setting?.id}`, formData);

      if (!welcomeFeatureChecked) {
        toggleWelcomeFeature();
      }

      if (!vCardFeatureChecked) {
        toggleVCardFeature();
      }
      contactCardModalRef.current?.toggleModal();
      toast.success('SMS Contact Card Updated.');

      // I dont see any easy way to sync the new feature values with the page, this is good enough
      if (!vCardFeatureChecked || !welcomeFeatureChecked) {
        if (window.location.href.includes('sms/settings')) {
          window.location.href = '/sms/settings?open=concierge-features';
        }
      }
    } catch {
      toast.error('Error Updating SMS Contact Card');
    }
  }

  return (
    <Modal ref={contactCardModalRef} onHide={handleModalHide}>
      <Form onSubmit={handleSubmit(update)}>
        <FormProvider {...formProps}>
          <ModalContainer>
            <ModalHeader>
              <h2>SMS Contact Card</h2>
              <h5>Customize your SMS Contact Card and let your customers easily save your info</h5>
              <p>This will accompany your Welcome SMS.</p>
            </ModalHeader>
            <ContactCardModalBody />
            {welcomeFeatureChecked === false && (
              <WarningContainer>
                SMS Contact Cards require the Welcome Message feature to be enabled, which you
                currently have disabled. By clicking Enable below, you will be turning on both of
                these features.
              </WarningContainer>
            )}
            <ModalFooter>
              <Button variant="secondary" className="ml-2" type="submit">
                <EditIcon className="mr-2" style={{ transform: 'scale(0.80)' }} />
                {vCardFeatureChecked ? 'Confirm' : 'Enable'}
              </Button>
            </ModalFooter>
          </ModalContainer>
        </FormProvider>
      </Form>
    </Modal>
  );
}

const Modal = styled(BaseModal)`
  .modal-dialog {
    max-width: 550px;
  }
`;

const ModalContainer = styled.div`
  padding: 24px;
  margin-bottom: 8px;
`;

const ModalHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  h2,
  h5 {
    font-weight: 700;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const WarningContainer = styled.div`
  background-color: rgba(255, 0, 0, 0.2);
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
`;
