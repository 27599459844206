import React from 'react';
import styled from '@emotion/styled';

import Template from './components/Template';
import { useWorkflowsContext } from '../WorkflowsContext';

export function Templates() {
  const { templates } = useWorkflowsContext();
  const filteredTemplates = templates?.filter((template) => !!template?.enabled);

  return (
    <Container>
      <Title>Select Workflow Template</Title>
      {filteredTemplates.map((template) => (
        <Template key={template.type} {...template} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 14px;
  color: #fff;
`;
