import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import beautify from 'js-beautify';

interface InstallationProps {
  htmlString: string;
  codeCopied: boolean;
  handleCopyCode(): void;
}

export function Installation(props: InstallationProps) {
  const { codeCopied, handleCopyCode, htmlString } = props;

  return (
    <Container>
      <Title>Widget Installation</Title>

      <CodeBlock showLineNumbers language="xml" style={atomOneDark}>
        {beautify.html(htmlString, {
          wrap_line_length: 80,
          wrap_attributes: 'force-expand-multiline',
        })}
      </CodeBlock>

      <CopyContainer>
        {codeCopied ? (
          <>
            <CopiedText>Copied to clipboard</CopiedText>
            <CheckIconContainer>
              <CheckIcon icon={faCheck} />
            </CheckIconContainer>
          </>
        ) : (
          <CopyButton onClick={handleCopyCode}>
            Copy
            <CopyIcon icon={faCode} />
          </CopyButton>
        )}
      </CopyContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1rem;
`;

const Title = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
`;

const CopyContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CopiedText = styled.span`
  font-size: 14px;
  color: #6b6b6b;
`;

const CheckIconContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3fbf3d;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: ${(props) => props.theme.radius.small};
  margin-left: 0.75rem;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  color: white;
`;

const CopyButton = styled.button`
  border-radius: ${(props) => props.theme.radius.small};
  padding: 7px 16px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  background: #1a1d45;
  color: #fff;
  border: 0;
`;

const CopyIcon = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
  font-size: 12px;
`;

const CodeBlock = styled(SyntaxHighlighter)`
  width: 100%;
  height: 400px;
  border-radius: ${(props) => props.theme.radius.small};
  background: #f4f4f6;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  margin-top: 1rem;
  tab-size: 4;
  font-size: 18px;
`;
