import React, { memo } from 'react';
import styled from '@emotion/styled';

import { Loading } from '@components/Loading';

export const LoadingNode = memo(() => (
  <Container>
    <Loading />
    <Label>Loading...</Label>
  </Container>
));

const Container = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
  margin: 0;
`;
