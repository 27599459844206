import styled from '@emotion/styled';
import { ActivationState } from '@hooks/useActivationState';
import { Shop } from '@typings/Shop';
import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RechargeLogoSVG from '@assets/icons/recharge-logo-new.svg';
import CollapsedLogoSVG from '@assets/icons/recharge-logo-collapsed.svg';
import { CustomTooltip } from './BaseSidebar';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';

interface LogoProps {
  activationState: ActivationState;
  shop: Shop;
  homeUrl: string;
}

export function Logo(props: LogoProps) {
  const { shop, homeUrl } = props;
  const dispatch = useAppDispatch();

  const onboardingState = useAppSelector((state) => state.onboarding);
  const navigationState = useAppSelector((state) => state.navigation);

  const navigate = useNavigate();

  function handleGoToHome() {
    if (!onboardingState?.data?.finished_at) {
      dispatch(onboardingSlice.actions.setOnboardingModal(false));
    } else {
      navigate(homeUrl);
    }
  }

  return (
    <OverlayTrigger
      placement="right"
      overlay={<CustomTooltip id="logo">{shop?.customName || shop?.shopifyDomain}</CustomTooltip>}
    >
      <LogoImageContainer isSideNavCollapsed={navigationState.isSideNavCollapsed}>
        {navigationState.isSideNavCollapsed && (
          <LogoImage onClick={handleGoToHome} src={CollapsedLogoSVG} />
        )}
        {!navigationState.isSideNavCollapsed && (
          <LogoImage onClick={handleGoToHome} src={RechargeLogoSVG} />
        )}
      </LogoImageContainer>
    </OverlayTrigger>
  );
}

const LogoImageContainer = styled.div<{ isSideNavCollapsed?: boolean }>`
  width: 100%;
  text-align: ${(props) => (props.isSideNavCollapsed ? 'center' : 'left')};
  position: relative;
  padding: 10px 20px;
`;

const LogoImage = styled.img`
  height: 25px;
  cursor: pointer;
`;
