import axios from '@lib/axios';
import { Settings } from '@typings/Settings';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import GenericPage from '@pages/Settings/Features/shared/GenericPage';

import { schema } from './components/schema';
import { FreshdeskFormSection } from './components/FreshdeskFormSection';
import { IntercomFormSection } from './components/IntercomFormSection';
import { GorgiasFormSection } from './components/GorgiasFormSection';
import { KustomerFormSection } from './components/KustomerFormSection';
import { ReamazeFormSection } from './components/ReamazeFormSection';
import { ZendeskFormSection } from './components/ZendeskFormSection';

interface CustomerServiceProps {
  settings: Settings;
}

function CustomerService({ settings }: CustomerServiceProps) {
  const {
    customer_service_config: customerServiceConfig,
    customer_service_provider: currentProvider,
  } = settings;

  const [invalidIntegration, setInvalidIntegration] = useState(false);
  const [service, setService] = useState('');

  const customerSupportFormSection = {
    freshdesk: <FreshdeskFormSection settings={settings} />,
    gorgias: <GorgiasFormSection settings={settings} />,
    intercom: <IntercomFormSection settings={settings} />,
    kustomer: <KustomerFormSection settings={settings} />,
    reamaze: <ReamazeFormSection settings={settings} />,
    zendesk: <ZendeskFormSection settings={settings} />,
  };

  const formProps = useForm({
    defaultValues: {
      customer_service_provider: settings.customer_service_provider,
    },
    resolver: yupResolver(schema[service]),
  });

  const { handleSubmit, register, watch } = formProps;

  async function update(formData) {
    try {
      const response = await axios.put(`/sms/settings/${settings.id}`, formData);
      toast.success('Customer service settings updated.');
      setInvalidIntegration(false);
      return response;
    } catch {
      toast.error('Error updating customer service settings.');
    }
  }

  const customerServiceProvider = watch('customer_service_provider');

  const checkInvalidIntegration = (provider) => {
    if (currentProvider !== provider) return false;

    return customerServiceConfig.status === 'disabled';
  };

  const InvalidIntegrationError = (
    <div className="alert alert-danger">
      Connection to {customerServiceProvider} failed, please review your credentials.
    </div>
  );

  useEffect(() => {
    setService(customerServiceProvider);

    const newInvalidIntegration = checkInvalidIntegration(customerServiceProvider);
    setInvalidIntegration(newInvalidIntegration);
  }, [customerServiceProvider]);

  return (
    <GenericPage>
      <Form onSubmit={handleSubmit(update)}>
        <FormProvider {...formProps}>
          <Row>
            <Col lg={5}>
              {invalidIntegration && InvalidIntegrationError}
              <Form.Group controlId="customer_service_provider">
                <Form.Control
                  as="select"
                  name="customer_service_provider"
                  {...register('customer_service_provider')}
                >
                  <option value="">None</option>
                  <option value="freshdesk">Freshdesk</option>
                  <option value="intercom">Intercom</option>
                  <option value="gorgias">Gorgias</option>
                  <option value="kustomer">Kustomer</option>
                  <option value="reamaze">Re:amaze</option>
                  <option value="zendesk">Zendesk</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {customerServiceProvider && customerSupportFormSection[customerServiceProvider]}
        </FormProvider>
      </Form>
    </GenericPage>
  );
}

export default CustomerService;
