import React from 'react';

import { formatString } from '@utils/date';
import { ProductImage } from '@components/Image/ProductImage';

import { EditableProductTitle } from './EditableProductTitle';
import { Features } from './Features';
import { Actions } from './Actions';
import { Price } from './Price';

export function SingleVariant(props) {
  const {
    shopifyProduct,
    productVariants,
    handleSearch,
    replenishment,
    ignoreDisabled = false,
  } = props;
  const productVariant = productVariants[0];

  return (
    <div className="product single mt-3">
      <div className="product__header d-flex">
        <div className="product__info" style={{ width: '33%' }}>
          <ProductImage
            className="product-image mx-3"
            src={productVariant?.shopifyProduct?.imageUrl}
            width={45}
            height={45}
          />
          <div
            className="d-flex flex-column justify-content-center"
            style={{ height: 118, width: '100%' }}
          >
            <h5 className="product__title">
              <EditableProductTitle id={productVariant.id} title={productVariant.title} />
            </h5>
            <p className="text-muted mb-0">Product ID: #{shopifyProduct?.externalId}</p>
            <p className="text-muted mb-0">Variant ID: #{productVariant?.shopifyId}</p>
          </div>
        </div>
        <div className="flex-grow-1 pr-3 d-flex align-items-center">
          <table className="table table-borderless mb-0 mt-2">
            <tbody>
              {productVariants.map((productVariant) => (
                <React.Fragment key={productVariant.id}>
                  <tr>
                    <td style={{ width: '15%' }}>
                      <Price productVariant={productVariant} />
                    </td>
                    {!replenishment && (
                      <td style={{ width: '37%' }}>
                        <Features productVariant={productVariant} />
                      </td>
                    )}
                    <td style={{ width: '23%' }}>
                      {formatString(productVariant.createdAt, 'h:mm a M/d/yy')}
                    </td>
                    <td className="product__actions px-0">
                      <Actions
                        productVariant={productVariant}
                        handleSearch={handleSearch}
                        replenishment={replenishment}
                        ignoreDisabled={ignoreDisabled}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
