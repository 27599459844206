import styled from '@emotion/styled';
import Loading from '@components/Loading';
import { Row, Col } from 'react-bootstrap';

export const FeatureContainer = styled.div<{ highlightSimulator?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-right: 27px;
  margin-left: 27px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0px;

  .simulator-onboarding {
    z-index: ${(props) => (props.highlightSimulator ? '2000' : 'auto')} !important;
  }
`;

export const CenterLoading = styled(Loading)`
  position: absolute;
  top: 40%;
`;

export const ColPl0 = styled(Col)`
  padding-left: 0px;
`;

export const ColPl5 = styled(Col)`
  padding-left: 5px;
`;

export const ColPr0 = styled(Col)`
  padding-right: 0px;
`;

export const RowMt16 = styled(Row)`
  margin-top: 16px;
`;

export const RowMt20 = styled(Row)`
  margin-top: 20px;
`;

export const RowMt30 = styled(Row)`
  margin-top: 30px;
`;
