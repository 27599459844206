import React, { useEffect, createRef } from 'react';
import styled from '@emotion/styled';
import { Table as BSTable } from 'react-bootstrap';

import { Loading } from '@components/Loading';
import { EmptyState } from '@components/EmptyState';

export type TableHeader = {
  label: string;
  description?: string;
  width?: string;
};

export type TableData = {
  onClick?(): void;
  data: React.ReactNode[];
};

export interface TableProps {
  loading?: boolean;
  headers: TableHeader[];
  rows: TableData[];
  banner?: React.ReactNode;
}

export function Table(props: TableProps) {
  const { loading = false, headers = [], rows = [], banner, ...rest } = props;
  const tHeadRef = createRef<HTMLTableSectionElement>();
  const tBodyRef = createRef<HTMLTableSectionElement>();

  function isOverflown({ clientHeight, scrollHeight }) {
    return scrollHeight > clientHeight;
  }

  useEffect(() => {
    if (isOverflown(tBodyRef?.current)) {
      tHeadRef.current.style.width = 'calc(100% - 1em)';
    }
  }, []);

  const isEmpty = rows.length === 0;

  return (
    <CustomTable borderless striped {...rest}>
      <thead ref={tHeadRef}>
        <tr>
          {headers?.map(({ label, description, width }, index) => (
            <th key={index} style={{ width }}>
              {label}
              {description && <small className="ml-2">{description}</small>}
            </th>
          ))}
        </tr>
      </thead>
      <tbody ref={tBodyRef}>
        {banner}
        {loading || isEmpty ? (
          <tr className="h-100">
            <td className="p-5">
              {loading ? (
                <Loading />
              ) : (
                isEmpty && <EmptyState description="Not enough data, yet..." />
              )}
            </td>
          </tr>
        ) : (
          rows?.map((row, index) => (
            <tr onClick={row?.onClick} data-has-onclick={!!row?.onClick} key={index}>
              {row?.data?.map((d, index) => (
                <td style={{ width: headers[index]?.width }} key={index}>
                  {d}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </CustomTable>
  );
}

const CustomTable = styled(BSTable)`
  height: 100%;

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    width: 100%;

    th:first-of-type {
      padding-left: 50px;
    }

    th {
      font-weight: 700;
      color: #6b6b6b;
      font-size: 14px;
      line-height: 18px;
      box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.12);
    }
  }

  tbody {
    display: block;
    height: calc(100% - 50px);
    overflow-y: auto;

    tr {
      height: 80px;

      &:nth-of-type(even) {
        background-color: rgba(196, 196, 196, 0.1);
      }

      &:nth-of-type(odd) {
        background-color: transparent;
      }

      &[data-has-onclick]:not([data-has-onclick='false']) {
        &:hover {
          cursor: pointer;
          background-color: rgba(196, 196, 196, 0.2);
        }
      }

      td:first-of-type {
        padding-left: 50px;
      }

      td {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #000;
        vertical-align: middle;
      }
    }
  }
`;
