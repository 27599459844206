import {
  faArchive,
  faChartPie,
  faCog,
  faCommentAlt,
  faEnvelope,
  faHeartBroken,
  faKey,
  faLayerGroup,
  faProjectDiagram,
  faQuestionCircle,
  faRandom,
  faTh,
  faUser,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

import House from '@assets/icons/house.svg';
import Messages from '@assets/icons/messages.svg';
import Products from '@assets/icons/products.svg';

export const routes = ({ workflowsRouteEnabled = false, homepageRouteEnabled = false }) => [
  {
    path: '/sms',
    title: 'My Shop',
    iconCustom: House,
    testId: 'home-menu-button',
    disabled: !homepageRouteEnabled,
    onboardingPath: ['/sms/onboarding/welcome', '/sms/onboarding/activation'],
  },
  {
    path: '/sms/simulator',
    title: 'Simulator',
    icon: faTh,
    testId: 'simulator-sub-menu-button',
  },
  {
    path: '/sms/recharge/customers',
    title: 'Customers',
    icon: faUser,
    testId: 'customers-menu-button',
    routes: [
      {
        path: '/sms/segments',
        title: 'Segments',
        icon: faChartPie,
        testId: 'segments-sub-menu-button',
      },
    ],
  },
  {
    path: '/sms/template_messages',
    title: 'Messages',
    iconCustom: Messages,
    testId: 'messages-menu-button',
    routes: [
      {
        path: '/sms/template_messages/custom',
        title: 'Custom Messages',
        icon: faCommentAlt,
        testId: 'custom-messages-sub-menu-button',
      },
      {
        path: '/sms/message_custom_keywords',
        title: 'Custom Keywords',
        icon: faKey,
        testId: 'custom-keywords-sub-menu-button',
      },
      {
        path: '/sms/cancellation_reasons',
        title: 'Cancellation Reasons',
        icon: faHeartBroken,
        testId: 'cancellation-reasons-sub-menu-button',
      },
    ],
  },
  {
    path: '/sms/product_variants',
    title: 'Products',
    iconCustom: Products,
    onboardingPath: ['/sms/onboarding/product'],
    testId: 'products-menu-button',
    routes: [
      {
        path: '/sms/swap_groups',
        title: 'Swap Groups',
        icon: faRandom,
        classname: 'onboarding-swap-groups-nav',
        testId: 'swap-groups-sub-menu-button',
      },
      {
        path: '/sms/quantity_groups',
        title: 'Quantity Groups',
        icon: faLayerGroup,
        testId: 'quantity-groups-sub-menu-button',
      },
      {
        path: '/sms/product_variants/archived',
        title: 'Archived',
        icon: faArchive,
        testId: 'archived-products-sub-menu-button',
      },
    ],
  },
  {
    path: '/sms/workflows',
    title: 'Workflows',
    icon: faProjectDiagram,
    disabled: !workflowsRouteEnabled,
    testId: 'workflows-menu-button',
  },
  {
    path: '/sms/settings',
    title: 'Settings',
    icon: faCog,
    testId: 'settings-menu-button',
    onboardingPath: ['/sms/onboarding/features'],
    routes: [
      {
        path: '/sms/settings/advanced',
        title: 'Advanced',
        icon: faWrench,
        testId: 'settings-advanced-sub-menu-button',
      },
      {
        path: '/sms/settings/email',
        title: 'Emails',
        icon: faEnvelope,
        testId: 'settings-email-sub-menu-button',
      },
      {
        path: '/sms/settings/help',
        title: 'Help',
        icon: faQuestionCircle,
        testId: 'settings-help-sub-menu-button',
      },
    ],
  },
];
