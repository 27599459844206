import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { EmptyState } from '@components/EmptyState';
import NoResultsIcon from '@assets/icons/no-results.svg';
import { Paginator, PaginationInfoPropTypes } from '@components/Paginator';

import { useCurrentShop } from '@hooks/useCurrentShop';

import SearchBar from './SearchBar';

const callSearch = async (searchParams, handleSearch) => {
  await handleSearch(searchParams);
};

const debouncedSearch = debounce(callSearch, 500);

export type GroupSelectItemAction = 'add' | 'remove';

type Option = { id: number };

interface GroupSelectProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  title: string;
  descriptionElement: object;
  label: string;
  initialSearch?: boolean;
  options: Option[];
  paginationInfo: PaginationInfoPropTypes;
  placeholder?: string;
  selectedOptions: Option[];
  onSearch(query: string, page: number): Promise<void>;
  onSelectedOptionsChange(selectedOptions: Option[]): void;
  renderOption?(option: any, props: RenderItemProps): React.ReactNode;
}

interface RenderItemProps {
  selected?: boolean;
  action: 'add' | 'remove';
}

const GroupSelect = (props: GroupSelectProps) => {
  const {
    children,
    className,
    disabled,
    title,
    descriptionElement,
    label,
    options = [],
    paginationInfo,
    placeholder = 'Type to search',
    initialSearch = true,
    onSearch,
    renderOption = (option) => <>{option}</>,
    onSelectedOptionsChange = () => null,
    selectedOptions,
  } = props;
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const selectedIds = selectedOptions.map((option) => option.id);
  const shop = useCurrentShop();

  const handleSearch = async (searchParams) => {
    setLoading(true);

    await onSearch(searchParams.query, searchParams.page);

    setLoading(false);
  };

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedSearch({ query: target.value }, handleSearch);
  };

  const handlePageChange = (page) => {
    handleSearch({ query: search, page });
  };

  const onSelectOption = (selectedOption) => () => {
    if (!includes(selectedIds, selectedOption.id)) {
      onSelectedOptionsChange([selectedOption, ...selectedOptions]);
    }
  };

  const onRemoveOption = (optionToRemove) => () => {
    const newSelectedOptions = filter(selectedOptions, (option) => option.id !== optionToRemove.id);

    onSelectedOptionsChange(newSelectedOptions);
  };

  useEffect(() => {
    if (initialSearch) {
      callSearch({ query: '' }, handleSearch);
    }
  }, []);

  const emptyResult =
    selectedOptions.length > 0
      ? {
          icon: NoResultsIcon,
          description: 'No more results',
        }
      : {
          icon: null,
          description: 'No results found',
        };


  const hasReachedSelectLimit = selectedOptions.length >= shop.swapGroupSelectedLimit;

  return (
    <Root className={cx('group-select', className)} disabled={disabled}>
      <Row>
        <Col xs={5}>
          <h4>{title}</h4>
          {descriptionElement}
        </Col>
      </Row>

      <Row>
        <Col xs={5}>
          <SearchBar
            label={label}
            value={search}
            placeholder={placeholder}
            onSearchChange={onSearchChange}
          />

          <div>
            {loading ? (
              <Row className="no-gutters justify-content-center mt-5">
                <Col className="col-auto">
                  <Spinner animation="border" variant="primary" />
                </Col>
              </Row>
            ) : options.length > 0 ? (
              <CustomListGroup disabled={hasReachedSelectLimit}>
                {options.map((option) => {
                  const selected = selectedIds.includes(option.id);
                  return (
                    <ListGroupItem
                      key={option.id}
                      as="div"
                      action={!selected}
                      disabled={disabled || hasReachedSelectLimit}
                      onClick={onSelectOption(option)}
                      style={{
                        cursor: !disabled && !selected && 'pointer',
                      }}
                    >
                      {renderOption(option, { selected, action: 'add' })}
                    </ListGroupItem>
                  );
                })}
              </CustomListGroup>
            ) : (
              <EmptyState iconURL={emptyResult.icon} description={emptyResult.description} />
            )}
          </div>
          <PaginatorArea>
            <Paginator paginationInfo={paginationInfo} onPageChange={handlePageChange} />
          </PaginatorArea>
        </Col>
        <Col xs={{ span: 5, offset: 2 }}>
          {selectedOptions.length > 0 ? (
            <>
              {children}
              <ListGroup variant="flush">
                {selectedOptions.map((option) => (
                  <ListGroupItem
                    key={option.id}
                    as="div"
                    action
                    disabled={disabled}
                    onClick={onRemoveOption(option)}
                    style={{ border: '1px solid #3FBF3D', cursor: !disabled && 'pointer' }}
                  >
                    {renderOption(option, { action: 'remove' })}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </>
          ) : (
            options.length > 0 && (
              <EmptyState iconURL={NoResultsIcon} description={`Select up to</br>${shop.swapGroupSelectedLimit} desired swaps`} />
            )
          )}
        </Col>
      </Row>
    </Root>
  );
};

interface RootProps {
  disabled?: boolean;
}

const Root = styled.div<RootProps>`
  opacity: ${(props) => props.disabled && 0.4};
`;

const CustomListGroup = styled(ListGroup)`
  filter: ${(props) => props.disabled && 'grayscale(1)'};
`;

const ListGroupItem = styled(ListGroup.Item)`
  border: none;
  margin: 5px auto;
  box-shadow: 0 0 4px #9a9b9c;
  border-radius: 8px !important;
  padding: 0 30px 0 0;
  width: 100%;
  max-height: 115px;
  overflow: hidden;

  svg {
    filter: ${(props) => props.disabled && 'opacity(0.5)'};
  }
`;

const PaginatorArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
`;

export default GroupSelect;
