import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Target } from 'react-feather';

export const WidgetPreview = (props) => {
  const [checkedState, setCheckedState] = useState(true);

  function checkHandler(el) {
    setCheckedState(!el.target.checked);
  }

  return (
    <WidgetPreviewStyled {...props}>
      <div className="widget-preview">
        <div className="widget-preview__header">
          <div className="widget-preview__header-title">SUBTOTAL</div>
          <div className="widget-preview__header-price">$75.00</div>
        </div>
        <div className="widget-preview__body">
          <div className="widget-preview__body-checkbox">
            <label id="label" htmlFor="checkbox">
              <input id="checkbox" type="checkbox" checked={checkedState} onChange={checkHandler} />
              <span id="checkmark" />
              Manage your orders via Text
            </label>
          </div>
          <div className="widget-preview__body-logo" />
        </div>
        <div className="widget-preview__footer">
          <button className="widget-preview__footer-button" type="button" disabled>
            CHECK-OUT
          </button>
        </div>
      </div>
    </WidgetPreviewStyled>
  );
};

interface WidgetPreviewStyledProps {
  responsive?: boolean;
  variant?: string;
  disableBranding?: boolean;
  backgroundColor?: string;
}
// --rcsms-color-dark: #1A1D45;
// --rcsms-color-light: #f5f5f5;
// --rcsms-color-cobalt: #3901F1;

let assetDomain = '';
if (document.domain.includes('admin.rechargeapps.com')) {
  assetDomain = 'https://app.electricsms.com';
} else if (document.domain.includes('admin.stage.rechargeapps.com')) {
  assetDomain = 'https://sms.stage.rechargeapps.com';
}

const WidgetPreviewStyled = styled.div<WidgetPreviewStyledProps>`
  .widget-preview {
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
    color: #050505;
    border-radius: 0.5rem;
    max-width: 100%;
    padding: 1rem;
    width: 100%;

    ${(props) =>
      props.variant === 'midnight' &&
      `
      background-color: #1A1D45;
      color: #ffffff;
    `}

    &__header {
      align-items: center;
      display: flex;
      font-size: 1.3rem;
      font-weight: bold;
      justify-content: space-between;
    }

    &__body {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;
      font-size: 12px;
    }

    &__body-checkbox {
      label {
        margin-bottom: 0;
      }

      #label {
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        align-items: center;
        color: var(--rcsms-color-dark);
        cursor: pointer;
        display: flex;
        font-size: 12px;
        margin-bottom: 0;
        position: relative;
        user-select: none;
        font-weight: 400;
        letter-spacing: normal;
        text-transform: none;

        #checkbox {
          cursor: pointer;
          height: 0;
          opacity: 0;
          position: absolute;
          width: 0;
        }

        #checkmark {
          border: 1px solid
            ${({ variant }) => {
              if (variant === 'cobalt') {
                return '#1A1D45';
              }
              if (variant === 'midnight') {
                return '#FEFBF6';
              }
              return '#000';
            }};
          border-radius: 2px;
          height: 20px;
          margin-right: 8px;
          width: 20px;
          background-color: ${({ variant }) => {
            if (variant === 'cobalt') {
              return '#1A1D45';
            }
            if (variant === 'midnight') {
              return '#FEFBF6';
            }
            return '#000';
          }};
          background-image: ${({ variant }) => {
            if (variant === 'midnight') {
              return `url(${assetDomain}/cart-widget/assets/check-dark.svg);`;
            }
            return `url(${assetDomain}/cart-widget/assets/check.svg);`;
          }};
          background-position: center;
          background-repeat: no-repeat;
          background-size: 60%;
        }
      }
    }

    &__body-logo {
      background-color: ${({ variant }) => {
        if (variant === 'cobalt') {
          return '#1A1D45';
        }
        if (variant === 'midnight') {
          return '#FEFBF6';
        }
        return '#000';
      }};
      background-position: center;
      background-repeat: no-repeat;
      background-size: 75px;
      background-image: ${({ variant }) => {
        if (variant === 'midnight') {
          return `url("${assetDomain}/cart-widget/assets/logo.svg");`;
        }
        return `url("${assetDomain}/cart-widget/assets/logo-white.svg");`;
      }};
      border-radius: 16px;
      height: 24px;
      padding: 2px 2px;
      visibility: ${(props) => (props.disableBranding ? 'hidden' : 'visible')};
      width: 100px;
      min-width: 100px;
    }

    &__footer {
      margin-top: 1rem;
    }

    &__footer-button {
      background: #cccccc;
      border-radius: 4px;
      border: none;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.7rem 1rem 0.5rem;
      width: 100%;
      color: #fff;
    }
  }
`;
