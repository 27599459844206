import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useCurrentShop } from '@hooks/useCurrentShop';
import { OptInWidget } from '@components/OptIn/OptInWidget';

import { Phone } from './Phone';
import 'react-phone-input-2/lib/style.css';
import './OptInPage.css';

export const OptInPage = ({
  watch = useFormContext().watch,
  showWidget = true,
  logoUrl = undefined,
}) => {
  const shop = useCurrentShop();

  return (
    <div className="opt-in-page">
      <div className="opt-in-page-row">
        <div className="opt-in-page-left-col">
          <div className="opt-in-page-left-col-container">
            {'\n'}
            <div
              className="opt-in-page-logo"
              style={{ backgroundImage: `url("${shop?.logoUrl || logoUrl}")` }}
            />
            <h2
              className="opt-in-page-main-text"
              style={{
                color: watch('heroCopyFontColor'),
              }}
            >
              {watch('heroCopy')}
            </h2>

            {showWidget ? (
              <OptInWidget
                shop={shop}
                ctaButtonColor={watch('ctaButtonColor')}
                ctaButtonFontColor={watch('ctaButtonFontColor')}
                ctaButtonLabel={watch('ctaButtonLabel')}
              />
            ) : (
              <iframe
                title="landing-page-opt-in"
                id="replace-opt-in-widget-frame"
                frameBorder="0"
                height="330"
              />
            )}
          </div>
        </div>

        <div className="opt-in-page-right-col">
          <div className="opt-in-page-phone">
            <Phone mainColor={watch('mainColor')} imageUrl={shop?.logoUrl || logoUrl} />
          </div>
        </div>
      </div>
    </div>
  );
};
