import React from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '@components/Layout/Page';
import { useFetch } from '@hooks/useFetch';

import { Form } from './Form';

function Edit() {
  const { id } = useParams();
  const { data, loading } = useFetch<any>(`/sms/message_custom_keywords/${id}`);

  return (
    <Page loading={loading} headerDescription="Update message custom keyword's data.">
      <Form messageCustomKeyword={data} />
    </Page>
  );
}

export default Edit;
