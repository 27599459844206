import * as yup from 'yup';
import axios from 'axios';
import { CustomerService } from '@typings/CustomerService';

const requiredMessage = 'Must be present.';
const urlMessage = 'Must be a valid URL.';

const checkKustomerApiKeyRole = async (apiKey) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/kustomer', {
      api_key: apiKey,
    });

    return data;
  } catch {
    return false;
  }
};

const checkFreshdeskCustomFields = async ({ freshdesk_api_key, freshdesk_api_url }) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/freshdesk', {
      api_key: freshdesk_api_key,
      api_url: freshdesk_api_url,
    });

    return data;
  } catch {
    return false;
  }
};

const checkIntercomConnection = async (intercom_api_key, intercom_email) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/intercom', {
      api_key: intercom_api_key,
      email: intercom_email,
    });

    return data;
  } catch {
    return false;
  }
};

const checkGorgiasConnection = async (api_url, username, password) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/gorgias', {
      api_url,
      username,
      password,
    });

    return data;
  } catch {
    return false;
  }
};

const checkReamazeConnection = async (api_url, username, api_key) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/reamaze', {
      api_url,
      username,
      api_key,
    });

    return data;
  } catch {
    return false;
  }
};

const checkZendeskConnection = async (api_url, username, password) => {
  try {
    const { data } = await axios.post('/sms/api/customer_support/validators/zendesk', {
      api_url,
      username,
      password,
    });

    return data;
  } catch {
    return false;
  }
};

export const schema: Record<CustomerService, any> = {
  zendesk: yup.object().shape({
    zendesk_api_url: yup.string().required(requiredMessage).url(urlMessage),
    zendesk_username: yup.string().required(requiredMessage),
    zendesk_password: yup
      .string()
      .required(requiredMessage)
      .test(
        'checkZendeskConnection',
        "Couldn't connect to Zendesk, check your credentials.",
        (password, context) =>
          checkZendeskConnection(
            context.parent.zendesk_api_url,
            context.parent.zendesk_username,
            password
          )
      ),
  }),
  reamaze: yup.object().shape({
    reamaze_api_url: yup.string().required(requiredMessage).url(urlMessage),
    reamaze_user: yup.string().required(requiredMessage),
    reamaze_api_key: yup
      .string()
      .required(requiredMessage)
      .test(
        'checkReamazeConnection',
        "Couldn't connect to Reamaze, check your credentials.",
        (api_key, context) =>
          checkReamazeConnection(
            context.parent.reamaze_api_url,
            context.parent.reamaze_user,
            api_key
          )
      ),
  }),
  gorgias: yup.object().shape({
    gorgias_api_url: yup.string().required(requiredMessage).url(urlMessage),
    gorgias_username: yup.string().required(requiredMessage),
    gorgias_password: yup
      .string()
      .required(requiredMessage)
      .test(
        'checkGorgiasConnection',
        "Couldn't connect to Gorgias, check your credentials.",
        (password, context) =>
          checkGorgiasConnection(
            context.parent.gorgias_api_url,
            context.parent.gorgias_username,
            password
          )
      ),
  }),
  freshdesk: yup.object().shape({
    customer_service_config: yup
      .object()
      .shape({
        freshdesk_api_url: yup.string().required(requiredMessage).url(urlMessage),
        freshdesk_api_key: yup.string().required(requiredMessage),
      })
      .test(
        'checkFreshdeskCustomFields',
        "You have required custom ticket fields within your Freshdesk configuration. We don't supply these fields from our system and were unable to connect to your account.",
        checkFreshdeskCustomFields
      ),
  }),
  kustomer: yup.object().shape({
    kustomer_api_key: yup
      .string()
      .required(requiredMessage)
      .test('checkKustomerApiKeyRole', 'Api key should have "org" role.', checkKustomerApiKeyRole),
  }),
  intercom: yup.object().shape({
    intercom_email: yup.string().email().required(requiredMessage),
    intercom_api_key: yup
      .string()
      .required(requiredMessage)
      .test(
        'checkIntercomConnection',
        "Couldn't connect to Intercom, check your credentials.",
        (intercom_api_key, context) =>
          checkIntercomConnection(intercom_api_key, context.parent.intercom_email)
      ),
  }),
};
