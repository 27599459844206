import React, { useState } from 'react';

import { Shop } from '@typings/Shop';
import { BaseSidebar, Route } from '@components/Layout/Sidebar/components';

import { routes } from './Router/navigationRoutes';

interface SidebarProps {
  shop: Shop;
}

export function Sidebar(props: SidebarProps) {
  const { shop } = props;
  const [open, setOpen] = useState('');

  return (
    <BaseSidebar
      shop={shop}
      homeUrl="/replenishment"
      activationState={shop.postPurchaseExtensionEnabled ? 'active' : 'pending_activation'}
    >
      {routes.map((route) => (
        <Route key={route.title} route={route} open={open} setOpen={setOpen} />
      ))}
    </BaseSidebar>
  );
}
