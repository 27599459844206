import React from 'react';
import { Nav, Tab, TabContainerProps } from 'react-bootstrap';

import { Card } from '@components/Layout/Card';

interface Item {
  key: string;
  label: string;
  component: React.ReactNode;
}

interface TabsProps extends TabContainerProps {
  id: string;
  items: Item[];
}

export function Tabs(props: TabsProps) {
  const { id, items, ...rest } = props;

  return (
    <Tab.Container id={id} {...rest}>
      <Card>
        <div className="subnav">
          <Nav variant="tabs">
            {items.map((item) => (
              <Nav.Item key={item.key}>
                <Nav.Link eventKey={item.key}>{item.label}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>

        <Tab.Content className="py-4">
          {items.map((item) => (
            <Tab.Pane key={item.key} eventKey={item.key}>
              {item.component}
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Card>
    </Tab.Container>
  );
}
