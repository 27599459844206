import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';

import { useWorkflowsContext } from '@pages/Workflows/WorkflowsContext';

const BackButton = () => {
  const { setSelectedView, handleSelectWorkflow } = useWorkflowsContext();

  function handleGoBack() {
    handleSelectWorkflow(null, null, true);
    setSelectedView('templates');
  }

  return (
    <Container onClick={handleGoBack}>
      <FontAwesomeIcon icon={faChevronLeft} />
      <Text>Back to Templates</Text>
    </Container>
  );
};

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: ${(props) => props.theme.colors.light1};
  box-shadow: ${(props) => `0px 0px 4px ${props.theme.colors.dark0}44`};
  border-radius: 6px;
  border: 0;
  margin-bottom: 20px;
  flex: 2;

  &:hover {
    filter: brightness(98%);
  }
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
`;

export default BackButton;
