import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ProductImage } from '@components/Image/ProductImage';
import { ProductVariant } from '@typings/ProductVariant';
import { switchStyle } from '@utils/styles';

interface ProductCardProps {
  title?: string | React.ReactNode;
  subtitle?: string;
  subtitleStyle?: CSSProperties;
  variants?: ProductVariant[];
  showQuantity?: boolean;
  selected?: boolean;
  handleClick?(): void;
  rightComponent?: React.ReactNode;
}

export function ProductCard(props: ProductCardProps) {
  const {
    title,
    subtitle,
    subtitleStyle,
    variants,
    selected,
    handleClick,
    rightComponent,
    showQuantity = true,
    ...rest
  } = props;

  const hasMultipleVariants = variants?.length > 1;
  return (
    <Container selected={selected} onClick={handleClick} {...rest}>
      <ImagesContainer>
        {hasMultipleVariants && showQuantity && (
          <TotalVariantsLabel>
            {variants?.length > 99 ? '99+' : variants?.length}
          </TotalVariantsLabel>
        )}

        <Image src={variants?.[0]?.imageUrl} />
        {variants?.[1] && <Image src={variants?.[1]?.imageUrl} position="second" />}
        {variants?.[2] && <Image src={variants?.[2]?.imageUrl} position="third" />}
      </ImagesContainer>

      <Body>
        <Title>{title}</Title>
        <Subtitle style={subtitleStyle}>{subtitle}</Subtitle>
      </Body>

      <RightContainer>{rightComponent}</RightContainer>
    </Container>
  );
}

interface ContainerProps {
  selected: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.light1};
  border: ${(props) => props?.selected && '1px solid #4ecb71'};
  box-shadow: ${(props) => `0px 0px 4px ${props.theme.colors.dark0}44`};
  border-radius: 6px;
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background: ${(props) => props.theme.colors.light2};
  }
`;

const ImagesContainer = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const Image = styled(ProductImage)<{ position?: 'second' | 'third' }>`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: ${(props) => props.theme.radius.small};
  z-index: 15;
  box-shadow: ${(props) => `0px 0px 4px ${props.theme.colors.dark0}22`};

  ${switchStyle('position')({
    second: css`
      position: absolute;
      z-index: 10;
      left: 13px;
      width: 35px;
      height: 35px;
      opacity: 0.75;
    `,
    third: css`
      position: absolute;
      z-index: 5;
      left: 28px;
      width: 25px;
      height: 25px;
      opacity: 0.5;
    `,
  })}
`;

const TotalVariantsLabel = styled.div`
  position: absolute;
  z-index: 20;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #4e56d3;
  color: ${(props) => props.theme.colors.light0};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  padding: 2px;
  top: 0;
  left: 0;
  transform: translate(-25%, -25%);
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.span`
  font-size: 14px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightContainer = styled.div`
  min-width: 30px;
  text-align: center;
`;

export default ProductCard;
