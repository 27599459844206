import React from 'react';
import { getMarkerEnd } from 'react-flow-renderer';

export function RoundedEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  arrowHeadType,
  markerEndId,
}) {
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const dPath = `M${sourceX},${sourceY} C${sourceX + 100} ${
    targetY + 200
  } ${sourceX} ${targetY} ${targetX},${targetY}`;

  return (
    <path id={id} style={style} className="react-flow__edge-path" markerEnd={markerEnd} d={dPath} />
  );
}
