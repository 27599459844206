import axios from '@lib/axios';
import { useEffect, useState } from 'react';

interface State {
  loading: boolean;
  enabled: boolean;
}

export function useFlag(flagKey: string) {
  const [state, setState] = useState<State>({
    loading: true,
    enabled: false,
  });

  useEffect(() => {
    async function fetch() {
      const response = await axios.get(`/sms/flags/${flagKey}`);

      const { data } = response;

      setState({
        loading: false,
        enabled: data.enabled,
      });
    }
    fetch();
  }, []);

  return {
    ...state,
  };
}
