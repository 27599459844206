import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAppSelector } from '@redux/hooks';

type Route = {
  title: string;
  tabTitle?: string;
  icon?: IconDefinition;
  iconCustom?: string;
  path?: string;
  onboardingPath?: string[];
  routes?: Route[];
  classname?: string;
  testId?: string;
};

interface RouteProps {
  route: Route;
  open: string;
  setOpen(open: string): void;
}

export function Route(props: RouteProps) {
  const { route, open, setOpen } = props;
  const { pathname: currentRoute } = useLocation();
  const navigate = useNavigate();
  const subRoutes = route?.routes || [];

  const navigationState = useAppSelector((state) => state.navigation);

  useEffect(() => {
    const defaultOpen = subRoutes.some((route) => route.path === currentRoute);

    if (defaultOpen) setOpen(route.title);
  }, []);

  function handlePageChange() {
    if (open === route.title) setOpen('');
    else setOpen(route.title);
  }

  const isRouteSelected = () =>
    route.path === currentRoute ||
    (!!route.onboardingPath && route.onboardingPath.indexOf(currentRoute) >= 0);

  if (subRoutes.length === 0) {
    return (
      <>
        {route.path === currentRoute && (
          <Helmet>
            <title>{route?.tabTitle || route.title} - RechargeSMS</title>
          </Helmet>
        )}

        <SingleItem
          data-test-id={route.testId}
          key={route.path}
          icon={
            route.iconCustom ? (
              <img src={route.iconCustom} />
            ) : (
              <Icon icon={route.icon} color="white" />
            )
          }
          selected={isRouteSelected()}
          onClick={() => {
            navigate(route.path);
            setOpen('');
          }}
        >
          {!navigationState.isSideNavCollapsed && route.title}
        </SingleItem>
      </>
    );
  }

  return (
    <StyledSubMenu
      open={open === route.title}
      onOpenChange={handlePageChange}
      title={
        <Link to={route.path} data-test-id={route.testId}>
          {route.title}
        </Link>
      }
      icon={
        route.iconCustom ? <img src={route.iconCustom} /> : <Icon icon={route.icon} color="white" />
      }
      data-test-id={route.testId}
      selected={isRouteSelected()}
    >
      {subRoutes.length > 0 &&
        subRoutes.map((subroute) => {
          const selected = subroute.path === currentRoute;

          return (
            <React.Fragment key={subroute.path}>
              {selected && (
                <Helmet>
                  <title>{subroute?.tabTitle || subroute.title} - RechargeSMS</title>
                </Helmet>
              )}
              <Item
                key={subroute.path}
                selected={selected}
                icon={
                  subroute.iconCustom ? (
                    <img src={subroute.iconCustom} />
                  ) : (
                    <FontAwesomeIcon icon={subroute.icon} color="#eee" />
                  )
                }
                className={subroute?.classname}
              >
                <Link to={subroute.path} data-test-id={subroute.testId}>
                  {subroute.title}
                </Link>
              </Item>
            </React.Fragment>
          );
        })}
    </StyledSubMenu>
  );
}

const StyledSubMenu = styled(SubMenu)<{ selected?: boolean }>`
  margin: 0 10px;
  border-radius: 3px;

  &.open {
    background-color: #262a5f;
  }

  > .pro-inner-item {
    z-index: ${(props) => (props.selected ? '2000' : 'auto')} !important;
    background-color: ${(props) => props.open && '#2e3270'} !important;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    > .pro-icon-wrapper {
      margin-right: 4px !important;
    }
  }

  > .pro-inner-item > .pro-item-content a {
    color: #ffffff !important;
  }

  > .pro-inner-item:hover {
    color: #ffffff !important;
  }

  .pro-inner-list-item {
    background: #262a5f !important;
    padding-left: 0 !important;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
`;

const Item = styled(MenuItem)<{ selected?: boolean }>`
  padding: 0 10px;
  border-right: 4px solid ${(props) => (props?.selected ? '#f2acbd' : 'transparent')};

  a {
    font-size: 11px;
    color: #eee !important;
    font-weight: 500;
  }
`;

const SingleItem = styled(Item)<{ selected?: boolean }>`
  .pro-inner-item {
    z-index: ${(props) => (props.selected ? '2000' : 'auto')} !important;
    background-color: ${(props) => props.selected && '#2e3270'} !important;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    > .pro-icon-wrapper {
      margin-right: 4px !important;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 12px;
`;
