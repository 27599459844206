import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from '@emotion/styled';

import { SmsMessage } from '@typings/SmsMessage';
import { PaginationInfo } from '@typings/PaginationInfo';
import { Loading } from '@components/Loading';

import { Alert, IAlert } from './Alert';
import { Message } from './Message';

interface Data {
  data: SmsMessage[];
  paginationInfo: PaginationInfo;
}

interface ChatHistoryInfiniteProps {
  id: string;
  alert?: IAlert;
  data: Data[];
  setSize: React.Dispatch<React.SetStateAction<number>>;
}

export function ChatHistoryInfinite(props: ChatHistoryInfiniteProps) {
  const { id, data = [], alert, setSize } = props;

  return (
    <Container id={`scrollableDiv-${id}`}>
      {alert && <Alert {...alert} />}

      <InfiniteScroll
        dataLength={data.length}
        next={() => setSize((size) => size + 1)}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
        inverse
        hasMore
        loader={<Loading />}
        scrollableTarget={`scrollableDiv-${id}`}
      >
        {data.map(({ data: smsMessages }) =>
          smsMessages.map((smsMessage) => <Message key={smsMessage.id} smsMessage={smsMessage} />)
        )}
      </InfiniteScroll>
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid #dee2e6;
  height: 420px;
  overflow-y: auto;
  padding: 1rem;
  padding-bottom: 0;
  scroll-behavior: smooth;
  border-radius: 6px;
  display: flex;
  flex-direction: column-reverse;
`;
