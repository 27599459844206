import React from 'react';
import { Credentials } from '@typings/Integration';

import { TextInput } from '../TextInput';

interface FormProps {
  credentials: Credentials;
}

export function ZendeskForm(props: FormProps) {
  const { credentials } = props;

  return (
    <div>
      <TextInput
        name="zendesk_base_url"
        label="Base API URL"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.base_url ? credentials.base_url : '',
          placeholder: 'https://shopname.zendesk.com/api/v2',
        }}
      />

      <TextInput
        name="zendesk_username"
        label="Username"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.username,
        }}
      />

      <TextInput
        name="zendesk_password"
        label="Password"
        customControlStyle={{ fontSize: 12 }}
        inputProps={{
          defaultValue: credentials.password,
        }}
      />
    </div>
  );
}
