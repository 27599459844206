import React from 'react';
import toast from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';

import { Page } from '@components/Layout/Page';
import { useFetch } from '@hooks/useFetch';
import axios from '@lib/axios';
import { ProductVariant } from '@typings/ProductVariant';

import { Form, FormValues } from './components/Form';

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useFetch<ProductVariant>(`/sms/product_variants/${id}`);

  async function handleSubmit(values: FormValues) {
    await axios.put(`/sms/product_variants/${id}`, values);
    toast.success('Product variant successfully updated');
    navigate('/sms/product_variants');
  }

  return (
    <Page loading={loading} headerDescription="Update product variant's title and features.">
      <Form productVariant={data} onSubmit={handleSubmit} />
    </Page>
  );
}

export default Edit;
