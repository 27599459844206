import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { ThemeProvider } from '@emotion/react';

import { ShopContext } from '@hooks/useCurrentShop';
import { InitialStateContext } from '@hooks/useInitialState';
import theme from 'styles/theme';
import { useFetch } from '@hooks/useFetch';
import { Shop } from '@typings/Shop';
import { CenteredLoading } from './Loading';

interface StateWrapperProps {
  element: React.ElementType;
}

const StateWrapper = (props: StateWrapperProps) => {
  const { element: Element, ...rest } = props;
  const { ...initialState } = (window as any).reactRailsInitialState;

  const { loading, data: shop, refetch } = useFetch<Shop>('/sms/shops/current');

  if (loading) {
    return <CenteredLoading />;
  }

  return (
    <InitialStateContext.Provider value={initialState}>
      <ShopContext.Provider value={{ ...shop, refetch }}>
        <ThemeProvider theme={theme}>
          {!isEmpty(initialState) && (
            <Element style={{ width: '100%' }} shop={shop} {...initialState} {...rest} />
          )}
        </ThemeProvider>
      </ShopContext.Provider>
    </InitialStateContext.Provider>
  );
};

export default StateWrapper;
