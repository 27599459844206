import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ChatForm, ChatHistory } from '@components/Chat/components';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { onboardingSlice } from '@redux/reducer/onboardingSlice';
import { DEFAULT_DELAY_TIME_MILLIS, sleep } from '@components/Onboarding/v2/onboarding';

const today = new Date();

const INBOUND_MESSAGE_INFO = {
  id: 1,
  body: 'info',
  deliveryFailureCode: null,
  createdAt: new Date().toDateString(),
  category: 'inbound',
  metadata: {},
};

const OUTBOUND_MESSAGE_INFO_RESPONSE = {
  id: 2,
  body:
    "Your next order of Vanilla Cereal for $10.00 is scheduled for Feb 22.\n\nReply 'Modify Order' to make changes :)",
  deliveryFailureCode: null,
  createdAt: new Date().toDateString(),
  category: 'outbound',
  metadata: {
    template_message: {
      id: 3,
      title: 'Help - Subscription Info',
    },
  },
};

const INBOUND_MESSAGE_MODIFY_ORDER = {
  id: 3,
  body: 'modify order',
  deliveryFailureCode: null,
  createdAt: new Date().toDateString(),
  category: 'inbound',
  metadata: {},
};

const OUTBOUND_MESSAGE_MODIFY_ORDER_RESPONSE = {
  id: 4,
  body:
    'Great! Reply with the corresponding number to make a change:\n\n1: Swap flavors\n2: Add a one-time purchase\n3: Skip or delay any order',
  deliveryFailureCode: null,
  createdAt: new Date().toDateString(),
  category: 'outbound',
  metadata: {
    template_message: {
      id: 3,
      title: 'Help - Subscription Info',
    },
  },
};

const INBOUND_MESSAGE_SKIP = {
  id: 5,
  body: '3',
  deliveryFailureCode: null,
  createdAt: new Date().toDateString(),
  category: 'inbound',
  metadata: {},
};

const OUTBOUND_MESSAGE_SKIP_RESPONSE = {
  id: 6,
  body: `Sure thing!\n\nYour upcoming orders has been skipped.\n\nYou'll receive your next shipment on ${new Date(
    today.setDate(today.getDate() + 30)
  ).toDateString()}`,
  deliveryFailureCode: null,
  createdAt: new Date().toDateString(),
  category: 'outbound',
  metadata: {
    template_message: {
      id: 3,
      title: 'Help - Subscription Info',
    },
  },
};

export const SimulatorView = () => {
  const onboardingState = useAppSelector((state) => state.onboarding);
  const dispatch = useAppDispatch();
  const [smsMessages, setSmsMessages] = useState([]);

  const simulateFirstResponse = async () => {
    setSmsMessages([INBOUND_MESSAGE_INFO]);
    await sleep(DEFAULT_DELAY_TIME_MILLIS);

    setSmsMessages([INBOUND_MESSAGE_INFO, OUTBOUND_MESSAGE_INFO_RESPONSE]);
    await sleep(DEFAULT_DELAY_TIME_MILLIS);
  };

  const simulateSecondResponse = async () => {
    setSmsMessages([
      INBOUND_MESSAGE_INFO,
      OUTBOUND_MESSAGE_INFO_RESPONSE,
      INBOUND_MESSAGE_MODIFY_ORDER,
    ]);
    await sleep(DEFAULT_DELAY_TIME_MILLIS);

    setSmsMessages([
      INBOUND_MESSAGE_INFO,
      OUTBOUND_MESSAGE_INFO_RESPONSE,
      INBOUND_MESSAGE_MODIFY_ORDER,
      OUTBOUND_MESSAGE_MODIFY_ORDER_RESPONSE,
    ]);
    await sleep(DEFAULT_DELAY_TIME_MILLIS);
  };

  const simulateThirdResponse = async () => {
    setSmsMessages([
      INBOUND_MESSAGE_INFO,
      OUTBOUND_MESSAGE_INFO_RESPONSE,
      INBOUND_MESSAGE_MODIFY_ORDER,
      OUTBOUND_MESSAGE_MODIFY_ORDER_RESPONSE,
      INBOUND_MESSAGE_SKIP,
    ]);
    await sleep(DEFAULT_DELAY_TIME_MILLIS);

    setSmsMessages([
      INBOUND_MESSAGE_INFO,
      OUTBOUND_MESSAGE_INFO_RESPONSE,
      INBOUND_MESSAGE_MODIFY_ORDER,
      OUTBOUND_MESSAGE_MODIFY_ORDER_RESPONSE,
      INBOUND_MESSAGE_SKIP,
      OUTBOUND_MESSAGE_SKIP_RESPONSE,
    ]);
    await sleep(DEFAULT_DELAY_TIME_MILLIS);
  };

  const simulateSendingMessages = async () => {
    await simulateFirstResponse();
    await simulateSecondResponse();
    await simulateThirdResponse();

    dispatch(onboardingSlice.actions.stopSimulate());
  };

  useEffect(() => {
    if (onboardingState.isSimulating) {
      simulateSendingMessages();
    }
  }, [onboardingState]);

  return (
    <>
      <Title>Simulator</Title>
      <SimulatorContainer>
        <Actions>
          <Select className="select-product-btn" placeholder="No product selected" isDisabled />
          <Select className="trigger-action-btn" placeholder="Trigger action..." isDisabled />
        </Actions>
        <ChatHistory style={{ height: 500 }} smsMessages={smsMessages} />
        <ChatForm disabled />
      </SimulatorContainer>
    </>
  );
};

const Actions = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-family: Greycliff;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #191d48;
  margin-bottom: 16px;
`;

const SimulatorContainer = styled.div`
  background-color: #ffffff;
  padding: 16px 21px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
`;
