import React, { ChangeEvent } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Search } from 'react-feather';
import styled from '@emotion/styled';

interface SearchBarProps {
  placeholder?: string;
  value: string;
  label: string;
  onSearchChange(event: ChangeEvent<HTMLInputElement>): void;
}

const SearchBar = (props: SearchBarProps) => {
  const { value, label, placeholder, onSearchChange } = props;

  return (
    <Form.Group controlId="search-bar">
      <Form.Label>
        <strong>{label}</strong>
      </Form.Label>
      <InputGroup>
        <SearchIconContainer>
          <InputGroup.Text>
            <Search size={20} />
          </InputGroup.Text>
        </SearchIconContainer>
        <FormControl
          name="search"
          placeholder={placeholder}
          value={value}
          onChange={onSearchChange}
        />
      </InputGroup>
    </Form.Group>
  );
};

const FormControl = styled(Form.Control)`
  border-radius: 5px;
  padding: 15px;
  background-color: #f6f6f7;
  box-shadow: 0 0 2px #9a9b9c;

  :focus {
    box-shadow: 0 0 3px #9a9b9c;
  }
`;

const SearchIconContainer = styled(InputGroup.Prepend)`
  .input-group-text {
    background: #f6f6f7;
    border: none;
    box-shadow: 0 0 2px #9a9b9c;
  }
`;

export default SearchBar;
