import React from 'react';

import { BaseApp } from '@components/BaseApp';

import { Router } from './Router';
import { Sidebar } from './Sidebar';

export function App(props) {
  return <BaseApp Router={Router} Sidebar={Sidebar} layout="replenishment" {...props} />;
}
