import React from 'react';

import { formatString } from '@utils/date';

import { ProductVariant as ProductVariantType } from '@typings/ProductVariant';
import { EditableProductTitle } from './EditableProductTitle';
import { Features } from './Features';
import { Actions } from './Actions';
import { Price } from './Price';

interface ProductVariantProps {
  productVariant: ProductVariantType;
  handleSearch(): void;
  replenishment?: boolean;
  ignoreDisabled?: boolean;
}

export function ProductVariant(props: ProductVariantProps) {
  const { productVariant, handleSearch, replenishment, ignoreDisabled = false } = props;

  return (
    <tr className="product-variant__row">
      <td style={{ width: '35%' }}>
        <div className="product-variant">
          <div className="product-variant__body pl-2">
            <h6 className="product-variant__title">
              <EditableProductTitle id={productVariant.id} title={productVariant.title} />
            </h6>
            <div className="product-variant__subtitle">
              <small>Variant ID: #{productVariant.shopifyId}</small>
            </div>
          </div>
        </div>
      </td>

      <td style={{ width: '8%' }}>
        <Price productVariant={productVariant} />
      </td>
      {!replenishment && (
        <td style={{ width: '24%' }}>
          <Features productVariant={productVariant} />
        </td>
      )}
      <td style={{ width: '13%' }}>{formatString(productVariant.createdAt, 'h:mm a M/d/yy')}</td>
      <td className="product__actions" style={{ width: '22%' }}>
        <Actions
          productVariant={productVariant}
          handleSearch={handleSearch}
          replenishment={replenishment}
          ignoreDisabled={ignoreDisabled}
        />
      </td>
    </tr>
  );
}
