import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from 'react-hook-form';

import { MultipleSelect } from '@components/Input/MultipleSelect';
import { IPage } from '@components/Layout/ViewPage';
import { CancelLink } from '@components/Router/CancelLink';
import axios from '@lib/axios';
import { RechargeCustomer } from '@typings/RechargeCustomer';
import { Segment } from '@typings/Segment';

const SEGMENTS_PATH = '/sms/segments';

interface FormComponentProps {
  segment?: Segment;
  segmentCustomers?: RechargeCustomer[];
  onSubmit(
    data: Segment,
    selectedCustomers: RechargeCustomer[],
    setLoading: (loading: boolean) => any,
    setErrors: (errors: any) => any
  ): Promise<any>;
}

const FormComponent = ({ segment, segmentCustomers, onSubmit }: FormComponentProps) => {
  const { register, handleSubmit } = useForm({
    defaultValues: segment,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [customersPage, setCustomersPage] = useState<IPage<RechargeCustomer>>();
  const [selectedCustomers, setSelectedCustomers] = useState(segmentCustomers || []);

  const searchCustomers = async (search: string, page: number) => {
    try {
      const { data } = await axios.get('/sms/recharge/customers', {
        params: { search, page },
      });

      setCustomersPage(data);
    } catch (e) {
      setErrors('It was not possible to load customers.');
    }
  };

  return (
    <Form
      onSubmit={handleSubmit((data) => onSubmit(data, selectedCustomers, setLoading, setErrors))}
    >
      <Card>
        <Card.Body>
          <Form.Group controlId="segments_name">
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" defaultValue="" {...register('name', { required: true })} />
          </Form.Group>
          <Form.Group controlId="segments_description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              name="description"
              as="textarea"
              rows={2}
              defaultValue=""
              {...register('description', { required: true })}
            />
          </Form.Group>
          <Form.Row>
            <Col xs={6}>
              <Form.Group controlId="segments_type">
                <Form.Label>Segment Type</Form.Label>
                <Form.Control as="select" custom>
                  <option>Custom</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Form.Row>
          <MultipleSelect
            label="Select Customers"
            placeholder="Customer's name, email or phone"
            onSearch={searchCustomers}
            options={customersPage?.data}
            paginationInfo={customersPage?.paginationInfo}
            onSelectedOptionsChange={setSelectedCustomers}
            renderOption={(option) => (
              <div>
                <h4>{option.name}</h4>
                <span>
                  {option.email} - {option.phone}
                </span>
              </div>
            )}
            selectedOptions={selectedCustomers}
          />
          {errors && <p className="text-danger mt-3">{errors}</p>}
        </Card.Body>
        <Card.Footer>
          <Row className="justify-content-end">
            <Col xs="auto">
              <CancelLink to={SEGMENTS_PATH} />
            </Col>
            <Col xs="auto">
              <Button type="submit" variant="primary" disabled={loading}>
                {loading ? 'Loading..' : segment ? 'Update' : 'Save'}
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default FormComponent;
