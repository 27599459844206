import React, { useState, useEffect } from 'react';
import ReactPhoneInput, { PhoneInputProps as ReactPhoneInputProps } from 'react-phone-input-2';

interface PhoneInputProps extends ReactPhoneInputProps {
  defaultValue?: string;
  handleChange?(phone: string, country: string): void;
}

export function PhoneInput(props: PhoneInputProps) {
  const { handleChange, defaultValue } = props;
  const [phone, setPhone] = useState(defaultValue?.replace('+', ''));

  function handlePhoneChange(phone, country) {
    setPhone(phone);

    handleChange?.(`+${phone}`, country);
  }

  useEffect(() => {
    document.querySelector('.selected-flag > div').classList.replace('0', 'us');
  }, []);

  return (
    <ReactPhoneInput
      {...props}
      placeholder="Enter your phone number"
      value={phone}
      onChange={handlePhoneChange}
    />
  );
}
