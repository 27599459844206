import { format as dateFnsFormat } from 'date-fns';

export function formatString(dateString: string, format = 'h:mm aa M/d/yy') {
  if (!dateString) return '-';
  let dx = new Date(dateString);
  let dy = new Date(dx.getFullYear(), dx.getMonth(), dx.getUTCDate());
  return dateFnsFormat(dy, format);
}

export default {
  formatString,
};
