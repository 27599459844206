import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'react-bootstrap';
import { ProSidebar, SidebarHeader, SidebarContent, Menu } from 'react-pro-sidebar';

import { Shop } from '@typings/Shop';

import { ActivationState } from '@hooks/useActivationState';
import { Logo } from './Logo';

interface BaseSidebarProps {
  activationState: ActivationState;
  shop: Shop;
  homeUrl: string;
}

export function BaseSidebar(props: PropsWithChildren<BaseSidebarProps>) {
  const { activationState, shop, homeUrl, children } = props;

  return (
    <Container>
      <Header>
        <Logo activationState={activationState} shop={shop} homeUrl={homeUrl} />
      </Header>
      <SidebarContent>
        <Menu>{children}</Menu>
      </SidebarContent>
    </Container>
  );
}

const Container = styled(ProSidebar)`
  z-index: 95 !important;

  .pro-inner-list-item {
    padding-left: 20px !important;
  }

  .pro-inner-list-item > div > ul {
    padding: 8px 0 !important;
  }

  .pro-icon-wrapper {
    margin: 0 10px !important;
  }

  .pro-inner-item {
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-radius: 3px;
  }

  .pro-arrow-wrapper {
    right: 10px !important;
  }
`;

const Header = styled(SidebarHeader)`
  border: 0 !important;
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-inner {
    color: white;
    background-color: #191d45;
    opacity: 1;
    font-weight: bold;
    border-radius: 3px;
  }

  .arrow::before {
    opacity: 1;
    border-right-color: #191d45;
  }
`;
